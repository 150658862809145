import React, { useEffect, useState } from 'react'
import * as ls from 'local-storage'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled'
import { useTranslation } from 'react-i18next'
import { API } from '../../providers/API'
import { Loading } from '../Loading/Loading'
import { Tab, TabsList } from './WeatherListing.styles'
import { HistoricalWeatherPremiumChart } from './HistoricalWeatherPremiumChart'
import { HistoricalWeatherPremiumChartBar } from './HistoricalWeatherPremiumChartBar'
import {
  addDataToTemplate, handleLeapYear, createYearTemplate, addDataToTemplateByYear,
} from './chartUtilities'
import { PremiumDaysCount } from './PremiumDaysCount'

interface FieldsItemProps {
  fieldId: string
}

export const HistoricalWeatherPremium: React.FC<FieldsItemProps> = ({
  fieldId,
}) => {
  const [weatherData, setWeatherData] = useState<any>()
  // const [snowData, setSnowData] = useState<any>()
  const [rainData, setRainData] = useState<any>()
  const [countDaysData, setCountDaysData] = useState<any>()
  const [isLoading, setIsLoading] = useState<any>()
  const { t } = useTranslation()

  useEffect(() => {
    setIsLoading(true)
    API.get(`/weatherHistoryPremium/${fieldId}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      // get the data for each year
      const weatherDataByYearOriginal: any = response.data.weatherHistoryGrouped
      // create a template containing all the days of the year
      const weatherDataByYearTemplate = createYearTemplate(weatherDataByYearOriginal)
      // add leap year placeholder
      handleLeapYear(weatherDataByYearTemplate, weatherDataByYearOriginal, true)
      // add the data to the template
      const weatherDataByYear = addDataToTemplateByYear(
        weatherDataByYearTemplate, weatherDataByYearOriginal,
      )

      // merge all the years into one array
      let weatherDataFull: any[] = []
      Object.keys(weatherDataByYear).forEach(
        (item:any) => {
          weatherDataFull = [...weatherDataFull, ...weatherDataByYear[item]]
        },
      )

      // const snowFullYearTemplate = createYearTemplateBarChart('snow', weatherDataByYear)
      const rainFullYearTemplate = createYearTemplate(weatherDataByYear, 'rain')

      // handleLeapYear(snowFullYearTemplate, weatherDataByYear)
      handleLeapYear(rainFullYearTemplate, weatherDataByYear)

      // addDataToTemplate(snowFullYearTemplate, 'snow', weatherDataFull)
      addDataToTemplate(rainFullYearTemplate, weatherDataFull, 'rain')

      const countData = { ...response.data.countData }

      setWeatherData(weatherDataByYear)
      // setSnowData(snowFullYearTemplate)
      setRainData(rainFullYearTemplate)
      setCountDaysData(countData)
      setIsLoading(false)
    })
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {weatherData && countDaysData && (
        <>
          <PremiumDaysCount data={countDaysData} />
          <TabsUnstyled defaultValue={0}>
            <TabsList>
              <Tab>{t('weather.premiumTabTemps')}</Tab>
              <Tab>{t('weather.premiumTabPrecipitation')}</Tab>
            </TabsList>
            <TabPanelUnstyled value={0}>
              <HistoricalWeatherPremiumChart weatherKey="airTemp" unit="degrees" title={t('weather.avgDailyTemp')} weatherData={weatherData} />
              <HistoricalWeatherPremiumChart weatherKey="temp_min" unit="degrees" title={t('weather.minDailyTemp')} weatherData={weatherData} />
              <HistoricalWeatherPremiumChart weatherKey="temp_max" unit="degrees" title={t('weather.maxDailyTemp')} weatherData={weatherData} />
            </TabPanelUnstyled>
            <TabPanelUnstyled value={1}>
              {/* <HistoricalWeatherPremiumChartBar
                unit="mm"
                title={t('weather.snowPrecipitation')}
                weatherData={snowData}
                totalData={weatherData}
              /> */}
              <HistoricalWeatherPremiumChartBar unit="mm" title={t('weather.rainPrecipitation')} weatherData={rainData} totalData={weatherData} />
            </TabPanelUnstyled>

          </TabsUnstyled>
        </>
      )}
    </>
  )
}
