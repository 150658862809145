import React, { useEffect, useState } from 'react'
import * as ls from 'local-storage'
import { useTranslation } from 'react-i18next'
import {
  ComposedChart, Bar, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer,
} from 'recharts'
import { API } from '../../providers/API'
import { Loading } from '../Loading/Loading'
import { DegreesSymbol } from './DegreesSymbol'
import { CustomChartTooltip } from './HistoricalWeatherChart.styles'
import { theme } from '../../theme/theme'

interface FieldsItemProps {
  fieldId: string
}

function toMonthName(monthNumber: number) {
  const date = new Date()
  date.setMonth(monthNumber - 1)

  return date.toLocaleString('en-US', {
    month: 'short',
  })
}

const CustomTooltip: React.FC<any> = ({
  active, payload, label,
}) => {
  const { t } = useTranslation()
  if (active && payload && payload.length) {
    return (
      <CustomChartTooltip>
        <strong>{label}</strong>
        <p>
          {`${t('weather.avgTemp')} : ${payload[0].value}`}
          <DegreesSymbol />
        </p>
        <p>
          {`${t('weather.minTemp')} : ${payload[0].payload.minTemp}`}
          <DegreesSymbol />
        </p>
        <p>
          {`${t('weather.maxTemp')} : ${payload[0].payload.maxTemp}`}
          <DegreesSymbol />
        </p>
        <p>
          {`${t('weather.rainPrecipitation')} : ${payload[0].payload.rain}`}
          mm
        </p>
      </CustomChartTooltip>
    )
  }

  return null
}

export const HistoricalWeatherChart: React.FC<FieldsItemProps> = ({
  fieldId,
}) => {
  const [weatherData, setWeatherData] = useState<any>()
  const [isLoading, setIsLoading] = useState<any>()

  useEffect(() => {
    setIsLoading(true)
    API.get(`/monthlyWeatherHistory/${fieldId}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      const historicalData = response.data.map((item: any) => ({
        name: `${toMonthName(item.month)} ${item.year}`,
        temp: parseInt(item.airTemp),
        rain: parseFloat(parseFloat(item.rain).toFixed(2)),
        minTemp: parseFloat(parseFloat(item.temp_min).toFixed(2)),
        maxTemp: parseFloat(parseFloat(item.temp_max).toFixed(2)),
      }))

      setWeatherData(historicalData)
      setIsLoading(false)
    })
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={300} debounce={1}>
        <ComposedChart
          data={weatherData}
          style={{ background: '#FFF', borderRadius: '10px' }}
          barCategoryGap="30%"
          margin={{
            top: 20,
            right: 20,
            left: 0,
            bottom: 10,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis dataKey="name" />
          <YAxis yAxisId="rainAxis" orientation="right" stroke={theme.colors.blueBGUI} />
          <YAxis yAxisId="tempAxis" orientation="left" stroke={theme.colors.agroOrange} />
          <Tooltip content={<CustomTooltip />} />
          <Line
            type="monotone"
            dataKey="temp"
            stroke={theme.colors.agroOrange}
            yAxisId="tempAxis"
          />
          <Bar dataKey="rain" yAxisId="rainAxis" barSize={5} fill={theme.colors.blueBGUI} />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  )
}
