import React from 'react'
import { useTranslation } from 'react-i18next'
import { DaysCountContainer } from './PremiumDaysCount.styles'
import { PremiumDaysCountItem } from './PremiumDaysCountItem'
import { theme } from '../../theme/theme'
import { IconMyMixes, IconSnowFlake, IconCloud } from '../../icons'

interface FieldsItemProps {
  data: any
}

export const PremiumDaysCount: React.FC<FieldsItemProps> = ({
  data,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <DaysCountContainer>
        <PremiumDaysCountItem dataItem={data.rainyDays} title={t('weather.rainyDays')} icon={<IconMyMixes size="100%" color={theme.colors.agroOrange} />} />
        <PremiumDaysCountItem dataItem={data.snowyDays} title={t('weather.snowyDays')} icon={<IconSnowFlake size="100%" color="#06C0E2" />} />
        <PremiumDaysCountItem dataItem={data.cloudyDays} title={t('weather.cloudyDays')} icon={<IconCloud size="100%" color="#A9A9A9" />} />
      </DaysCountContainer>
    </>
  )
}
