import React, { useEffect, useState } from 'react'
import * as ls from 'local-storage'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { DashboardWeatherItemData, FieldItem } from '../FieldsListing/Fields.types'
import {
  MainWeatherData, WeatherItem, WeatherTable,
} from './WeatherListing.styles'
import { FieldPolygonHeader } from '../FieldsListing/FieldPolygonHeader'
import { DegreesSymbol } from './DegreesSymbol'
import { API } from '../../providers/API'
import { Loading } from '../Loading/Loading'

interface FieldsItemProps {
  field: FieldItem
  type?: 'current' | 'daily'
  showDetailedInfo?: boolean
}

export const WeatherListingItem: React.FC<FieldsItemProps> = ({
  field, type, showDetailedInfo = true,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [weatherData, setWeatherData] = useState<DashboardWeatherItemData>()
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    setIsLoading(true)
    API.get(`/currentWeather/${field.id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setWeatherData(response.data)
      setIsLoading(false)
    })
  }, [])

  const goToDetails = () => {
    history.push(`/WeatherField/${field.id}`)
  }

  return (
    <WeatherItem
      link={type === 'current'}
      tabIndex={0}
      role="button"
      onKeyUp={() => {
        goToDetails()
      }}
      onClick={
      () => {
        goToDetails()
      }
    }
    >
      {showDetailedInfo && (
      <FieldPolygonHeader
        field={{
          coordinates: field.coordinates,
          name: field.name,
          id: field.id,
        }}
        key={field.id}
      />
      )}
      {isLoading || !weatherData ? (
        <Loading />
      ) : (
        <>
          <MainWeatherData fullHeight={!showDetailedInfo}>
            <div className="icon">
              <img src={`https://openweathermap.org/img/wn/${weatherData.weather[0].icon}@4x.png`} alt={weatherData.weather[0].description} />
            </div>
            <div className="temperature">
              <strong className="mainTemp">
                {weatherData.main.temp.toFixed(2)}
                <DegreesSymbol />
              </strong>
              <span>
                {t('weatherItem.feelsLike')}
                {' '}
                <span className="noBreak">
                  {weatherData.main.feels_like.toFixed(2)}
                  <DegreesSymbol />
                </span>
              </span>
              <span>{weatherData.weather[0].description}</span>
            </div>
          </MainWeatherData>
          {showDetailedInfo && (
          <WeatherTable>
            <div>
              {t('weatherItem.minTemp')}:
              {' '}
              {weatherData.main.temp_min.toFixed(2)}
              <DegreesSymbol />
            </div>
            <div>
              {t('weatherItem.maxTemp')}:
              {' '}
              {weatherData.main.temp_max.toFixed(2)}
              <DegreesSymbol />
            </div>
            <div>
              {t('weatherItem.humidity')}:
              {' '}
              {weatherData.main.humidity}
              %
            </div>
            <div>
              {t('weatherItem.pressure')}:
              {' '}
              {weatherData.main.pressure}
              {' '}
              hA
            </div>
            <div>
              {t('weatherItem.wind')}:
              {' '}
              {weatherData.wind.speed}
              {' '}
              m/s
            </div>
            <div>
              {t('weatherItem.direction')}:
              {' '}
              {weatherData.wind.deg}
              &deg;
            </div>

            <div>
              {t('weatherItem.cloudCover')}:
              {' '}
              {weatherData.clouds.all}
              %
            </div>
          </WeatherTable>
          )}
        </>
      )}
    </WeatherItem>
  )
}
