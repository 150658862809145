import React, { useContext } from 'react'
import Checkbox from '@mui/material/Checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaintBrush } from '@fortawesome/free-solid-svg-icons'
import { ControlsContainer } from './FieldMap.styles'
import { theme } from '../../theme/theme'
import { MapDataContext } from '../../providers/MapDataProvider'

export const ToggleFieldFill = ({ withZones }: {withZones?: boolean}) => {
  const {
    fieldFill,
    setFieldFill,
    zoneVisibility,
    setZoneVisibility,
    nonZonedFieldsVisibility,
    setNonZonedFieldsVisibility,
  } = useContext(MapDataContext)

  return (
    <ControlsContainer>
      <div>
        <Checkbox
          icon={<FontAwesomeIcon icon={faPaintBrush} color="#06C0E2" />}
          checkedIcon={<FontAwesomeIcon icon={faPaintBrush} color="#FFF" />}
          style={{
            borderRadius: '5px',
            padding: '5px',
            backgroundColor: fieldFill ? '#06C0E2' : '#FFF',
          }}
          size="small"
          checked={fieldFill}
          onChange={() => setFieldFill((value) => !value)}
        />
      </div>
      {withZones && setNonZonedFieldsVisibility && (
      <div>

        <Checkbox
          size="small"
          sx={{
            color: '#838383',
            '&.Mui-checked': {
              color: '#838383',
            },
            '& .MuiSvgIcon-root': {
              color: 'inherit',
            },
          }}
          style={{
            backgroundColor: '#FF0',
            borderRadius: '5px',
            padding: theme.space.xxs,
          }}
          checked={nonZonedFieldsVisibility}
          onChange={() => {
            setNonZonedFieldsVisibility((prevState: boolean) => !prevState)
          }}
        />

      </div>
      )}
      {withZones && setZoneVisibility && zoneVisibility?.map((item: any, index: number) => (
        <div key={item.id}>
          <Checkbox
            size="small"
            style={{
              backgroundColor: item.colour,
              borderRadius: '5px',
              padding: theme.space.xxs,
            }}
            checked={item.visible}
            onChange={() => {
              setZoneVisibility((prevState: any) => {
                const updatedVisibility = [...prevState]
                updatedVisibility[index].visible = !updatedVisibility[index].visible
                return updatedVisibility
              })
            }}
          />
        </div>
      ))}

    </ControlsContainer>
  )
}
