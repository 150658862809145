import { rgba } from 'polished'

import { colors } from './colors'

const shadowColor1: string = rgba(colors.black, 0.1)
const shadowColor2: string = rgba(colors.black, 0.06)
const shadowColor3: string = rgba(colors.black, 0.07)

export const shadows = {
  sm: `0 1px 5px 0 ${shadowColor1}, 0 3px 4px 0 ${shadowColor2}, 0 2px 4px 0 ${shadowColor3}`,
  md: `0 1px 10px 0 ${shadowColor1}, 0 4px 5px 0 ${shadowColor2}, 0 2px 4px 0 ${shadowColor3}`,
  lg: `0 4px 5px 0 ${shadowColor1}, 0 3px 14px 3px ${shadowColor2}, 0 8px 10px 1px ${shadowColor3}`,
  xl: `0 8px 10px 0 ${shadowColor1}, 0 6px 30px 5px ${shadowColor2}, 0 16px 24px 2px ${shadowColor3}`,
} as const

export type Shadow = keyof typeof shadows
