import React from 'react'

export const IconCredits = ({ size = '24', color = 'currentColor' }) => (
  <svg width={size} height={size} viewBox="0 0 39 39" fill={color} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_706_4331)">
      <path d="M15.044 29.4707C15.044 29.4707 10.4279 26.3705 9.91759 24.2377C9.40724 22.1049 9.40724 18.959 9.55958 18.3953C9.71193 17.8393 10.5727 15.8055 10.984 15.2951C11.3877 14.7848 13.7262 13.368 14.1299 13.109C14.5336 12.8576 14.8383 12.4463 16.4684 12.1416C18.0984 11.8369 28.2522 10.9229 28.2522 10.9229C28.2522 10.9229 26.8277 22.7523 26.3707 23.6207C25.9137 24.4814 25.251 26.2105 24.0322 27.2236C22.8135 28.2367 23.5219 28.5947 21.0844 29.1508C18.6469 29.7068 15.044 29.4707 15.044 29.4707Z" fill="#F26522" />
      <path d="M33.2871 5.71289C29.608 2.02617 24.7102 0 19.5 0C14.2898 0 9.39199 2.02617 5.71289 5.71289C2.03379 9.39961 0 14.2898 0 19.5C0 24.7102 2.02617 29.608 5.71289 33.2871C9.39961 36.9662 14.2898 39 19.5 39C24.7102 39 29.608 36.9738 33.2871 33.2871C36.9662 29.6004 39 24.7102 39 19.5C39 14.2898 36.9738 9.39199 33.2871 5.71289ZM19.5 37.4766C9.59004 37.4766 1.52344 29.41 1.52344 19.5C1.52344 9.59004 9.59004 1.52344 19.5 1.52344C29.41 1.52344 37.4766 9.59004 37.4766 19.5C37.4766 29.41 29.41 37.4766 19.5 37.4766Z" fill="white" />
      <path d="M23.7504 3.603C23.3467 3.49636 22.9278 3.74011 22.8211 4.14382C22.7145 4.54753 22.9582 4.96647 23.3619 5.07311C29.8746 6.81745 34.4221 12.7436 34.4221 19.5001C34.4221 27.7266 27.7266 34.4221 19.5 34.4221C11.2735 34.4221 4.57795 27.7266 4.57795 19.5001C4.57795 12.7055 9.1635 6.76413 15.7295 5.05788C16.1332 4.95124 16.377 4.53229 16.278 4.12858C16.1713 3.72487 15.7524 3.48112 15.3487 3.58015C8.11233 5.46921 3.06213 12.0124 3.06213 19.5001C3.06213 23.8952 4.776 28.0237 7.8762 31.1315C10.9764 34.2393 15.1125 35.9456 19.5076 35.9456C23.9028 35.9456 28.0313 34.2317 31.1391 31.1315C34.2469 28.0313 35.9532 23.8952 35.9532 19.5001C35.9455 12.0581 30.9334 5.52253 23.7504 3.603Z" fill="white" />
      <path d="M19.4695 4.57812H19.5C19.9189 4.57812 20.2617 4.23535 20.2617 3.81641C20.2617 3.39746 19.9189 3.05469 19.5 3.05469H19.4619C19.043 3.05469 18.7002 3.39746 18.7002 3.81641C18.7078 4.23535 19.0506 4.57812 19.4695 4.57812Z" fill="white" />
      <path d="M8.39409 20.7264C8.39409 23.2782 9.39194 25.6776 11.2048 27.4829C11.2734 27.5514 11.3496 27.62 11.4181 27.6885C11.1896 28.4655 11.0753 29.2805 11.0753 30.1184C11.0753 30.5374 11.4181 30.8801 11.8371 30.8801C12.256 30.8801 12.5988 30.5374 12.5988 30.1184C12.5988 29.6462 12.6445 29.1815 12.7359 28.7245C14.2746 29.7376 16.0798 30.286 17.9689 30.286H17.9841C20.5283 30.286 22.9277 29.2958 24.7253 27.4981C26.264 25.9594 27.239 23.9104 27.4675 21.7471V21.7395L28.831 10.7555C28.8615 10.5194 28.7777 10.2909 28.6177 10.1233C28.4501 9.95572 28.2216 9.87955 27.9855 9.9024L17.0167 11.2126C16.9939 11.2126 16.9787 11.2202 16.9558 11.2202C14.7849 11.4411 12.7435 12.4161 11.1972 13.9624C9.3767 15.7753 8.38647 18.1747 8.39409 20.7264ZM17.1691 12.736C17.1996 12.736 17.23 12.7284 17.2681 12.7208L27.1933 11.5325L25.9517 21.5186C25.9517 21.5338 25.9441 21.5491 25.9441 21.5643C25.7613 23.3924 24.9386 25.1215 23.6437 26.4241C22.1279 27.9323 20.1246 28.7702 17.9765 28.7702H17.9689C16.2398 28.7702 14.5869 28.2217 13.2158 27.2087C13.5662 26.4241 14.0613 25.7081 14.6859 25.0835L14.922 24.8473H21.0996C21.5185 24.8473 21.8613 24.5046 21.8613 24.0856C21.8613 23.6667 21.5185 23.3239 21.0996 23.3239H16.4378L18.9134 20.8483H23.08C23.499 20.8483 23.8417 20.5055 23.8417 20.0866C23.8417 19.6676 23.499 19.3249 23.08 19.3249H20.4369L24.7939 14.9678C25.091 14.6708 25.091 14.1909 24.7939 13.8938C24.4968 13.5967 24.0169 13.5967 23.7199 13.8938L19.3628 18.2508V15.6077C19.3628 15.1887 19.0201 14.846 18.6011 14.846C18.1822 14.846 17.8394 15.1887 17.8394 15.6077V19.7743L15.3638 22.2499V19.4696C15.3638 19.0506 15.021 18.7079 14.6021 18.7079C14.1831 18.7079 13.8404 19.0506 13.8404 19.4696V23.7733L13.6042 24.0094C12.9644 24.6493 12.4388 25.3729 12.0351 26.1499C10.6716 24.6645 9.92514 22.7526 9.91752 20.7264C9.91752 18.5784 10.7478 16.5674 12.2636 15.0516C13.5738 13.7339 15.3181 12.9112 17.1691 12.736Z" fill="white" />
      <path d="M14.5945 17.3672C14.7925 17.3672 14.9906 17.2834 15.1353 17.1463C15.28 17.0016 15.3562 16.8111 15.3562 16.6055C15.3562 16.3998 15.2724 16.2094 15.1353 16.0646C14.9906 15.9199 14.8001 15.8438 14.5945 15.8438C14.3964 15.8438 14.1984 15.9275 14.0537 16.0646C13.9089 16.2094 13.8328 16.3998 13.8328 16.6055C13.8328 16.8111 13.9166 17.0016 14.0537 17.1463C14.1984 17.2834 14.3964 17.3672 14.5945 17.3672Z" fill="white" />
      <path d="M8.39409 20.7264C8.39409 23.2782 9.39194 25.6776 11.2048 27.4829C11.2734 27.5514 11.3496 27.62 11.4181 27.6885C11.1896 28.4655 11.0753 29.2805 11.0753 30.1184C11.0753 30.5374 11.4181 30.8801 11.8371 30.8801C12.256 30.8801 12.5988 30.5374 12.5988 30.1184C12.5988 29.6462 12.6445 29.1815 12.7359 28.7245C14.2746 29.7376 16.0798 30.286 17.9689 30.286H17.9841C20.5283 30.286 22.9277 29.2958 24.7253 27.4981C26.264 25.9594 27.239 23.9104 27.4675 21.7471V21.7395L28.831 10.7555C28.8615 10.5194 28.7777 10.2909 28.6177 10.1233C28.4501 9.95572 28.2216 9.87955 27.9855 9.9024L17.0167 11.2126C16.9939 11.2126 16.9787 11.2202 16.9558 11.2202C14.7849 11.4411 12.7435 12.4161 11.1972 13.9624C9.3767 15.7753 8.38647 18.1747 8.39409 20.7264ZM17.1691 12.736C17.1996 12.736 17.23 12.7284 17.2681 12.7208L27.1933 11.5325L25.9517 21.5186C25.9517 21.5338 25.9441 21.5491 25.9441 21.5643C25.7613 23.3924 24.9386 25.1215 23.6437 26.4241C22.1279 27.9323 20.1246 28.7702 17.9765 28.7702H17.9689C16.2398 28.7702 14.5869 28.2217 13.2158 27.2087C13.5662 26.4241 14.0613 25.7081 14.6859 25.0835L14.922 24.8473H21.0996C21.5185 24.8473 21.8613 24.5046 21.8613 24.0856C21.8613 23.6667 21.5185 23.3239 21.0996 23.3239H16.4378L18.9134 20.8483H23.08C23.499 20.8483 23.8417 20.5055 23.8417 20.0866C23.8417 19.6676 23.499 19.3249 23.08 19.3249H20.4369L24.7939 14.9678C25.091 14.6708 25.091 14.1909 24.7939 13.8938C24.4968 13.5967 24.0169 13.5967 23.7199 13.8938L19.3628 18.2508V15.6077C19.3628 15.1887 19.0201 14.846 18.6011 14.846C18.1822 14.846 17.8394 15.1887 17.8394 15.6077V19.7743L15.3638 22.2499V19.4696C15.3638 19.0506 15.021 18.7079 14.6021 18.7079C14.1831 18.7079 13.8404 19.0506 13.8404 19.4696V23.7733L13.6042 24.0094C12.9644 24.6493 12.4388 25.3729 12.0351 26.1499C10.6716 24.6645 9.92514 22.7526 9.91752 20.7264C9.91752 18.5784 10.7478 16.5674 12.2636 15.0516C13.5738 13.7339 15.3181 12.9112 17.1691 12.736Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_706_4331">
        <rect width="39" height="39" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
