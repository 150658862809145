/* eslint-disable no-tabs */
import React from 'react'

export const IconSoilAnalysis = ({ size = '24', color = 'currentColor' }) => (
  <svg width={size} height={size} viewBox="0 0 223 223" fill={color} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_867_5555)">
      <mask id="mask0_867_5555" maskUnits="userSpaceOnUse" x="-38" y="-38" width="299" height="299">
        <path d="M260.183 260.139H-37.1503V-37.1939H260.183V260.139Z" fill="white" />
      </mask>
      <g mask="url(#mask0_867_5555)">
        <path d="M66.3706 198.036C65.6302 198.036 64.8898 197.774 64.2801 197.295C62.8864 196.119 62.7122 194.072 63.8446 192.679C64.4107 192.025 65.064 190.893 65.064 189.499C65.064 186.233 61.7105 183.576 57.6165 183.576C53.5226 183.576 50.1691 186.233 50.1691 189.499C50.1691 190.937 50.8223 192.025 51.3885 192.722C52.5209 194.116 52.3467 196.163 50.953 197.339C49.5593 198.471 47.5124 198.297 46.3364 196.903C44.5508 194.769 43.6362 192.2 43.6362 189.543C43.6362 182.705 49.9077 177.087 57.6165 177.087C65.3254 177.087 71.5969 182.618 71.5969 189.499C71.5969 192.156 70.6823 194.726 68.8967 196.86C68.2434 197.644 67.3288 198.036 66.3706 198.036Z" />
        <path d="M111.299 178.888C101.281 178.888 93.4855 173.008 93.4855 165.517C93.4855 162.643 94.6615 159.899 96.8826 157.59C98.1457 156.284 100.193 156.24 101.499 157.503C102.806 158.766 102.849 160.813 101.586 162.12C100.889 162.86 100.018 164.036 100.018 165.474C100.018 169.132 105.288 172.311 111.299 172.311C117.309 172.355 122.622 169.132 122.622 165.517C122.622 164.036 121.795 162.86 121.054 162.164C119.791 160.857 119.835 158.81 121.141 157.547C122.448 156.284 124.495 156.327 125.758 157.634C127.979 159.855 129.155 162.599 129.155 165.517C129.155 173.008 121.316 178.888 111.299 178.888Z" />
        <path d="M112.662 198.051H5.6097C3.34497 198.051 1.29799 196.701 0.426943 194.61C-0.444108 192.52 0.0349712 190.124 1.64642 188.513L32.4816 157.547C33.7447 156.284 35.8352 156.284 37.0982 157.547C38.3612 158.81 38.3612 160.9 37.0982 162.163L7.87443 191.518H112.662C114.448 191.518 115.928 192.999 115.928 194.784C115.928 196.57 114.448 198.051 112.662 198.051Z" />
        <path d="M157.146 198.042H127.879C126.093 198.042 124.612 196.561 124.612 194.776C124.612 192.99 126.093 191.509 127.879 191.509H157.146C157.669 191.509 158.191 191.292 158.54 190.943L215.115 134.151H188.199C186.414 134.151 184.933 132.67 184.933 130.884C184.933 129.099 186.414 127.618 188.199 127.618H217.379C219.644 127.618 221.691 128.968 222.562 131.058C223.433 133.149 222.954 135.544 221.343 137.156L163.156 195.516C161.589 197.128 159.411 198.042 157.146 198.042Z" />
        <path d="M46.672 163.121C45.8445 163.121 45.017 162.816 44.3637 162.163L20.976 138.775C19.7129 137.512 19.7129 135.422 20.976 134.159C22.239 132.896 24.3295 132.896 25.5925 134.159L48.9803 157.547C50.2433 158.81 50.2433 160.9 48.9803 162.163C48.327 162.816 47.4995 163.121 46.672 163.121Z" />
        <path d="M74.1657 163.122C73.3382 163.122 72.5107 162.817 71.8574 162.164L34.7942 125.1C33.5312 123.837 33.5312 121.747 34.7942 120.484C36.0572 119.221 38.1477 119.221 39.4108 120.484L76.474 157.547C77.737 158.81 77.737 160.901 76.474 162.164C75.8207 162.817 74.9932 163.122 74.1657 163.122Z" />
        <path d="M101.665 163.122C100.837 163.122 100.01 162.817 99.3564 162.164L62.2932 125.1C61.0302 123.837 61.0302 121.747 62.2932 120.484C63.5562 119.221 65.6468 119.221 66.9098 120.484L103.973 157.547C105.236 158.81 105.236 160.901 103.973 162.164C103.32 162.817 102.492 163.122 101.665 163.122Z" />
        <path d="M129.164 163.122C128.336 163.122 127.509 162.817 126.855 162.164L89.7923 125.1C88.5292 123.837 88.5292 121.747 89.7923 120.484C91.0553 119.221 93.1458 119.221 94.4088 120.484L131.472 157.547C132.735 158.81 132.735 160.901 131.472 162.164C130.819 162.817 129.991 163.122 129.164 163.122Z" />
        <path d="M156.658 163.122C155.831 163.122 155.003 162.817 154.35 162.164L117.287 125.1C116.024 123.837 116.024 121.747 117.287 120.484C118.55 119.221 120.64 119.221 121.904 120.484L158.967 157.547C160.23 158.81 160.23 160.901 158.967 162.164C158.313 162.817 157.486 163.122 156.658 163.122Z" />
        <path d="M171.723 150.692C170.896 150.692 170.068 150.387 169.415 149.734L144.808 125.127C143.545 123.864 143.545 121.773 144.808 120.51C146.071 119.247 148.161 119.247 149.424 120.51L174.032 145.117C175.295 146.38 175.295 148.471 174.032 149.734C173.378 150.387 172.551 150.692 171.723 150.692Z" />
        <path d="M185.447 136.912C184.619 136.912 183.792 136.607 183.138 135.954L164.629 117.444C163.366 116.181 163.366 114.09 164.629 112.827C165.892 111.564 167.982 111.564 169.245 112.827L187.755 131.337C189.018 132.6 189.018 134.691 187.755 135.954C187.102 136.607 186.274 136.912 185.447 136.912Z" />
        <path d="M199.17 123.145C198.343 123.145 197.515 122.84 196.862 122.187L178.352 103.677C177.089 102.414 177.089 100.323 178.352 99.0602C179.615 97.7972 181.705 97.7972 182.969 99.0602L201.478 117.57C202.741 118.833 202.741 120.924 201.478 122.187C200.825 122.84 199.998 123.145 199.17 123.145Z" />
        <path d="M157.151 163.126H5.58766C3.32292 163.126 1.27595 161.776 0.404903 159.686C-0.466148 157.595 0.012925 155.2 1.62437 153.588L34.5937 120.532C35.8567 119.269 37.9472 119.269 39.2102 120.532C40.4733 121.795 40.4733 123.885 39.2102 125.148L7.85238 156.637H157.151C157.673 156.637 158.196 156.419 158.544 156.071L215.119 99.2346H186.069C184.284 99.2346 182.803 97.7538 182.803 95.9682C182.803 94.1825 184.284 92.7017 186.069 92.7017H217.384C219.648 92.7017 221.695 94.0519 222.567 96.1424C223.438 98.2329 222.958 100.628 221.347 102.24L163.161 160.6C161.593 162.212 159.415 163.126 157.151 163.126Z" />
        <path d="M157.146 126.076H5.58344C3.31871 126.076 1.27174 124.726 0.400692 122.636C-0.470359 120.545 0.00871353 118.15 1.62016 116.538L42.8644 75.1632C44.1275 73.9002 46.218 73.9002 47.481 75.1632C48.744 76.4263 48.744 78.5168 47.481 79.7798L7.84818 119.543H157.146C157.669 119.543 158.192 119.326 158.54 118.977L215.115 62.1846H116.207C114.421 62.1846 112.941 60.7038 112.941 58.9181C112.941 57.1325 114.421 55.6517 116.207 55.6517H217.38C219.644 55.6517 221.691 57.0018 222.562 59.0923C223.433 61.1829 222.954 63.5782 221.343 65.1897L163.157 123.55C161.589 125.162 159.411 126.076 157.146 126.076Z" />
        <path d="M100.964 62.1846H84.0651C82.2795 62.1846 80.7987 60.7038 80.7987 58.9181C80.7987 57.1325 82.2795 55.6517 84.0651 55.6517H100.964C102.749 55.6517 104.23 57.1325 104.23 58.9181C104.23 60.7038 102.749 62.1846 100.964 62.1846Z" />
        <path d="M80.3118 126.075C79.4843 126.075 78.6568 125.77 78.0035 125.117C76.7405 123.854 76.7405 121.763 78.0035 120.5L141.808 56.6086C143.071 55.3456 145.162 55.3456 146.425 56.6086C147.688 57.8716 147.688 59.9622 146.425 61.2252L82.6201 125.117C82.0104 125.77 81.1829 126.075 80.3118 126.075Z" />
        <path d="M191.161 94.1257H76.4433C74.6577 94.1257 73.1769 92.6449 73.1769 90.8593C73.1769 89.0736 74.6577 87.5928 76.4433 87.5928H191.161C192.946 87.5928 194.427 89.0736 194.427 90.8593C194.427 92.6449 192.946 94.1257 191.161 94.1257Z" />
        <path d="M53.9443 94.1257H31.8196C30.0339 94.1257 28.5531 92.6449 28.5531 90.8593C28.5531 89.0736 30.0339 87.5928 31.8196 87.5928H53.9443C55.7299 87.5928 57.2107 89.0736 57.2107 90.8593C57.2107 92.6449 55.7299 94.1257 53.9443 94.1257Z" />
        <path d="M65.3035 103.517C64.6938 103.517 64.1276 103.342 63.605 103.038C62.6468 102.471 40.4786 88.6652 40.4786 69.5456C40.4786 55.8701 51.628 44.7207 65.3035 44.7207C78.979 44.7207 90.1285 55.8701 90.1285 69.5456C90.1285 88.0119 68.0038 102.384 67.0456 102.994C66.523 103.342 65.9133 103.517 65.3035 103.517ZM65.3035 51.2535C55.1993 51.2535 47.0115 59.4414 47.0115 69.5456C47.0115 82.2194 60.3821 92.8462 65.26 96.2869C70.1379 92.7591 83.5956 81.8274 83.5956 69.5456C83.5956 59.4414 75.4077 51.2535 65.3035 51.2535ZM65.3035 79.9982C59.5546 79.9982 54.8509 75.2945 54.8509 69.5456C54.8509 63.7967 59.5546 59.093 65.3035 59.093C71.0525 59.093 75.7561 63.7967 75.7561 69.5456C75.7561 75.2945 71.0525 79.9982 65.3035 79.9982ZM65.3035 65.6259C63.1259 65.6259 61.3838 67.368 61.3838 69.5456C61.3838 71.7232 63.1259 73.4653 65.3035 73.4653C67.4812 73.4653 69.2233 71.7232 69.2233 69.5456C69.2233 67.368 67.4812 65.6259 65.3035 65.6259Z" />
        <path d="M167.2 79.3457C165.414 79.3457 163.933 77.8649 163.933 76.0793V47.5088C163.933 45.7231 165.414 44.2423 167.2 44.2423C168.985 44.2423 170.466 45.7231 170.466 47.5088V76.0793C170.466 77.8649 168.985 79.3457 167.2 79.3457Z" />
        <path d="M167.212 52.1309H163.423C154.146 52.1309 146.568 44.5527 146.568 35.2325V30.1368C146.568 28.656 147.221 27.2188 148.31 26.2606C149.399 25.2589 150.88 24.8234 152.36 24.9976C162.682 26.2606 170.435 35.0583 170.435 45.4673V48.8644C170.478 50.6501 168.997 52.1309 167.212 52.1309ZM153.101 31.7483V35.2325C153.101 40.9379 157.717 45.5544 163.423 45.5544H163.945V45.4673C163.945 38.8909 159.372 33.2291 153.101 31.7483Z" />
        <path d="M170.977 52.1309H167.188C165.402 52.1309 163.921 50.6501 163.921 48.8644V45.5109C163.921 35.1018 171.717 26.3042 181.996 25.0412C183.476 24.867 184.957 25.3025 186.046 26.3042C187.178 27.3059 187.788 28.6996 187.788 30.1804V35.276C187.832 44.5527 180.254 52.1309 170.977 52.1309ZM170.454 45.598H170.977C176.682 45.598 181.299 40.9814 181.299 35.276V31.7918C175.027 33.2726 170.454 38.9344 170.454 45.5109V45.598Z" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_867_5555">
        <rect width="223" height="223" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
