import React, { useEffect, useState } from 'react'
import * as ls from 'local-storage'
import { useTranslation } from 'react-i18next'

import { FieldItem } from '../FieldsListing/Fields.types'
import {
  HeadContent, MainWeatherData, WeatherItem, WeatherTable,
} from './WeatherListing.styles'
import { API } from '../../providers/API'
import { Loading } from '../Loading/Loading'
import { DegreesSymbol } from './DegreesSymbol'
import { theme } from '../../theme/theme'

interface FieldsItemProps {
  field: FieldItem
}

export const WeatherListingItemFlat: React.FC<FieldsItemProps> = ({
  field,
}) => {
  const [weatherData, setWeatherData] = useState<any>()
  const [isLoading, setIsLoading] = useState<any>()
  const { t } = useTranslation()

  useEffect(() => {
    setIsLoading(true)
    API.get(`/currentWeather/${field.id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setWeatherData(response.data)
      setIsLoading(false)
    })
  }, [])

  return (
    <WeatherItem flatItem>
      <HeadContent>
        <div style={{
          backgroundColor: theme.colors.blueBGUILight, padding: theme.space.md, borderRadius: '5px', marginBottom: theme.space.md,
        }}
        >
          {t('weatherItem.theWeatherNow')}:
          {' '}
          <strong>{weatherData?.dt}</strong>
        </div>

        {isLoading ? (
          <Loading />
        ) : (
          <MainWeatherData>
            <div className="icon">
              <img src={`https://openweathermap.org/img/wn/${weatherData?.weather[0].icon}@4x.png`} alt={weatherData?.weather[0].description} />
            </div>
            <div className="temperature">
              <strong className="mainTemp">
                {parseFloat(weatherData?.main.temp).toFixed(2)}
                <DegreesSymbol />
              </strong>
              <span>
                {t('weatherItem.feelsLike')}
                {' '}
                <span className="noBreak">
                  {parseFloat(weatherData?.main.feels_like).toFixed(2)}
                  <DegreesSymbol />
                </span>
              </span>
              <span>{weatherData?.weather[0].description}</span>
            </div>
          </MainWeatherData>
        )}
      </HeadContent>
      {!isLoading && (
      <WeatherTable flatItem>
        <div className="row">
          <div>
            {t('weatherItem.minTemp')}:
            {' '}
            {parseFloat(weatherData?.main.temp_min).toFixed(2)}
            <DegreesSymbol />
          </div>
          <div>
            {t('weatherItem.maxTemp')}:
            {' '}
            {parseFloat(weatherData?.main.temp_max).toFixed(2)}
            <DegreesSymbol />
          </div>
        </div>
        <div className="row">
          <div>
            {t('weatherItem.humidity')}:
            {' '}
            {weatherData?.main.humidity}
            %
          </div>
          <div>
            {t('weatherItem.pressure')}:
            {' '}
            {weatherData?.main.pressure}
            {' '}
            hA
          </div>
        </div>
        <div className="row">
          <div>
            {t('weatherItem.wind')}:
            {' '}
            {weatherData?.wind.speed}
            {' '}
            m/s
          </div>
          <div>
            {t('weatherItem.direction')}:
            {' '}
            {weatherData?.wind.deg}
            &deg;
          </div>
        </div>
        <div className="row">
          <div>
            {t('weatherItem.cloudCover')}:
            {' '}
            {weatherData?.clouds.all}
            %
          </div>
        </div>
      </WeatherTable>
      )}
    </WeatherItem>
  )
}
