import styled, { css } from 'styled-components'
import { theme } from '../../theme/theme'

export const StatRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ValueHolder = styled.span`
  display: flex;
  align-items: center;
`

export const NvdiColorSwatch = styled.span<{ color: string, noData?: boolean }>(
  ({ color, noData = false }) => css`
    display: inline-block;
    margin-left: ${noData ? '0px' : theme.space.md};
    background: ${color};
    width: ${noData ? '0px' : '30px'};
    height: 30px;
    border-radius: 10px;
`,
)
