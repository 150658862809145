import React from 'react'
import { SimpleBox, SimpleGrid } from '../../../App.styles'
import { Title } from './SoilGroupsContainer.styles'
import { theme } from '../../../theme/theme'

interface SoilGroupsContainerProps {
  title: string
  titleColor: string
  children: React.ReactNode
}

export const SoilGroupsContainer: React.FC<SoilGroupsContainerProps> = (
  { title, titleColor, children },
) => (
  <>
    <Title style={{ backgroundColor: titleColor }}>{title}</Title>
    <SimpleBox style={{ marginTop: `-${theme.space.md}`, marginBottom: theme.space.lg }}>
      <SimpleGrid>
        {children}
      </SimpleGrid>
    </SimpleBox>
  </>
)
