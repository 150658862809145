import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as ls from 'local-storage'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faCancel } from '@fortawesome/free-solid-svg-icons'
import { CropRotationItem } from './CropRotation.types'
import { CashCropOptionsTypes } from '../../utils/useCashCropsData'
import { AddFormHeading, EditCropRotationItem } from './CropRotation.styles'
import { API } from '../../providers/API'
import { theme } from '../../theme/theme'
import { formatDateStrings } from '../../utils/formatDateStrings'

export const CropRotationEditForm = (
  {
    rowData, cropType,
    cashCropOptions, replaceRowData, reloadCropRotationData, fieldId, userId, toggleAddForm,
  }: {
    rowData: CropRotationItem | null
    cropType?: 'prevCrop' | 'nextCrop'
    cashCropOptions: CashCropOptionsTypes[]
    replaceRowData?: ({ newData, cropTypes } : {newData: CropRotationItem, cropTypes: 'nextCrop' | 'previousCrops'}) => void
    reloadCropRotationData?: () => void
    fieldId?: number
    userId?: number
    toggleAddForm?: () => void
  },
) => {
  const transformDateForJS = (dateString: string) => {
    const parts = dateString.split('-')
    return `${parseInt(parts[2])}-${parseInt(parts[1])}-${parseInt(parts[0])}`
  }

  const { t } = useTranslation()
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [seedingDate, setSeedingDate] = React.useState<Date>(
    new Date(rowData?.seedingDate ? transformDateForJS(rowData?.seedingDate) : new Date()),
  )
  const [harvestDate, setHarvestDate] = React.useState<Date>(
    new Date(rowData?.harvestDate ? transformDateForJS(rowData?.harvestDate) : new Date()),
  )

  const initialCashCropOption = cashCropOptions.find(
    (option: CashCropOptionsTypes) => option.id === rowData?.cropId,
  ) || null

  const ValidationSchema = Yup.object().shape({
    cashCrop: Yup.number().required('Моля въведете култура'),
  })

  return (
    <Formik
      initialValues={{
        cashCrop: initialCashCropOption?.id ?? '',
        seedingDate,
        harvestDate,
      }}
      validationSchema={ValidationSchema}
      enableReinitialize
      onSubmit={(values) => {
        setIsSaving(true)
        const dataToSave = {
          ...rowData,
          userId: rowData?.userId ?? userId,
          fieldId: rowData?.fieldId ?? fieldId,
          cropId: values.cashCrop,
          seedingDate: formatDateStrings(values.seedingDate),
          harvestDate: formatDateStrings(values.harvestDate),
          type: cropType ?? rowData?.type,
        }
        API.post('/cropRotationAdd', dataToSave, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(
          ({ data }: {data: CropRotationItem}) => {
            if (reloadCropRotationData) {
              reloadCropRotationData()
            }
            if (toggleAddForm) {
              toggleAddForm()
            }
            if (replaceRowData) {
              const cropTypeToReplace = rowData?.type === 'prevCrop' ? 'previousCrops' : 'nextCrop'
              replaceRowData({ newData: data, cropTypes: cropTypeToReplace })
            }
            setIsSaving(false)
          },
        )
      }}
    >
      {({
        handleSubmit, errors, setFieldValue, values,
      }) => (
        <form onSubmit={handleSubmit}>
          <AddFormHeading>{rowData ? t('cropRotation.editRowTitle') : t('cropRotation.addRowTitle')}</AddFormHeading>
          <EditCropRotationItem>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                // @ts-ignore
                label={t('cropRotation.seedingDate')}
                inputFormat="dd/MM/yyyy"
                value={values.seedingDate}
                onChange={(newValue) => {
                  setSeedingDate(newValue!)
                  setFieldValue('seedingDate', newValue)
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                  />
                )}
              />
              <DatePicker
                // @ts-ignore
                label={t('cropRotation.harvestDate')}
                inputFormat="dd/MM/yyyy"
                value={values.harvestDate}
                onChange={(newValue) => {
                  setHarvestDate(newValue!)
                  setFieldValue('harvestDate', newValue)
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                  />
                )}
              />

            </LocalizationProvider>
          </EditCropRotationItem>
          <Autocomplete
            disablePortal
            options={cashCropOptions}
            value={initialCashCropOption}
            renderInput={(params) => (
              <TextField
                {...params}
                name="cashCrop"
                label={t('cropRotation.cropName')}
                error={!!errors.cashCrop}
                helperText={errors.cashCrop ? errors.cashCrop : ''}
              />
            )}
            fullWidth
            onChange={(event, newValue: CashCropOptionsTypes | null) => {
              setFieldValue('cashCrop', newValue?.id)
            }}
          />

          <LoadingButton
            variant="contained"
            type="button"
            color="primary"
            loading={isSaving}
            loadingPosition="start"
            endIcon={<FontAwesomeIcon icon={faAnglesRight} />}
            onClick={() => handleSubmit()}
          >
            {t('general.save')}
          </LoadingButton>
          <Button
            variant="contained"
            type="button"
            color="error"
            onClick={() => {
              if (toggleAddForm) {
                toggleAddForm()
              }
            }}
            endIcon={<FontAwesomeIcon icon={faCancel} />}
            style={{ marginLeft: theme.space.sm }}
          >
            {t('general.cancel')}
          </Button>
        </form>
      )}
    </Formik>
  )
}
