import React from 'react'
import { useTranslation } from 'react-i18next'
import { DegreesSymbol } from '../WeatherListing/DegreesSymbol'
import { SoilDataRows } from './NdviField.styles'

export const FieldSoilData: React.FC<{soilData: any, withMargin?: boolean}> = ({
  soilData, withMargin = false,
}) => {
  const { t } = useTranslation()

  return (
    <SoilDataRows withMargin={withMargin}>
      <div className="statsRow">
        <span>{t('satSoilHistory.t0')}</span>
        <span className="statValue">{soilData.t0.toFixed(1)}<DegreesSymbol /></span>
      </div>
      <div className="statsRow">
        <span>{t('satSoilHistory.t10')}</span>
        <span className="statValue">{soilData.t10.toFixed(1)}<DegreesSymbol /></span>
      </div>
      <div className="statsRow">
        <span>{t('satSoilHistory.moisture')}</span>
        <span className="statValue">{(soilData.moisture * 100).toFixed(1)}%</span>
      </div>
    </SoilDataRows>
  )
}
