import React from 'react'

export const IconMyMixes = ({ size = '24', color = 'currentColor' }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill={color} xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M28.4,20.3l-2.1-1.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.3-0.1-0.4-0.2c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2,0-0.4 c0-0.1,0-0.2,0-0.2c1.1-0.4,1.6-1.6,1.2-2.7c-0.4-1.1-1.5-1.7-2.6-1.4c-0.9,0.2-1.5,1-1.6,1.9c-0.1,0.9,0.5,1.7,1.4,2.1 c0,0,0,0.1,0,0.1L22,16.7c-0.5-0.3-0.9-0.5-1.4-0.8c-0.4-0.2-0.9-0.1-1.1,0.3c-0.2,0.3-0.1,0.6,0,0.8l-0.3,0.2 c-1.4,0.8-2.9,1.6-4.3,2.4c-0.7-0.4-1.5-0.8-2.2-1.2L10.4,17c0.2-0.2,0.2-0.5,0-0.8c-0.2-0.3-0.5-0.4-0.9-0.3 c-0.1,0-0.2,0.1-0.3,0.2L5.7,18c0-0.1,0-0.1,0-0.2c1.1-0.4,1.6-1.6,1.2-2.7c-0.4-1.1-1.5-1.7-2.6-1.4c-0.9,0.2-1.5,1-1.5,1.9 c-0.1,1,0.5,1.8,1.4,2.2c0,0.3,0,0.7,0,1.1c-0.9,0.5-1.8,1-2.7,1.5c-0.3,0.2-0.5,0.4-0.5,0.7c0,0.3,0,0.5,0.2,0.7 c0.1,0.2,0.3,0.3,0.5,0.3l13.1,7.7h0.5l9.1-5.3c0.6-0.3,0.6-0.8,0.4-1.1c-0.2-0.3-0.6-0.5-1.2-0.2l-0.3,0.2 c-0.3,0.2-0.5,0.3-0.9,0.5c-0.6-0.4-1.3-0.7-2-1.1l-4-2.2c1.5-0.9,3.1-1.7,4.5-2.6c1.1,0.6,2.1,1.2,3.2,1.8c0,0.3,0,0.7,0,1l0,0.3 c0,0.4,0.2,0.7,0.6,0.8c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.3-0.4,0.3-0.7c0-0.1,0-0.3,0-0.5l1.2,0.7l-0.3,0.2c-0.5,0.3-0.9,0.5-1.4,0.8 c-0.3,0.2-0.4,0.4-0.4,0.7c0,0.3,0.1,0.5,0.3,0.6c0.4,0.3,0.8,0,0.9,0l2.3-1.4c0.4-0.2,0.6-0.6,0.6-0.9 C29.1,20.9,28.8,20.6,28.4,20.3z M25,16.4c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4 c0.1-0.1,0.3-0.2,0.4-0.2c0,0,0,0,0,0c0.3,0,0.6,0.3,0.6,0.6C25.6,16.1,25.3,16.4,25,16.4z M10.4,23.9c1.5-0.8,3-1.7,4.5-2.6l0.1,0 c1.5,0.9,3.1,1.7,4.6,2.6l1.3,0.8L15,28.2c-1.5-0.9-3.1-1.8-4.6-2.7L9,24.7L10.4,23.9z M4.2,20.6l0,0.4c0,0.3,0.1,0.5,0.3,0.7 c0.1,0.1,0.3,0.2,0.5,0.2c0.3,0,0.7-0.2,0.7-0.9l0-0.4c0-0.3,0-0.6,0-0.8c1.1-0.6,2.1-1.2,3.2-1.8c1.4,0.8,2.8,1.6,4.2,2.4l0.4,0.2 l-3.8,2.1c-0.7,0.4-1.4,0.8-2.1,1.2c-1.4-0.8-2.8-1.6-4.2-2.5L3,21.2L4.2,20.6z M4.9,15.2c0.2,0,0.3,0.1,0.4,0.2 c0.1,0.1,0.2,0.3,0.2,0.4c0,0.3-0.3,0.6-0.6,0.6c0,0,0,0,0,0c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4 C4.3,15.5,4.6,15.2,4.9,15.2C4.9,15.2,4.9,15.2,4.9,15.2z" />
      <path d="M12.9,16c0.6,0.2,1,0.5,1.3,0.9c0.2,0.3,0.4,1.3,0.7,1.3c0,0,0,0,0,0c0.3,0,0.5-1.1,0.8-1.3l0.2-0.2 c0.1-0.1,0.2-0.3,0.3-0.4c0,0,0.1-0.1,0.1-0.1c3.1-0.7,5.3-3.4,5.4-6.6c0-0.1,0-0.3,0-0.4c0-0.3-0.1-0.6-0.1-0.9 c-0.1-0.4-0.2-0.9-0.4-1.2c-0.1-0.3-0.6-0.5-0.9-0.4c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.3-0.2,0.5 c0,0.3,0.1,0.5,0.2,0.8c0.1,0.3,0.2,0.6,0.2,0.9c0.3,2.9-1.5,5.4-4.3,5.9c-0.1,0-0.2,0.1-0.3,0.1c0-0.1,0-0.2,0-0.4 c0-0.8,0-1.5,0-1.6l0-0.1l0.1,0c0.3,0,0.6,0,0.8,0c1.1-0.1,2.1-0.8,2.3-1.8c0.1-0.4,0.1-0.8,0.1-1.2l0-0.1c0-0.3-0.1-0.6-0.3-0.8 c-0.2-0.2-0.5-0.3-0.8-0.3c-0.1,0-0.2,0-0.3,0l-0.1,0c-0.6,0-1.3,0-1.9,0.2V6.7c0.1,0,0.2,0,0.3,0c0.5,0,0.9,0,1.4,0 c0.6-0.1,1.1-0.2,1.6-0.4l0.1,0c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.4,0-0.6c-0.2-0.4-0.6-0.6-1-0.4c-0.1,0-0.1,0-0.2,0.1l0,0 c-0.7,0.3-1.4,0.3-2.1,0.2c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.3,0-0.4c0-1.5,1-2.8,2.5-3.1c0.6-0.1,1.2-0.1,1.8-0.1c0.2,0,0.3,0,0.5,0 c0,0.9-0.2,1.5-0.7,2.4c-0.3,0.4-0.2,0.8,0.1,1.1c0.2,0.1,0.4,0.2,0.6,0.1c0.2,0,0.4-0.2,0.6-0.4c0.2-0.2,0.3-0.5,0.4-0.7 c0.5-0.9,0.6-2,0.6-2.9c0-0.8-0.4-1.2-1.2-1.2c-0.5,0-1.1,0-1.6,0c-2.4,0-4.3,1.5-4.8,3.7c0,0.1,0,0.1,0,0.2 c-0.5-0.4-1-0.6-1.6-0.7c-0.8-0.1-1.6-0.1-2.2-0.1c-0.5,0-0.9,0.4-1,1c0,0.4,0,0.8,0,1.2c0.1,1.4,0.8,2.4,2.1,3.1 c0.6,0.3,1.2,0.4,2,0.4c0.2,0,0.3,0,0.5,0v6c0,0,0,0,0,0c-2.3-0.4-4.1-2.4-4.3-4.7c0-0.3,0-0.6,0-0.9c0-0.1,0-0.3,0-0.4 c0-0.1-0.1-0.3-0.1-0.4C9.4,8.3,9.3,8.3,9.2,8.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.3,0-0.5,0.1c-0.4,0.2-0.4,0.6-0.4,1 C8,12.3,9.9,15.1,12.9,16z M14,7.5c0,0-0.1,0-0.1,0c-0.8,0-1.5,0-2.1-0.4c-0.6-0.4-0.9-1-0.9-1.7c0-0.2,0-0.3,0-0.5 c0.1,0,0.2,0,0.3,0c0.4,0,0.9,0,1.2,0.1c0.9,0.2,1.5,1,1.6,1.9C14,7,14,7.3,14,7.5L14,7.5z M15.8,10.7L15.8,10.7 c0.2-0.2,0.5-0.3,0.7-0.3c0.2,0,0.5,0,0.8,0c0,0,0.1,0,0.1,0c0,0.3-0.1,0.6-0.2,0.8c-0.2,0.3-0.5,0.4-0.9,0.4c-0.2,0-0.3,0-0.5,0 h-0.2C15.5,11.2,15.6,10.9,15.8,10.7z" />
      <path d="M14,25.9c0.3,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.1,0,0.2l0.4,0h-0.4l0,0.2c0,0.1,0,0.3,0,0.4 c0,0.5,0.3,0.8,0.8,0.8c0,0,0,0,0,0c0.4,0,0.7-0.3,0.8-0.8c0-0.3,0-0.6,0-1c0.9-0.4,1.5-1.3,1.3-2.3c-0.2-1-1-1.8-2-1.8 c-1-0.1-1.9,0.6-2.2,1.5C12.6,24.4,13.1,25.5,14,25.9z M14.4,24c0-0.3,0.3-0.6,0.6-0.6c0.2,0,0.3,0.1,0.4,0.2 c0.1,0.1,0.2,0.3,0.2,0.4c0,0.3-0.3,0.6-0.6,0.6c-0.2,0-0.3-0.1-0.4-0.2C14.4,24.3,14.4,24.2,14.4,24z" />
    </g>
  </svg>
)
