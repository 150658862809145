import React, { useEffect, useState } from 'react'
import * as ls from 'local-storage'
import {
  Area, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis, Brush,
} from 'recharts'
import { useTranslation } from 'react-i18next'
import { API } from '../../providers/API'
import { FieldItem } from '../FieldsListing/Fields.types'
import { CustomChartTooltip } from '../WeatherListing/HistoricalWeatherChart.styles'
import { Loading } from '../Loading/Loading'
import { SimpleBox } from '../../App.styles'
import { RangeDatePickers } from './RangeDatePickers'
import { ChartHeader } from './NdviField.styles'

const CustomTooltip: React.FC<any> = ({
  active, payload, label,
}) => {
  if (active && payload && payload.length) {
    return (
      <CustomChartTooltip>
        <strong>{label}</strong>
        <p>
          {`median : ${payload[0].payload.median.toFixed(2)}`}
        </p>
        <p>
          {`min : ${payload[0].payload.minmax[0].toFixed(2)}`}
        </p>
        <p>
          {`max : ${payload[0].payload.minmax[1].toFixed(2)}`}
        </p>
      </CustomChartTooltip>
    )
  }

  return null
}

export const HistoryChart: React.FC<{field: FieldItem}> = ({
  field,
}) => {
  const [fullReferenceData, setfullReferenceData] = useState<any>()
  const [historyData, setHistoryData] = useState<any>()
  const [isLoading, setIsLoading] = useState<any>()
  const [startDate, setStartDate] = useState<any>()
  const [endDate, setEndDate] = useState<any>()
  const [allDates, setAllDates] = useState<string[]>()
  const { t } = useTranslation()

  const formatChartData = (data: any) => {
    // format data for chart
    const chartDataFormated = data.map((item: any) => ({
      day: item.date,
      median: item.median,
      minmax: [
        item.min,
        item.max,
      ],
    }))

    return chartDataFormated
  }

  useEffect(() => {
    setIsLoading(true)
    API.get(`/NDVIHistory/${field.id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setfullReferenceData(response.data)

      // get all dates for date picker
      const allDatesData = response.data.map((item: any) => item.date)
      setAllDates(allDatesData)
      setStartDate(allDatesData[0])
      setEndDate(allDatesData[allDatesData.length - 1])
      setHistoryData(formatChartData(response.data))
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    if (allDates && startDate && endDate) {
      // get index of start and end date from all dates array
      // and apply slice to full data to use in chart
      const startDateIndex = allDates.indexOf(startDate)
      const endDateIndex = allDates.indexOf(endDate)
      const slicedData = fullReferenceData.slice(startDateIndex, endDateIndex + 1)
      setHistoryData(formatChartData(slicedData))
    }
  }, [startDate, endDate])

  if (isLoading) {
    return (
      <Loading />
    )
  }

  return (
    <SimpleBox>
      <ChartHeader>
        <h2><span>{t('general.historical')}</span><br />NDVI</h2>
        <div>
          {allDates && (
          <RangeDatePickers
            allDates={allDates}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
          />
          )}
        </div>
      </ChartHeader>
      {historyData && (
      <ResponsiveContainer width="100%" height={300} debounce={1}>
        <ComposedChart
          data={historyData}
          margin={{
            top: 20,
            right: 20,
            left: 0,
            bottom: 10,
          }}
        >
          <XAxis dataKey="day" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Area dataKey="minmax" stroke="#1F8EF1" fill="#DCEDFE" />
          <Line
            type="monotone"
            dataKey="median"
            stroke="#00D6B4"
          />
          {historyData.length > 50 && (
            <Brush dataKey="day" height={30} stroke="#8884d8" endIndex={50} />
          )}
        </ComposedChart>
      </ResponsiveContainer>
      )}
    </SimpleBox>
  )
}
