import React, { Dispatch, SetStateAction } from 'react'
import { useHistory } from 'react-router'
import * as ls from 'local-storage'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { OverlayContainer } from '../../App.styles'
import { SimpleNotification } from '../SuccessNotification/SuccessNotification.styles'
import { API } from '../../providers/API'
import { theme } from '../../theme/theme'

type typesOfItemsToDelete = 'field' | 'zone'
interface DeleteFieldPromptProps {
  itemId: number
  itemName: string
  setShowDeletePrompt: Dispatch<SetStateAction<boolean>>
  itemToDelete: typesOfItemsToDelete
  callback?: () => void
  onCancel?: () => void
}

export const DeleteFieldPrompt: React.FC<DeleteFieldPromptProps> = (
  {
    itemId, itemName, setShowDeletePrompt, itemToDelete, callback, onCancel,
  },
) => {
  const { t } = useTranslation()
  const history = useHistory()

  const deleteItem = (itemType: typesOfItemsToDelete) => {
    if (itemType === 'field') {
      API.delete(`/fields/${itemId}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(() => {
        setShowDeletePrompt(false)
        history.push('/fields')
      })
    }

    if (itemType === 'zone') {
      API.get(`personalZoneDelete/${itemId}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(() => {
        setShowDeletePrompt(false)
        if (callback) {
          callback()
        }
      })
    }
  }

  return (
    <OverlayContainer>
      <SimpleNotification>
        <p>
          {t('createField.deletePromptText')}
          {' '}
          {itemName}
          ?
        </p>
        <div style={{ textAlign: 'left' }}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={() => deleteItem(itemToDelete)}
            style={{ marginRight: theme.space.md }}
          >
            {t('general.yes')}
          </Button>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={() => {
              if (onCancel) {
                onCancel()
              }
              setShowDeletePrompt(false)
            }}
          >
            {t('general.no')}
          </Button>
        </div>
      </SimpleNotification>
    </OverlayContainer>
  )
}
