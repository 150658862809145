import styled from 'styled-components'
import { theme } from '../../theme/theme'

export const FullCropsAndEffectsContainer = styled.div`
    background: #FFF;
    color: #2C2C2C;
    border-radius: 10px;
    padding: ${theme.space.lg};
    transition: all 0.2s ease-in;

    .MuiOutlinedInput-root input {
        color: #2C2C2C !important;
    }

    &:hover {
        box-shadow: 0px 5px 8px rgb(6 192 226 / 30%);
    }

    .nameContainer {
        color: #2C2C2C !important;
    }

    .effectsContainer {
        margin-top: ${theme.space.md};
    }
`
export const MainDataContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    ${theme.mediaQueries.mdMin} {
        flex-direction: row;
        margin-bottom: ${theme.space.lg};
    }

    .container {
        display: flex;
        flex-direction: column;
        margin-bottom: ${theme.space.lg};

        ${theme.mediaQueries.mdMin} {
            margin: 0;
            width: 48%;
        }

        .dataBox {
            background: #FFF;
            border-radius: 10px;
            padding: ${theme.space.md};
            color: #2C2C2C;
            flex-grow: 1;
            transition: all 0.2s ease-in;

            &:hover {
                box-shadow: 0px 5px 8px rgb(6 192 226 / 30%);
            }

            .nameContainer.lastItem {
                border: none;
            }
    

            &.crops {
                background: rgba(6, 192, 226, 0.14);
            }

            &.effects {
                display: flex;
                align-items: center;
                font-size: 0.9em;

                & > div {
                    flex-grow: 1;
                }
            }
        }
    }
`

export const CropItemRow = styled.div`
    display: flex;

    .imageContainer {
        align-items: center;
        display: flex;
        margin-right: ${theme.space.md};
        padding: ${theme.space.xs} 0;
        width: 50px;
        flex-shrink: 0;
    }

    .nameContainer {
        display: flex;
        align-items: center;
        flex-grow: 1;
        color: #FFF;
        padding: ${theme.space.sm} 0;
        border-bottom: 3px dotted ${theme.colors.blueUI};
    }

    
    &.detailed {
        .nameContainer.lastItem {
            border: none;   
        }

        ${theme.mediaQueries.lgMax} {
            flex-wrap: wrap;

            .nameContainer {
                border: none;
            }
        }

        ${theme.mediaQueries.smMax} {
            justify-content: center;

            .nameContainer {
                flex-grow: 0;
            }
        }
    }
`

export const OrderMainCrops = styled.div`
    margin-bottom: ${theme.space.lg};

    .dataBox {
        border-radius: 10px;
        padding: ${theme.space.md};
        transition: all 0.2s ease-in;
        background: rgba(6, 192, 226, 0.14);

        &:hover {
            box-shadow: 0px 5px 8px rgb(6 192 226 / 30%);
        }
    }

    .nameContainer.lastItem {
        border: none;
    }
    
`

export const OrderDetailColumns = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: end;
    border-bottom: 3px dotted ${theme.colors.blueUI};

    ${theme.mediaQueries.lgMax} {
        justify-content: start;
        padding-bottom: ${theme.space.md};
        margin: ${theme.space.sm} 0 ${theme.space.md};
    }

    ${theme.mediaQueries.smMax} {
        display: block;
    }
    
    .item {
        width: 150px;
        padding: ${theme.space.sm} 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        ${theme.mediaQueries.smMax} {
            display: block;
            width: auto;
        }

        &:nth-child(odd) {
            background: rgba(6, 192, 226, 0.14);
        }
    }

    &.firstItem {
        .item {
            justify-content: space-between;
        }
    }

    .header {
        display: none;
        margin-bottom: ${theme.space.sm};

        ${theme.mediaQueries.lgMax} {
            display: block;
        }
    }

    &.lastItem {
        border: none;
    }

    &.firstItem {
        .item {
            justify-content: space-between;
        }

        .header {
            display: block;
        }
    }
`
