import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import {
  AcidityContainer, ProcessButtonsHolder,
  NoAnalysisContainer, SoilColorContainer, SoilStructureContainer, ImageFormLabel, TopRow,
} from './CreateMix.styles'
import { WarningNotification } from '../SuccessNotification/SuccessNotification.styles'

interface NoAnalysisProps {
  setFieldValue: any
  fieldValues: any
  formHandleChange: any
  setProcessStep: Dispatch<SetStateAction<number>>
  processStep: number
  setStepTitle: Dispatch<SetStateAction<string>>
}

export const NoAnalysis: React.FC<NoAnalysisProps> = (
  {
    fieldValues, setFieldValue, formHandleChange, setStepTitle, setProcessStep, processStep,
  },
) => {
  const { t } = useTranslation()
  setStepTitle(t('analysis.noAnalysisTitle'))

  return (
    <>
      <WarningNotification>
        {t('analysis.soilNotice')}
      </WarningNotification>

      <NoAnalysisContainer>
        <TopRow>
          <SoilStructureContainer>
            <h2>{t('analysis.soilStructure')}</h2>
            <div className="content">
              <ImageFormLabel htmlFor="soilLight" selected={fieldValues.soilStruc === 'Лека'}>
                <input
                  type="radio"
                  value="Лека"
                  name="soilStruc"
                  id="soilLight"
                  checked={fieldValues.soilStruc === 'Лека'}
                  onChange={formHandleChange}
                />
                <img src="/images/soil/light.png" alt="Light soil structure" />
                <strong>{t('analysis.light')}</strong>
              </ImageFormLabel>
              <ImageFormLabel htmlFor="soilMedium" selected={fieldValues.soilStruc === 'Средна'}>
                <input
                  type="radio"
                  value="Средна"
                  name="soilStruc"
                  id="soilMedium"
                  checked={fieldValues.soilStruc === 'Средна'}
                  onChange={formHandleChange}
                />
                <img src="/images/soil/medium.png" alt="Medium soil structure" />
                <strong>{t('analysis.medium')}</strong>
              </ImageFormLabel>
              <ImageFormLabel htmlFor="soilHeavy" selected={fieldValues.soilStruc === 'Тежка'}>
                <input
                  type="radio"
                  value="Тежка"
                  name="soilStruc"
                  id="soilHeavy"
                  checked={fieldValues.soilStruc === 'Тежка'}
                  onChange={formHandleChange}
                />
                <img src="/images/soil/heavy.png" alt="Heavy soil structure" />
                <strong>{t('analysis.heavy')}</strong>
              </ImageFormLabel>
            </div>
          </SoilStructureContainer>
          <AcidityContainer>
            <h2>{t('analysis.soilAcidity')}</h2>
            <RadioGroup
              row
              aria-label={t('analysis.soilAcidity')}
              name="soilAcidity"
              value={fieldValues.soilAcidity}
              onChange={(event) => {
                setFieldValue('soilAcidity', event.currentTarget.value)
              }}
            >
              <FormControlLabel value="Силно кисела (pH, H2O, <5.5)" control={<Radio />} label={t('analysis.highlyAcidic')} />
              <FormControlLabel value="Кисела (pH, H2O, <6.5)" control={<Radio />} label={t('analysis.acidic')} />
              <FormControlLabel value="Неутрална (pH, H2O, >6.5; <7.3)" control={<Radio />} label={t('analysis.neutral')} />
              <FormControlLabel value="Алкална (pH, H2O, >7.3)" control={<Radio />} label={t('analysis.alkaline')} />
              <FormControlLabel value="Силно алкална (pH, H2O, >8.2)" control={<Radio />} label={t('analysis.highlyAlkaline')} />
            </RadioGroup>
          </AcidityContainer>
        </TopRow>
        <div>
          <h2>{t('analysis.soilColor')}</h2>
          <SoilColorContainer>
            <ImageFormLabel htmlFor="soilColorCherna" selected={fieldValues.soilColour === 'Черна'}>
              <input
                type="radio"
                value="Черна"
                name="soilColour"
                id="soilColorCherna"
                checked={fieldValues.soilColour === 'Черна'}
                onChange={formHandleChange}
              />
              <img src="/images/soil/cherna.png" alt="Черен цвят почва" />
              <strong>{t('analysis.black')}</strong>
            </ImageFormLabel>
            <ImageFormLabel htmlFor="soilBrown" selected={fieldValues.soilColour === 'Кафява'}>
              <input
                type="radio"
                value="Кафява"
                name="soilColour"
                id="soilBrown"
                checked={fieldValues.soilColour === 'Кафява'}
                onChange={formHandleChange}
              />
              <img src="/images/soil/kafyava.png" alt="Кафяв цвят почва" />
              <strong>{t('analysis.brown')}</strong>
            </ImageFormLabel>
            <ImageFormLabel htmlFor="soilRed" selected={fieldValues.soilColour === 'Червена'}>
              <input
                type="radio"
                value="Червена"
                name="soilColour"
                id="soilRed"
                checked={fieldValues.soilColour === 'Червена'}
                onChange={formHandleChange}
              />
              <img src="/images/soil/chervena.png" alt="Червен цвят почва" />
              <strong>{t('analysis.red')}</strong>
            </ImageFormLabel>
            <ImageFormLabel htmlFor="soilYellow" selected={fieldValues.soilColour === 'Жълта'}>
              <input
                type="radio"
                value="Жълта"
                name="soilColour"
                id="soilYellow"
                checked={fieldValues.soilColour === 'Жълта'}
                onChange={formHandleChange}
              />
              <img src="/images/soil/julta.png" alt="Жълт цвят почва" />
              <strong>{t('analysis.yellow')}</strong>
            </ImageFormLabel>
            <ImageFormLabel htmlFor="soilGray" selected={fieldValues.soilColour === 'Сива'}>
              <input
                type="radio"
                value="Сива"
                name="soilColour"
                id="soilGray"
                checked={fieldValues.soilColour === 'Сива'}
                onChange={formHandleChange}
              />
              <img src="/images/soil/siva.png" alt="Сив цвят почва" />
              <strong>{t('analysis.gray')}</strong>
            </ImageFormLabel>
          </SoilColorContainer>
        </div>
      </NoAnalysisContainer>

      <ProcessButtonsHolder>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          endIcon={<FontAwesomeIcon icon={faAnglesRight} />}
        >
          {t('general.nextStep')}

        </Button>
        <Button
          variant="contained"
          type="button"
          color="primary"
          onClick={
              () => {
                setProcessStep(processStep - 1)
              }
              }
          startIcon={<FontAwesomeIcon icon={faAnglesLeft} />}
        >
          {t('general.prevStep')}
        </Button>
      </ProcessButtonsHolder>
    </>
  )
}
