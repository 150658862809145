import React, { useState } from 'react'
import * as ls from 'local-storage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faHourglass } from '@fortawesome/free-solid-svg-icons'
import { DeleteGridItemButton, PersonalFieldWrap } from './ZonesListing.styles'
import { ZoneField, ZoneListingType } from './ZonesListing.types'
import { SoilListingItem } from '../SoilAnalysis/SoilListingItem/SoilListingItem'
import { API } from '../../providers/API'

interface PersonalZoneFieldItemProps {
    field: ZoneField
    zone: ZoneListingType
    personalZoneFields: ZoneField[]
    zoneFields: ZoneField[]
    setPersonalZoneFields: React.Dispatch<React.SetStateAction<ZoneField[]>>
    setZoneFields: React.Dispatch<React.SetStateAction<ZoneField[]>>
}

export const PersonalZoneFieldItem = (
  {
    field, zone, personalZoneFields, setPersonalZoneFields, setZoneFields, zoneFields,
  }: PersonalZoneFieldItemProps,
) => {
  const [isLoading, setIsLoading] = useState(false)

  const deleteField = (fieldId: number) => {
    setIsLoading(true)
    const postData = {
      id: zone.id,
      fieldId,
    }
    API.post('/personalZoneRemoveField', postData, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(() => {
      const remainingPersonalFields = personalZoneFields.filter((item) => item.id !== fieldId)
      const remainingFields = zoneFields.filter((item) => item.id !== fieldId)
      setPersonalZoneFields(remainingPersonalFields)
      setZoneFields(remainingFields)
      setIsLoading(false)
    })
  }

  return (
    <PersonalFieldWrap>
      <DeleteGridItemButton
        type="button"
        disabled={isLoading}
        onClick={() => {
          deleteField(field.id)
        }}
      ><FontAwesomeIcon
        color="#FFF"
        icon={isLoading ? faHourglass : faTrash}
      />
      </DeleteGridItemButton>
      <SoilListingItem item={field} key={`zoneField_${field.id}`} />
    </PersonalFieldWrap>
  )
}
