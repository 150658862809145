import styled from 'styled-components'
import { theme } from '../../theme/theme'
import { FieldItemRow } from '../FieldsListing/FieldsListing.styles'
import { FailureNotification } from '../SuccessNotification/SuccessNotification.styles'

export const FieldAreaHolder = styled.div`
    display: flex;
    margin-top: ${theme.space.sm};

    ${FailureNotification} {
        flex-grow: 1;
        align-items: center;
        display: flex;

        ${theme.mediaQueries.mdMin} {
            margin: 0 0 0 ${theme.space.md};
        }
    }

    ${theme.mediaQueries.mdMax} {
        display: block;
    }
    
`

export const FieldAreaStat = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    padding: ${theme.space.md};
    border: 3px solid ${theme.colors.blueUI};

    img, span {
        vertical-align: middle;
        display: inline-block;
    }
    
    img {
        margin-right: ${theme.space.md};
    }
`

export const MultiFieldItemContainer = styled.div`
    background: #FFF;
    margin-bottom: ${theme.space.lg};
    padding: ${theme.space.md};
    color: #000;
    border-radius: 10px;

    ${FieldItemRow} {
        margin: 0;
        background: ${theme.colors.blueBGUILight};
    }
`
