const getSWCoordinates = (coordinatesCollection:any) => {
  const lowestLng = Math.min(
    ...coordinatesCollection.map((coordinates:any) => coordinates[0]),
  )
  const lowestLat = Math.min(
    ...coordinatesCollection.map((coordinates:any) => coordinates[1]),
  )

  return [lowestLng, lowestLat]
}

const getNECoordinates = (coordinatesCollection:any) => {
  const highestLng = Math.max(
    ...coordinatesCollection.map((coordinates:any) => coordinates[0]),
  )
  const highestLat = Math.max(
    ...coordinatesCollection.map((coordinates:any) => coordinates[1]),
  )

  return [highestLng, highestLat]
}

export const calcBoundsFromCoordinates = (coordinatesCollection:any) => (
  [
    getSWCoordinates(coordinatesCollection),
    getNECoordinates(coordinatesCollection),
  ]
)
