import React, {
  useContext, useEffect, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import * as ls from 'local-storage'
import Button from '@mui/material/Button'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesLeft, faLock } from '@fortawesome/free-solid-svg-icons'
import { WeatherListingItemFlat } from '../components/WeatherListing/WeatherListingItemFlat'
import { Tab, TabsList } from '../components/WeatherListing/WeatherListing.styles'
import { FieldItem } from '../components/FieldsListing/Fields.types'
import { API } from '../providers/API'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import { FieldPolygonHeader } from '../components/FieldsListing/FieldPolygonHeader'
import { HourlyWeather } from '../components/WeatherListing/HourlyWeather'
import { DailyWeather } from '../components/WeatherListing/DailyWeather'
import { HistoricalWeatherChart } from '../components/WeatherListing/HistoricalWeatherChart'
import { ProcessButtonsHolder } from '../components/MixCreation/CreateMix.styles'
import { HistoricalWeatherPremium } from '../components/WeatherListing/HistoricalWeatherPremium'
import { CreditsPrompt } from '../components/CreditsPrompt/CreditsPrompt'

export const WeatherField = () => {
  const {
    setIsLoading,
  } = useContext(GlobalStateContext)
  const [field, setField] = useState<FieldItem>()
  const [meteoPricing, setMeteoPricing] = useState<any>({})
  const [creditsPromptShow, setCreditsPromptShow] = useState<boolean>(false)
  const [showHistoryTabs, setShowHistoryTabs] = useState<boolean>(false)
  const { id } = useParams<any>()
  const { t } = useTranslation()
  const history = useHistory()

  useEffect(() => {
    setIsLoading(true)
    API.get(`/fields/${id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setField(response.data)
      setShowHistoryTabs(response.data.meteoHistory === 1)
      setIsLoading(false)
    })

    API.get('/pricingTable', { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      const AIMixPriceData = response.data.filter((item: any) => item.description === 'meteoHistory').pop()
      setMeteoPricing(AIMixPriceData)
    })
  }, [])

  return (
    <>
      {field && (
        <>
          <FieldPolygonHeader
            field={{
              coordinates: field.coordinates,
              name: field.name,
              id: field.id,
              area: field.area,
            }}
            key={field.id}
            showArea
          />

          <WeatherListingItemFlat
            field={field}
          />

          <CreditsPrompt
            showPrompt={creditsPromptShow}
            setShowPrompt={setCreditsPromptShow}
            textContent={t('weather.creditsPromptText')}
            title={t('weather.creditsPromptTitle')}
            pricingData={meteoPricing}
            fieldId={field.id}
            callback={() => setShowHistoryTabs(true)}
            modal
          />

          <TabsUnstyled defaultValue={0}>
            <TabsList>
              <Tab>{t('weather.dailyTab')}</Tab>
              <Tab>{t('weather.hourlyTab')}</Tab>
              {showHistoryTabs && <Tab>{t('weather.historicalTab')}</Tab>}
              {showHistoryTabs && <Tab>{t('weather.historicalTabPremium')}</Tab>}
              {!showHistoryTabs
                && (
                <Tab className="unlockPremium" value={0} onClick={() => setCreditsPromptShow(true)}>
                  <FontAwesomeIcon icon={faLock} />&nbsp;{t('weather.unlockHistorycalData')}
                </Tab>
                )}
            </TabsList>
            <TabPanelUnstyled value={0}>
              <DailyWeather fieldId={id} />
            </TabPanelUnstyled>
            <TabPanelUnstyled value={1}>
              <HourlyWeather fieldId={id} />
            </TabPanelUnstyled>
            <TabPanelUnstyled value={2}>
              <HistoricalWeatherChart fieldId={id} />
            </TabPanelUnstyled>
            <TabPanelUnstyled value={3}>
              <HistoricalWeatherPremium fieldId={id} />
            </TabPanelUnstyled>
          </TabsUnstyled>

          <ProcessButtonsHolder>
            <div />
            <Button
              variant="contained"
              type="button"
              color="primary"
              startIcon={<FontAwesomeIcon icon={faAnglesLeft} />}
              onClick={() => history.push('/weatherListing')}
            >
              {t('weather.backToListing')}
            </Button>

          </ProcessButtonsHolder>
        </>
      )}
    </>

  )
}
