import styled from 'styled-components'
import { theme } from '../../theme/theme'

export const ControlsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${theme.space.xs};
    position: absolute;
    overflow-y: auto;
    top: 0;
    right: 0;
    bottom: 30px;
    z-index: 98;
    padding: ${theme.space.sm} ${theme.space.sm} ${theme.space.xs};
    background-color: rgba(255,255,255,0.2);
    border-radius: 5px;

    & > div {
        flex-grow: 0;
        display: table-cell;
    width: 1%;
    }
`
