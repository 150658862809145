import React from 'react'

export const IconSateliteData = ({ size = '24', color = 'currentColor' }) => (
  <svg width={size} height={size} viewBox="0 0 31 31" fill={color} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_914_8001)">
      <mask id="mask0_914_8001" maskUnits="userSpaceOnUse" x="-6" y="-6" width="43" height="43">
        <path d="M36.1693 36.1631H-5.16406V-5.17025H36.1693V36.1631Z" fill="white" />
      </mask>
      <g mask="url(#mask0_914_8001)">
        <path d="M13.7918 25.4502C12.6899 25.4502 11.479 23.9729 10.6617 22.7318C9.85042 21.5027 9.02702 19.8862 9.02702 19.0446C9.02702 18.3242 9.26919 17.8035 9.74749 17.4887C10.135 17.2344 10.5769 17.1799 10.9765 17.1315C11.7212 17.0406 11.7212 16.9862 11.7212 16.6592C11.7212 16.1809 11.6062 16.1143 10.8978 15.9206C10.3893 15.7813 9.75355 15.6058 9.2813 15.0366C8.77273 14.413 8.59716 13.5049 8.73035 12.179C8.76668 11.846 9.06334 11.6038 9.39634 11.6401C9.72933 11.6765 9.9715 11.9731 9.93518 12.3061C9.83831 13.2688 9.93518 13.9287 10.2197 14.2738C10.4437 14.5462 10.801 14.6492 11.2187 14.7581C11.9029 14.9458 12.9321 15.2304 12.9321 16.6653C12.9321 17.2949 12.702 17.7551 12.248 18.0336C11.9029 18.2455 11.4912 18.2939 11.1279 18.3423C10.359 18.4392 10.2379 18.4937 10.2379 19.0507C10.2379 19.4079 10.7344 20.6491 11.6728 22.0658C12.7202 23.652 13.5315 24.2393 13.7918 24.2393C14.2338 24.2393 14.7908 23.1919 15.3357 22.1808C15.9835 20.9821 16.7827 19.4866 18.0057 18.3605C18.2539 18.1365 18.6353 18.1486 18.8594 18.3968C19.0834 18.6451 19.0713 19.0265 18.823 19.2505C17.7453 20.2434 17.0309 21.5754 16.4013 22.756C15.6263 24.1969 14.9543 25.4502 13.7918 25.4502Z" />
        <path d="M10.1667 30.9834C9.8882 30.9834 9.63997 30.7897 9.57337 30.5051C8.81658 27.0117 7.69046 26.7151 6.5038 26.4063C5.58353 26.1641 4.54218 25.8917 3.93068 24.3054C3.39184 22.9129 4.09415 21.7444 4.7117 20.7152C5.11129 20.0431 5.49271 19.4074 5.49271 18.808C5.49271 18.2086 5.18394 17.2641 2.53212 15.9382C2.23545 15.7869 2.11436 15.4236 2.25967 15.1269C2.40497 14.8303 2.77429 14.7092 3.07096 14.8545C5.58353 16.1078 6.70359 17.3307 6.70359 18.808C6.70359 19.7464 6.21924 20.5577 5.75305 21.3388C5.20816 22.2469 4.74197 23.034 5.06285 23.8755C5.45033 24.8806 5.99523 25.0259 6.81863 25.2438C7.46645 25.4134 8.27773 25.6253 9.01032 26.3578C9.79133 27.1449 10.3665 28.4163 10.7661 30.2569C10.8387 30.5838 10.6268 30.9047 10.2999 30.9773C10.2515 30.9834 10.2091 30.9834 10.1667 30.9834Z" />
        <path d="M21.4025 12.7705C21.2391 12.7705 21.0877 12.7039 20.9727 12.5949L18.4056 10.0279C18.1695 9.79175 18.1695 9.41032 18.4056 9.1742L26.1128 1.4609C26.2279 1.34587 26.3792 1.28533 26.5427 1.28533C26.7062 1.28533 26.8575 1.35192 26.9726 1.4609L29.5396 4.02797C29.7758 4.26409 29.7758 4.64551 29.5396 4.88164L21.8324 12.5949C21.7174 12.7039 21.566 12.7705 21.4025 12.7705ZM19.6891 9.59801L21.4025 11.3114L28.25 4.46388L26.5367 2.75049L19.6891 9.59801Z" />
        <path d="M27.8238 14.0566C27.6663 14.0566 27.515 13.9961 27.3939 13.8811L23.5433 10.0305C23.3072 9.79435 23.3072 9.41292 23.5433 9.1768C23.7794 8.94068 24.1608 8.94068 24.397 9.1768L27.8238 12.6036L29.5371 10.8902L26.1104 7.45735C25.8742 7.22123 25.8742 6.83375 26.1104 6.59763C26.3465 6.36151 26.734 6.36151 26.9701 6.59763L30.8207 10.4482C30.9357 10.5633 30.9963 10.7146 30.9963 10.8781C30.9963 11.0416 30.9297 11.1929 30.8207 11.3079L28.2536 13.875C28.1325 13.9961 27.9812 14.0566 27.8238 14.0566Z" />
        <path d="M21.4029 7.63281C21.2454 7.63281 21.0941 7.57227 20.973 7.45724L17.1224 3.60664C16.8863 3.37052 16.8863 2.98909 17.1224 2.75297L19.6895 0.185911C19.8045 0.0708776 19.9559 0.0103335 20.1193 0.0103335C20.2828 0.0103335 20.4342 0.076932 20.5492 0.185911L24.3998 4.0365C24.6359 4.27263 24.6359 4.65405 24.3998 4.89017C24.1637 5.12629 23.7822 5.12629 23.5461 4.89017L20.1193 1.46339L18.4059 3.17678L21.8327 6.59751C22.0688 6.83363 22.0688 7.22111 21.8327 7.45724C21.7116 7.57227 21.5603 7.63281 21.4029 7.63281Z" />
        <path d="M18.8338 10.2002C18.6764 10.2002 18.5251 10.1397 18.404 10.0246L17.1205 8.74109C16.8843 8.50497 16.8843 8.11748 17.1205 7.88136C17.3566 7.64524 17.7441 7.64524 17.9802 7.88136L19.2637 9.16489C19.4998 9.40102 19.4998 9.78244 19.2637 10.0186C19.1426 10.1397 18.9913 10.2002 18.8338 10.2002Z" />
        <path d="M22.6874 14.0547C22.5299 14.0547 22.3786 13.9941 22.2575 13.8791L20.974 12.5956C20.7378 12.3595 20.7378 11.978 20.974 11.7419C21.2101 11.5058 21.5915 11.5058 21.8276 11.7419L23.1172 13.0194C23.3533 13.2555 23.3533 13.643 23.1172 13.8791C22.9961 13.9941 22.8448 14.0547 22.6874 14.0547Z" />
        <path d="M28.7649 28.6133C28.6922 28.6133 28.6135 28.6012 28.5409 28.5709C28.2321 28.4498 28.0807 28.0926 28.2018 27.7838C28.3471 27.4206 28.4864 27.0452 28.6075 26.6759C28.7164 26.361 29.0555 26.1915 29.3764 26.2945C29.6912 26.4034 29.8607 26.7425 29.7578 27.0634C29.6246 27.4508 29.4793 27.8444 29.3279 28.2319C29.2371 28.468 29.007 28.6133 28.7649 28.6133ZM29.8365 25.1441C29.7941 25.1441 29.7517 25.1381 29.7094 25.132C29.3824 25.0594 29.1766 24.7385 29.2492 24.4115C29.334 24.0241 29.4066 23.6366 29.4611 23.2551C29.5096 22.9222 29.8183 22.6981 30.1513 22.7466C30.4843 22.795 30.7083 23.1038 30.6599 23.4368C30.5994 23.8424 30.5206 24.2541 30.4298 24.6658C30.3632 24.9504 30.115 25.1441 29.8365 25.1441ZM30.2603 21.5418H30.2543C29.9213 21.5357 29.6549 21.2632 29.6549 20.9303C29.6549 20.8637 29.6549 20.8031 29.6549 20.7365C29.6549 20.4096 29.6488 20.0826 29.6307 19.7557C29.6125 19.4227 29.8728 19.1382 30.2058 19.12C30.5388 19.1018 30.8234 19.3622 30.8415 19.6952C30.8597 20.0403 30.8657 20.3914 30.8657 20.7365C30.8657 20.8092 30.8657 20.8758 30.8657 20.9484C30.8597 21.2754 30.5933 21.5418 30.2603 21.5418ZM29.9697 17.9273C29.6791 17.9273 29.4248 17.7214 29.3764 17.4248C29.3098 17.0433 29.2311 16.6559 29.1402 16.2744C29.0615 15.9475 29.2674 15.6206 29.5883 15.5479C29.9152 15.4692 30.2421 15.675 30.3148 15.9959C30.4117 16.4016 30.4964 16.8072 30.563 17.2189C30.6175 17.5459 30.3996 17.8607 30.0666 17.9212C30.0363 17.9273 30.006 17.9273 29.9697 17.9273ZM29.0313 14.4218C28.783 14.4218 28.553 14.2704 28.4622 14.0283C28.329 13.665 28.1776 13.2957 28.0202 12.9385C27.887 12.6357 28.0262 12.2785 28.335 12.1393C28.6377 12.0061 28.9949 12.1453 29.1342 12.4541C29.3037 12.8355 29.4611 13.223 29.6004 13.6105C29.7154 13.9253 29.558 14.2704 29.2432 14.3855C29.1705 14.4097 29.0979 14.4218 29.0313 14.4218Z" />
        <path d="M1.20016 3.54004C0.982198 3.54004 0.770294 3.41895 0.661315 3.20705C0.509955 2.91038 0.624989 2.54712 0.921654 2.39576C1.29097 2.20807 1.6724 2.02644 2.05383 1.86297C2.3626 1.72978 2.71981 1.86903 2.853 2.1778C2.9862 2.48657 2.84695 2.84378 2.53818 2.97698C2.18097 3.12834 1.82376 3.29786 1.47866 3.47344C1.38784 3.51582 1.29097 3.54004 1.20016 3.54004ZM16.4935 2.34732C16.433 2.34732 16.3664 2.33522 16.3059 2.31705C15.9365 2.19596 15.5551 2.08698 15.1797 1.98406C14.8589 1.8993 14.6651 1.56631 14.7438 1.24542C14.8286 0.924542 15.1555 0.730801 15.4825 0.809509C15.8821 0.912433 16.2877 1.03352 16.6812 1.16066C16.9961 1.26359 17.1716 1.60869 17.0687 1.92352C16.984 2.18386 16.7478 2.34732 16.4935 2.34732ZM4.5664 2.18991C4.30606 2.18991 4.06388 2.02039 3.98518 1.76005C3.88831 1.43917 4.06994 1.10012 4.39082 1.0093C4.79041 0.888216 5.19605 0.785291 5.59564 0.688421C5.92258 0.609714 6.24952 0.815563 6.32217 1.1425C6.40088 1.46944 6.19503 1.79637 5.86809 1.86903C5.48666 1.95984 5.10524 2.05671 4.73592 2.17175C4.68143 2.18386 4.62694 2.18991 4.5664 2.18991ZM12.9578 1.54209C12.9336 1.54209 12.9033 1.54209 12.8791 1.53604C12.4916 1.48155 12.0981 1.44522 11.7106 1.41495C11.3776 1.39073 11.1294 1.10012 11.1536 0.767128C11.1778 0.434136 11.4684 0.179852 11.8014 0.210124C12.2131 0.240396 12.6308 0.282776 13.0425 0.337266C13.3755 0.379647 13.6056 0.688421 13.5632 1.01536C13.5148 1.31808 13.2544 1.54209 12.9578 1.54209ZM8.12033 1.47549C7.81761 1.47549 7.55121 1.24542 7.52094 0.936651C7.48461 0.603659 7.72679 0.306994 8.05978 0.270668C8.47148 0.228287 8.88923 0.191961 9.30093 0.173797C9.63392 0.155634 9.91848 0.415973 9.93664 0.748965C9.95481 1.08196 9.69447 1.36651 9.36147 1.38468C8.97399 1.40284 8.58046 1.43311 8.19298 1.47549C8.16876 1.47549 8.14454 1.47549 8.12033 1.47549Z" />
        <path d="M16.7099 27.7295C16.7099 28.0625 16.4375 28.335 16.1045 28.335C15.7715 28.335 15.499 28.0625 15.499 27.7295C15.499 27.3965 15.7715 27.1241 16.1045 27.1241C16.4375 27.1241 16.7099 27.3965 16.7099 27.7295Z" />
        <path d="M9.6841 30.9971C4.34412 30.9971 -0.00292969 26.65 -0.00292969 21.31C-0.00292969 18.9912 0.826521 16.7511 2.33406 14.9953C4.08378 12.961 6.62663 11.732 9.30267 11.6291C9.43587 11.623 9.56301 11.623 9.67804 11.623C11.8213 11.623 13.8556 12.3132 15.5569 13.6089C17.2037 14.8682 18.4206 16.6542 18.9897 18.634C19.2379 19.4998 19.3651 20.4019 19.3651 21.31C19.3651 22.8781 18.9776 24.4402 18.2511 25.8206C18.0936 26.1172 17.7304 26.2323 17.4337 26.0748C17.1371 25.9174 17.022 25.5542 17.1794 25.2575C17.8151 24.0527 18.1542 22.6844 18.1542 21.31C18.1542 20.5109 18.0452 19.7238 17.8273 18.967C16.798 15.3586 13.4499 12.8339 9.6841 12.8339C9.58117 12.8339 9.47219 12.8339 9.35111 12.8399C7.00806 12.9308 4.78609 14.0024 3.25433 15.7884C1.93447 17.3202 1.20795 19.2818 1.20795 21.31C1.20795 25.984 5.01011 29.7862 9.6841 29.7862C9.83546 29.7862 9.98682 29.7801 10.1321 29.7741C11.3672 29.7135 12.5478 29.3866 13.6316 28.8175C13.9282 28.6601 14.2975 28.7751 14.4489 29.0657C14.6063 29.3624 14.4913 29.7317 14.2007 29.8831C12.9595 30.5369 11.6094 30.9063 10.2048 30.985C10.0292 30.9971 9.85967 30.9971 9.6841 30.9971Z" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_914_8001">
        <rect width="31" height="31" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
