import styled, { css } from 'styled-components'

export const FieldBaseDetailsContainer = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 250px 1fr;
    column-gap: ${theme.space.md};
    row-gap: ${theme.space.md};
    grid-template-rows: auto;
    margin-bottom: ${theme.space.lg};

    h2 {
      margin: 0 0 ${theme.space.md} 0;
    }

    ${theme.mediaQueries.lgMax} {
        grid-template-columns: 1fr;
    }
    
    .fieldsToggleHolder {
      display: flex;
      flex-direction: column;
      padding-bottom: ${theme.space.md};
      margin-bottom: ${theme.space.md};
      border-bottom: 1px dotted ${theme.colors.blueUI};
    }

    .seedingTypeRadioGroup {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    
    }
`,
)

export const BaseInfoActionButtons = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${theme.space.md};

    ${theme.mediaQueries.smMax} {
      grid-template-columns: 1fr;
  }
`,
)

export const FieldDetailsAddButton = styled.button(
  ({ theme }) => css`
  padding: 0;
  border: none;
  background: ${theme.colors.agroOrange};
  font-size: 1.9em;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  `,
)

export const FieldServiceItem = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-direction: column;
    grid-template-rows: auto 1fr;
    position: relative;
    margin-bottom: ${theme.space.lg};

    h2 {
      margin: 0 0 ${theme.space.md} 0;
    }
`,
)
