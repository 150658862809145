import React from 'react'
import { useTranslation } from 'react-i18next'
import { theme } from '../../theme/theme'
import { NdviPaletteHolder } from './NdviField.styles'

export const NdviPalette = () => {
  const { t } = useTranslation()

  return (
    <NdviPaletteHolder>
      <div className="label">
        {t('general.ndviPalette')}
      </div>
      <div className="palette">
        {[...Array(8)].map(
          (item: any, index: number) => <span className="breakpoint" style={{ left: `${index * 10}%` }}>{index / 10}</span>,
        )}
        <span className="breakpoint">1</span>

      </div>
      <div className="paletteItem" style={{ background: theme.colors.ndviColors[0] }} />

    </NdviPaletteHolder>

  )
}
