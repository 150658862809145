import React from 'react'
import { useTranslation } from 'react-i18next'
import { EffectItemTypes } from './MixElement.types'
import { theme } from '../../theme/theme'

export const EffectItem: React.FC<EffectItemTypes> = ({
  item, effectData,
}) => {
  const { t } = useTranslation()

  return (
    <div style={{ marginBottom: '10px' }} key={`item_${item}`}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>
          {t(`effects.${item}`)}
        </span>
        <span>
          {effectData[item] !== null && effectData[item].toFixed(2)}
        </span>
      </div>
      <div className="progress" style={{ borderRadius: '5px', backgroundColor: '#F2F2F2' }}>
        <span style={{
          borderRadius: '5px', display: 'block', width: `${(effectData[item] * 10).toFixed(2)}%`, height: '10px', backgroundColor: theme.colors.progressColors[parseInt(effectData[item]) - 1],
        }}
        />
      </div>
    </div>
  )
}
