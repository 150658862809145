import React, {
  useContext, useState, useEffect, Dispatch, SetStateAction,
} from 'react'
import * as ls from 'local-storage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { OrderMixContext } from '../../providers/OrderMixProvider'
import { API } from '../../providers/API'
import { MixOrderMainCrops } from '../MixElements/MixOrderMainCrops'
import { MixOrderAdditionalCrops } from '../MixElements/MixOrderAdditionalCrops'
import { ProcessButtonsHolder, RecalcNormsButtonContainer } from './CreateMix.styles'
import { FailureNotification, SuccessNotification } from '../SuccessNotification/SuccessNotification.styles'

interface OrderMixProps {
  newMixId: number
  zoneId?: string
  setStepTitle?: Dispatch<SetStateAction<string>>
  processStep?: number
  setProcessStep?: Dispatch<SetStateAction<number>>
  detailedMix?: boolean
  setShowOrder?: Dispatch<SetStateAction<boolean>>
  mixType?: string
}

export const OrderMix: React.FC<OrderMixProps> = (
  {
    newMixId, zoneId, setStepTitle, processStep, setProcessStep, detailedMix, setShowOrder, mixType,
  },
) => {
  const { t } = useTranslation()
  if (setStepTitle) {
    setStepTitle(t('orderMix.pageTitle'))
  }

  const { userInfo, resultsLoading } = useContext(GlobalStateContext)
  const {
    changeSeedingNorms,
    setChangeSeedingNorms,
    mixData,
    setMixData,
  } = useContext(OrderMixContext)
  const [orderResponse, setOrderResponse] = useState<string>('')
  const [normsCalcError, setNormsCalcError] = useState<boolean | string>(false)
  const [isRecalculatingNorms, setIsRecalculatingNorms] = useState<boolean>(false)

  useEffect(() => {
    let endpointUrl = `/finalizeMix/${newMixId}`
    if (zoneId !== '' && zoneId !== undefined) {
      endpointUrl = `/finalizeMixZone/${newMixId}?zoneId=${zoneId}`
    }

    API.get(endpointUrl, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setMixData(response.data)
    })
  }, [])

  const makeOrder = () => {
    const orderData = {
      ...mixData,
      userId: userInfo.id,
    }

    API.post('/orderMix', orderData, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      if (response.data) {
        setOrderResponse('success')
      } else {
        setOrderResponse('failure')
      }
    }).catch(() => {
      setOrderResponse('failure')
    })
  }

  const saveNorms = () => {
    const cropsToSubmit = mixData.mainCrops
      ? [...mixData.mainCrops, ...mixData.additionalCrops] : [...mixData.additionalCrops]

    const recalcData = {
      mixId: mixData.mixId,
      crops: cropsToSubmit,
    }

    setIsRecalculatingNorms(true)

    let endpointUrl = '/finalizeMixRecalc'
    if (zoneId !== '') {
      endpointUrl = '/finalizeMixRecalcZone'
    }

    API.post(endpointUrl, recalcData, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setMixData(response.data)
      setChangeSeedingNorms(false)
      setNormsCalcError(false)
      setIsRecalculatingNorms(false)
    })
    setNormsCalcError(false)
  }

  const calcNorms = () => {
    let mainCropsSum = 0
    let additionalCropsSum = 0

    if (mixData.mainCrops && mixData.mainCrops.length > 0) {
      mainCropsSum = mixData.mainCrops.reduce(
        (sum: any, item: any) => sum + parseFloat(item.percentSeedingNorm), 0,
      )
    }

    if (mixData.additionalCrops && mixData.additionalCrops.length > 0) {
      additionalCropsSum = mixData.additionalCrops.reduce(
        (sum: any, item: any) => sum + parseFloat(item.percentSeedingNorm), 0,
      )
    }

    if ((mainCropsSum + additionalCropsSum) > 150 || (mainCropsSum + additionalCropsSum) < 80) {
      setNormsCalcError((mainCropsSum + additionalCropsSum) > 150 ? 'high' : 'low')
    } else {
      saveNorms()
    }
  }

  return (
    <>
      {mixData && Object.keys(mixData).length > 0 && !resultsLoading && (
        <>
          {changeSeedingNorms ? (
            <RecalcNormsButtonContainer>
              {normsCalcError && (
                <FailureNotification>{normsCalcError === 'low' ? t('orderMix.seedingNormsLow') : t('orderMix.seedingNormsHigh')}</FailureNotification>
              )}
              <LoadingButton
                variant="contained"
                type="button"
                color="primary"
                onClick={normsCalcError ? saveNorms : calcNorms}
                loading={isRecalculatingNorms}
                loadingPosition="start"
                disabled={isRecalculatingNorms}
              >
                {normsCalcError ? t('orderMix.saveNormsConfirm') : t('orderMix.saveNorms')}
              </LoadingButton>
            </RecalcNormsButtonContainer>
          ) : (
            <RecalcNormsButtonContainer style={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                type="button"
                color="primary"
                onClick={() => setChangeSeedingNorms(true)}
              >
                {t('orderMix.editNorms')}
              </Button>
            </RecalcNormsButtonContainer>
          )}
          {mixData.mainCrops && (
            <MixOrderMainCrops cropsData={mixData.mainCrops} title={t('mixResults.mainCrops')} />
          )}

          {mixData.additionalCrops && mixData.additionalCrops.length > 0 && (
            <MixOrderAdditionalCrops cropsData={mixData.additionalCrops} title={t('orderMix.additionalCropsTitle')} />
          )}

          {orderResponse === 'failure' && (
            <FailureNotification>
              {t('orderMix.failureMessage')}
            </FailureNotification>
          )}

          {orderResponse === 'success' && (
            <SuccessNotification>
              {t('orderMix.successMessage')}
            </SuccessNotification>
          )}

          {orderResponse !== 'success' && (
            <ProcessButtonsHolder>
              <Button
                variant="contained"
                type="button"
                color="primary"
                endIcon={<FontAwesomeIcon icon={faAnglesRight} />}
                onClick={makeOrder}
                disabled={changeSeedingNorms}
              >
                {t('mixResults.orderMix')}

              </Button>
              {detailedMix && setShowOrder ? (
                <Button
                  variant="contained"
                  type="button"
                  color="primary"
                  onClick={
                    () => {
                      setShowOrder(false)
                    }
                  }
                  startIcon={<FontAwesomeIcon icon={faAnglesLeft} />}
                >
                  {t('general.prevStep')}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  type="button"
                  color="primary"
                  onClick={
                    () => {
                      if (setProcessStep && processStep) {
                        if (mixType === 'manual') {
                          setProcessStep(2)
                        } else {
                          setProcessStep(processStep - 1)
                        }
                      }
                    }
                  }
                  startIcon={<FontAwesomeIcon icon={faAnglesLeft} />}
                >
                  {t('general.prevStep')}
                </Button>
              )}
            </ProcessButtonsHolder>
          )}
        </>
      )}
    </>
  )
}
