import React, { useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { API } from '../providers/API'
import { InputField } from '../components/FormElements/InputField'
import { CenteredContainer } from '../App.styles'
import { FailureNotification, SuccessNotification } from '../components/SuccessNotification/SuccessNotification.styles'
import logo from '../media/agrovar-logo.svg'

export const ForgotPassword = () => {
  const [forgotPassErrors, setForgotPassErrors] = useState<string[]>([])
  const [linkSentSuccess, setLinkSentSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [emailSent, setEmailSent] = useState('')
  const { t } = useTranslation()

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('formErrors.invalidEmail'))
      .required(t('formErrors.enterEmail')),
  })

  return (
    <CenteredContainer>
      <img src={logo} alt="Agrovar" />
      <h1>{t('forgotPass.title')}</h1>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={ValidationSchema}
        enableReinitialize
        onSubmit={(values) => {
          setIsLoading(true)
          API.post('/forgotPassword', values).then((response) => {
            if (response.data.status) {
              setLinkSentSuccess(true)
              setEmailSent(values.email)
              setIsLoading(false)
            }
          }).catch((error) => {
            setIsLoading(false)
            if (error.response.data.errors) {
              const errorsArray = Object.keys(error.response.data.errors)
              const errors:any = errorsArray.map(() => error.response.data.errors[errorsArray[0]])
              setForgotPassErrors(errors)
            } else if (error.response.data.message) {
              setForgotPassErrors([error.response.data.message])
            } else {
              setForgotPassErrors([t('forgotPass.genericError')])
            }
          })
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <p>{t('forgotPass.description')}</p>
            <InputField label={t('general.email')} name="email" id="email" type="text" />
            <LoadingButton
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
              loading={isLoading}
              loadingPosition="start"
            >
              {t('forgotPass.forgotPassBtn')}
            </LoadingButton>

            {linkSentSuccess && (
              <SuccessNotification>
                {t('forgotPass.success')}
                {' '}
                {emailSent}
              </SuccessNotification>
            )}
            {forgotPassErrors && (
              forgotPassErrors.map((item: any) => (
                <FailureNotification>{t(`submitErrors.${item}`)}</FailureNotification>
              ))
            )}
          </form>
        )}
      </Formik>
    </CenteredContainer>
  )
}
