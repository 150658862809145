import React, { useState, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNavicon, faCancel } from '@fortawesome/free-solid-svg-icons'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import {
  Controls, StyledBurger, TopBar, LanguageMenu, LanguageSwitchMenuHolder,
} from './Header.styles'
import i18n from '../../i18n'
import { IconAccount, IconCredits } from '../../icons'
import logo from '../../media/logo-white.svg'
import flagBG from '../../icons/bg.svg'
import flagGB from '../../icons/gb.svg'

export const Header: React.FC = () => {
  const {
    slideoutOpen, setSlideoutOpen, userInfo,
  } = useContext(GlobalStateContext)
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false)

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng)
  }

  if (!userInfo.name) {
    return null
  }

  return (
    <TopBar>
      <Controls>
        {userInfo.name && (
        <>
          <span className="name">{userInfo.name}</span>
          <IconCredits size="25" />
          <span className="credits">&nbsp;{userInfo.totalCredits} cr.</span>
          <LanguageSwitchMenuHolder onClick={() => setLanguageMenuOpen(!languageMenuOpen)}>
            <button type="button" aria-label="Language selection">
              <img src={i18n.language === 'bg' ? flagBG : flagGB} alt="" />
            </button>
            <LanguageMenu open={languageMenuOpen}>
              <li>
                <button
                  type="button"
                  onClick={() => changeLanguage('bg')}
                >Български <img src={flagBG} alt="" />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => changeLanguage('en')}
                >English <img src={flagGB} alt="" />
                </button>
              </li>
            </LanguageMenu>
          </LanguageSwitchMenuHolder>
          <IconAccount size="25" />
        </>

        )}
        <StyledBurger onClick={() => setSlideoutOpen(!slideoutOpen)}>
          {slideoutOpen ? (
            <FontAwesomeIcon icon={faCancel} size="2x" />
          ) : (
            <FontAwesomeIcon icon={faNavicon} size="2x" />
          )}

        </StyledBurger>
      </Controls>
      <div className="mobileLogo">
        <img src={logo} alt="" />
      </div>
    </TopBar>
  )
}
