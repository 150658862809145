import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import * as ls from 'local-storage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { API } from '../../providers/API'
import { Loading } from '../Loading/Loading'
import { invoicesData } from './Account.types'
import { SimpleBox } from '../../App.styles'
import { theme } from '../../theme/theme'

export const Invoices: React.FC = () => {
  const {
    userInfo, isLoading, setIsLoading,
  } = useContext(GlobalStateContext)
  const [invoices, setInvoices] = useState<invoicesData[]>([])
  const { t } = useTranslation()

  useEffect(() => {
    setIsLoading(true)
    API.get(`/invoices/${userInfo.id}  `, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setInvoices(response.data)
      setIsLoading(false)
    })
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {invoices?.map((item: invoicesData) => (
        <SimpleBox style={{ marginBottom: theme.space.md }}>
          <h2>{item.name}</h2>
          <strong>{t('general.field')}:</strong> {item.name} ({`${t('general.id')} ${item.fieldId}`})<br />
          <strong>{t('general.mix')}:</strong> {item.mixName} ({`${t('general.id')} ${item.mixId}`})<br />
          <strong>{t('invoices.orderDate')}:</strong> {item.orderDate}<br />
          <strong>{t('invoices.orderNumber')}:</strong> {item.orderNumber}<br />
          {item.invoiceURL !== null && (
            <>
              <strong>{t('invoices.invoice')}:</strong>{' '}
              <a href={item.invoiceURL} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faDownload} /> {t('invoices.downloadInvoice')}</a>
              <br />
            </>
          )}
        </SimpleBox>
      ))}
    </>
  )
}
