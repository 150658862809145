import styled from 'styled-components'
import { theme } from '../../theme/theme'
import { SimpleBox } from '../../App.styles'

export const SuccessNotification = styled.div`
    margin: ${theme.space.md} 0;
    padding: ${theme.space.md};
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
    font-weight: bold;
    border-radius: 10px;
`

export const FailureNotification = styled.div`
    margin: ${theme.space.md} 0;
    padding: ${theme.space.md};
    color: #2C2C2C;
    background-color: #f0d8d8;
    border-color: #f0d8d8;
    font-weight: bold;
    border-radius: 10px;
`

export const InfoNotification = styled.div<{marginBottom?: string}>(
  ({ marginBottom }) => `
    margin: ${theme.space.md} 0;
    margin-bottom: ${marginBottom || theme.space.md};
    padding: ${theme.space.md};
    color: #2C2C2C;
    background-color: #9dcefd;
    border-color: #f0d8d8;
    font-weight: bold;
    border-radius: 10px;
`,
)

export const WarningNotification = styled.div`
    margin: ${theme.space.md} 0;
    padding: ${theme.space.md};
    color: #2C2C2C;
    background-color: #fdf5b1;
    border-color: #fff392;
    font-weight: bold;
    border-radius: 10px;
`

export const SimpleNotification = styled(SimpleBox)`
    margin: ${theme.space.md} 0;
`
