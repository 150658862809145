import React, {
  Dispatch, createContext, useState, SetStateAction,
} from 'react'

interface OrderMixContextProps {
  changeSeedingNorms: boolean,
  setChangeSeedingNorms: Dispatch<SetStateAction<boolean>>,
  mixData: any,
  setMixData: Dispatch<SetStateAction<object>>,
}

export const OrderMixContext = createContext<OrderMixContextProps>({
  changeSeedingNorms: false,
  setChangeSeedingNorms: () => {},
  mixData: {},
  setMixData: () => {},
})

export const OrderMixProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [mixData, setMixData] = useState<any>({})
  const [changeSeedingNorms, setChangeSeedingNorms] = useState<boolean>(false)

  return (
    <OrderMixContext.Provider value={{
      changeSeedingNorms,
      setChangeSeedingNorms,
      mixData,
      setMixData,
    }}
    >
      {children}
    </OrderMixContext.Provider>
  )
}
