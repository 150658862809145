import React from 'react'
import { DaysCountItem, DaysTable } from './PremiumDaysCount.styles'

interface DaysCountProps {
  dataItem: any,
  icon: any,
  title: string
}

export const PremiumDaysCountItem: React.FC<DaysCountProps> = ({
  dataItem, icon, title,
}) => (

  <DaysCountItem>
    <div className="iconHolder">{icon}</div>
    <div className="tableHolder">
      <h3>{title}</h3>
      <DaysTable>
        {Object.keys(dataItem).map((key, index) => (

          <span className="tableItem" key={`tableItem_${index}`}>
            {key}
            <strong className="dayCount">{dataItem[key]}</strong>
          </span>

        ))}
      </DaysTable>
    </div>
  </DaysCountItem>

)
