import React, {
  useEffect, useState,
} from 'react'
import { Link, useParams } from 'react-router-dom'
import * as ls from 'local-storage'
import { useTranslation } from 'react-i18next'
import { FieldItem } from '../components/FieldsListing/Fields.types'
import { API } from '../providers/API'
import { AgromonStateProvider } from '../providers/AgromonStateProvider'
import { getAgromonData } from '../utils/getAgromonData'
import { SoilHistoryChart } from '../components/NdviFIeld/SoilHistoryChart'
import { Loading } from '../components/Loading/Loading'
import { HeaderInfo } from '../components/MixElements/HeaderInfo/HeaderInfo'
import { HeaderInfoTypes } from '../components/MixElements/MixElement.types'
import FieldMap from '../components/FieldMap/FieldMap'
import { NdviMapAndDataHolder, SoilDataHolder } from '../components/NdviFIeld/NdviField.styles'
import { getNdviImageCoords } from '../utils/getNdviImageCoords'
import { SimpleBox } from '../App.styles'
import { FieldSoilData } from '../components/NdviFIeld/FieldSoilData'

export const SatSoilHistory = () => {
  const { t } = useTranslation()
  const { fieldId } = useParams<{fieldId: string}>()

  const [field, setField] = useState<FieldItem>()
  const [mixHeaderInfo, setMixHeaderInfo] = useState<HeaderInfoTypes | null>(null)
  const [agromonData, setAgromonData] = useState<any>()
  const [currentSoilData, setCurrentSoilData] = useState<any>()
  const [ndviMapSource, setNdviMapSource] = useState<any>()
  const [dataFetchFailed, setdataFetchFailed] = useState<boolean>()

  useEffect(() => {
    API.get(`/fields/${fieldId}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response: {data: FieldItem}) => {
      setField(response.data)

      API.get(`/currentFieldSoil/${fieldId}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((currentSoilDataResponse) => {
        setCurrentSoilData(currentSoilDataResponse.data.soilData)
      })

      // take current time as one minute ago, to avoid getting error on agromon response
      const unixTime = Math.round((new Date()).getTime() / 1000) - 60
      const unixTimeMinusOneYear = unixTime - 31536000
      getAgromonData(
        unixTimeMinusOneYear.toString(), unixTime.toString(), response.data.OWid!,
      ).then((res: any) => {
        setAgromonData(res.data.reverse())
      })

      if (response.data.mix) {
        setMixHeaderInfo({
          title: response.data.mix?.mixName,
          fieldData: response.data,
          mixData: {
            seedingDate: response.data.mix.seedingDate,
            terminationDate: response.data.mix.terminationDate,
            totalMixArea: response.data.area!,
            mixName: response.data.mix.mixName,
          },
          connectedFieldsData: null,
        })
      }
    })
  }, [])

  useEffect(() => {
    if (agromonData && field) {
      let ndviImage
      try {
        // @ts-ignore
        ndviImage = agromonData[0].image.ndvi.replace('http://', 'https://')
      } catch {
        setdataFetchFailed(true)
      }

      const sourceCoordinates = getNdviImageCoords(field)

      setNdviMapSource({
        type: 'image',
        url: ndviImage,
        coordinates: sourceCoordinates,
      })
    }
  }, [agromonData, field])

  if (!field || !currentSoilData) {
    return <Loading />
  }

  return (
    <AgromonStateProvider>

      <h1>{t('navigation.soilHistory')} {field.name}</h1>
      {mixHeaderInfo && (
        <HeaderInfo {...mixHeaderInfo!} />
      )}
      <NdviMapAndDataHolder>
        <FieldMap
          fields={[field]}
          ndviData={ndviMapSource}
          expectNdviData
          containerMargin={false}
          height="400px"
        />
        <SoilDataHolder>
          <h2>{t('satSoilHistory.currentDataTitle1')} <span>{t('satSoilHistory.currentDataTitle2')}</span></h2>
          {dataFetchFailed ? (<div>{t('failedDataFetch')}</div>) : (
            <SimpleBox>
              <FieldSoilData soilData={currentSoilData} />
            </SimpleBox>
          )}
        </SoilDataHolder>
      </NdviMapAndDataHolder>

      <SoilHistoryChart fieldId={fieldId} />

      <Link style={{ marginTop: '16px', display: 'inline-block' }} to="/fields">&lt; {t('createField.backToMyFields')}</Link>

    </AgromonStateProvider>
  )
}
