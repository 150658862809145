import React, {
  useContext, useEffect, useState,
} from 'react'
import * as ls from 'local-storage'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import { API } from '../providers/API'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import { Loading } from '../components/Loading/Loading'
import { MainEffects } from '../components/MixElements/MainEffects'
import { MainRecommendedCrops } from '../components/MixElements/MainRecommendedCrops'
import { FullCropsAndEffects } from '../components/MixElements/FullCropsAndEffects'
import { MainDataContainer } from '../components/MixElements/MixElements.styles'
import { OrderMix } from '../components/MixCreation/OrderMix'
import { ProcessButtonsHolder } from '../components/MixCreation/CreateMix.styles'
import { MixOrderMainCrops } from '../components/MixElements/MixOrderMainCrops'
import { OrderMixProvider } from '../providers/OrderMixProvider'
import { MixDataTypes, HeaderInfoTypes } from '../components/MixElements/MixElement.types'
import { HeaderInfo } from '../components/MixElements/HeaderInfo/HeaderInfo'

export const MixDetails = () => {
  const {
    isLoading, setIsLoading,
  } = useContext(GlobalStateContext)
  const [mixData, setMixData] = useState<MixDataTypes>()
  const [orderDetails, setOrderDetails] = useState<null | any[]>(null)
  const [mixStatus, setMixStatus] = useState<string>()
  const [mixHeaderInfo, setMixHeaderInfo] = useState<HeaderInfoTypes>()
  const [showOrder, setShowOrder] = useState<boolean>(false)
  // @ts-ignore
  const { mixId } = useParams<number>()
  const { t } = useTranslation()

  useEffect(() => {
    setIsLoading(true)
    API.get(`/mixes/${mixId}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setMixData(response.data.mixDetails)
      setOrderDetails(response.data.orderDetails)
      setMixStatus(response.data.mixStatus)
      setMixHeaderInfo({
        title: response.data.mixName,
        fieldData: response.data.mainField,
        zoneName: response.data.zone ? response.data.zone.name : undefined,
        mixData: {
          seedingDate: response.data.seedingDate,
          terminationDate: response.data.terminationDate,
          totalMixArea: response.data.totalMixArea,
        },
        connectedFieldsData: response.data.connectedFields,
      })

      setIsLoading(false)
    })
  }, [])

  if (isLoading) {
    return (
      <Loading />
    )
  }

  return (
    <>
      {mixHeaderInfo && (
        <HeaderInfo {...mixHeaderInfo} />
      )}
      {!showOrder ? (
        <>
          {orderDetails && (
          <MixOrderMainCrops cropsData={orderDetails} title={t('mixResults.orderedMixCrops')} />
          )}
          {mixData?.mixType === 'smart' && (
          <MainDataContainer>
            <MainEffects mixData={mixData} effectsData={mixData?.mainEffect} title={t('mixResults.mainEffects')} />
            <MainRecommendedCrops mixData={mixData} crops={mixData?.mainCrops} title={t('mixResults.mainCrops')} />
          </MainDataContainer>
          )}

          <FullCropsAndEffects mixData={mixData} effectsData={mixData?.additionalEffects} cropsData={mixData?.additionalCrops} title={t('mixResults.extraCropsAndEffects')} />
        </>
      ) : (
        <OrderMixProvider>
          <OrderMix
            newMixId={mixId}
            detailedMix
            setShowOrder={setShowOrder}
          />
        </OrderMixProvider>
      )}

      {!showOrder && mixStatus === 'demo' && (
      <ProcessButtonsHolder>

        <Button
          variant="contained"
          type="button"
          color="primary"
          endIcon={<FontAwesomeIcon icon={faAnglesRight} />}
          onClick={() => setShowOrder(true)}
        >
          {t('mixResults.previewMix')}
        </Button>

      </ProcessButtonsHolder>
      )}
    </>
  )
}
