export const getAllCoordinates = (MapRef: any, multiZone = false, centerOnAllFields = false) => {
  let mapFeatures
  let mapChildren

  if (MapRef.current.props.children[0].props.children !== null) {
    mapChildren = MapRef.current.props.children
  } else {
    const childrenWithoutFirstItem = MapRef.current.props.children.slice(1)
    mapChildren = childrenWithoutFirstItem
  }

  // The array of features is nested in the children of the children of the Map component
  // The order of GeoJSONLayer componets in FieldMap.tsx dictates which children we need to access
  // to get the appropriate features

  if (multiZone && MapRef.current.props.children[0].props.children?.length > 0) {
    // if multiZone is true, and there are actual zones available
    const allFeatures = []

    const fieldsInZones = MapRef.current.props.children[0].props.children[0].map(
      (item: any) => item.props.data.features,
    )

    allFeatures.push(fieldsInZones)

    if (centerOnAllFields) {
      // if we want to center on all fields, we also need the non-zoned fields
      const nonZonedFields = MapRef.current.props.children[0]
        .props.children[1].props.data.features
      allFeatures.push(nonZonedFields)
    }

    mapFeatures = allFeatures.flat(Infinity)
  } else if (Array.isArray(mapChildren)) {
    // else we get all fields regardless of zones
    if (mapChildren[0].props.data.features) {
      mapFeatures = mapChildren[0].props.data.features
    } else {
      mapFeatures = mapChildren[0].props.data
    }
  } else {
    mapFeatures = mapChildren.props.data.features
  }

  let allCoordinates = []

  if (mapFeatures && mapFeatures.length > 0) {
    allCoordinates = mapFeatures.reduce(
      (finalCoords:any, currentCoords: any) => {
        if (currentCoords.geometry.coordinates[0].length === 2
           || currentCoords.geometry.coordinates[0].length === 3) {
          currentCoords.geometry.coordinates.forEach(
            (item:any) => finalCoords.push(item),
          )
        } else if (currentCoords.geometry.coordinates[0][0].length === 2
          || currentCoords.geometry.coordinates[0][0].length === 3) {
          currentCoords.geometry.coordinates[0].forEach(
            (item:any) => finalCoords.push(item),
          )
        } else if (currentCoords.geometry.coordinates[0][0][0].length === 2
          || currentCoords.geometry.coordinates[0][0][0].length === 3) {
          currentCoords.geometry.coordinates[0][0].forEach(
            (item:any) => finalCoords.push(item),
          )
        }
        return finalCoords
      }, [],
    )
  }

  return allCoordinates
}
