import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import * as ls from 'local-storage'
import { MapDataContext } from '../../../../providers/MapDataProvider'
import { API } from '../../../../providers/API'
import { AccordionItemHeader } from '../accordionElements/AccordionItemHeader'
import { AccordionItemContent } from '../accordionElements/AccordionElements.styles'

type dataFetchingProps = 'temp' | 'clouds' | 'humidity' | 'rain' | 'pressure' | 'wind'
type dataOptionsType = { value: dataFetchingProps, name: string }

export const WeatherDataLayer = () => {
  const {
    allVisibleFieldIds,
    setDataLayerContent,
    activeDataSection,
    activeDataLayer,
    setActiveDataLayer,
    setMapOverlayLoading,
  } = useContext(MapDataContext)
  const { t } = useTranslation()

  const [weatherData, setWeatherData] = useState<any>([])
  const [dataFetchingType, setDataFetchingType] = useState<dataFetchingProps>()

  const weatherDataOptions: dataOptionsType[] = [
    { value: 'temp', name: t('mapOverlay.weatherOptionLabels.temperature') },
    { value: 'clouds', name: t('mapOverlay.weatherOptionLabels.clouds') },
    { value: 'humidity', name: t('mapOverlay.weatherOptionLabels.humidity') },
    { value: 'rain', name: t('mapOverlay.weatherOptionLabels.rain') },
    { value: 'pressure', name: t('mapOverlay.weatherOptionLabels.pressure') },
    { value: 'wind', name: t('mapOverlay.weatherOptionLabels.wind') }]

  const fetchWeatherData = ({ dataType } : {dataType: dataFetchingProps}) => {
    setMapOverlayLoading(true)
    setWeatherData([])
    setActiveDataLayer(dataType)

    API.post('/currentWeatherAggregator', { fieldIds: allVisibleFieldIds }, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      const weatherDataObject = response.data.map((item: any) => ({
        fieldId: item.fieldId,
        weatherData: item,
        dataType,
      }))
      setWeatherData(weatherDataObject)
      setMapOverlayLoading(false)
    })
  }

  useEffect(() => {
    const weatherDataContent = weatherData.map((item: any) => {
      let displayData = ''

      switch (item.dataType) {
        case 'temp':
          displayData = `${item.weatherData.main.temp.toFixed(2)}°C`
          break
        case 'clouds':
          displayData = `${item.weatherData.clouds.all.toFixed(2)}%`
          break
        case 'humidity':
          displayData = `${item.weatherData.main.humidity.toFixed(2)}%`
          break
        case 'pressure':
          displayData = `${item.weatherData.main.pressure.toFixed(2)}hPa`
          break
        case 'wind':
          displayData = `${item.weatherData.wind.speed.toFixed(2)}m/s`
          break
        case 'rain':
          if (item.weatherData.rain) {
            const rainKey = Object.keys(item.weatherData.rain)[0]
            displayData = `${t('mapOverlay.for')} ${rainKey.split('h')[0]}ч. - ${item.weatherData.rain[rainKey].toFixed(2)}mm`
          } else {
            displayData = t('mapOverlay.noRain')
          }
          break
        default:
          displayData = t('mapOverlay.noData')
      }

      const name = weatherDataOptions.find(
        (option: dataOptionsType) => option.value === item.dataType,
      )?.name

      return ({
        fieldId: item.fieldId,
        title: name,
        data: displayData,
      })
    })
    setDataLayerContent(weatherDataContent)
  }, [weatherData])

  const handleDataFetching = ({ dataType } : {dataType: dataFetchingProps}) => {
    fetchWeatherData({ dataType })
    setDataFetchingType(dataType)
  }

  useEffect(() => {
    if (dataFetchingType === undefined || activeDataSection !== 'weatherData') return
    fetchWeatherData({ dataType: dataFetchingType })
  }, [allVisibleFieldIds])

  return (
    <>
      <AccordionItemHeader title="Weather data" value="weatherData" type="data" />
      <AccordionItemContent isOpen={activeDataSection === 'weatherData'}>
        <RadioGroup
          name="dataLayers"
          value={activeDataLayer}
          onChange={(e) => {
            handleDataFetching({ dataType: e.target.value as dataFetchingProps })
          }}
        >
          {weatherDataOptions.map((item: dataOptionsType) => (
            <FormControlLabel key={`weatherDataOption_${item.value}`} value={item.value} control={<Radio size="small" />} label={item.name} />
          ))}
        </RadioGroup>
      </AccordionItemContent>
    </>
  )
}
