import React, {
  Dispatch, useContext, useEffect, useRef, useState, SetStateAction,
  useCallback,
} from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import * as ls from 'local-storage'
import { GeoJSONLayer } from 'react-mapbox-gl'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { format } from 'date-fns'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import { CreateMixHolder, ProcessButtonsHolder, FormContainer } from './CreateMix.styles'
import { calcBoundsFromCoordinates } from '../../utils/calcBounds'
import { StaticMap } from '../../utils/mapInstance'
import { FieldItem } from '../FieldsListing/Fields.types'
import { API } from '../../providers/API'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { InfoNotification, WarningNotification, FailureNotification } from '../SuccessNotification/SuccessNotification.styles'
import { Loading } from '../Loading/Loading'
import { theme } from '../../theme/theme'
import { CreditsPrompt } from '../CreditsPrompt/CreditsPrompt'
import i18n from '../../i18n'
import { ZoneField, ZoneListingType } from '../ZonesListing/ZonesListing.types'
import { CropRotationResponse, CropRotationItem } from '../CropRotation/CropRotation.types'
import { getAllCoordinates } from '../../utils/getAllCoordsFromMap'
import { updateCropLabels, useCashCropsData, CashCropTypes } from '../../utils/useCashCropsData'
import { CropRotation } from '../CropRotation/CropRotation'

interface MixBasicsProps {
  setFieldValue: any
  fieldValues: any
  formHandleChange: any
  setProcessStep: Dispatch<SetStateAction<number>>
  processStep: number
  setNewMixId: Dispatch<SetStateAction<number>>
  setStepTitle: Dispatch<SetStateAction<string>>
  mixType: string
}

interface FieldSelectProps {
  label: string
  id: number
}

interface CashCropOptionsTypes {
  label: string
  id: number
}

export const MixBasics: React.FC<MixBasicsProps> = ({
  fieldValues,
  processStep, setProcessStep, setNewMixId, formHandleChange,
  setFieldValue, setStepTitle, mixType,
}) => {
  const {
    userInfo, isLoading, setIsLoading,
  } = useContext(GlobalStateContext)
  const [center] = useState<[number, number]>([25.181873, 42.229602])
  const [zoom] = useState<[number]>([8])
  const [fields, setFields] = useState<FieldItem[]>([])
  const [zones, setZones] = useState<ZoneListingType[]>([])
  const [areaType, setAreaType] = useState<string>(fieldValues.areaType)
  const [cashCropSelectOptions, setCashCropsSelectOptions] = useState<CashCropOptionsTypes[]>([])
  const [availabeCrops, setAvailableCrops] = useState<CashCropTypes[]>([])
  const [prevCrops, setPrevCrops] = useState<any[]>([])
  const [prevCropsObject, setPrevCropsObject] = useState<any[]>()
  const [prevCropsLimitReached, setPrevCropsLimitReached] = useState(false)
  const [fieldOptions, setFieldOptions] = useState<FieldSelectProps[]>([])
  const [selectedFieldOption, setSelectedFieldOption] = useState<FieldSelectProps>()
  const [zoneOptions, setZoneOptions] = useState<FieldSelectProps[]>([])
  const [selectedZoneOption, setSelectedZoneOption] = useState<FieldSelectProps>()
  const [geojsonFields, setGeojsonFields] = useState([])
  const [geojsonZoneFields, setGeojsonZoneFields] = useState<FieldItem[]>([])
  const [step1Errors, setStep1Errors] = useState<any>({})
  const [germinationPeriodError, setGerminationPeriodError] = useState<boolean>(false)
  const [mixError, setMixError] = useState<string>('')
  const [isCreatingField, setIsCreatingField] = useState<boolean>(false)
  const [creditsPromptShow, setCreditsPromptShow] = useState<boolean>(false)
  const [aiMixPricingData, setAiMixPricingData] = useState<any>({})
  const [cropRotationData, setCropRotationData] = useState<CropRotationResponse | undefined>()
  const history = useHistory()
  const { t } = useTranslation()
  const MapRef = useRef<any>(null)
  const { cashCrops, cashCropSelectOptions: cashCropSelectOptionsSource } = useCashCropsData()

  setStepTitle(mixType === 'manual' ? t('createMix.createMixTitle') : t('createMix.createAiMixTitle'))
  const step1ValidationObject = {
    mixName: fieldValues.mixName,
    zoneId: fieldValues.areaType === 'zone' ? fieldValues.zoneId : '',
    nextCrop: fieldValues.nextCrop,
    prevCrops,
  }

  useEffect(() => {
  // setup options for cashCrop autocompelte fields
    if (cashCrops.length > 0 && cashCropSelectOptionsSource.length > 0) {
      setCashCropsSelectOptions(cashCropSelectOptionsSource)
      setAvailableCrops(cashCrops)
    }
  }, [cashCrops, cashCropSelectOptionsSource])

  const year = new Date().getFullYear()
  const month = new Date().getMonth()
  const day = new Date().getDate()
  const dateLastYear = new Date(year - 1, month, day)
  const dateNextYear = new Date(year + 1, month, day)

  const [terminationDate, setTerminationDate] = React.useState<Date>(
    new Date(year, month, day + 40),
  )
  const [seedingDate, setSeedingDate] = React.useState<Date>(
    new Date(),
  )

  useEffect(() => {
    if (cropRotationData?.nextCrop) {
      setFieldValue('nextCrop', cropRotationData?.nextCrop?.cropId)
    } else {
      setFieldValue('nextCrop', '')
    }
    if (cropRotationData?.previousCrops) {
      const cropsArray = cropRotationData?.previousCrops.map(
        (item:CropRotationItem) => item.cropId.toString(),
      )
      setPrevCrops(cropsArray)
    }
  }, [cropRotationData])

  useEffect(() => {
    if (fieldValues.areaType === 'zone') {
      const cashCropValuesObject = Object.keys(fieldValues).filter((item: any) => item.match(/CashCropId/))
      cashCropValuesObject.forEach((item: any) => {
        setPrevCrops([])
        setFieldValue(item, '')
      })
    }
  }, [fieldValues.areaType])

  const seedingHandleChange = (newValue: Date) => {
    setSeedingDate(newValue)
    setFieldValue('seedingDate', newValue)
    const fortyDaysLater = new Date()
    fortyDaysLater.setDate(newValue!.getDate() + 40)
    setTerminationDate(fortyDaysLater)
    setFieldValue('terminationDate', fortyDaysLater)
  }

  const terminationHandleChange = (newValue: Date) => {
    setTerminationDate(newValue)
    setFieldValue('terminationDate', newValue)
  }

  const DatePickerTheme = createTheme({
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            width: '48%',
          },
        },
      },
    },
  })

  const zoomInOnSelection = () => {
    if (fieldValues.areaType === 'field') {
      const selectedField = fields.filter((item) => item.id === selectedFieldOption!.id)
      const coords = JSON.parse(selectedField[0].coordinates)
      const geoCoordinates = coords.geometry.coordinates[0]
      MapRef.current.state.map.fitBounds(
        calcBoundsFromCoordinates(geoCoordinates), { padding: 10 },
      )
    } else {
      const allCoordinates = getAllCoordinates(MapRef)
      if (allCoordinates.length > 0) {
        MapRef.current.state.map.fitBounds(
          calcBoundsFromCoordinates(allCoordinates), { padding: 10 },
        )
      }
    }
  }

  const getZoneFieldsToMap = (zonesToUse: ZoneListingType[]) => {
    const fieldsList: ZoneField[] = []
    zonesToUse.forEach((item:ZoneListingType) => {
      if (item.containedFields !== null) {
        item.containedFields.forEach((zoneFieldItem: ZoneField) => {
          fieldsList.push(JSON.parse(zoneFieldItem.coordinates))
        })
      }
    })

    setGeojsonZoneFields(fieldsList)
  }

  useEffect(() => {
    setIsLoading(true)
    // setup options for field autocomplete and geoJsonData for map display
    API.get(`/fields/search/${userInfo.id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setFields(response.data)
      const geoJsonData = response.data.map((item:FieldItem) => {
        const data = JSON.parse(item.coordinates)
        return data
      })
      setGeojsonFields(geoJsonData)

      const fieldOptionsArray:any = []
      response.data.forEach((item:FieldItem) => {
        fieldOptionsArray.push({ label: item.name, id: item.id })
      })

      setFieldOptions(fieldOptionsArray)
      setIsLoading(false)
    })

    API.get('/zonesListing', { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setZones(response.data)

      let zonesToUse: any = []
      if (fieldValues.areaType === 'zone' && fieldValues.zoneId !== '') {
        zonesToUse = response.data.filter((item:ZoneListingType) => item.id === fieldValues.zoneId)
      } else {
        zonesToUse = response.data
      }

      getZoneFieldsToMap(zonesToUse)

      const zonesOptionsArray:any = []
      response.data.forEach((item:ZoneField) => {
        zonesOptionsArray.push({ label: item.name, id: item.id })
      })

      setZoneOptions(zonesOptionsArray)
      setIsLoading(false)
    })

    // apply current date to formik values
    setFieldValue('seedingDate', seedingDate)
    setFieldValue('terminationDate', terminationDate)

    API.get('/pricingTable', { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      const AIMixPriceData = response.data.filter((item: any) => item.description === 'aiMix').pop()
      setAiMixPricingData(AIMixPriceData)
    })
  }, [])

  useEffect(() => {
    setCashCropsSelectOptions(updateCropLabels(availabeCrops))
  }, [i18n.language])

  useEffect(() => {
    // pre-fill field autocomplete value with already selected one
    // or pre-fill with first in fields array
    if (fieldOptions.length > 0) {
      const selectedOption = fieldOptions?.filter((item) => item.id === fieldValues.fieldId).pop()
      if (selectedOption) {
        setSelectedFieldOption(selectedOption)
      } else {
        setSelectedFieldOption(fieldOptions[0])
        setFieldValue('fieldId', fieldOptions[0].id)
      }
    }
  }, [fieldOptions])

  useEffect(() => {
    // pre-fill field autocomplete value with already selected one
    // or pre-fill with first in fields array
    if (zoneOptions.length > 0) {
      const selectedOption = zoneOptions?.filter((item) => item.id === fieldValues.zoneId).pop()
      if (selectedOption) {
        setSelectedZoneOption(selectedOption)
      }
      // } else {
      //   console.log()
      //   setSelectedZoneOption(zoneOptions[0])
      //   setFieldValue('zoneId', zoneOptions[0].id)
      // }
    }
  }, [zoneOptions])

  useEffect(() => {
    // get values for n1-n4 previous CashCropId fields
    // set object to use for pre-filling previous crops autocomplete field
    const cashCropValuesObject = Object.keys(fieldValues).filter((item: any) => item.match(/CashCropId/))
    if (fieldValues.areaType === 'zone') {
      const cashCropValuesArray = cashCropValuesObject.map((item:any) => fieldValues[item])
      const selectedCashCropOptions = cashCropSelectOptions.filter(
        (cashCropItem) => cashCropValuesArray.some(
          (selectedCropItem: any) => parseInt(selectedCropItem) === cashCropItem.id,
        ),
      )

      setPrevCropsObject(selectedCashCropOptions)
    }
  }, [cashCropSelectOptions])

  const saveMixData = () => {
    // setup data for previous cashcrops selected in autocomplete
    const prevCropsToSave: any = {}

    if (fieldValues.areaType === 'zone') {
      prevCrops.forEach((item:any, index: number) => {
      // set field value to formik props
        setFieldValue(`n${index + 1}CashCropId`, item)
        // set field value to array used to send to endpoint
        prevCropsToSave[`n${index + 1}CashCropId`] = parseInt(item)
      })
    } else {
      cropRotationData?.previousCrops.forEach((item:CropRotationItem, index: number) => {
        if (index > 3) return
        // set field value to formik props
        setFieldValue(`n${index + 1}CashCropId`, item.cropId)
        // set field value to array used to send to endpoint
        prevCropsToSave[`n${index + 1}CashCropId`] = item.cropId
      })
    }

    const mixData = {
      mixName: fieldValues.mixName,
      fieldId: fieldValues.areaType === 'field' ? fieldValues.fieldId : null,
      zoneId: fieldValues.areaType === 'zone' ? fieldValues.zoneId : null,
      areaType: fieldValues.areaType,
      nextCrop: fieldValues.nextCrop,
      ...prevCropsToSave,
      userId: userInfo.id,
      seedingDate: format(fieldValues.seedingDate, 'yyyy-MM-dd'),
      terminationDate: format(fieldValues.terminationDate, 'yyyy-MM-dd'),
    }

    setIsCreatingField(true)

    API.post(mixType === 'manual' ? '/createBasicMix' : '/mixes', mixData, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      if (response.data.message) {
        setMixError(response.data.message)
        setIsCreatingField(false)
      } else {
        setNewMixId(response.data.mix.id)
        setProcessStep(processStep + 1)
        setIsCreatingField(false)
      }
    })
  }

  const checkAiMixPayment = () => {
    API.get(`/fields/${fieldValues.fieldId}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      const isMixPaid = response.data.aiMix === 1

      if (isMixPaid) {
        setIsCreatingField(true)
        saveMixData()
      } else {
        setCreditsPromptShow(true)
      }
    })
  }

  const checkPrevCropsDisabled = useCallback(
    (option: any) => prevCropsLimitReached && !prevCropsObject?.includes(option),
    [prevCropsLimitReached, prevCropsObject],
  )

  const validateStep1 = (values:any) => {
    const errors:any = {}
    if (values.mixName === '') {
      errors.mixName = t('createMix.errors.mixName')
    }
    if (fieldValues.areaType === 'zone' && values.zoneId === '') {
      errors.zoneId = t('createMix.errors.zoneId')
    }
    if (!values.nextCrop) {
      errors.nextCrop = t('createMix.errors.nextCrop')
    }
    if (values.prevCrops.length < 1) {
      errors.prevCrops = t('createMix.errors.prevCrop')
    }

    // ...
    setStep1Errors(errors)
    return errors
  }

  useEffect(() => {
    if ((selectedFieldOption && fieldValues.areaType === 'field')
    || (selectedZoneOption && fieldValues.areaType === 'zone')) {
      zoomInOnSelection()
    }
  }, [areaType, geojsonZoneFields])

  const handleGoToNextStep = () => {
    const formErrors = validateStep1(step1ValidationObject)

    if (Object.keys(formErrors).length === 0) {
      const diffTime = Math.abs(
        // @ts-ignore
        new Date(fieldValues.seedingDate) - new Date(fieldValues.terminationDate),
      )
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      if (diffDays < 40) {
        setGerminationPeriodError(true)
      } else if (mixType === 'ai') {
        checkAiMixPayment()
      } else {
        saveMixData()
      }
    }
  }

  if (isLoading
    || (fields && fields.length === 0)
    || !selectedFieldOption) {
    return <Loading />
  }

  return (
    <>
      {fields && fields.length > 0 && selectedFieldOption ? (
        <>
          <CreateMixHolder>
            {geojsonFields.length > 0 && selectedFieldOption !== undefined && (
            <StaticMap
        // eslint-disable-next-line react/style-prop-object
              style="mapbox://styles/mapbox/satellite-streets-v11"
              containerStyle={{
                height: '400px',
                width: '100%',
                flexBasis: '250px',
                marginRight: '20px',
                border: `3px solid ${theme.colors.blueUI}`,
                borderRadius: '5px',
                overflow: 'hidden',
              }}
              zoom={zoom}
              center={center}
              ref={MapRef}
              onStyleLoad={() => {
                zoomInOnSelection()
              }}
            >
              <GeoJSONLayer
                fillPaint={{
                  'fill-color': '#FF0',
                  'fill-opacity': 0.4,
                }}
                data={{
                  type: 'FeatureCollection',
                  features: fieldValues.areaType === 'field' ? geojsonFields : geojsonZoneFields,
                }}
              />
            </StaticMap>
            )}
            <FormContainer style={{ flexGrow: '1' }}>
              <CreditsPrompt
                showPrompt={creditsPromptShow}
                setShowPrompt={setCreditsPromptShow}
                textContent={t('createMix.creditsPromptText')}
                title={t('createMix.creditsPromptTitle')}
                pricingData={aiMixPricingData}
                fieldId={fieldValues.fieldId}
                callback={saveMixData}
                modal
              />
              {t('createMix.createMixFor')}:
              <RadioGroup
                row
                aria-label="gender"
                name="areaType"
                value={fieldValues.areaType}
                onChange={(event) => {
                  setFieldValue('areaType', event.currentTarget.value)
                  setAreaType(event.currentTarget.value)
                }}
                style={{ marginBottom: theme.space.md }}
              >
                <FormControlLabel value="field" checked={fieldValues.areaType === 'field'} control={<Radio />} label={t('general.field')} />
                <FormControlLabel value="zone" checked={fieldValues.areaType === 'zone'} control={<Radio />} label={t('general.zone')} />
              </RadioGroup>
              {fieldValues.areaType === 'field' && fieldOptions.length > 0 && selectedFieldOption !== undefined && (
              <Autocomplete
                disablePortal
                options={fieldOptions}
                defaultValue={selectedFieldOption}
                renderInput={(params) => <TextField {...params} label={t('general.field')} />}
                fullWidth
                onChange={(event, newValue: FieldSelectProps | null) => {
                  setFieldValue('fieldId', newValue?.id)
                  setStep1Errors({})
                  const selectedField = fields.filter((item) => item.id === newValue?.id)
                  const coords = JSON.parse(selectedField[0].coordinates)
                  const geoCoordinates = coords.geometry.coordinates[0]
                  MapRef.current.state.map.fitBounds(
                    calcBoundsFromCoordinates(geoCoordinates), { padding: 10 },
                  )
                  if (mixError !== '') {
                    setMixError('')
                  }
                }}
              />
              )}
              {fieldValues.areaType === 'zone' && (
                zoneOptions.length > 0 ? (
                // && selectedZoneOption !== undefined ? (
                  <Autocomplete
                    disablePortal
                    options={zoneOptions}
                    defaultValue={selectedZoneOption}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="zoneId"
                        label={t('general.zone')}
                        error={step1Errors?.zoneId}
                        helperText={step1Errors.zoneId ? step1Errors.zoneId : ''}
                      />
                    )}
                    fullWidth
                    onChange={(event, newValue: FieldSelectProps | null) => {
                      setFieldValue('zoneId', newValue?.id)
                      const selectedZone = zones.filter((item) => item.id === newValue?.id)
                      getZoneFieldsToMap(selectedZone)

                      if (mixError !== '') {
                        setMixError('')
                      }
                    }}
                  />
                ) : (
                  <InfoNotification>
                    {t('createMix.createZoneNotice')}
                  </InfoNotification>
                )

              )}
              {((fieldValues.areaType === 'zone' && zoneOptions.length > 0)
            || (fieldValues.areaType === 'field' && fieldOptions.length > 0)) && (
              <>
                <TextField
                  label={t('general.name')}
                  name="mixName"
                  id="mixName"
                  value={fieldValues.mixName}
                  onChange={formHandleChange}
                  fullWidth
                  error={step1Errors?.mixName}
                  helperText={step1Errors.mixName ? step1Errors.mixName : ''}
                />
                {cashCropSelectOptions.length > 0 && (
                  <>
                    {fieldValues.areaType === 'zone' && (
                    <>
                      <Autocomplete
                        disablePortal
                        options={cashCropSelectOptions}
                        defaultValue={cashCropSelectOptions[fieldValues.nextCrop - 1]}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="nextCrop"
                            label={t('createMix.nextCashCrop')}
                            error={step1Errors?.nextCrop}
                            helperText={step1Errors.nextCrop ? step1Errors.nextCrop : ''}
                          />
                        )}
                        fullWidth
                        onChange={(event, newValue: CashCropOptionsTypes | null) => {
                          setFieldValue('nextCrop', newValue?.id)
                        }}
                      />
                      <Autocomplete
                        multiple
                        options={cashCropSelectOptions}
                        value={prevCropsObject}
                        getOptionDisabled={checkPrevCropsDisabled}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="prevCrops"
                            label={t('createMix.previousCashCrops')}
                            error={step1Errors?.prevCrops}
                            helperText={step1Errors.prevCrops ? step1Errors.prevCrops : (prevCropsObject?.length === 4 && 'Избрали сте максималния брой от 4 допустими предишни култури')}
                          />
                        )}
                        onChange={(event, newValue) => {
                          // save value for autocomplete tags management
                          setPrevCropsObject(newValue)

                          // setup and save data for sending to endpoint
                          const cropsArray = newValue.map((item:any) => item.id.toString())
                          setPrevCrops([...cropsArray])

                          // determine if max allowed number of options is reached
                          setPrevCropsLimitReached(newValue.length >= 4)
                        }}
                      />
                    </>
                    )}
                    <ThemeProvider theme={DatePickerTheme}>
                      {/*
              // @ts-ignore */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <DesktopDatePicker
                // @ts-ignore
                            label={t('createMix.seedingDate')}
                            inputFormat="MM/dd/yyyy"
                            value={seedingDate}
                            onChange={(newValue) => seedingHandleChange(newValue!)}
                            renderInput={(params: any) => (
                              <TextField
                                {...params}
                              />
                            )}
                            minDate={dateLastYear}
                            maxDate={dateNextYear}
                          />
                          <DesktopDatePicker
                // @ts-ignore
                            label={t('createMix.terminationDate')}
                            inputFormat="MM/dd/yyyy"
                            value={terminationDate}
                            onChange={(newValue) => terminationHandleChange(newValue!)}
                            renderInput={(params: any) => (
                              <TextField
                                {...params}
                              />
                            )}
                          />
                        </div>
                      </LocalizationProvider>
                    </ThemeProvider>
                  </>
                )}
                {mixError !== '' && (
                <WarningNotification>
                  {t(`createMix.errors.${mixError}`)}
                </WarningNotification>
                )}
                {germinationPeriodError && (
                <WarningNotification>
                  {t('createMix.terminationPeriodWarning')}
                  <div style={{ marginTop: theme.space.lg }}>
                    <Button
                      variant="contained"
                      type="button"
                      color="primary"
                      onClick={() => {
                        saveMixData()
                        setProcessStep(processStep + 1)
                      }}
                      style={{ marginRight: theme.space.md }}
                    >
                      {t('general.yes')}
                    </Button>

                    <Button
                      variant="contained"
                      type="button"
                      color="primary"
                      onClick={() => {
                        setGerminationPeriodError(false)
                      }}
                    >
                      {t('general.no')}
                    </Button>
                  </div>
                </WarningNotification>
                )}

              </>
              )}
            </FormContainer>
          </CreateMixHolder>
          {fieldValues.areaType === 'field' && fieldOptions.length > 0 && (
            <>
              {step1Errors.nextCrop || step1Errors.prevCrops ? (
                <FailureNotification>
                  {step1Errors.nextCrop && (
                    <p>{t('createMix.errors.cropRotationNextCrop')}</p>
                  )}
                  {step1Errors.prevCrops && (
                    <p>{t('createMix.errors.cropRotationPrevCrop')}</p>
                  )}
                </FailureNotification>
              ) : null}
              <CropRotation
                fieldId={fieldValues.fieldId}
                handleCropRotationData={setCropRotationData}
              />
            </>
          )}
          <ProcessButtonsHolder>
            <LoadingButton
              variant="contained"
              type="button"
              color="primary"
              loading={isCreatingField}
              loadingPosition="start"
              disabled={mixError === 'There is an ordered mix already!' || mixError === 'There is no weather data coverage for the selected field, mix creation is NOT possible!'}
              onClick={
        () => handleGoToNextStep()
            }
              endIcon={<FontAwesomeIcon icon={faAnglesRight} />}
            >
              {t('general.nextStep')}
            </LoadingButton>

          </ProcessButtonsHolder>
        </>
      ) : (
        <>
          <InfoNotification>
            {t('createMix.createFieldNotice')}
          </InfoNotification>
          <Button
            variant="contained"
            type="button"
            color="primary"
            onClick={() => {
              history.push('/createField')
            }}
          >
            {t('general.createField')}
          </Button>
        </>
      )}
    </>
  )
}
