import React, {
  useEffect, useState,
} from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FieldItem as FieldItemTypes } from '../FieldsListing/Fields.types'
import { FieldGridItem } from '../FieldsListing/FieldsListing.styles'
import { FieldPolygonHeader } from '../FieldsListing/FieldPolygonHeader'
import { getAgromonData } from '../../utils/getAgromonData'
import { Loading } from '../Loading/Loading'
import { theme } from '../../theme/theme'
import { NvdiColorSwatch, StatRow, ValueHolder } from './NdviField.styles'
import { ndviColorRange } from '../../utils/ndviColorRange'

interface NdviFieldsListingProps {
  field: FieldItemTypes
  setFieldWithAgromonData?: (id: number, data: object) => void
  showFieldPolygonHeader?: boolean
}

export const FieldItem: React.FC<NdviFieldsListingProps> = ({
  field, setFieldWithAgromonData, showFieldPolygonHeader = true,
}) => {
  const [agromonData, setAgromonData] = useState<any>()

  const history = useHistory()
  const { t } = useTranslation()

  const goToFieldPage = (fieldId: number) => {
    history.push(`/ndviField/${fieldId}`)
  }

  useEffect(() => {
    // take current time as one minute ago, to avoid getting error on agromon response
    const unixTime = Math.round((new Date()).getTime() / 1000) - 60
    const unixTimeMinusOneYear = unixTime - 31536000
    getAgromonData(
      unixTimeMinusOneYear.toString(), unixTime.toString(), field.OWid!,
    ).then((res: any) => {
      const agromonStatUrl = res.data[res.data.length - 1].stats.ndvi.replace('http://', 'https://')
      axios.get(agromonStatUrl).then((agroStatResponse) => {
        setAgromonData(agroStatResponse.data)
        if (setFieldWithAgromonData) {
          setFieldWithAgromonData(field.id, agroStatResponse.data)
        }
      }).catch(() => {
        setAgromonData(undefined)
      })
    })
  }, [])

  return (
    <FieldGridItem>
      <div
        tabIndex={0}
        role="button"
        onKeyUp={() => {
          goToFieldPage(field.id)
        }}
        onClick={
            () => {
              goToFieldPage(field.id)
            }
          }
      >
        {showFieldPolygonHeader && (
        <FieldPolygonHeader
          field={field}
          key={field.id}
          showArea={false}
        />
        )}
      </div>
      {agromonData ? (
        <>
          <StatRow
            className="fieldStats"
            tabIndex={0}
            role="button"
            onKeyUp={() => {
              goToFieldPage(field.id)
            }}
            onClick={
            () => {
              goToFieldPage(field.id)
            }
          }
          >
            <span>{t('general.average')}</span>
            <ValueHolder>
              {agromonData.median.toFixed(2)}
              <NvdiColorSwatch
            // @ts-ignore
                color={theme.colors.ndviColors[ndviColorRange(agromonData.median)]}
              />
            </ValueHolder>
          </StatRow>
          <StatRow
            className="fieldStats"
            tabIndex={0}
            role="button"
            onKeyUp={() => {
              goToFieldPage(field.id)
            }}
            onClick={
          () => {
            goToFieldPage(field.id)
          }
        }
          >
            <span>{t('general.maxShort')}</span>
            <ValueHolder>
              {agromonData.max.toFixed(2)}
              <NvdiColorSwatch
            // @ts-ignore
                color={theme.colors.ndviColors[ndviColorRange(agromonData.max)]}
              />
            </ValueHolder>
          </StatRow>
          <StatRow
            className="fieldStats"
            tabIndex={0}
            role="button"
            onKeyUp={() => {
              goToFieldPage(field.id)
            }}
            onClick={
          () => {
            goToFieldPage(field.id)
          }
        }
          >
            <span>{t('general.minShort')}</span>
            <ValueHolder>
              {agromonData.min.toFixed(2)}
              <NvdiColorSwatch
            // @ts-ignore
                color={theme.colors.ndviColors[ndviColorRange(agromonData.min)]}
              />
            </ValueHolder>
          </StatRow>
        </>
      ) : (
        <Loading />
      )}
    </FieldGridItem>
  )
}
