import styled from 'styled-components'
import { theme } from '../../theme/theme'

export const SummaryTable = styled.table`
  width: 100%;
  color: ${theme.colors.blueBGUI};

  th {
    text-align: center;
    padding: ${theme.space.sm} 0;
    width: 26%;

    &:first-child {
      width: 22%;
    }

    .colorIndicator {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;
      margin-right: ${theme.space.sm};
    }
  }

  tr:not(:first-child) {
    cursor: pointer;

    &:hover {
      box-shadow: 0px 5px 8px rgb(0 0 0 / 30%);
    }
  }

  ${theme.mediaQueries.mdMax} {
    display: block;

    th {
      display: none;
    }

    tr {
      width: 100%;
      display: block;
      margin-bottom: ${theme.space.md};
    }

    td {
      display: block;
      width: 100%;

      &::before {
        content: attr(data-responseive-title);
        font-weight: bold;
        margin-right: ${theme.space.sm};
      }
    }
}
`

export const SoftScoreCell = styled.td<{contrastingColor?: boolean}>(
  ({ contrastingColor }) => `
  text-align: center;
  ${contrastingColor && 'color: #FFF'};
  border-bottom: 5px solid #FFF;
  padding: ${theme.space.sm} 0;
  `,
)

export const SoilGroupDataItem = styled.span`
  text-align: center;
  border: 1px solid ${theme.colors.blueBGUI};
  padding: ${theme.space.sm} 0;

  strong {
    display: block;
    margin-bottom: ${theme.space.sm};
  }
`
