import React, {
  useContext, useEffect, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import * as ls from 'local-storage'
import { useParams, useHistory } from 'react-router'
import Button from '@mui/material/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons'
import { API } from '../providers/API'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import { Loading } from '../components/Loading/Loading'
import { FieldItem } from '../components/FieldsListing/Fields.types'
import FieldMap from '../components/FieldMap/FieldMap'
import { SoilDataProps } from '../components/SoilAnalysis/SoilAnalysis.types'
import { SoilGroupsContainer } from '../components/SoilAnalysis/SoilGroupsContainer/SoilGroupsContainer'
import { theme } from '../theme/theme'
import { SoilGroupDataItem } from '../components/SoilAnalysis/SoilAnalysis.styles'

export const SoilAnalysisDetails = () => {
  const {
    isLoading, setIsLoading,
  } = useContext(GlobalStateContext)
  const [soilData, setSoilData] = useState<SoilDataProps>()
  const [field, setField] = useState<FieldItem>()
  const history = useHistory()
  const { t } = useTranslation()
  const { soilAnalysisId } = useParams<any>()

  useEffect(() => {
    setIsLoading(true)
    API.get(`/showSpecific/${soilAnalysisId}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setSoilData(response.data)

      const { fieldId } = response.data

      API.get(`/fields/${fieldId}  `, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((fieldResponse) => {
        setField(fieldResponse.data)
        setIsLoading(false)
      })
    })
  }, [])

  const formatDate = (date: string) => {
    const newDate = new Date(date)
    newDate.toLocaleDateString()
    return `${newDate.getMonth() + 1}.${newDate.getDate()}.${newDate.getFullYear()}`
  }

  formatDate('2021-05-05T12:00:00.000000Z')

  const outputValue = (value: number | null) => {
    if (value === null) {
      return t('general.noData')
    }
    return value
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <>

      {field && (
        <>
          <h1>{t('general.soilAnalysis')} - {field.name}</h1>
          <FieldMap fields={[field]} displayPoint eixstingPoint={soilData?.coordinates!} />
        </>
      )}

      {soilData && (
        <>
          <SoilGroupsContainer
            title={t('soilAnalysis.chemicalHealth')}
            titleColor={theme.colors.soilCategoryColors.chem}
          >
            <SoilGroupDataItem><strong>{t('soilData.pH')}:</strong> {outputValue(soilData.pH)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.N')}:</strong> {outputValue(soilData.N)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.TMN')}:</strong> {outputValue(soilData.TMN)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.P')}:</strong> {outputValue(soilData.P)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.K')}:</strong> {outputValue(soilData.K)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.S')}:</strong> {outputValue(soilData.S)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.Mg')}:</strong> {outputValue(soilData.Mg)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.Ca')}:</strong> {outputValue(soilData.Ca)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.B')}:</strong> {outputValue(soilData.B)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.Cu')}:</strong> {outputValue(soilData.Cu)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.Fe')}:</strong> {outputValue(soilData.Fe)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.Mn')}:</strong> {outputValue(soilData.Mn)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.Zn')}:</strong> {outputValue(soilData.Zn)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.Carbonates')}:</strong> {outputValue(soilData.Carbonates)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.EC')}:</strong> {outputValue(soilData.EC)}</SoilGroupDataItem>
          </SoilGroupsContainer>

          <SoilGroupsContainer
            title={t('soilAnalysis.physicalHealth')}
            titleColor={theme.colors.soilCategoryColors.phys}
          >
            <SoilGroupDataItem><strong>{t('soilData.sand')}:</strong> {outputValue(soilData.sand)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.silt')}:</strong> {outputValue(soilData.silt)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.clay')}:</strong> {outputValue(soilData.clay)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.bulkDensity')}:</strong> {outputValue(soilData.bulkDensity)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.aggregateStability')}:</strong> {outputValue(soilData.aggregateStability)}</SoilGroupDataItem>
          </SoilGroupsContainer>

          <SoilGroupsContainer
            title={t('soilAnalysis.bioHealth')}
            titleColor={theme.colors.soilCategoryColors.bio}
          >
            <SoilGroupDataItem><strong>{t('soilData.mineralizableNitrogen')}:</strong> {outputValue(soilData.mineralizableNitrogen)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.hummus')}:</strong> {outputValue(soilData.hummus)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.organicCarbon')}:</strong> {outputValue(soilData.organicCarbon)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.soilRespiration')}:</strong> {outputValue(soilData.soilRespiration)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.activeCarbon')}:</strong> {outputValue(soilData.activeCarbon)}</SoilGroupDataItem>
            <SoilGroupDataItem><strong>{t('soilData.TOCTN')}:</strong> {outputValue(soilData.TOCTN)}</SoilGroupDataItem>
          </SoilGroupsContainer>

          <Button
            variant="contained"
            type="button"
            color="primary"
            onClick={
              () => {
                history.push(`/soilAnalysisField/${field?.id}`)
              }
            }
            startIcon={<FontAwesomeIcon icon={faAnglesLeft} />}
          >
            {t('soilAnalysis.backToSummary')}

          </Button>
        </>
      )}
    </>
  )
}
