import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Link, useHistory, useLocation } from 'react-router-dom'
import * as ls from 'local-storage'
import { API } from '../providers/API'
import { InputField } from '../components/FormElements/InputField'
import { CenteredContainer, LoginLanguageButton, LoginLogoContainer } from '../App.styles'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import { theme } from '../theme/theme'
import logo from '../media/agro-logo.svg'
import { FailureNotification } from '../components/SuccessNotification/SuccessNotification.styles'
import i18n from '../i18n'
import flagBG from '../icons/bg.svg'
import flagGB from '../icons/gb.svg'

export const LoginPage = () => {
  const [loginFailure, setLoginFailure] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { setUserAccess, setUserInfo } = useContext(GlobalStateContext)
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng)
  }

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(t('login.pleaseEnterEmail')),
    password: Yup.string().required(t('login.pleaseEnterPassword')),
  })

  return (
    <>
      <LoginLogoContainer>
        <img src={logo} alt="Agrovar" />
      </LoginLogoContainer>
      <CenteredContainer>
        <p>{t('login.loginPageSub1')}</p>
        <p>{t('login.loginPageSub2')}</p>
        {location.pathname.includes('login') ? (
          <>
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={ValidationSchema}
              enableReinitialize
              onSubmit={(values) => {
                API.get('/csrf-cookie').then(() => {
                  setIsLoading(true)
                  API.post('/login',
                    { email: values.email, password: values.password }).then((response) => {
                    if (response.data.token) {
                      ls.set('loginCredentials', response.data.token)
                      ls.set('userAccess', response.data.user.access)
                      setUserAccess(response.data.user.access)
                      setUserInfo(response.data.user)
                      ls.set('userInfo', response.data.user)
                      history.push('/fields')
                    } else if (response.data.message) {
                      setLoginFailure(true)
                    }
                  }).catch(() => {
                    setLoginFailure(true)
                    setIsLoading(false)
                  })
                })
              }}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <InputField label="Email" name="email" id="email" type="text" />
                  <InputField label={t('general.password')} name="password" id="password" type="password" />
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth
                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={null}
                  >
                    {t('general.login')}
                  </LoadingButton>
                  {loginFailure && <FailureNotification style={{ marginTop: theme.space.md }}>{t('login.wrongMailOrPass')}</FailureNotification>}
                </form>
              )}
            </Formik>
            <div style={{ marginTop: '20px' }}>
              <Link to="/register">{t('general.register')}</Link>
              {' '}
              |
              {' '}
              <Link to="/forgotPassword">{t('general.forgotPass')}</Link>
            </div>
          </>
        ) : (
          <>
            <Button
              variant="contained"
              type="button"
              color="primary"
              fullWidth
              onClick={() => {
                history.push('/login')
              }}
              style={{ marginTop: '60px' }}
            >
              {t('general.login')}
            </Button>
            <div style={{ marginTop: '20px' }}>
              {t('login.dontHaveAnAccount')} <Link to="/register">{t('general.registerNow')}</Link>
            </div>
          </>
        )}
        <div style={{ marginTop: '20px' }}>
          <LoginLanguageButton type="button" onClick={() => changeLanguage('bg')} active={i18n.language === 'bg'}>
            <img src={flagBG} alt="" />
          </LoginLanguageButton>
          <LoginLanguageButton type="button" onClick={() => changeLanguage('en')} active={i18n.language === 'en'}>
            <img src={flagGB} alt="" />
          </LoginLanguageButton>
        </div>
      </CenteredContainer>
    </>
  )
}
