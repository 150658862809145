import React, {
  useContext, useEffect, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import * as ls from 'local-storage'
import { TopContainer } from '../components/FieldsListing/FieldsListing.styles'
import { ZoneListingType, ZoneField } from '../components/ZonesListing/ZonesListing.types'
import { API } from '../providers/API'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import { MapDataContext } from '../providers/MapDataProvider'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { Loading } from '../components/Loading/Loading'
import FieldMap from '../components/FieldMap/FieldMap'
import { FieldsListing } from '../components/FieldsListing/FieldsListing'
import { FieldItem } from '../components/FieldsListing/Fields.types'

export const FieldsDataOverview = () => {
  const {
    userInfo, isLoading, setIsLoading,
  } = useContext(GlobalStateContext)
  const {
    allVisibleFieldIds, allAccountFields, setAllAccountFields,
  } = useContext(MapDataContext)
  const [zones, setZones] = useState<ZoneListingType[]>([])
  const [allGeojsonFieldsWithoutAZone, setAllGeojsonFieldsWithoutAZone] = useState<any>()
  const [zonedFields, setZonedFields] = useState<ZoneField[] | undefined>(undefined)
  const [clusterPoints, setClusterPoints] = useState<any>([])
  const [fieldsForListing, setFieldsForListing] = useState<FieldItem[]>([])
  const { t } = useTranslation()

  const setFieldMarkers = (currentlyVisibleFields: any) => {
    const geoJsonData = currentlyVisibleFields.map((item:any) => {
      const data = JSON.parse(item.coordinates)
      const returnData = {
        ...data,
        fieldId: item.id,
      }
      return returnData
    })

    // create cluster points only for the fields that are currently visible
    const filteredGeoJsonData = geoJsonData.filter(
      (item:any) => allVisibleFieldIds.includes(item.fieldId),
    )

    const clusterPointsTemp = filteredGeoJsonData.map((item: any) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        // take second [0] to get the first point of the polygon
        coordinates: item.geometry.coordinates[0][0],
      },
      fieldCoordinates: item.geometry.coordinates[0],
      fieldId: item.fieldId,
    }))

    setClusterPoints(clusterPointsTemp)
  }

  const getFieldsNotInZone = () => {
    const allZoneFields = zonedFields!.map((item:any) => item.fields)
    const allZoneFieldsFlat = allZoneFields.flat(Infinity)
    const fieldsNotInZoneTemp = allAccountFields.filter(
      (item) => !allZoneFieldsFlat.some((item2) => item2.id === item.id),
    )

    return fieldsNotInZoneTemp
  }

  const fetchFieldData = () => {
    setIsLoading(true)
    API.get(`/fields/search/${userInfo.id}  `, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setAllAccountFields(response.data)
      setFieldsForListing(response.data)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    // re-create cluster points when field filters change
    if (allAccountFields.length > 0) {
      setFieldMarkers(allAccountFields)
    }
    const newFieldsForListing = allAccountFields.filter(
      (field) => allVisibleFieldIds.includes(field.id),
    )
    setFieldsForListing(newFieldsForListing)
  }, [allVisibleFieldIds])

  const updateZonedFiledsForMap = (zonesData: any) => {
    const fieldsInZones = zonesData.map((item:any) => {
      const currentFieldInfo = {
        fields: item.containedFields,
        colour: item.colour,
        zoneId: item.id,
        name: item.name,
      }
      return currentFieldInfo
    })

    const filteredFieldsInZones = fieldsInZones.filter(
      (item:any) => item.fields !== null && item.fields.length > 0,
    )

    setZonedFields(filteredFieldsInZones)
  }

  const fetchZoneData = () => {
    setIsLoading(true)
    API.get('/zonesListing', { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setZones(response.data)
      updateZonedFiledsForMap(response.data)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    updateZonedFiledsForMap(zones)
  }, [zones])

  useEffect(() => {
    fetchZoneData()
    fetchFieldData()
  }, [])

  useEffect(() => {
    if (allAccountFields.length > 0 && zonedFields !== undefined) {
      const fieldsNotInZoneTemp = getFieldsNotInZone()

      const geoJsonDataNotInZone = fieldsNotInZoneTemp.map((item:any) => {
        const data = JSON.parse(item.coordinates)
        const returnData = {
          ...data,
          fieldId: item.id,
        }
        return returnData
      })

      setAllGeojsonFieldsWithoutAZone(geoJsonDataNotInZone)
    }
  }, [allAccountFields, zonedFields])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <h1>{t('navigation.fieldsDataOverview')}</h1>
      {allGeojsonFieldsWithoutAZone !== undefined
        && zonedFields !== undefined ? (
          <>
            <TopContainer>
              <FieldMap
                fields={allAccountFields}
                zonedFields={zonedFields}
                mapOverlayType="full"
                clusterPoints={clusterPoints}
                allGeojsonFieldsWithoutAZone={allGeojsonFieldsWithoutAZone}
                height="100%"
              />
            </TopContainer>

            <FieldsListing fields={fieldsForListing} isSorted={false} />
          </>
        ) : (
          <Loading />
        )}
    </>
  )
}
