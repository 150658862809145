import styled from 'styled-components'
import { theme } from '../../../theme/theme'
import { FieldItemRow } from '../../FieldsListing/FieldsListing.styles'

export const HeadingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 ${theme.space.md};
    padding: ${theme.space.sm};
    background: rgba(6, 192, 226, 0.14);
    border-radius: 10px;

    h1 {
        background: none;
        margin: 0;
        padding: 0 0 0 ${theme.space.md};
        border-radius: 0;
    }

    ${FieldItemRow} {
        margin: 0;
    }
`

export const AdditionalDataContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 ${theme.space.lg};

    & > * {
        height: 45px;
    }

    .smallBoxContainer {
        display: flex;
        flex-grow: 1;
        
        justify-content: space-between;

        .smallBox {
            display: flex;
            flex-grow: 1;
            align-items: center;
            justify-content: center;
            background: rgba(6, 192, 226, 0.14);
            padding: 0 ${theme.space.md};
            margin-left: ${theme.space.md};
            border-radius: 10px;
            color: ${theme.colors.blueUI};
            font-size: 0.9em;
    
            svg {
                margin-right: ${theme.space.sm};
            }
        }
    }
    

    .connectedFields {
        display: flex;
        align-items: center;
        background: white;
        border-radius: 10px;
        padding-right: ${theme.space.md};
        color: black;
        overflow: hidden;
        max-width: 650px;

        .title {
            flex-shrink: 0;
            color: white;
            height: 45px;
            line-height: 45px;
            padding: 0 ${theme.space.md};
            margin-right: ${theme.space.md};
            background: ${theme.colors.agroOrange};
            border-radius: 10px 0 0 10px;
        }

        .fieldNames {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            dislay: block;
        }
    }

    ${theme.mediaQueries.xlMax} {
        display: block;

        .connectedFields {
            display: block;
            height: auto;
            max-width: 100%;
            padding: 0 0 ${theme.space.sm} 0;

            .title {
                width: 100%;
                display: block;
                border-radius: 10px 10px 0 0;
                margin-bottom: ${theme.space.sm};
            }
            .fieldNames {
                display: block;
                padding: 0 ${theme.space.md};
                white-space: normal;
                overflow: visible;
                text-overflow: unset;

            }
        }

        .smallBoxContainer {
            margin-top: ${theme.space.md};

            .smallBox:first-child {
                margin-left: 0;
            }
            .smallBox {
                padding: 0 ${theme.space.sm};
                margin-left: ${theme.space.sm};
            }
        }
    }

    ${theme.mediaQueries.smMax} {
        .smallBoxContainer {
            display: block;
            height: auto;

            .smallBox {
                width: 100%;
                margin: 0 0 ${theme.space.sm};
                padding: ${theme.space.sm} 0;
            }
        }
    }
`
