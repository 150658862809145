import styled from 'styled-components'
import { theme } from '../../../theme/theme'

export const ListingHolder = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: ${theme.space.lg};
`

export const SoilItemWrap = styled.div`
    background: #FFF;
    border-radius: 10px;
    padding: ${theme.space.sm};
    font-weight: bold;
    cursor: pointer;

    &:hover {
        box-shadow: 0px 5px 8px rgb(0 0 0 / 30%);
    }

    .infoRow {
        padding: ${theme.space.sm};
        color: ${theme.colors.blueBGUI};
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:nth-child(odd) {
            background: ${theme.colors.blueBGUILight};
        }

        .scoreWrap {
            display: flex;
            align-items: center;
        }
    }
`
