import styled from 'styled-components'
import { theme } from '../../theme/theme'

export const CustomChartTooltip = styled.div`
    background: #FFF;
    color: #000;
    padding: ${theme.space.md};

    p, strong {
      display: block;
      margin: 0 0 ${theme.space.xs};
    }
`
