import styled from 'styled-components'
import { theme } from '../../theme/theme'

export const PackageItemWrap = styled.div`
   .packageStatsHolder {
        background: #FFF;
        border-radius: 10px;
        padding: ${theme.space.sm};
        margin-bottom: ${theme.space.md};
        font-size: 1.2em;
        font-weight: bold;
    }

    .infoRow {
        padding: ${theme.space.md};
        color: ${theme.colors.blueBGUI};
        display: flex;
        align-items: center;

        &:nth-child(odd) {
            background: ${theme.colors.blueBGUILight};
        }

        svg {
            margin-right: ${theme.space.md};
        }

        &.packageTitle {
            .packageImage {
                height: 50px;
                width: auto;
                margin-right: ${theme.space.md};
            }

            strong {
                font-size: 1.4em;
            }
        }
    }
`
