import React from 'react'
import { useTranslation } from 'react-i18next'
import { FieldPolygonHeader } from '../../FieldsListing/FieldPolygonHeader'
import { AdditionalDataContainer, HeadingContainer } from './HeaderInfo.styles'
import { HeaderInfoTypes } from '../MixElement.types'
import { IconArea, IconCalendar } from '../../../icons'

export const HeaderInfo: React.FC<HeaderInfoTypes> = ({
  title, fieldData, zoneName, mixData, connectedFieldsData,
}) => {
  const { t } = useTranslation()

  const connectedFieldsStringList = () => {
    if (!connectedFieldsData) return null

    if (connectedFieldsData.length === 0) {
      return t('general.noConnectedFields')
    }

    return connectedFieldsData.map((item: any) => (item.name)).join(', ')
  }
  return (
    <>
      {connectedFieldsData && (
        <HeadingContainer>
          <h1>{title}</h1>
          <FieldPolygonHeader
            field={fieldData}
            zoneName={zoneName || undefined}
            showArea={false}
            isMainField={!!fieldData}
          />
        </HeadingContainer>
      )}
      <AdditionalDataContainer>
        <div className="connectedFields">
          {connectedFieldsData ? (
            <>
              <span className="title">{t('general.connectedFields')}</span>
              <span className="fieldNames">{connectedFieldsStringList()}</span>
            </>
          ) : (
            <>
              <span className="title">{t('general.connectedAiMix')}</span>
              <span className="fieldNames">{mixData.mixName}</span>
            </>
          )}
        </div>
        <div className="smallBoxContainer">
          <div className="smallBox">
            <IconCalendar />
            <div>
              {t('general.seeding')}<br />
              {mixData.seedingDate}
            </div>
          </div>
          <div className="smallBox">
            <IconCalendar />
            <div>
              {t('general.termination')}<br />
              {mixData.terminationDate}
            </div>
          </div>
          <div className="smallBox">
            <IconArea />
            <div>
              {mixData.totalMixArea.toFixed(3)} {t('createField.dka')}
            </div>
          </div>
        </div>
      </AdditionalDataContainer>
    </>
  )
}
