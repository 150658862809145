import React from 'react'

export const IconCreditsPrompt = ({ size = '24', color = 'currentColor' }) => (
  <svg width={size} height={size} viewBox="0 0 225 225" fill={color} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_706_5287)">
      <path d="M192.041 32.959C170.815 11.6895 142.559 0 112.5 0C82.4414 0 54.1846 11.6895 32.959 32.959C11.7334 54.2285 0 82.4414 0 112.5C0 142.559 11.6895 170.815 32.959 192.041C54.2285 213.267 82.4414 225 112.5 225C142.559 225 170.815 213.311 192.041 192.041C213.267 170.771 225 142.559 225 112.5C225 82.4414 213.311 54.1846 192.041 32.959ZM112.5 216.211C55.3271 216.211 8.78906 169.673 8.78906 112.5C8.78906 55.3271 55.3271 8.78906 112.5 8.78906C169.673 8.78906 216.211 55.3271 216.211 112.5C216.211 169.673 169.673 216.211 112.5 216.211Z" fill="#004D5F" />
      <path d="M137.021 20.7863C134.692 20.1711 132.275 21.5774 131.66 23.9065C131.045 26.2356 132.451 28.6525 134.78 29.2678C172.354 39.3313 198.589 73.5207 198.589 112.5C198.589 159.961 159.961 198.589 112.5 198.589C65.0391 198.589 26.4111 159.961 26.4111 112.5C26.4111 73.301 52.8662 39.0236 90.7471 29.1799C93.0762 28.5647 94.4824 26.1477 93.9111 23.8186C93.2959 21.4895 90.8789 20.0832 88.5498 20.6545C46.8018 31.5529 17.666 69.302 17.666 112.5C17.666 137.857 27.5537 161.675 45.4395 179.605C63.3252 197.534 87.1875 207.378 112.544 207.378C137.9 207.378 161.719 197.49 179.648 179.605C197.578 161.719 207.422 137.857 207.422 112.5C207.378 69.5656 178.462 31.8606 137.021 20.7863Z" fill="#004D5F" />
      <path d="M112.324 26.4111H112.5C114.917 26.4111 116.895 24.4336 116.895 22.0166C116.895 19.5996 114.917 17.6221 112.5 17.6221H112.28C109.863 17.6221 107.886 19.5996 107.886 22.0166C107.93 24.4336 109.907 26.4111 112.324 26.4111Z" fill="#004D5F" />
      <path d="M102.946 96.8741C102.946 96.8741 91.3949 89.0393 90.1177 83.6493C88.8406 78.2592 88.8406 70.3089 89.2218 68.8844C89.603 67.4791 91.7571 62.3394 92.7864 61.0496C93.7967 59.7598 99.6487 56.1793 100.659 55.5248C101.669 54.8895 102.432 53.85 106.511 53.08C110.59 52.31 136 50 136 50C136 50 132.435 79.8955 131.292 82.09C130.148 84.2653 128.49 88.6351 125.44 91.1953C122.39 93.7556 124.162 94.6604 118.063 96.0656C111.963 97.4709 102.946 96.8741 102.946 96.8741Z" fill="#F26522" />
      <path d="M86.0001 74.3516C86.0001 80.7974 88.5383 86.8583 93.1496 91.4185C93.324 91.5916 93.5178 91.7648 93.6921 91.938C93.1109 93.9006 92.8202 95.9594 92.8202 98.0759C92.8202 99.1342 93.6921 100 94.7578 100C95.8234 100 96.6953 99.1342 96.6953 98.0759C96.6953 96.8829 96.8116 95.7092 97.0441 94.5548C100.958 97.1138 105.55 98.4992 110.355 98.4992H110.394C116.865 98.4992 122.968 95.9979 127.541 91.457C131.455 87.5703 133.935 82.3944 134.516 76.9299V76.9107L137.984 49.165C138.062 48.5685 137.849 47.9913 137.442 47.568C137.015 47.1447 136.434 46.9523 135.834 47.01L107.933 50.3195C107.875 50.3195 107.836 50.3387 107.778 50.3387C102.256 50.8967 97.0635 53.3596 93.1303 57.2655C88.4995 61.8449 85.9807 67.9059 86.0001 74.3516ZM108.321 54.1677C108.398 54.1677 108.476 54.1485 108.572 54.1292L133.819 51.1276L130.66 76.3527C130.66 76.3912 130.641 76.4297 130.641 76.4681C130.176 81.086 128.083 85.4537 124.79 88.744C120.934 92.5537 115.838 94.6702 110.374 94.6702H110.355C105.957 94.6702 101.752 93.2849 98.2647 90.7258C99.156 88.744 100.415 86.9353 102.004 85.3575L102.605 84.7611H118.318C119.384 84.7611 120.256 83.8952 120.256 82.8369C120.256 81.7787 119.384 80.9128 118.318 80.9128H106.461L112.758 74.6595H123.356C124.421 74.6595 125.293 73.7936 125.293 72.7354C125.293 71.6771 124.421 70.8113 123.356 70.8113H116.633L127.715 59.8054C128.471 59.0549 128.471 57.8428 127.715 57.0924C126.96 56.342 125.739 56.342 124.983 57.0924L113.901 68.0983V61.4216C113.901 60.3633 113.029 59.4975 111.963 59.4975C110.897 59.4975 110.026 60.3633 110.026 61.4216V71.9465L103.729 78.1998V71.1768C103.729 70.1186 102.857 69.2527 101.791 69.2527C100.725 69.2527 99.8535 70.1186 99.8535 71.1768V82.0481L99.2529 82.6445C97.6253 84.2608 96.2884 86.0887 95.2615 88.0513C91.7934 84.2993 89.8946 79.4698 89.8752 74.3516C89.8752 68.9256 91.9871 63.846 95.8428 60.017C99.1754 56.6883 103.612 54.6103 108.321 54.1677Z" fill="#004D5F" />
    </g>
    <defs>
      <clipPath id="clip0_706_5287">
        <rect width="225" height="225" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
