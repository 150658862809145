import React, {
  useContext, useEffect, useState,
} from 'react'
import * as ls from 'local-storage'
import { useTranslation } from 'react-i18next'
import { MixListingItem } from '../components/MixesListing/MixListingItem'
import { MixTypes } from '../components/MixesListing/Mix.types'
import { API } from '../providers/API'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import { Loading } from '../components/Loading/Loading'

export const MixesPage = () => {
  const {
    isLoading, setIsLoading, userInfo,
  } = useContext(GlobalStateContext)
  const [mixes, setMixes] = useState<MixTypes[]>([])
  const { t } = useTranslation()

  useEffect(() => {
    setIsLoading(true)
    API.get(`/userMixes/${userInfo.id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setMixes(response.data)
      setIsLoading(false)
    })
  }, [])

  return (
    <>
      <h1>{t('navigation.myMixes')}</h1>
      {isLoading && <Loading />}

      <div>
        {mixes && mixes.map((item: MixTypes) => (
          <MixListingItem mix={item} key={item.mix.id} />
        ))}
      </div>
    </>
  )
}
