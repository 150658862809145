import React, {
  useState, useEffect, Dispatch, SetStateAction,
} from 'react'
import * as ls from 'local-storage'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import i18n from '../../i18n'
import { API } from '../../providers/API'
import { FullCropsAndEffects } from '../MixElements/FullCropsAndEffects'
import { theme } from '../../theme/theme'
import { ProcessButtonsHolder } from './CreateMix.styles'

interface OwnMixProps {
  fieldId: string
  newMixId: number
  setStepTitle: Dispatch<SetStateAction<string>>
  processStep: number
  setProcessStep: Dispatch<SetStateAction<number>>
  setCreateOwnMix: Dispatch<SetStateAction<boolean>>
  mixType: string
}

interface CompatibleCoverCropsTypes {
  cropName: string
  id: number
}

interface CompatibleCoverCropsOptionsTypes {
  label: string
  id: number
}

export const OwnMix: React.FC<OwnMixProps> = (
  {
    fieldId, newMixId, setStepTitle, setProcessStep, setCreateOwnMix, mixType,
  },
) => {
  const { t } = useTranslation()
  setStepTitle(t('mixResults.ownMixTitle'))
  const [mixData, setMixData] = useState<any>({})
  const [selectedCustomCrops, setSelectedCustomCrops] = useState<any[]>([])
  const [
    compatibleCoverCrops,
    setCompatibleCoverCrops,
  ] = useState<CompatibleCoverCropsOptionsTypes[]>([])
  const [availabeCrops, setAvailableCrops] = useState<CompatibleCoverCropsTypes[]>([])
  const [ownMix, setOwnMix] = useState<any>()

  function updateCropLabels(cropsArray: CompatibleCoverCropsTypes[]) {
    const compatibleCoverCropsSelectOptions:
     CompatibleCoverCropsOptionsTypes[] = cropsArray.map(
       (item: CompatibleCoverCropsTypes) => ({
         label: t(`crops.${item.cropName}`),
         id: item.id,
       }),
     )
    setCompatibleCoverCrops(compatibleCoverCropsSelectOptions)
  }

  useEffect(() => {
    API.post(`/basicMixCalculation?mixId=${newMixId}`, {}, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setMixData(response.data)

      API.get(mixType === 'ai' ? `/listCompatibleCoverCrops/${fieldId}` : '/coverCrops', { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((compatibleCropsResponse) => {
        updateCropLabels(compatibleCropsResponse.data)
        setAvailableCrops(compatibleCropsResponse.data)
      })
    })
  }, [])

  useEffect(() => {
    updateCropLabels(availabeCrops)
  }, [i18n.language])

  const calculateOwnMix = () => {
    API.post(`/basicMixCalculation?crops=${selectedCustomCrops}&mixId=${newMixId}`, {}, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setOwnMix(response.data)
    })
  }

  return mixData && Object.keys(mixData).length > 0 && (
    <>
      <h3>{t('mixResults.chooseCompatibleCrops')}</h3>

      <Autocomplete
        multiple
        options={compatibleCoverCrops}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('mixResults.chooseCrops')}
          />
        )}
        onChange={(event, newValue) => {
          const cropsArray = newValue.map((item:any) => item.id.toString())
          setSelectedCustomCrops([...cropsArray])
        }}
      />

      <Button
        variant="contained"
        type="button"
        color="primary"
        onClick={
          () => calculateOwnMix()
        }
        style={{ marginBottom: theme.space.md }}
      >
        {ownMix ? t('mixResults.recalculateMix') : t('mixResults.calculateMix')}
      </Button>

      {ownMix && (
      <FullCropsAndEffects mixData={ownMix} effectsData={ownMix?.additionalEffects} cropsData={ownMix?.additionalCrops} title="Избрани култури и цялостен ефект от микса" />
      )}

      <ProcessButtonsHolder>
        {ownMix ? (
          <Button
            variant="contained"
            type="button"
            color="primary"
            onClick={
        () => {
          setProcessStep(5)
        }
            }
          >
            {t('mixResults.previewMix')}
          </Button>
        ) : <div />}
        <Button
          variant="contained"
          type="button"
          color="primary"
          onClick={
              () => {
                setCreateOwnMix(false)
                if (mixType === 'manual') {
                  setProcessStep(1)
                }
              }
              }
          startIcon={<FontAwesomeIcon icon={faAnglesLeft} />}
        >
          {t('general.prevStep')}
        </Button>
      </ProcessButtonsHolder>
    </>
  )
}
