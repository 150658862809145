import React, { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { createTheme, ThemeProvider as MaterialThemeProvider } from '@mui/material/styles'
import { GlobalStateProvider } from './providers/GlobalStateProvider'
import { GlobalStyle } from './theme/global.styles'
import { theme } from './theme/theme'
import { Layout } from './components/Layout/Layout'
import { MainContent } from './components/Layout/Layout.styles'

import { PageContent } from './App.styles'
import { Routes } from './Routes'
import { Header } from './components/Header/Header'
import { Slideout } from './components/Slideout/Slideout'
import { Loading } from './components/Loading/Loading'
import { MapDataProvider } from './providers/MapDataProvider'

const MUItheme = createTheme({
  palette: {
    primary: {
      main: theme.colors.orangeUI,
    },
    secondary: {
      main: theme.colors.blueBGUILight,
      contrastText: theme.colors.blueBGUI,
    },
    action: {
      disabledBackground: '#888',
    },
  },

  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
        // Some CSS
          marginBottom: theme.space.md,

        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: '#FFF',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#FFF',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: 'transparent',
        },
        input: {
          color: '#FFF',
        },
        notchedOutline: {
        // Some CSS
          borderWidth: '3px',

        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#FFF',
        },
      },
    },
  },
})

export const App = () => (
  <GlobalStateProvider>
    <ThemeProvider theme={theme}>
      <MaterialThemeProvider theme={MUItheme}>
        <Suspense fallback={<Loading />}>
          <GlobalStyle />
          <Layout>
            <Router>
              <Slideout />
              <MainContent>
                <Header />
                <PageContent>
                  <MapDataProvider>
                    <Routes />
                  </MapDataProvider>
                </PageContent>
              </MainContent>
            </Router>
          </Layout>
        </Suspense>
      </MaterialThemeProvider>
    </ThemeProvider>
  </GlobalStateProvider>
)
