import React, {
  useContext, useEffect, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import * as ls from 'local-storage'
import Button from '@mui/material/Button'
import { FieldItem as FieldItemTypes } from '../components/FieldsListing/Fields.types'
import { InfoNotification } from '../components/SuccessNotification/SuccessNotification.styles'
import { API } from '../providers/API'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { Loading } from '../components/Loading/Loading'
import { FieldItem } from '../components/SatSoilDataListing/FieldItem'
import { SimpleGrid } from '../App.styles'
import PageHeading from '../components/PageHeading/PageHeading'

export const SatSoilHistoryListing = () => {
  const {
    userInfo,
  } = useContext(GlobalStateContext)
  const [fields, setFields] = useState<FieldItemTypes[]>()
  const [fieldsDataSource, setFieldsDataSource] = useState<FieldItemTypes[]>()
  const [sortedData, setSortedData] = useState<any[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    setIsLoading(true)
    API.get(`/fields/search/${userInfo.id}  `, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setFields(response.data)
      setFieldsDataSource(response.data)
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    if (sortedData) {
      setFieldsDataSource(sortedData)
    }
  }, [sortedData])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <PageHeading
        title={t('navigation.soilHistory')}
        sortingData={fields}
        searchByName
        nameKey="name"
        areaKey="area"
        hasMixKey="mix"
        dateKey="created_at"
        cacheDataKey="soilHistoryListing"
        setSortedData={setSortedData}
      />
      {fieldsDataSource && fieldsDataSource.length === 0 ? (
        <>
          <InfoNotification>{t('createField.noFields')}</InfoNotification>
          <Button
            variant="contained"
            type="button"
            color="primary"
            onClick={() => {
              history.push('/createField')
            }}
          >
            {t('createField.createField')}
          </Button>
        </>
      ) : (
        <SimpleGrid>
          {fieldsDataSource?.map((field: FieldItemTypes) => (
            <FieldItem
              field={field}
              key={field.id}
            />
          ))}
        </SimpleGrid>
      )}
    </>
  )
}
