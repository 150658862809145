import React, { useState } from 'react'
import {
  ComposedChart, Line, CartesianGrid, XAxis,
  YAxis, Tooltip, ResponsiveContainer, Legend, Surface, Symbols,
} from 'recharts'
import { useTranslation } from 'react-i18next'
import { DegreesSymbol } from './DegreesSymbol'
import { CustomChartTooltip } from './HistoricalWeatherChart.styles'
import { theme } from '../../theme/theme'

type ChartProps = {
  weatherData: any,
  weatherKey: string,
  unit: string,
  title: string
}

export const HistoricalWeatherPremiumChart: React.FC<ChartProps> = ({
  weatherData, weatherKey, title, unit,
}) => {
  const [disabledLines, setDisabledLines] = useState<any>([])
  const { t } = useTranslation()

  function toMonthName(monthNumber: number) {
    const date = new Date()
    date.setMonth(monthNumber - 1)

    return date.toLocaleString('en-US', {
      month: 'short',
    })
  }

  const CustomTooltip: React.FC<any> = ({
    active, payload, label,
  }) => {
    if (active && payload && payload.length) {
      return (
        <CustomChartTooltip>
          <strong>{toMonthName(label)} {payload[0].payload.day}</strong>
          {payload.map((entry: any, index: number) => (
            <p key={`tooltipItem_${index}`}>
              {`${entry.payload.year}${t('general.yearShort')}: ${parseFloat(entry.payload[weatherKey]).toFixed(2)}`}
              {unit === 'degrees' ? <DegreesSymbol /> : unit}
            </p>
          ))}
        </CustomChartTooltip>
      )
    }

    return null
  }

  const handleClick = (dataKey: any) => {
    if (disabledLines.includes(dataKey)) {
      setDisabledLines(disabledLines.filter((obj: any) => obj !== dataKey))
    } else {
      setDisabledLines(disabledLines.concat(dataKey))
    }
  }

  const renderCusomizedLegend = (props:any) => (
    <div className="customized-legend">
      {props.payload.map((entry: any, index: number) => {
        const { color } = entry
        const active = disabledLines.includes(entry.payload.xAxisId)
        const style = {
          marginRight: 15,
          color: active ? '#AAA' : '#000',
          cursor: 'pointer',
        }

        return (
          <span
            className="legend-item"
            onClick={() => handleClick(entry.payload.xAxisId)}
            onKeyUp={() => handleClick(entry.payload.xAxisId)}
            role="button"
            tabIndex={0}
            style={style}
            key={`legendItem_${index}`}
          >
            <Surface width={10} height={10}>
              <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
              {active && (
              <Symbols
                cx={5}
                cy={5}
                type="circle"
                size={25}
                fill="#FFF"
              />
              )}
            </Surface>
            <span style={{ paddingLeft: 5 }}>{entry.payload.data[0].year}</span>
          </span>
        )
      })}
    </div>
  )

  return (
    <>
      <h3>{title}</h3>
      <ResponsiveContainer width="100%" height={300} debounce={1}>
        <ComposedChart
          style={{ background: '#FFF', borderRadius: '10px' }}
          barCategoryGap="30%"
          margin={{
            top: 50,
            right: 20,
            left: 0,
            bottom: 0,
          }}
        >
          <Legend content={renderCusomizedLegend} wrapperStyle={{ top: 15, right: 10, textAlign: 'right' }} />
          <CartesianGrid vertical={false} />
          {Object.keys(weatherData).map((item, index) => (
            <XAxis dataKey="month" allowDuplicatedCategory xAxisId={`${weatherKey}_${index}`} interval={30} tickFormatter={(label) => `${toMonthName(label)}`} hide={index !== 0} key={`axisKey_${index}`} />
          ))}
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          {Object.keys(weatherData).map((item: any, index) => (
            <Line
              type="monotone"
              data={weatherData[item]}
              dataKey={weatherKey}
              xAxisId={`${weatherKey}_${index}`}
              hide={disabledLines.includes(`${weatherKey}_${index}`)}
              stroke={theme.colors.lineChartColors[index]}
              dot={false}
              key={`lineKey_${index}`}
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </>
  )
}
