import React, { useState } from 'react'
import {
  ComposedChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Brush,
  ResponsiveContainer, Legend, Surface, Symbols,
} from 'recharts'
import { useTranslation } from 'react-i18next'
import { theme } from '../../theme/theme'
import { DegreesSymbol } from './DegreesSymbol'
import { CustomChartTooltip } from './HistoricalWeatherChart.styles'

type ChartProps = {
  // using weatherData to pass all the actual data used in the chart
  weatherData: any,
  // using totalData to foreach the individual bars according to how many years are there
  totalData: any,
  unit: string,
  title: string
}

export const HistoricalWeatherPremiumChartBar: React.FC<ChartProps> = ({
  weatherData, totalData, title, unit,
}) => {
  const [disabledLines, setDisabledLines] = useState<any>([])
  const { t } = useTranslation()

  function toMonthName(monthNumber: number) {
    const date = new Date()
    date.setMonth(monthNumber - 1)

    return date.toLocaleString('en-US', {
      month: 'short',
    })
  }

  const handleClick = (dataKey: any) => {
    if (disabledLines.includes(dataKey)) {
      setDisabledLines(disabledLines.filter((obj: any) => obj !== dataKey))
    } else {
      setDisabledLines(disabledLines.concat(dataKey))
    }
  }

  const CustomTooltip: React.FC<any> = ({
    active, payload, label,
  }) => {
    if (active && payload && payload.length) {
      return (
        <CustomChartTooltip>
          <strong>{toMonthName(label)} {payload[0].payload.day}</strong>
          {payload.map((entry: any, index: number) => (
            <p key={`data_entry_${index}`}>
              {`${entry.dataKey}${t('general.yearShort')}: ${parseFloat(entry.payload[entry.dataKey]).toFixed(2)}`}
              {unit === 'degrees' ? <DegreesSymbol /> : unit}
            </p>
          ))}
        </CustomChartTooltip>
      )
    }

    return null
  }

  const renderCusomizedLegend = (props:any) => (
    <div className="customized-legend">
      {props.payload.map((entry: any, index: number) => {
        // console.log('legend entry', entry)
        const { color } = entry
        const active = disabledLines.includes(entry.dataKey)
        const style = {
          marginRight: 15,
          color: active ? '#AAA' : '#000',
          cursor: 'pointer',
        }

        return (
          <span
            className="legend-item"
            onClick={() => handleClick(entry.dataKey)}
            onKeyUp={() => handleClick(entry.dataKey)}
            role="button"
            tabIndex={0}
            style={style}
            key={`legend-item-${index}`}
          >
            <Surface width={10} height={10}>
              <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
              {active && (
              <Symbols
                cx={5}
                cy={5}
                type="circle"
                size={25}
                fill="#FFF"
              />
              )}
            </Surface>
            <span style={{ paddingLeft: 5 }}>{entry.dataKey}</span>
          </span>
        )
      })}
    </div>
  )

  return (
    <>
      <h3>{title}</h3>
      <ResponsiveContainer width="100%" height={300} debounce={1}>
        <ComposedChart
          style={{ background: '#FFF', borderRadius: '10px' }}
          barCategoryGap="30%"
          margin={{
            top: 50,
            right: 20,
            left: 0,
            bottom: 0,
          }}
          data={weatherData}
        >
          <Legend content={renderCusomizedLegend} wrapperStyle={{ top: 15, right: 10, textAlign: 'right' }} />
          <CartesianGrid vertical={false} />
          <Brush dataKey="month" height={30} stroke="#8884d8" endIndex={100} />
          <XAxis dataKey="month" interval={30} tickFormatter={(label) => `${toMonthName(label)}`} />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          {Object.keys(totalData).map((item: any, index: number) => (
            <Bar
              dataKey={item}
              fill={theme.colors.lineChartColors[index]}
              hide={disabledLines.includes(item)}
              width={60}
              stackId="a"
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </>
  )
}
