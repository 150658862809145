import React, {
  Dispatch, SetStateAction,
} from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faAnglesLeft } from '@fortawesome/free-solid-svg-icons'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { ProcessButtonsHolder } from './CreateMix.styles'
import { InfoNotification } from '../SuccessNotification/SuccessNotification.styles'

interface SoilChoiceProps {
  fieldValues: any
  setProcessStep: Dispatch<SetStateAction<number>>
  processStep: number
  setStepTitle: Dispatch<SetStateAction<string>>
  soilAnalysis: string
  setSoilAnalysis: Dispatch<SetStateAction<string>>
}

export const SoilAnalysisChoise: React.FC<SoilChoiceProps> = ({
  fieldValues, processStep, setProcessStep, setStepTitle, soilAnalysis, setSoilAnalysis,
}) => {
  const { t } = useTranslation()
  setStepTitle(t('analysis.analysisTitle'))

  return (
    <>
      <p>{t('analysis.haveSoilAnalysis')}</p>
      <RadioGroup
        row
        aria-label={t('analysis.haveSoilAnalysis')}
        name="soilAnalysis"
        value={soilAnalysis}
        onChange={(event) => {
          setSoilAnalysis(event.currentTarget.value)
        }}
      >
        <FormControlLabel value="yes" control={<Radio />} label={t('analysis.yes')} />
        <FormControlLabel value="want" control={<Radio />} label={t('analysis.iWantSoilAnalysis')} />
        <FormControlLabel value="no" control={<Radio />} label={t('analysis.no')} />
      </RadioGroup>

      {soilAnalysis === 'yes' && fieldValues.areaType === 'zone' && (
        <InfoNotification>{t('createMix.infoZoneSoilAnalysis')}</InfoNotification>
      )}

      <ProcessButtonsHolder>
        <Button
          variant="contained"
          type="button"
          color="primary"
          onClick={
        () => {
          let nextStep = processStep + 1
          if (fieldValues.areaType === 'zone' && soilAnalysis === 'yes') {
            nextStep = processStep + 2
          }
          setProcessStep(nextStep)
        }
            }
          endIcon={<FontAwesomeIcon icon={faAnglesRight} />}
        >
          {t('general.nextStep')}

        </Button>
        <Button
          variant="contained"
          type="button"
          color="primary"
          onClick={
        () => {
          setProcessStep(processStep - 1)
        }
            }
          startIcon={<FontAwesomeIcon icon={faAnglesLeft} />}
        >
          {t('general.prevStep')}
        </Button>
      </ProcessButtonsHolder>
    </>
  )
}
