import React, { useContext, useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import * as ls from 'local-storage'
import { API } from '../providers/API'
import { InputField } from '../components/FormElements/InputField'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import { CenteredContainer } from '../App.styles'
import { FailureNotification } from '../components/SuccessNotification/SuccessNotification.styles'
import logo from '../media/agrovar-logo.svg'

export const RegisterPage = () => {
  const { setUserAccess, setUserInfo } = useContext(GlobalStateContext)
  const [registerErrors, setRegisterErrors] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const { t } = useTranslation()

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required(t('formErrors.enterName')),
    email: Yup.string()
      .email(t('formErrors.invalidEmail'))
      .required(t('formErrors.enterEmail')),
    password: Yup.string().min(8, t('formErrors.passMin8')).required(t('formErrors.enterPass')),
    password_confirmation: Yup.string().required(t('formErrors.confirmPass')).oneOf([Yup.ref('password')], t('formErrors.passwordsMustMatch')),
    telephone: Yup.string().required(t('formErrors.enterPhone')),
    companyName: Yup.string().required(t('formErrors.enterCompanyName')),
    headquaters: Yup.string().required(t('formErrors.enterHeadquarters')),
  })

  return (
    <CenteredContainer>
      <img src={logo} alt="Agrovar" />
      <h1>{t('registration.title')}</h1>
      <Formik
        initialValues={{
          name: '', email: '', password: '', password_confirmation: '', telephone: '', companyName: '', headquaters: '',
        }}
        validationSchema={ValidationSchema}
        enableReinitialize
        onSubmit={(values) => {
          API.post('/register', values).then((response) => {
            setIsLoading(true)
            if (response.data.token) {
              ls.set('loginCredentials', response.data.token)
              setUserInfo(response.data.user)
              ls.set('userInfo', response.data.user)
              ls.set('userAccess', 1)
              setUserAccess(1)
              history.push('/')
            }
          }).catch((error) => {
            setIsLoading(false)
            if (error.response.data.errors) {
              const errorsArray = Object.keys(error.response.data.errors)
              const errors:any = errorsArray.map(() => error.response.data.errors[errorsArray[0]])
              setRegisterErrors(errors)
            } else if (error.response.data.message) {
              setRegisterErrors([error.response.data.message])
            } else {
              setRegisterErrors([t('register.submitErrors.generic')])
            }
            const errorsArray = Object.keys(error.response.data.errors)
            const errors:any = errorsArray.map(() => error.response.data.errors[errorsArray[0]])
            setRegisterErrors(errors)
          })
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <InputField label={t('general.name')} name="name" id="name" type="text" />
            <InputField label={t('general.email')} name="email" id="email" type="text" />
            <InputField label={t('registration.phone')} name="telephone" id="telephone" type="text" />
            <InputField label={t('registration.companyName')} name="companyName" id="company_name" type="text" />
            <InputField label={t('registration.headquarters')} name="headquaters" id="headquaters" type="text" />
            <InputField label={t('general.password')} name="password" id="password" type="password" />
            <InputField label={t('general.passwordConfirm')} name="password_confirmation" id="password_confirmation" type="password" />
            <LoadingButton
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
              loading={isLoading}
              loadingPosition="start"
            >
              Регистрация
            </LoadingButton>
            {registerErrors && (
              registerErrors.map((item: any) => (
                <FailureNotification>{t(`submitErrors.${item}`)}</FailureNotification>
              ))
            )}
          </form>
        )}
      </Formik>
      <Link to="/login" style={{ marginTop: '20px' }}>{t('registration.alreadyRegistered')}</Link>
    </CenteredContainer>
  )
}
