import styled from 'styled-components'
import { theme } from '../../../theme/theme'

export const MapOverlayContainer = styled.div<{isOpen: boolean}>(
  ({ isOpen }) => `
      position: absolute;
      width: 70px;
      left: ${isOpen ? 'calc(100% - 320px)' : 'calc(100% - 70px)'};
      top: ${theme.space.md};
      z-index: 98;
      padding: ${theme.space.sm} ${theme.space.sm} ${theme.space.xs};
      background: rgba(255,255,255,0.8);
      border-radius: 10px 0 0 10px;
      transition: left 0.3s;
  `,
)

export const MapOverlayLoadingContainer = styled.div<{isActive: boolean}>(
  ({ isActive }) => `
      display: ${isActive ? 'flex' : 'none'};
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255,0.8);
      z-index: 99;
      justify-content: center;
      align-items: center;
  `,
)

export const SectionToggles = styled.div`
      display: flex;
      flex-direction: column;
      gap: ${theme.space.xs};

      button {
        display: flex;
        align-items: center;
        flex-direction: column;
        background: none;
        border: none;
        padding: 0;
        margin-bottom: ${theme.space.sm};
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }
         
      .icon {
        display: block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 5px;
        background: #FFF;
        color: #FFF;
        margin-bottom: ${theme.space.xs};

        &.filters {
          background: ${theme.colors.blueUI};
        }

        &.data {
          background: ${theme.colors.agroOrange};
        }
      }
  `

export const SectionsContainer = styled.div`
      position: absolute;
      left: 70px;
      top: 0px;
      width: 250px;
      max-height: 350px;
      overflow-y: auto; 
      padding: ${theme.space.sm} ${theme.space.md};
      background: rgba(2,104,125, 0.8);
      border-radius: 0 0 0 10px;

      h3 {
        font-size: ${theme.fontSizes.md};
      }
  `
export const FiltersContainer = styled.div<{isActive: boolean}>(
  ({ isActive }) => `
      display: ${isActive ? 'flex' : 'none'};
      flex-direction: column;
      gap: ${theme.space.sm};
  `,
)

export const DataLayersContainer = styled.div<{isActive: boolean}>(
  ({ isActive }) => `
        display: ${isActive ? 'flex' : 'none'};
        flex-direction: column;
        gap: ${theme.space.sm};
  `,
)

export const FilterGroupContainer = styled.div`
    [class*="MuiRadio-root"] {
        padding: 0;
        margin-right: ${theme.space.xs};
    }

    [class*="MuiFormControlLabel-root"] {
        margin: 0;
    }
`

export const FilterOptionRow = styled.div`
    margin-bottom: ${theme.space.sm};
`

export const ClearFiltersButton = styled.button`
    background: none;
    border: none;
    color: ${theme.colors.blueUI};
    cursor: pointer;
    display: inline;
    float: right;
`
