import styled from 'styled-components'
import { theme } from '../../theme/theme'

export const CreditsPromptLayoutHolder = styled.div`
  display: flex;
  justify-content: fill;
  max-width: 600px;
  margin: 0 auto;

  .rejectButton {
    margin-left: ${theme.space.md};
  }

  & .promptConditions {
    flex-grow: 1;

    h2 {
      font-size: ${theme.fontSizes.xxl};
      color: ${theme.colors.blueBGUI};
    }
  }

  & .promptIcon {

    .container {
      position: relative;
    }
    
    margin-left: ${theme.space.md};

    @media screen and (max-width: 600px) {
      & svg {
        width: 100px;
        height: 100px;
      }
    }

    .amount {
      position: absolute;
      width: 100%;
      text-align: center;
      top: 50%;
      left: 0;
      font-size: 40px;
      color: #004D5F;

      @media screen and (max-width: 600px) {

        font-size: 20px;
      }
    }
  }
`
