import React, { useRef } from 'react'
import {
  faChevronLeft, faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  TimelineHolder, Data, TimelineContent,
} from './NdviField.styles'
import { AgromonData } from './NdviField.types'
import { TimelineItem } from './TimelineItem'
import { CalendarHolder } from './CalendarHolder'

export const Timeline: React.FC<{agromonData: AgromonData[]}> = ({
  agromonData,
}) => {
  const timelineRef = useRef<HTMLDivElement>(null)

  function scrollToNextPage() {
    timelineRef.current!.scrollBy(document.querySelector('.timelineFirstItem')!.clientWidth * 3, 0)
  }
  function scrollToPrevPage() {
    timelineRef.current!.scrollBy(-document.querySelector('.timelineFirstItem')!.clientWidth * 3, 0)
  }

  const agromonDataWihtoutDuplicatedDates = agromonData.filter((item, index, self) => {
    if (item.dt === self[index - 1]?.dt) {
      return false
    }

    return true
  })

  return (
    <TimelineHolder>
      <CalendarHolder data={agromonDataWihtoutDuplicatedDates} />
      <Data>
        <button
          type="button"
          className="timelineControl left"
          onClick={() => {
            scrollToPrevPage()
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} color="#FFF" size="2x" />
          <span className="visuallyHidden">Move timeline left</span>
        </button>
        <TimelineContent ref={timelineRef} id="timelineContent">
          {agromonDataWihtoutDuplicatedDates.map((item: AgromonData, index: number) => (
            <TimelineItem key={index} itemData={item} isFirstItem={index === 0} />
          ))}

        </TimelineContent>
        <button
          type="button"
          className="timelineControl right"
          onClick={() => {
            scrollToNextPage()
          }}
        ><FontAwesomeIcon icon={faChevronRight} color="#FFF" size="2x" /><span className="visuallyHidden">Move timeline right</span>
        </button>
      </Data>
    </TimelineHolder>
  )
}
