import React, {
  useContext, useEffect, useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import * as ls from 'local-storage'
import Button from '@mui/material/Button'
import { WeatherListingItem } from '../components/WeatherListing/WeatherListingItem'
import { FieldItem } from '../components/FieldsListing/Fields.types'
import { InfoNotification } from '../components/SuccessNotification/SuccessNotification.styles'
import { API } from '../providers/API'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import { Loading } from '../components/Loading/Loading'
import { SimpleGrid } from '../App.styles'

export const WeatherListing = () => {
  const {
    isLoading, setIsLoading, userInfo,
  } = useContext(GlobalStateContext)
  const [fields, setFields] = useState<FieldItem[]>([])
  const history = useHistory()

  useEffect(() => {
    setIsLoading(true)
    API.get(`/fields/search/${userInfo.id}  `, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setFields(response.data)

      setIsLoading(false)
    })
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <h1>Метео данни</h1>
      {fields && fields.length === 0 ? (
        <>
          <InfoNotification>Все още нямате създадени полета.</InfoNotification>
          <Button
            variant="contained"
            type="button"
            color="primary"
            onClick={() => {
              history.push('/createField')
            }}
          >
            Създай поле
          </Button>
        </>
      ) : (
        <>
          <SimpleGrid columnsSplit="1fr 1fr 1fr" style={{ marginTop: '20px' }}>
            {fields && fields.map((item: FieldItem) => (
              <WeatherListingItem
                field={item}
                key={item.id}
                type="current"
              />
            ))}
          </SimpleGrid>
        </>
      )}
    </>
  )
}
