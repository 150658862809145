import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { MixTypes } from './Mix.types'
import { MixInfo, MixListinItem, MixStatus } from './Mix.styles'
import { FieldPolygonHeader } from '../FieldsListing/FieldPolygonHeader'

export const MixListingItem: React.FC<{mix: MixTypes}> = ({
  mix,
}) => {
  const history = useHistory()
  const { t } = useTranslation()

  const displayMix = () => {
    history.push(`/mixDetails/${mix.mix.id}`)
  }

  return (
    <MixListinItem
      tabIndex={0}
      role="button"
      onKeyUp={() => {
        displayMix()
      }}
      onClick={
      () => {
        displayMix()
      }
    }
    >
      <span>{mix.mix.mixName}</span>
      <MixInfo>
        <MixStatus type={mix.mix.status}>
          {t('mixes.status')}
          :
          {' '}
          <span>{t(`mixes.statuses.${mix.mix.status}`)}</span>
        </MixStatus>
        <FieldPolygonHeader
          field={{
            coordinates: mix.coordinates,
            name: mix.fieldName,
            id: mix.mix.id,
          }}
          key={mix.mix.id}
        />
      </MixInfo>
    </MixListinItem>
  )
}
