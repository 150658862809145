import styled, { keyframes } from 'styled-components'
import { theme } from '../../theme/theme'

const keyframe1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`

const keyframe2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`

const keyframe3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`
export const LoadingContainerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.3);
`

export const LoadingContainer = styled.div`
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;

  & div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${theme.colors.orangeUI};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  & div:nth-child(1) {
    left: 8px;
    animation: ${keyframe1} 0.6s infinite;
  }
  & div:nth-child(2) {
    left: 8px;
    animation: ${keyframe2} 0.6s infinite;
  }
  & div:nth-child(3) {
    left: 32px;
    animation: ${keyframe2} 0.6s infinite;
  }
  & div:nth-child(4) {
    left: 56px;
    animation: ${keyframe3} 0.6s infinite;
  }
`
