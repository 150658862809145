export const createYearTemplate = (weatherDataByYear: any, dataItem?: any) => {
  // using the second year in the data set, as a base data object
  // because the first one doesn't start from January 1st
  const template = weatherDataByYear[Object.keys(weatherDataByYear)[1]].reduce(
    (finalFeatures:any, currentFeature: any) => {
      if (dataItem !== undefined) {
        // if the dataItem is defined, output the data for that item only
        // as a vaklu for a key with the year as the name
        finalFeatures.push(
          {
            [currentFeature.year]: currentFeature[dataItem],
            month: currentFeature.month,
            day: currentFeature.day,
          },
        )
      } else {
        // if the dataItem is NOT defined, output the data for all items with null values
        // also output the year, month and day with their original values
        const emptyCurrentFeature: any = {}

        Object.keys(currentFeature).reduce(
          (total:any, value: any) => {
            if (value !== 'year' && value !== 'month' && value !== 'day') {
              emptyCurrentFeature[value] = null
            } else {
              emptyCurrentFeature[value] = currentFeature[value]
            }
            return total
          },
        )

        finalFeatures.push(
          {
            ...emptyCurrentFeature,
          },
        )
      }

      return finalFeatures
    }, [],
  )

  return template
}

export const handleLeapYear = (
  templateItem: any, weatherDataByYear: any, fullData: any = false,
) => {
  const checkLeapYear = templateItem.filter(
    (item: any) => item.month === 2 && item.day === 29,
  )
  if (checkLeapYear.length === 0) {
    const leapDayIndexCheck = (el: any) => el.month === 2 && el.day === 28
    const leapDayIndex = templateItem.findIndex(leapDayIndexCheck)
    if (fullData === true) {
      templateItem.splice(leapDayIndex + 1, 0, {
        month: 2,
        day: 29,
      })
    } else {
      templateItem.splice(leapDayIndex + 1, 0, {
        month: 2,
        day: 29,
        [Object.keys(weatherDataByYear)[1]]: 0,
      })
    }
  }
}

export const addDataToTemplate = (template: any, weatherDataFull: any, dataItem: any) => {
  // iterate through the snowFullYearTemplate array and add the snow data for each year
  template.forEach((initialYear: any) => {
    weatherDataFull.forEach((currentYearItem: any) => {
      if (initialYear.month === currentYearItem.month
              && initialYear.day === currentYearItem.day) {
        // eslint-disable-next-line no-param-reassign
        initialYear[currentYearItem.year] = currentYearItem[dataItem]
      }
    })
  })
}

export const addDataToTemplateByYear = (template: any, weatherDataByYear: any) => {
  const merged: any[] = []

  Object.keys(weatherDataByYear).forEach((itm: any) => {
    // iterate through all years and add each year to the merged array as empty first
    merged[itm] = []
    for (let i = 0; i < template.length; i++) {
      // iterate over the template containing all 365 days
      merged[itm].push({
        // and push the data for each day to the merged array's corresponding year
        ...template[i],
        ...(weatherDataByYear[itm].find(
          (itmInner: any) => itmInner.month === template[i].month
          && itmInner.day === template[i].day,
        )),
        year: itm,
      })
    }
  })

  return merged
}
