import bbox from '@turf/bbox'
import bboxpolygon from '@turf/bbox-polygon'
import { FieldItem } from '../components/FieldsListing/Fields.types'

export const getNdviImageCoords = (field: FieldItem) => {
  const geoJsonData = JSON.parse(field!.coordinates)
  // get bounding box and create polygon to use for NDVI overlay
  const boundingBox = bbox(geoJsonData)
  const bboxpolygonTest = bboxpolygon(boundingBox)
  // remove the last coordinate point,
  // because it is the same as the first one (it should be a rectangle)
  const sourceCoordinates = bboxpolygonTest.geometry.coordinates[0].slice(0, 4).reverse()

  return sourceCoordinates
}
