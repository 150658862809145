import React from 'react'
import { CropItem } from './CropItem'
import { MixOrderCropsTypes } from './MixElement.types'
import { OrderMainCrops } from './MixElements.styles'

export const MixOrderMainCrops: React.FC<MixOrderCropsTypes> = ({
  cropsData, title,
}) => (
  <OrderMainCrops>
    <h2>{title}</h2>
    <div className="dataBox">
      {cropsData && (
        cropsData.map((item: any, index: any) => (
          <CropItem item={item} cropType="mainCrops" isFirst={index === 0} isLast={cropsData.length === index + 1} detailed key={`result_${index}`} />
        ))
      )}
    </div>
  </OrderMainCrops>
)
