import React, {
  Dispatch, SetStateAction, useState,
} from 'react'
import TextField from '@mui/material/TextField'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { theme } from '../../theme/theme'
import { formatDateStrings } from '../../utils/formatDateStrings'

const DatePickerTheme = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          backgroundColor: theme.colors.blueBGUILight,
          marginLeft: theme.space.md,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: theme.colors.blueUI,
          borderWidth: '1px !important',
        },
      },
    },
  },
})

interface RangeDatePickersProps {
  allDates: any
  setStartDate: Dispatch<SetStateAction<string>>
  setEndDate: Dispatch<SetStateAction<string>>
  ignoreRanges?: boolean
}
export const RangeDatePickers: React.FC<RangeDatePickersProps> = ({
  allDates, setStartDate, setEndDate, ignoreRanges = false,
}) => {
  const firstDate = allDates[0].split('-')
  const lastDate = allDates[allDates.length - 1].split('-')

  const firstDateFormatted = new Date(
    firstDate[0], firstDate[1] - 1, firstDate[2],
  )
  const lastDateFormatted = new Date(lastDate[0], lastDate[1] - 1, lastDate[2])

  const earliestDate = ignoreRanges ? new Date(
    firstDate[0] - 4, firstDate[1] - 1, firstDate[2],
  ) : firstDateFormatted

  const [localStartDate, setLocalStartDate] = useState<Date>(firstDateFormatted)
  const [localEndDate, setLocalEndDate] = useState<Date>(lastDateFormatted)
  const [maxDateStart, setMaxDateStart] = useState<Date>(lastDateFormatted)
  const [minDateEnd, setMinDateEnd] = useState<Date>(firstDateFormatted)

  function disableEmptyDates(date: any) {
    if (ignoreRanges) {
      return false
    }

    const dateFormatted = formatDateStrings(date)
    return !allDates.includes(dateFormatted)
  }

  const handleDatePickChange = (newValue: any, instance: string) => {
    if (newValue === null) return

    const dateFormatted = formatDateStrings(newValue)

    if (instance === 'start') {
      setStartDate(dateFormatted)
      setLocalStartDate(newValue)
      setMinDateEnd(newValue)
    } else {
      setEndDate(dateFormatted)
      setLocalEndDate(newValue)
      setMaxDateStart(newValue)
    }
  }

  return (
    localStartDate && localEndDate ? (
      <ThemeProvider theme={DatePickerTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Начална дата"
            inputFormat="dd/MM/yyyy"
            value={localStartDate}
            onChange={(newValue: any) => handleDatePickChange(newValue, 'start')}
            renderInput={(params: any) => (
              <TextField
                {...params}
              />
            )}
            minDate={earliestDate}
            maxDate={maxDateStart}
            // eslint-disable-next-line
            shouldDisableDate={disableEmptyDates}
          />

          <DatePicker
            label="Kрайна дата"
            inputFormat="dd/MM/yyyy"
            value={localEndDate}
            onChange={(newValue: any) => handleDatePickChange(newValue, 'end')}
            renderInput={(params: any) => (
              <TextField
                {...params}
              />
            )}
            minDate={minDateEnd}
            maxDate={lastDateFormatted}
            // eslint-disable-next-line
            shouldDisableDate={disableEmptyDates}
          />
        </LocalizationProvider>
      </ThemeProvider>
    ) : null
  )
}
