import React, { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import * as ls from 'local-storage'
import { Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { API } from '../../providers/API'
import { InputField } from '../FormElements/InputField'
import { OverlayContainer, SimpleBox } from '../../App.styles'
import { FailureNotification, SuccessNotification } from '../SuccessNotification/SuccessNotification.styles'
import { theme } from '../../theme/theme'

interface PurchasePromptProps {
  setShowPrompt: Dispatch<SetStateAction<boolean>>
  showPrompt: boolean,
  packageType: string
}

export const PurchasePrompt: React.FC<PurchasePromptProps> = (
  {
    setShowPrompt,
    showPrompt,
    packageType,
    // action
  },
) => {
  const [isLoading, setIsLoading] = useState(false)
  const [purchaseFailure, setPurchaseFailure] = useState(false)
  const [purchaseSuccess, setPurchaseSuccess] = useState(false)
  const { t } = useTranslation()

  const ValidationSchema = Yup.object().shape({
    companyName: Yup.string().required(t('tokenPackages.formErrors.companyName')),
    companyAddress: Yup.string().required(t('tokenPackages.formErrors.companyAddress')),
    VAT: Yup.string().required(t('tokenPackages.formErrors.VAT')),
  })

  if (showPrompt) {
    return (
      <OverlayContainer>
        <SimpleBox>
          {!purchaseSuccess ? (
            <Formik
              initialValues={{ companyName: '', companyAddress: '', VAT: '' }}
              validationSchema={ValidationSchema}
              enableReinitialize
              onSubmit={(values) => {
                setIsLoading(true)
                const postData = {
                  companyName: values.companyName,
                  companyAddress: values.companyAddress,
                  VAT: values.VAT,
                  pack: packageType,
                }

                API.post('/purchaseCreditsOrder', postData, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
                  if (response.data.status === 'success') {
                    setPurchaseSuccess(true)
                    setIsLoading(false)
                  } else if (response.data.message) {
                    setPurchaseFailure(true)
                    setIsLoading(false)
                  }
                }).catch(() => {
                  setPurchaseFailure(true)
                  setIsLoading(false)
                })
              }}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <InputField label={t('registration.companyName')} name="companyName" id="companyName" type="text" />
                  <InputField label={t('registration.headquarters')} name="companyAddress" id="companyAddress" type="text" />
                  <InputField label={t('registration.VAT')} name="VAT" id="VAT" type="text" />
                  <Button
                    type="button"
                    variant="contained"
                    fullWidth
                    color="secondary"
                    onClick={() => {
                      setShowPrompt(false)
                    }}
                    style={{ marginBottom: theme.space.md }}
                  >
                    {t('tokenPackages.cancelPurchase')}
                  </Button>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth
                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={null}
                  >
                    {t('tokenPackages.buy')}
                  </LoadingButton>
                  {purchaseFailure && <FailureNotification style={{ marginTop: theme.space.md }}>{t('tokenPackages.purchaseFailedMessage')}</FailureNotification>}
                </form>
              )}
            </Formik>
          ) : (
            <>
              <SuccessNotification style={{ marginBottom: theme.space.md }}>{t('tokenPackages.purchaseSuccessMessage')}</SuccessNotification>
              <Button
                type="button"
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => {
                  setShowPrompt(false)
                }}
              >
                {t('general.close')}
              </Button>
            </>
          )}
        </SimpleBox>
      </OverlayContainer>
    )
  }

  return null
}
