import React from 'react'
import { useTranslation } from 'react-i18next'
import geojson2svg from 'geojson-to-svg'
import { FieldItem } from './Fields.types'
import { FieldItemRow } from './FieldsListing.styles'
import areaIcon from '../../icons/area.svg'

interface FieldsItemProps {
  field?: FieldItem
  zoneName?: string
  showArea?: boolean
  blueBG?: boolean
  fullWidth?: boolean
  isMainField?: boolean
}

export const FieldPolygonHeader: React.FC<FieldsItemProps> = ({
  field, zoneName, showArea, blueBG, fullWidth, isMainField,
}) => {
  const { t } = useTranslation()

  const generateSvgUrl = () => {
    const coords = JSON.parse(field!.coordinates)
    const svgStr = geojson2svg().type('myField')
      .styles({ YourType: { fill: '#FFF' } })
      .projection(([x, y]:[any, any]) => [x, -y])
      .data({ ...coords, properties: { myField: 'YourType' } })
      .render()
    const svg = new Blob([svgStr], { type: 'image/svg+xml' })
    const svgUrl = URL.createObjectURL(svg)

    return svgUrl
  }

  return (
    <FieldItemRow blueBG={blueBG} fullWidth={fullWidth}>
      <span className="svgHolder">
        {field && field.coordinates && (
          <img src={generateSvgUrl()} alt="" />
        )}
      </span>
      <span className="name">
        {isMainField && (
          <>
            <span className="isMainField">{t('mixes.mainField')}</span>
            <br />
          </>
        )}
        {field && field.name}
        {zoneName && zoneName}
      </span>
      {field && field!.area && showArea === true ? (
        <span className="area">
          <img src={areaIcon} alt="" />
          {field && field.area}
          {' '}
          {t('createField.dka')}
        </span>
      ) : (
        null
      )}
    </FieldItemRow>
  )
}
