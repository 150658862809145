import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faDatabase } from '@fortawesome/free-solid-svg-icons'
import {
  DataLayersContainer,
  FiltersContainer,
  MapOverlayContainer,
  MapOverlayLoadingContainer,
  SectionToggles,
  SectionsContainer,

} from './MapOverlayFilters.styles'
import { MapDataContext } from '../../../providers/MapDataProvider'
import { ZoneFilters } from './filters/ZoneFilters'
import { PolygonsFillToggle } from './filters/PolygonsFillToggle'
import { NDVIDataLayers } from './dataLayers/NDVIDataLayers'
import { CashCropFilters } from './filters/CashCropFilters'
import { WeatherDataLayer } from './dataLayers/WeatherDataLayer'
import { SoilDataLayer } from './dataLayers/SoilDataLayer'
import { Loading } from '../../Loading/Loading'

export const MapOverlay = () => {
  const { t } = useTranslation()
  const {
    mapOverlayState,
    setMapOverlayState,
    mapOverlayLoading,
  } = useContext(MapDataContext)

  const toggleOpenedSections = (section: 'filters' | 'data') => () => {
    setMapOverlayState((prevState) => ({
      isOpen: prevState.activeSection !== section || !prevState.isOpen,
      activeSection: section,
    }))
  }

  return (
    <MapOverlayContainer isOpen={mapOverlayState.isOpen}>
      <SectionToggles>
        <button type="button" onClick={toggleOpenedSections('filters')}>
          <span className="icon filters"><FontAwesomeIcon icon={faFilter} /></span>
          {t('mapOverlay.filters')}
        </button>
        <button type="button" onClick={toggleOpenedSections('data')}>
          <span className="icon data"><FontAwesomeIcon icon={faDatabase} /></span>
          {t('mapOverlay.data')}
        </button>
      </SectionToggles>
      <SectionsContainer>
        <MapOverlayLoadingContainer isActive={mapOverlayLoading}>
          <Loading />
        </MapOverlayLoadingContainer>
        <FiltersContainer isActive={mapOverlayState.activeSection === 'filters'}>
          <PolygonsFillToggle />
          <ZoneFilters />
          <CashCropFilters />
        </FiltersContainer>
        <DataLayersContainer isActive={mapOverlayState.activeSection === 'data'}>
          <NDVIDataLayers />
          <WeatherDataLayer />
          <SoilDataLayer />
        </DataLayersContainer>
      </SectionsContainer>
    </MapOverlayContainer>
  )
}
