import React, { useContext, useState, useEffect } from 'react'
import * as ls from 'local-storage'
import { useTranslation } from 'react-i18next'
import { CompareGrid } from '../components/NdviFIeld/NdviField.styles'
import { NdviCompareItem } from '../components/NdviFIeld/NdviCompareItem'
import { FieldItem } from '../components/FieldsListing/Fields.types'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import { API } from '../providers/API'
import { Loading } from '../components/Loading/Loading'
import { NdviPalette } from '../components/NdviFIeld/NdviPalette'

// const falls = require('../utils/falls.json')

export const NdviFieldsCompare = () => {
  const { userInfo } = useContext(GlobalStateContext)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [fields, setFields] = useState<FieldItem[]>([])
  const { t } = useTranslation()

  useEffect(() => {
    API.get(`/fields/search/${userInfo.id}  `, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setFields(response.data)
      setIsLoading(false)
    })
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <h1>{t('navigation.ndviCompare')}</h1>
      <NdviPalette />
      <CompareGrid>
        <NdviCompareItem fields={fields} />
        <NdviCompareItem fields={fields} />
      </CompareGrid>
    </>
  )
}
