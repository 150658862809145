import styled, { css } from 'styled-components'

import { theme } from '../../theme/theme'

export const ZoneItemContainer = styled.div(
  () => css`
    border: 3px solid ${theme.colors.blueUI};
    border-radius: 5px;
    padding: ${theme.space.sm} ${theme.space.md};
    margin-bottom: ${theme.space.md};

    .zoneFieldsDetails {
      margin: ${theme.space.lg} 0 ${theme.space.md} 0;
    }
`,
)

export const Header = styled.div<{zoneColor: string}>(
  ({ zoneColor }) => css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: ${theme.space.md};
  margin-bottom: ${theme.space.md};

  .titleContainer {
    display: flex;
    align-items: center;
    

    h2 {
      font-weight: normal;
      font-size: ${theme.fontSizes.xl};
      margin: 0;
    }

    & > svg {
      margin-left: ${theme.space.sm};
      font-size: 16px;
    }

    .zoneTypeIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      border-radius: 5px;
      background: ${zoneColor};
      margin-right: ${theme.space.md};

      img {
        height: 30px;
        width: 30px;
      }
    }
  }

  .fieldDetails {
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.space.md};

    &__item {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      background: ${theme.colors.blueBGUI};
      padding: ${theme.space.sm} ${theme.space.md};
      border-radius: 5px;
      margin-right: ${theme.space.md};

      &:last-child {
        margin-right: 0;
      }

      svg {
        margin-right: ${theme.space.md};
      }

      &.mixChoice {
        width: 220px;
        padding-right: 0;

        .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
            border-color: transparent !important;
        }


        .MuiOutlinedInput-root.MuiInputBase-root {
            padding-left: 0 !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;

            input {
                padding-left: 0 !important;
            }
        }

        .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root {
            margin: 0;
        }

        .MuiAutocomplete-endAdornment > .MuiIconButton-root svg {
            width: 1.4em;
            height: 1.4em;
            margin: 0 !important;
        }

        .MuiAutocomplete-endAdornment {
            top: calc(50% - 17px) !important;
        }
      }
    }
  }
`,
)

export const ColorPickerHolder = styled.div<{isOpen: boolean, color: string, position?: string}>(
  ({ isOpen, color, position = 'right' }) => css`
    position: relative;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    background: ${color};
    cursor: pointer;

    & > * {
      position: absolute !important;
      z-index: 9999;
      top: calc(100% + ${theme.space.xs});
      right: ${position === 'left' ? 'auto' : 0};
      left: ${position === 'left' ? 0 : 'auto'};
      display: ${isOpen ? 'inline-block' : 'none'};
    }
  `,
)

export const AddNewItemButton = styled.div<{show?: boolean}>(
  ({ show = true }) => css`
    display: ${show ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    border: 2px dashed ${theme.colors.blueUI};
    border-radius: 5px;
    padding: ${theme.space.sm} ${theme.space.md};
    min-height: 180px;

    .fieldChoiceHolder {
      flex-grow: 1;

      label {
        display: block;
        text-align: center;
        margin-bottom: ${theme.space.sm};
      }
    }
`,
)

export const PersonalFieldWrap = styled.div(
  () => css`
    position: relative;
`,
)

export const DeleteGridItemButton = styled.button(
  () => css`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: ${theme.colors.agroOrange};
  color: #FFF;
  cursor: pointer;
`,
)
