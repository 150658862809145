import React, { useContext, useState } from 'react'
import TextField from '@mui/material/TextField'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AgromonContext } from '../../providers/AgromonStateProvider'
import { disableEmptyDates } from '../../utils/disableCalendarDates'

import { Calendar } from './NdviField.styles'
import { AgromonData } from './NdviField.types'
import { IconCalendar } from '../../icons'

export const CalendarHolder: React.FC<{data: AgromonData[]}> = ({ data }) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const { setDateItemSelector, setSelectedDate } = useContext(AgromonContext)

  const allDates:string[] = data.map((item: AgromonData) => {
    const date = new Date(item.dt * 1000)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const dateFormatted = `${day}-${month}-${year}`

    return dateFormatted
  })

  const handleDatePickChange = (newValue: any) => {
    if (newValue === null) return

    const selector = `date_${newValue.getDate()}-${newValue.getMonth() + 1}-${newValue.getFullYear()}`
    const timelineItem = document.getElementById(selector)
    const timelineContent = document.getElementById('timelineContent')
    const itemTimestamp = timelineItem!.dataset.timestamp
    const timelineContentOffset = timelineContent!.offsetLeft
    const itemOffset = timelineItem!.offsetLeft - timelineContentOffset
    setDateItemSelector(selector)
    setSelectedDate(parseInt(itemTimestamp!))
    timelineContent!.scrollTo({
      top: 0,
      left: itemOffset,
      behavior: 'smooth',
    })
    setIsCalendarOpen(false)
  }

  function checkDisableDates(date: any) {
    const shouldDisableDate = disableEmptyDates(date, allDates)
    return shouldDisableDate
  }

  const firstDate = new Date(data[0].dt * 1000)
  const lastDate = new Date(data.reverse()[0].dt * 1000)

  const firstDateYear = firstDate.getFullYear()
  const firstDateMonth = firstDate.getMonth()
  const firstDateDay = firstDate.getDate()
  const firstDateFormatted = new Date(firstDateYear, firstDateMonth, firstDateDay)

  const lastDateYear = lastDate.getFullYear()
  const lastDateMonth = lastDate.getMonth()
  const lastDateDay = lastDate.getDate()
  const lastDateFormatted = new Date(lastDateYear, lastDateMonth, lastDateDay)

  const DatePickerTheme = createTheme({
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            opacity: 0,
            pointerEvents: 'none',
            width: '1px',
            height: '1px',
            padding: '0px',
            overflow: 'hidden',
            clip: 'rect(0, 0, 0, 0)',
          },
        },
      },
    },
  })

  return (

    <Calendar>
      {isCalendarOpen && (
      <div
        style={{
          position: 'fixed', zIndex: 1, left: '0', top: '0', width: '100%', height: '100%', background: 'rgba(0,0,0,0.2)',
        }}
        onClick={() => setIsCalendarOpen(false)}
        onKeyUp={() => setIsCalendarOpen(false)}
        role="button"
        tabIndex={0}
        aria-label="Close calendar"
      />
      )}
      <button
        type="button"
        className="calendarButton"
        onClick={() => setIsCalendarOpen((prev:boolean) => !prev)}
      >
        <IconCalendar />
      </button>
      <ThemeProvider theme={DatePickerTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="asdasd"
            inputFormat="MM/dd/yyyy"
            value={lastDateFormatted}
            open={isCalendarOpen}
            onChange={(newValue: any) => handleDatePickChange(newValue)}
            renderInput={(params: any) => (
              <TextField
                {...params}
              />
            )}
            minDate={firstDateFormatted}
            maxDate={lastDateFormatted}
            // eslint-disable-next-line
            shouldDisableDate={checkDisableDates}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </Calendar>
  )
}
