import React, {
  useState, useEffect, useContext,
} from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { MixBasics } from '../components/MixCreation/MixBasics'
import { SoilData } from '../components/MixCreation/SoilData'
import { MixResults } from '../components/MixCreation/MixResults'
import { SoilAnalysisChoise } from '../components/MixCreation/SoilAnalysisChoise'
import { RequestAnalysis } from '../components/MixCreation/RequestAnalysis'
import { NoAnalysis } from '../components/MixCreation/NoAnalysis'
import { OwnMix } from '../components/MixCreation/ownMix'
import { OrderMix } from '../components/MixCreation/OrderMix'
import { OrderMixProvider } from '../providers/OrderMixProvider'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { HeaderInfoTypes } from '../components/MixElements/MixElement.types'
import { HeaderInfo } from '../components/MixElements/HeaderInfo/HeaderInfo'
import { GlobalStateContext } from '../providers/GlobalStateProvider'

export const CreateMixPage = () => {
  const location = useLocation<any>()
  const mixType = location.search === '?mixType=manual' ? 'manual' : 'ai'
  const { resultsLoading } = useContext(GlobalStateContext)

  const [processStep, setProcessStep] = useState<number>(1)
  const [newMixId, setNewMixId] = useState<number>(0)
  const [stepTitle, setStepTitle] = useState<string>('Създай Микс')
  const [soilAnalysis, setSoilAnalysis] = useState<string>('yes')
  const [createOwnMix, setCreateOwnMix] = useState<boolean>(false)
  const [mixHeaderInfo, setMixHeaderInfo] = useState<HeaderInfoTypes | undefined >(undefined)
  const { t } = useTranslation()

  useEffect(() => {
    setProcessStep(1)
  }, [mixType])

  const ValidationSchema = Yup.object().shape({
    seedingDate: Yup.date().required(t('createMix.errors.seedingDate')),
    terminationDate: Yup.date().required(t('createMix.errors.terminationDate')),
    pH: Yup.number().required(t('createMix.errors.noValue')).min(1, `${t('createMix.errors.valueMustBeMin')} 1`).max(13, `${t('createMix.errors.valueMustBeMax')} 13`),
    N: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(10, `${t('createMix.errors.valueMustBeMax')} 10`),
    TMN: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 1`).max(200, `${t('createMix.errors.valueMustBeMax')} 200`),
    P: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(500, `${t('createMix.errors.valueMustBeMax')} 500`),
    K: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(2000, `${t('createMix.errors.valueMustBeMax')} 2000`),
    Ca: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(50000, `${t('createMix.errors.valueMustBeMax')} 50000`),
    Mg: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(2000, `${t('createMix.errors.valueMustBeMax')} 2000`),
    S: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(100, `${t('createMix.errors.valueMustBeMax')} 100`),
    B: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(20, `${t('createMix.errors.valueMustBeMax')} 20`),
    Cu: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(50, `${t('createMix.errors.valueMustBeMax')} 50`),
    Fe: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(2000, `${t('createMix.errors.valueMustBeMax')} 2000`),
    Mn: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(800, `${t('createMix.errors.valueMustBeMax')} 800`),
    Zn: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(1000, `${t('createMix.errors.valueMustBeMax')} 1000`),
    EC: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(2000, `${t('createMix.errors.valueMustBeMax')} 2000`),
    Carbonates: Yup.number().required('Моля въведете истойностме').min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(100, `${t('createMix.errors.valueMustBeMax')} 100`),
    bulkDensity: Yup.number().required(t('createMix.errors.noValue')).min(0.1, `${t('createMix.errors.valueMustBeMin')} 0.1`).max(2, `${t('createMix.errors.valueMustBeMax')} 2`),
    aggregateStability: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(100, `${t('createMix.errors.valueMustBeMax')} 100`),
    mineralizableNitrogen: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(100, `${t('createMix.errors.valueMustBeMax')} 100`),
    organicCarbon: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(25, `${t('createMix.errors.valueMustBeMax')} 25`),
    hummus: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(35, `${t('createMix.errors.valueMustBeMax')} 35`),
    soilRespiration: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(20, `${t('createMix.errors.valueMustBeMax')} 20`),
    sand: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(100, `${t('createMix.errors.valueMustBeMax')} 100`),
    silt: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(100, `${t('createMix.errors.valueMustBeMax')} 100`),
    clay: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(100, `${t('createMix.errors.valueMustBeMax')} 100`),
  })

  return (
    <>
      {mixHeaderInfo && !resultsLoading && processStep > 2 ? (
        <HeaderInfo {...mixHeaderInfo} />
      ) : (
        <h1>{stepTitle}</h1>
      )}
      <Formik
        initialValues={{
          areaType: 'field',
          mixName: '',
          fieldId: '',
          zoneId: '',
          nextCrop: '',
          n1CashCropId: '',
          n2CashCropId: '',
          n3CashCropId: '',
          n4CashCropId: '',
          seedingDate: '',
          terminationDate: '',
          analysisId: null,
          pH: '8.17',
          N: '8.12',
          TMN: '9.26',
          P: '15.41',
          K: '176.03',
          Ca: '6012.11',
          Mg: '308.11',
          S: '10.55',
          B: '1.04',
          Cu: '0.72',
          Fe: '6.97',
          Mn: '2.78',
          Zn: '0.24',
          EC: '114.65',
          Carbonates: '3',
          bulkDensity: '1.17',
          aggregateStability: '76.55',
          mineralizableNitrogen: '11.28',
          organicCarbon: '1.6',
          hummus: '2.76',
          soilRespiration: '0.11',
          activeCarbon: '300',
          TOCTN: '50',
          sand: '12.16',
          silt: '64.71',
          clay: '23.13',
          soilStruc: 'Лека',
          soilAcidity: 'Силно кисела (pH, H2O, <5.5)',
          soilColour: 'Черна',
          coordinates: null,
        }}
        validationSchema={ValidationSchema}
        enableReinitialize
        onSubmit={() => {
          setProcessStep(processStep + 1)
        }}
      >
        {({
          values, handleSubmit, setFieldValue, handleChange, validateForm, errors,
        }) => (
          <form onSubmit={handleSubmit}>
            {processStep === 1 && (
            <MixBasics
              fieldValues={values}
              setFieldValue={setFieldValue}
              setProcessStep={setProcessStep}
              formHandleChange={handleChange}
              processStep={processStep}
              setNewMixId={setNewMixId}
              setStepTitle={setStepTitle}
              mixType={mixType}
            />
            )}

            {processStep === 2 && mixType === 'ai' && (
              <SoilAnalysisChoise
                fieldValues={values}
                setProcessStep={setProcessStep}
                processStep={processStep}
                setStepTitle={setStepTitle}
                setSoilAnalysis={setSoilAnalysis}
                soilAnalysis={soilAnalysis}
              />
            )}

            {processStep === 2 && mixType === 'manual' && (
              <OwnMix
                fieldId={values.fieldId}
                newMixId={newMixId}
                setStepTitle={setStepTitle}
                processStep={processStep}
                setProcessStep={setProcessStep}
                setCreateOwnMix={setCreateOwnMix}
                mixType={mixType}
              />
            )}

            {processStep === 3 && soilAnalysis === 'yes' && (
            <SoilData
              setProcessStep={setProcessStep}
              processStep={processStep}
              setStepTitle={setStepTitle}
              fieldValues={values}
              formHandleChange={handleChange}
              validateForm={validateForm}
              formErrors={errors}
              setFieldValue={setFieldValue}
            />
            )}

            {processStep === 3 && soilAnalysis === 'want' && (
              <RequestAnalysis
                fieldId={values.fieldId}
                newMixId={newMixId}
                setStepTitle={setStepTitle}
              />
            )}

            {processStep === 3 && soilAnalysis === 'no' && (
              <NoAnalysis
                setStepTitle={setStepTitle}
                fieldValues={values}
                setFieldValue={setFieldValue}
                formHandleChange={handleChange}
                processStep={processStep}
                setProcessStep={setProcessStep}
              />
            )}

            {processStep === 4 && !createOwnMix && (
            <MixResults
              fieldId={values.fieldId}
              zoneId={values.zoneId}
              fieldValues={values}
              newMixId={newMixId}
              setStepTitle={setStepTitle}
              soilAnalysis={soilAnalysis}
              processStep={processStep}
              setProcessStep={setProcessStep}
              setMixHeaderInfo={setMixHeaderInfo}
            />
            )}

            {processStep === 4 && createOwnMix && (
            <OwnMix
              fieldId={values.fieldId}
              newMixId={newMixId}
              setStepTitle={setStepTitle}
              processStep={processStep}
              setProcessStep={setProcessStep}
              setCreateOwnMix={setCreateOwnMix}
              mixType={mixType}
            />
            )}

            {processStep === 5 && (
            <OrderMixProvider>
              <OrderMix
                newMixId={newMixId}
                zoneId={values.zoneId}
                setStepTitle={setStepTitle}
                processStep={processStep}
                setProcessStep={setProcessStep}
                mixType={mixType}
              />
            </OrderMixProvider>
            )}
          </form>
        )}
      </Formik>

    </>
  )
}
