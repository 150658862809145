import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  parseJSON,
} from 'date-fns'
import {
  MainWeatherData, WeatherDateHolder, WeatherItem, WeatherTable,
} from './WeatherListing.styles'
import { DegreesSymbol } from './DegreesSymbol'

interface FieldsItemProps {
  weatherData: any
}

export const WeatherListingItemHourly: React.FC<FieldsItemProps> = ({
  weatherData,
}) => {
  const formattedDate = parseJSON(weatherData?.dt)
  const { t } = useTranslation()

  return (
    <>
      {formattedDate.getHours() === 0 && (
      <h3>
        {formattedDate.getDate()}
        {' '}
        {formattedDate.toLocaleDateString('en-US', { month: 'long' })}
      </h3>
      )}
      <WeatherItem hourly>

        <WeatherDateHolder>
          {String(formattedDate.getHours()).padStart(2, '0')}
          :00

        </WeatherDateHolder>

        <MainWeatherData>
          <div className="icon">
            <img src={`https://openweathermap.org/img/wn/${weatherData?.weather[0].icon}@4x.png`} alt={weatherData?.weather[0].description} />
          </div>
          <div className="temperature">
            <strong className="mainTemp">
              {parseFloat(weatherData?.main.temp).toFixed(2)}
              <DegreesSymbol />
            </strong>
            <span>{weatherData?.weather[0].description}</span>
          </div>
        </MainWeatherData>
        <WeatherTable>
          <div>
            {t('weatherItem.wind')}:
            {' '}
            {weatherData?.wind.speed}
            {' '}
            m/s
          </div>
          <div>
            {t('weatherItem.rain')}:
            {' '}
            {weatherData?.rain ? weatherData?.rain['3h'] : 0.00}
            mm
          </div>
        </WeatherTable>

      </WeatherItem>
    </>
  )
}
