import React, { useState, useEffect } from 'react'
import * as ls from 'local-storage'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { FieldItem } from './Fields.types'
import { IconMyMixes } from '../../icons'
import { API } from '../../providers/API'

interface FieldProps {
  field: FieldItem
  mixOptions: any
}

interface MixSelectProps {
  label: string
  id: number
}

export const FieldMixSelect: React.FC<FieldProps> = ({ field, mixOptions }) => {
  const [selectedMixOption, setSelectedMixOption] = useState<MixSelectProps>()

  useEffect(() => {
    // pre-fill field autocomplete value with already selected one
    // or pre-fill with first in fields array
    if (mixOptions.length > 0) {
      const selectedOption = mixOptions?.filter((item: any) => item.id === field.mix?.id).pop()
      if (selectedOption) {
        setSelectedMixOption(selectedOption)
      } else {
        setSelectedMixOption(mixOptions[0])
      }
    }
  }, [mixOptions])

  if (!selectedMixOption) {
    <div>loading</div>
  }

  return (
    <div className="fieldStats">
      <IconMyMixes size="24" color="#56CCF2" />
      {mixOptions.length > 0 && selectedMixOption !== undefined && (
      <Autocomplete
        disablePortal
        options={mixOptions}
        defaultValue={selectedMixOption}
        renderInput={(params) => <TextField {...params} />}
        fullWidth
        onChange={(event, newValue: MixSelectProps | null) => {
          const assignMixData = {
            fieldId: field.id,
            mixId: newValue?.id,
          }
          API.post('/assignMix', assignMixData, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } })
        }}
      />
      )}
    </div>
  )
}
