import React from 'react'
import { theme } from '../../theme/theme'
import { ndviColorRange } from '../../utils/ndviColorRange'

export const StatRow: React.FC<{statName: string, currentAgroStats: any}> = ({
  statName, currentAgroStats,
}) => {
  const coloredStats = ['min', 'max', 'mean', 'median']

  return (
    <div className="statsRow" key={statName}>
      <span className="statName">{statName === 'std' ? 'deviation' : statName}</span>
      {coloredStats.includes(statName) ? (
        <span
          className="statValue"
          style={{
            color: ndviColorRange(currentAgroStats[statName]) > 4 ? '#FFF' : '#000',
            // @ts-ignore
            background: theme.colors.ndviColors[ndviColorRange(currentAgroStats[statName])],
          }}
        >{currentAgroStats[statName].toFixed(2)}
        </span>
      ) : (
        <span className="statValue">{currentAgroStats[statName].toFixed(2)}</span>
      )}
    </div>

  )
}
