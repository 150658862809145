import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import {
  LoadingBar, Progress, StepsContainerOverlay, StepsContainer, StepText,
} from './LoadingSteps.styles'

export const LoadingSteps: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const [loaderWidth, setLoaderWidth] = useState(0)
  const { resultsLoading, setResultsLoading } = useContext(GlobalStateContext)
  const { t } = useTranslation()

  function getRandomInt(min: number, max: number) {
    const minNumber = Math.ceil(min)
    const maxNumber = Math.floor(max)
    return Math.floor(Math.random() * (maxNumber - minNumber + 1) + minNumber)
  }

  const incrementProgress = (loadingProgress = 1) => {
    if (loadingProgress >= 100) {
      setResultsLoading(false)
      setLoaderWidth(0)
      setCurrentStep(1)
      return
    }

    setTimeout(async () => {
      let nextStop = getRandomInt(loadingProgress, loadingProgress + 40)
      if (nextStop >= 100) {
        nextStop = 100
      }
      for (let i = loadingProgress; i <= nextStop; i++) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve) => {
          setTimeout(() => {
            setCurrentStep(Math.ceil(i / 20))
            setLoaderWidth(i)
            resolve(true)
          }, 50)
        })

        if (i >= 100) {
          break
        }
      }

      incrementProgress(nextStop)
    }, 600)
  }

  useEffect(() => {
    setResultsLoading(true)
    incrementProgress()
  }, [])

  if (!resultsLoading) {
    return <div />
  }

  return (

    <StepsContainerOverlay>
      <StepsContainer>
        {currentStep === 1 && (<StepText>{t('createMix.loadingSteps.step1')}</StepText>)}
        {currentStep === 2 && (<StepText>{t('createMix.loadingSteps.step2')}</StepText>)}
        {currentStep === 3 && (<StepText>{t('createMix.loadingSteps.step3')}</StepText>)}
        {currentStep === 4 && (<StepText>{t('createMix.loadingSteps.step4')}</StepText>)}
        {(currentStep === 5 || currentStep === 6) && (
        <StepText>{t('createMix.loadingSteps.step5')}</StepText>
        )}
        <LoadingBar>
          <Progress style={{ width: `${loaderWidth}%` }} />
          <span className="displayPercentage">
            {loaderWidth}
            %
          </span>
        </LoadingBar>
      </StepsContainer>
    </StepsContainerOverlay>
  )
}
