import React, { useState } from 'react'
import * as ls from 'local-storage'
import { useTranslation } from 'react-i18next'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Collapse from '@mui/material/Collapse'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { theme } from '../../theme/theme'
import { CropRotationEditForm } from './CropRotationEditForm'
import { CropRotationItem } from './CropRotation.types'
import { CashCropOptionsTypes } from '../../utils/useCashCropsData'
import { API } from '../../providers/API'

export const CropRotationRow = (
  {
    rowData, cashCropOptions, replaceRowData, reloadCropRotationData,
  }: {
    rowData: CropRotationItem
    cashCropOptions: CashCropOptionsTypes[]
    replaceRowData: ({ newData, cropTypes } : {newData: CropRotationItem, cropTypes: 'nextCrop' | 'previousCrops'}) => void
    reloadCropRotationData: () => void
  },
) => {
  const { t } = useTranslation()
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const deleteRow = () => {
    setIsDeleting(true)
    API.delete(`/cropRotationDelete/${rowData.id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(
      () => {
        reloadCropRotationData()
        setIsDeleting(false)
      },
    )
  }

  const cropLabel = cashCropOptions.filter(
    (item: CashCropOptionsTypes) => rowData.cropId === item.id,
  )[0].label

  return (
    <>
      <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {t(cropLabel)}
        </TableCell>
        <TableCell align="center">{rowData.harvestDate.split('-')[2]}</TableCell>
        <TableCell align="center">{rowData.seedingDate}</TableCell>
        <TableCell align="center">{rowData.harvestDate}</TableCell>
        <TableCell align="right">
          <Button
            sx={{ minWidth: 'auto' }}
            variant="contained"
            type="button"
            color="primary"
            onClick={() => setIsEditOpen((prev) => !prev)}
          ><FontAwesomeIcon icon={faEdit} size="lg" />
          </Button>
          <LoadingButton
            sx={{ minWidth: 'auto', marginLeft: theme.space.sm }}
            variant="contained"
            type="button"
            color="error"
            loadingPosition="start"
            loading={isDeleting}
            onClick={deleteRow}
          ><FontAwesomeIcon icon={faTrash} size="lg" />
          </LoadingButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderWidth: isEditOpen ? 1 : 0 }}
          colSpan={6}
        >
          <Collapse in={isEditOpen} timeout="auto" unmountOnExit sx={{ padding: `${theme.space.md} 0;` }}>
            <CropRotationEditForm
              rowData={rowData}
              cashCropOptions={cashCropOptions}
              replaceRowData={replaceRowData}
              toggleAddForm={() => setIsEditOpen(false)}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
