import React, { useContext, useEffect, useState } from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useTranslation } from 'react-i18next'
import { MapDataContext } from '../../../../providers/MapDataProvider'
import { AccordionItemHeader } from '../accordionElements/AccordionItemHeader'
import { AccordionItemContent } from '../accordionElements/AccordionElements.styles'

type dataFetchingProps = 'bioScore' | 'chemScore' | 'physScore'
type dataOptionsType = { value: dataFetchingProps, name: string }

export const SoilDataLayer = () => {
  const {
    allVisibleFieldIds, setDataLayerContent,
    allAccountFields, activeDataSection, activeDataLayer, setActiveDataLayer,
  } = useContext(MapDataContext)
  const { t } = useTranslation()

  const [soilData, setSoilData] = useState<any>([])
  const [dataFetchingType, setDataFetchingType] = useState<dataFetchingProps>()

  const soilDataOptions: dataOptionsType[] = [
    { value: 'chemScore', name: t('soilAnalysis.chemicalHealth') },
    { value: 'physScore', name: t('soilAnalysis.physicalHealth') },
    { value: 'bioScore', name: t('soilAnalysis.bioHealth') }]

  const fetchSoilData = ({ dataType } : {dataType: dataFetchingProps}) => {
    setSoilData([])
    setActiveDataLayer(dataType)

    const soilDataCollection = allAccountFields.map((field) => (
      {
        fieldId: field.id, soilData: field.softScoring, dataType,
      }
    ))

    const filteredSoilData = soilDataCollection.filter(
      (item) => allVisibleFieldIds.some((item2) => item2 === item.fieldId),
    )

    setSoilData(filteredSoilData)
  }

  useEffect(() => {
    const soilDataContent = soilData.map((item: any) => {
      const displayData = item.soilData[item.dataType] ? item.soilData[item.dataType].toFixed(2) : t('mapOverlay.noData')
      const name = soilDataOptions.find(
        (option: dataOptionsType) => option.value === item.dataType,
      )?.name

      return ({
        fieldId: item.fieldId,
        title: name,
        data: displayData,
        isColorCoded: item.soilData[item.dataType],
        type: 'soil',
      })
    })
    setDataLayerContent(soilDataContent)
  }, [soilData])

  const handleDataFetching = ({ dataType } : {dataType: dataFetchingProps}) => {
    fetchSoilData({ dataType })
    setDataFetchingType(dataType)
  }

  useEffect(() => {
    if (dataFetchingType === undefined || activeDataSection !== 'soilData') return
    fetchSoilData({ dataType: dataFetchingType })
  }, [allVisibleFieldIds])

  return (
    <>
      <AccordionItemHeader title="Soil data" value="soilData" type="data" />
      <AccordionItemContent isOpen={activeDataSection === 'soilData'}>
        <RadioGroup
          name="dataLayers"
          value={activeDataLayer}
          onChange={(e) => {
            handleDataFetching({ dataType: e.target.value as dataFetchingProps })
          }}
        >
          {soilDataOptions.map((item: dataOptionsType) => (
            <FormControlLabel key={`soilDataOption_${item.value}`} value={item.value} control={<Radio size="small" />} label={item.name} />
          ))}
        </RadioGroup>
      </AccordionItemContent>
    </>
  )
}
