import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { MapDataContext } from '../../../../providers/MapDataProvider'
import { CashCrop, FieldItem } from '../../../FieldsListing/Fields.types'
import { AccordionItemHeader } from '../accordionElements/AccordionItemHeader'
import { AccordionItemContent } from '../accordionElements/AccordionElements.styles'
import { ClearFiltersButton } from '../MapOverlayFilters.styles'

export const CashCropFilters = () => {
  const { t } = useTranslation()
  const {
    allAccountFields,
    setCashCropVisibilityFilter,
    cashCropVisibilityFilter,
    activeFilterSection,
  } = useContext(MapDataContext)

  const cashCrops:CashCrop[] = []
  allAccountFields.forEach((field: FieldItem) => {
    if (field.cashCrop !== undefined) {
      if (field.cashCrop !== null && !cashCrops.some(
        (cashCrop: CashCrop) => cashCrop.id === field.cashCrop?.id,
      )) {
        cashCrops.push(field.cashCrop)
      }
    }
  })

  return (
    <>
      <AccordionItemHeader title={t('mapOverlay.cashCropFilter')} value="crops" type="filter" />
      <AccordionItemContent isOpen={activeFilterSection === 'crops'}>
        <RadioGroup
          name="cashCropFilter"
          value={cashCropVisibilityFilter}
          onChange={(e) => {
            setCashCropVisibilityFilter(parseInt(e.target.value))
          }}
          className="seedingTypeRadioGroup"
        >
          {cashCrops.map((item: CashCrop) => (
            <FormControlLabel key={item.id} value={item.id} control={<Radio size="small" />} label={t(`crops.${item.cropName}`)} />

          ))}
        </RadioGroup>
        <ClearFiltersButton type="button" onClick={() => setCashCropVisibilityFilter(null)}>{t('mapOverlay.clear')} <FontAwesomeIcon icon={faTimes} /></ClearFiltersButton>
      </AccordionItemContent>
    </>
  )
}
