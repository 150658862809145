import styled, { css } from 'styled-components'
import { theme } from '../../theme/theme'

export const CreateMixHolder = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: ${theme.space.lg};

    ${theme.mediaQueries.mdMin} {
        flex-direction: row;
    }
`

export const FormContainer = styled.div`
    flex-grow: 1;
    margin-top: ${theme.space.md};

    ${theme.mediaQueries.mdMin} {
        margin-top: 0;
    }
`

export const ProcessButtonsHolder = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-top: ${theme.space.md};
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        border-bottom: 3px dotted ${theme.colors.blueUI};
        width: 100%;
        z-index: 1;
    }

    button {
        z-index: 2;
    }
`

export const TopRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    ${theme.mediaQueries.mdMin} {
        flex-direction: row;
    }
`

export const NoAnalysisContainer = styled.div`
    background: ${theme.colors.blueBGUI};
    border-radius: 10px;
    padding: ${theme.space.lg};
`

export const ImageFormLabel = styled.label<{selected: boolean}>(
  ({ selected }) => css`
      text-align: center;
      cursor: pointer;
  
      img {
          display: block;
          margin-bottom: ${theme.space.sm};
          border: 3px solid transparent;
          border-radius: 10px;
          border-color: ${selected ? theme.colors.blueUI : 'transparent'};
      }
  
      input {
          display: none;
      }    
      `,
)

export const SoilStructureContainer = styled.div`
    

    ${theme.mediaQueries.mdMin} {
        width: 50%;
        flex-shrink: 0;
    }

    .content { 
        display: flex;
        justify-content: space-between;
    }

    ${ImageFormLabel} {
        width: 30%;
    }
`

export const AcidityContainer = styled.div`
    border-top: 3px dotted ${theme.colors.blueUI};
    border-bottom: 3px dotted ${theme.colors.blueUI};
    margin: ${theme.space.md} 0;
    padding: ${theme.space.md} 0;

    ${theme.mediaQueries.mdMin} {
        flex-grow: 1;
        border: none;
        border-left: 3px dotted ${theme.colors.blueUI};
        padding: 0 ${theme.space.lg};
        margin-left: ${theme.space.lg};
    }
`

export const SoilColorContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    ${theme.mediaQueries.smMin} {
        flex-wrap: nowrap;
    }

    ${ImageFormLabel} {
        width: 48%;

        ${theme.mediaQueries.smMin} {
            width: 18%;
        }
    }
`

export const OwnMixChoiceContainer = styled.div`
    display: flex;
    align-items: center;
    border-top: 3px dotted ${theme.colors.blueUI};
    padding-top: ${theme.space.lg};
    margin-top: ${theme.space.lg};
`

export const RecalcNormsButtonContainer = styled.div`
    text-align: right;

    ${theme.mediaQueries.mdMax} {
        margin-bottom: ${theme.space.md};
    }
`
