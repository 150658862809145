import React, {
  useContext, useEffect, useRef, useState,
} from 'react'
import { BlockPicker } from 'react-color'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as ls from 'local-storage'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaintbrush, faPencil } from '@fortawesome/free-solid-svg-icons'
import Button from '@mui/material/Button'

import { ZoneListingType, ZoneField } from '../components/ZonesListing/ZonesListing.types'
import { API } from '../providers/API'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { theme } from '../theme/theme'
import { Loading } from '../components/Loading/Loading'
import {
  AddNewItemButton, ColorPickerHolder, Header, ZoneItemContainer,
} from '../components/ZonesListing/ZonesListing.styles'
import { IconArea } from '../icons'
import { SoilListingItem } from '../components/SoilAnalysis/SoilListingItem/SoilListingItem'
import { SimpleGrid } from '../App.styles'
import zoneIconSmart from '../icons/zoneIconSmart.svg'
import zoneIconPersonal from '../icons/zoneIconPersonal.svg'
import { MixTypes } from '../components/MixesListing/Mix.types'
import { ZoneMixChoice } from '../components/ZonesListing/ZoneMixChoice'
import { AddFieldChoice } from '../components/ZonesListing/AddFieldChoice'

import { PersonalZoneFieldItem } from '../components/ZonesListing/PersonalZoneFieldItem'
import FieldMap from '../components/FieldMap/FieldMap'

export const FieldZoneDetails = () => {
  // @ts-ignore
  const { zoneId } = useParams<number>()
  const {
    userInfo, isLoading, setIsLoading,
  } = useContext(GlobalStateContext)

  const [zone, setZone] = useState<ZoneListingType>()
  const [zoneFields, setZoneFields] = useState<ZoneField[]>([])
  const [personalZoneFields, setPersonalZoneFields] = useState<ZoneField[]>([])
  const [smartZoneFields, setSmartZoneFields] = useState<ZoneField[]>([])
  const [clusterPoints, setClusterPoints] = useState<any>([])
  const [mixOptions, setMixOptions] = useState<MixTypes[]>([])
  const [zoneColor, setZoneColor] = useState<string>('')
  const [zoneName, setZoneName] = useState<string>('')
  const [isColorPickerOpen, setIsColorPickerOpen] = useState<boolean>(false)
  const [showFieldChoice, setShowFieldChoice] = useState<boolean>(false)

  const { t } = useTranslation()

  const isFirstRun = useRef(true)
  const editableTitleRef = useRef<any>(null)

  const fetchZoneData = () => {
    setIsLoading(true)
    API.get(`/findZone/${zoneId}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setZone(response.data)
      setZoneColor(response.data.colour)
      setZoneName(response.data.name)
      setZoneFields(response.data.containedFields)
      let geoJsonData:any = []
      if (response.data.containedFields !== null) {
        geoJsonData = response.data.containedFields.map((item:ZoneField) => {
          const data = JSON.parse(item.coordinates)
          const returnData = {
            ...data,
            fieldId: item.id,
          }
          return returnData
        })
      }

      const clusterPointsTemp = geoJsonData.map((item: any) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          // take second [0] to get the first point of the polygon
          coordinates: item.geometry.coordinates[0][0],
        },
        fieldCoordinates: item.geometry.coordinates[0],
        fieldId: item.fieldId,
      }))
      setClusterPoints(clusterPointsTemp)
      setIsLoading(false)
    })
  }

  const fetchMixOptions = () => {
    // setup options for field autocomplete
    API.get(`/userMixes/${userInfo.id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      const mixOptionsArray:any = []
      mixOptionsArray.push({ label: t('general.noAssignedMix'), id: 0 })
      response.data.forEach((item:MixTypes) => {
        mixOptionsArray.push({ label: item.mix.mixName, id: item.mix.id })
      })

      setMixOptions(mixOptionsArray)
    })
  }

  useEffect(() => {
    fetchZoneData()
    fetchMixOptions()
  }, [])

  useEffect(() => {
    if (zoneFields) {
      const personal = zoneFields.filter((item: ZoneField) => item.zoneType === 'personal')
      const smart = zoneFields.filter((item: ZoneField) => item.zoneType === 'smart')
      setPersonalZoneFields(personal)
      setSmartZoneFields(smart)
      const newGeoJsonData = zoneFields.map((item:ZoneField) => {
        const data = JSON.parse(item.coordinates)
        const returnData = {
          ...data,
          fieldId: item.id,
        }
        return returnData
      })

      const clusterPointsTemp = newGeoJsonData.map((item: any) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          // take second [0] to get the first point of the polygon
          coordinates: item.geometry.coordinates[0][0],
        },
        fieldCoordinates: item.geometry.coordinates[0],
        fieldId: item.fieldId,
      }))
      setClusterPoints(clusterPointsTemp)
    }
  }, [zoneFields])

  const handleColorChange = (value: any) => {
    setZoneColor(value.hex)
  }

  let nameEditTimeout: any = null
  const handleEditTitle = (e: any) => {
    clearTimeout(nameEditTimeout)
    nameEditTimeout = setTimeout(() => {
      setZoneName(e.target.innerText)
    }, 500)
  }

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }

    if ((zoneColor !== zone?.colour || zoneName !== zone?.name)
    && (zoneColor !== '' && zoneName !== '')) {
      const editFieldData = {
        colour: zoneColor,
        name: zoneName,
        zoneId: zone?.id,
      }

      API.post('/personalZoneEdit', editFieldData, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } })
    }
  }, [zoneColor, zoneName])

  if (isLoading || !zone) {
    return <Loading />
  }

  const focusEditableHeader = () => {
    editableTitleRef.current.focus()
  }

  return (
    <>
      <h1>{zoneName || zone.name}</h1>

      <FieldMap
        zoneColor={zoneColor}
        fields={zoneFields}
        clusterPoints={clusterPoints}
      />

      <ZoneItemContainer>
        <Header zoneColor={zoneColor}>
          <div className="titleContainer">
            <span className="zoneTypeIcon">
              {zone.type === 'smart' ? (
                <img src={zoneIconSmart} alt="" />
              ) : (
                <img src={zoneIconPersonal} alt="" />
              )}
            </span>
            <h2
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="textbox"
              tabIndex={0}
              contentEditable
              ref={editableTitleRef}
              onKeyUp={handleEditTitle}
            >{zone.name}
            </h2>
            <FontAwesomeIcon
              color={theme.colors.blueUI}
              icon={faPencil}
              onClick={focusEditableHeader}
            />
          </div>
          <div className="fieldDetails">
            <div className="fieldDetails__item mixChoice">
              <ZoneMixChoice zone={zone} mixOptions={mixOptions} />
            </div>
            <div className="fieldDetails__item">
              <IconArea size="18" color="#56CCF2" /><span>{zone.area} {t('general.dka')}</span>
            </div>
            <div className="fieldDetails__item">
              <FontAwesomeIcon icon={faPaintbrush} />
              <ColorPickerHolder
                isOpen={isColorPickerOpen}
                color={zoneColor}
                onClick={() => { setIsColorPickerOpen((value: boolean) => !value) }}
              >
                <BlockPicker
                  color={zoneColor}
                  onChange={handleColorChange}
                  triangle="hide"
                />
              </ColorPickerHolder>
            </div>
          </div>
        </Header>
        {smartZoneFields && smartZoneFields.length > 0 && (
          <>
            <h3 className="zoneFieldsDetails">{t('zones.smartZonesTitle')}</h3>
            <SimpleGrid>
              {smartZoneFields.map((item) => (
                <SoilListingItem item={item} key={`zoneField_${item.id}`} />
              ))}
            </SimpleGrid>
          </>
        )}
        <h3 className="zoneFieldsDetails">{t('zones.personalZonesTitle')}</h3>
        <SimpleGrid>
          <AddNewItemButton>
            <Button
              variant="contained"
              type="button"
              color="primary"
              fullWidth
              onClick={() => {
                setShowFieldChoice(true)
              }}
              style={{ display: showFieldChoice ? 'none' : 'block' }}
            >
              {t('zones.addField')}
            </Button>
            <div className="fieldChoiceHolder" style={{ display: showFieldChoice ? 'block' : 'none' }}>
              <AddFieldChoice
                zone={zone}
                setZoneFields={setZoneFields}
                setShowFieldChoice={setShowFieldChoice}
              />
            </div>
          </AddNewItemButton>
          {personalZoneFields !== null && personalZoneFields.map((item) => (
            <PersonalZoneFieldItem
              field={item}
              zone={zone}
              personalZoneFields={personalZoneFields}
              setPersonalZoneFields={setPersonalZoneFields}
              setZoneFields={setZoneFields}
              zoneFields={zoneFields}
              key={`zoneField_${item.id}`}
            />
          ))}
        </SimpleGrid>
      </ZoneItemContainer>
    </>
  )
}
