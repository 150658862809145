import React, { useContext, useState } from 'react'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { PackageItemWrap } from './PackageListing.styles'
import { PackageItemProps } from './Package.types'
import { PurchasePrompt } from './PurchasePrompt'
import { IconCreditsLeafCenter, IconPrice } from '../../icons'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { Loading } from '../Loading/Loading'
import coinsPackage1 from '../../icons/coinsPackage_1.png'
import coinsPackage2 from '../../icons/coinsPackage_2.png'
import coinsPackage3 from '../../icons/coinsPackage_3.png'

export const PackageItem: React.FC<PackageItemProps> = ({ item, iterationNumber }) => {
  const {
    isLoading,
  } = useContext(GlobalStateContext)
  const [showPrompt, setShowPrompt] = useState(false)
  const { t } = useTranslation()

  function getPackageImage(index: number) {
    switch (index) {
      case 0:
        return coinsPackage1
      case 1:
        return coinsPackage2
      case 2:
        return coinsPackage3
      default:
        return coinsPackage1
    }
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <PackageItemWrap key={`packageItem_${item.id}`}>
      <div className="packageStatsHolder">
        <div className="infoRow packageTitle">
          <img src={getPackageImage(iterationNumber)} alt="" className="packageImage" />
          <span>{t('tokenPackages.package')} <br />
            <strong>{t(`tokenPackages.packageLevels.${item.description}`)}</strong>
          </span>
        </div>
        <div className="infoRow">
          <IconCreditsLeafCenter size="50" />
          {item.quantity} cr
        </div>
        <div className="infoRow">
          <IconPrice size="50" />
          {item.price} {t('general.currencyShort')}
        </div>
      </div>
      <Button
        variant="contained"
        type="button"
        color="primary"
        fullWidth
        onClick={() => {
          setShowPrompt(true)
        }}
      >
        {t('tokenPackages.buy')}
      </Button>
      <PurchasePrompt
        showPrompt={showPrompt}
        setShowPrompt={setShowPrompt}
        packageType={item.description}
      />
    </PackageItemWrap>
  )
}
