import styled, { keyframes } from 'styled-components'
import { theme } from '../../theme/theme'

export const StepsContainerOverlay = styled.div`
  position: absolute;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
`

export const StepsContainer = styled.div`
  background: #FFF;
  color: #000;
  border-radius: 10px;
  max-width: 600px;
  width: 600px;
  margin: 0 ${theme.space.lg};
  padding: ${theme.space.lg};
`

export const StepText = styled.div`
font-size: 1.2em;
  margin-bottom: ${theme.space.md};
  color: #000;
`

export const LoadingBar = styled.div`
  height: 25px;
  background: #F2F2F2;
  overflow: hidden;
  position: relative;
  border-radius: 10px;

  .displayPercentage {
    position: absolute;
    right: 10px;
    top: 0px;
    color: #000;
    height: 25px;
    line-height: 25px;
  }
`
const gradient = keyframes`
  from {
    background-position: 100% 0%;
  }
  to {
    background-position: 0% 0%;
  }
`

export const Progress = styled.div`
  height: 100%;
  width: 0%;
  border-radius: 10px;
  background: repeating-linear-gradient(90deg, #56C568 0%, #09701a 25%, #56C568 50%);
  background-size: 200%;
  background-position: 100% 0%;
  animation-name: ${gradient};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
`
