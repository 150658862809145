export const formatDateStrings = (date: Date, reverse: boolean = false) => {
  // for day and month values less than 10, add a leading zero
  // calendar picker returns single digits but data is stored with leading zeros
  const day = (date.getDate()).toString().length === 1 ? `0${date.getDate()}` : date.getDate()
  const month = (date.getMonth() + 1).toString().length === 1 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const year = date.getFullYear()
  if (reverse) {
    const dateFormatted = `${day}-${month}-${year}`
    return dateFormatted
  }
  const dateFormatted = `${year}-${month}-${day}`
  return dateFormatted
}
