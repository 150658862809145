import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTime } from 'luxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import axios from 'axios'
import { FieldItem } from '../FieldsListing/Fields.types'
import { getAgromonData } from '../../utils/getAgromonData'
import { formatDateStrings } from '../../utils/formatDateStrings'
import { Loading } from '../Loading/Loading'
import { FieldMap } from '../FieldMap/FieldMap'
import { AgromonData } from './NdviField.types'
import { getNdviImageCoords } from '../../utils/getNdviImageCoords'
import { SimpleBox } from '../../App.styles'
import { StatRow } from './StatRow'
import { CompareTopHolder, ConnectedFields, DataHolder } from './NdviField.styles'
import { theme } from '../../theme/theme'
import { FailureNotification, InfoNotification } from '../SuccessNotification/SuccessNotification.styles'

const DatePickerTheme = createTheme({
  components: {},
})

interface FieldSelectOptionsProps {
  label: string
  id: number
}

export const NdviCompareItem: React.FC<{fields: FieldItem[]}> = ({ fields }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [fieldOptions, setFieldOptions] = useState<FieldSelectOptionsProps[]>([])
  const [selectedFieldOption, setSelectedFieldOption] = useState<FieldSelectOptionsProps>()
  const [currentField, setCurrentField] = useState<FieldItem>()
  const [agromonData, setAgromonData] = useState<any>([])
  const [allDates, setAllDates] = useState<any>([])
  const [inputDate, setInputDate] = useState<Date>()
  const [minDate, setMinDate] = useState<Date>()
  const [maxDate, setMaxDate] = useState<Date>()
  const [currentAgroStats, setCurrentAgroStats] = useState<any>()
  const [ndviMapSource, setNdviMapSource] = useState<any>()

  const { t } = useTranslation()

  useEffect(() => {
    const fieldOptionsArray = fields.map((field) => ({
      label: field.name,
      id: field.id,
    }))

    fieldOptionsArray.unshift({ label: t('general.chooseField'), id: 0 })

    setFieldOptions(fieldOptionsArray)
  }, [])

  useEffect(() => {
    if (fieldOptions.length > 0) {
      setSelectedFieldOption(fieldOptions[0])
    }
  }, [fieldOptions])

  const handleSelectField = (fielId: number) => {
    setCurrentField(fields.find((field) => field.id === fielId))
    setSelectedFieldOption(fieldOptions.find((field) => field.id === fielId))
  }

  const handleDatePickChange = (newValue: any) => {
    if (newValue === null) return

    setInputDate(newValue)
  }

  function disableEmptyDates(date: any) {
    const dateFormatted = formatDateStrings(date)
    return !allDates.includes(dateFormatted)
  }

  useEffect(() => {
    if (currentField) {
      // take current time as one minute ago, to avoid getting error on agromon response
      const unixTime = Math.round((new Date()).getTime() / 1000) - 60
      const unixTimeMinusFourYears = unixTime - 4 * 31536000
      setIsLoading(true)
      getAgromonData(
        unixTimeMinusFourYears.toString(), unixTime.toString(), currentField.OWid!,
      ).then((res: any) => {
        const agromonDataObject = res.data
        setAgromonData(agromonDataObject)
        const allDatesData = agromonDataObject.map(
          (item: any) => formatDateStrings(new Date(item.dt * 1000)),
        )
        setAllDates(allDatesData)
        setInputDate(new Date(agromonDataObject[agromonDataObject.length - 1].dt * 1000))
        const firstDate = formatDateStrings(new Date(agromonDataObject[0].dt * 1000)).split('-')
        const lastDate = formatDateStrings(new Date(agromonDataObject[agromonDataObject.length - 1].dt * 1000)).split('-')
        const firstDateFormatted = new Date(
          parseInt(firstDate[0]), parseInt(firstDate[1]) - 1, parseInt(firstDate[2]),
        )
        const lastDateFormatted = new Date(
          parseInt(lastDate[0]), parseInt(lastDate[1]) - 1, parseInt(lastDate[2]),
        )

        setMinDate(firstDateFormatted)
        setMaxDate(lastDateFormatted)
        setIsLoading(false)
      })
    }
  }, [currentField])

  const getAgromonCurrentData = (date: number | undefined) => {
    let dataObject: AgromonData
    if (date === undefined) {
      // if date hasn't been selected, use the first item in the array
      // eslint-disable-next-line prefer-destructuring
      dataObject = agromonData[0]
    } else {
      dataObject = agromonData.find(
        (agromonDataObject: AgromonData) => agromonDataObject.dt === date,
      )!
    }

    let ndviImage
    try {
    // @ts-ignore
      ndviImage = dataObject.image.ndvi.replace('http://', 'https://')
    } catch {
      setCurrentAgroStats(undefined)
    }

    let agromonStatUrl: string
    try {
    // @ts-ignore
      agromonStatUrl = dataObject.stats.ndvi.replace('http://', 'https://')
    } catch {
      setCurrentAgroStats(undefined)
    }

    axios.get(agromonStatUrl!).then((agroStatResponse) => {
      const rearrangedData = Object.keys(agroStatResponse.data).filter(
        (item: string) => item !== 'min'
        && item !== 'max'
        && item !== 'mean'
        && item !== 'median'
        && item !== 'p25'
        && item !== 'p75',
      )
      rearrangedData.unshift('min', 'max', 'mean', 'median')
      const finalData = Object.fromEntries(
        rearrangedData.map((item: string) => [item, agroStatResponse.data[item]]),
      )
      setCurrentAgroStats(finalData)
    }).catch(() => {
      setCurrentAgroStats(undefined)
    })

    const sourceCoordinates = getNdviImageCoords(currentField!)

    setNdviMapSource({
      type: 'image',
      url: ndviImage,
      coordinates: sourceCoordinates,
    })
  }

  useEffect(() => {
    if (agromonData && currentField && inputDate) {
      const luxonHour = DateTime.fromJSDate(inputDate, {
        zone: 'GMT',
      }).hour

      let finalInputDate = inputDate.getTime() / 1000
      if (luxonHour === 1) {
        finalInputDate -= 3600
      }

      if (luxonHour === 21) {
        finalInputDate += 3 * 3600
      }

      getAgromonCurrentData(finalInputDate)
    }
  }, [agromonData, currentField, inputDate])

  return (
    <div>
      <CompareTopHolder>
        {fieldOptions.length > 0 && selectedFieldOption && (
        <Autocomplete
          disablePortal
          options={fieldOptions}
          defaultValue={selectedFieldOption}
          renderInput={(params) => <TextField {...params} />}
          fullWidth
          onChange={(event, newValue: FieldSelectOptionsProps | null) => {
            handleSelectField(newValue!.id)
          }}
        />
        )}
        {inputDate && minDate && maxDate && (
          <ThemeProvider theme={DatePickerTheme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Начална дата"
                inputFormat="dd/MM/yyyy"
                value={inputDate}
                onChange={(newValue: any) => handleDatePickChange(newValue)}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                  />
                )}
                minDate={minDate}
                maxDate={maxDate}
            // eslint-disable-next-line
            shouldDisableDate={disableEmptyDates}
              />
            </LocalizationProvider>
          </ThemeProvider>
        )}
      </CompareTopHolder>
      {isLoading ? <Loading /> : (
        <>
          {currentField ? (
            <FieldMap
              fields={[currentField]}
              ndviData={ndviMapSource}
              expectNdviData={currentAgroStats}
              containerMargin={false}
              height="400px"
            />
          ) : (
            <InfoNotification>{t('general.pleaseFirstChooseAField')}</InfoNotification>
          )}
          {currentField && (

            currentAgroStats ? (
              <DataHolder style={{ marginTop: theme.space.md }}>
                <ConnectedFields>

                  <span className="title">{t('general.connectedAiMix')}</span>
                  <span className="fieldNames">{currentField.mix?.mixName ? currentField.mix?.mixName : t('general.noConnectedMix')}</span>

                </ConnectedFields>
                <SimpleBox>
                  {Object.keys(currentAgroStats).map((stat: any) => (
                    <StatRow statName={stat} currentAgroStats={currentAgroStats} />
                  ))}
                </SimpleBox>
              </DataHolder>
            ) : (
              <FailureNotification>{t('general.dataNotAvailable')}</FailureNotification>
            )
          )}
        </>
      )}
    </div>
  )
}
