export const soilDataRanges = {
  pH: {
    minWarning: 3,
    maxWarning: 9,
  },
  N: {
    minWarning: 0.1,
    maxWarning: 0.6,
  },
  TMN: {
    minWarning: 0.1,
    maxWarning: 80,
  },
  P: {
    minWarning: 5,
    maxWarning: 100,
  },
  K: {
    minWarning: 60,
    maxWarning: 800,
  },
  Ca: {
    minWarning: 500,
    maxWarning: 10000,
  },
  Mg: {
    minWarning: 50,
    maxWarning: 1000,
  },
  S: {
    minWarning: 1,
    maxWarning: 40,
  },
  B: {
    minWarning: 0.1,
    maxWarning: 3.1,
  },
  Cu: {
    minWarning: 0.3,
    maxWarning: 10,
  },
  Fe: {
    minWarning: 10,
    maxWarning: 300,
  },
  Mn: {
    minWarning: 0.5,
    maxWarning: 150,
  },
  Zn: {
    minWarning: 0.2,
    maxWarning: 200,
  },
  EC: {
    minWarning: 20,
    maxWarning: 400,
  },
  Carbonates: {
    minWarning: 1,
    maxWarning: 16,
  },
  bulkDensity: {
    minWarning: 0,
    maxWarning: 1.6,
  },
  aggregateStability: {
    minWarning: 10,
    maxWarning: 999999999999,
  },
  mineralizableNitrogen: {
    minWarning: 5,
    maxWarning: 999999999999,
  },
  organicCarbon: {
    minWarning: 0.3,
    maxWarning: 999999999999,
  },
  hummus: {
    minWarning: 0.5,
    maxWarning: 25,
  },
  soilRespiration: {
    minWarning: 0.25,
    maxWarning: 5,
  },
  activeCarbon: {
    minWarning: 250,
    maxWarning: 999999999999,
  },
  TOCTN: {
    minWarning: 5,
    maxWarning: 80,
  },
  sand: {
    minWarning: 0,
    maxWarning: 90,
  },
  silt: {
    minWarning: 0,
    maxWarning: 90,
  },
  clay: {
    minWarning: 0,
    maxWarning: 90,
  },
}
