import React from 'react'
import { CropItem } from './CropItem'
import { MixRecommendedCropTypes } from './MixElement.types'

export const MainRecommendedCrops: React.FC<MixRecommendedCropTypes> = ({
  mixData, crops, title,
}) => (
  <div className="container">
    <h2>{title}</h2>
    <div className="dataBox crops">
      {mixData && crops && (
        crops.map((item: any, index: any) => (
          <CropItem item={item} isLast={crops.length === index + 1} key={`result_${index}`} />
        ))
      )}
    </div>
  </div>
)
