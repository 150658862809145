import styled, { css } from 'styled-components'
import { theme } from '../../theme/theme'
import { SimpleBox } from '../../App.styles'

export const NdviMapAndDataHolder = styled.div`
    display: grid;
    grid-template-columns: 1fr 250px;
    column-gap: ${theme.space.md};
    row-gap: ${theme.space.md};
    grid-template-rows: auto;
    margin-bottom: ${theme.space.md};

    ${theme.mediaQueries.smMax} {
        grid-template-columns: 1fr;
    }
`

export const DataHolder = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${theme.space.md};

    ${SimpleBox} {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1px;
      flex-grow: 1;

      .statsRow {
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: space-between;
        padding: 0 ${theme.space.sm};

        &:nth-child(even) {
          background: ${theme.colors.blueBGUILight};
        }

        .statValue {
          display: inline-block;
          padding: ${theme.space.sm};
          border-radius: 10px;
        }
    }
    }
`

export const SoilDataHolder = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${theme.space.md};

    h2 {
      font-weight: normal;
      font-size: ${theme.fontSizes.xl};
      margin: 0;

      span {
        display: block;
        font-size: 1.2em;
        text-transform: uppercase;
      }
    }

    ${SimpleBox} {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: ${theme.space.md};
      flex-grow: 1;

      .statsRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 ${theme.space.sm};
        font-size: ${theme.fontSizes.md};

        .statValue {
          display: inline-block;
          padding: ${theme.space.sm};
          border-radius: 10px;
          background: ${theme.colors.blueBGUILight};
          font-size: ${theme.fontSizes.lg};
          white-space: nowrap;
        }
      }
    }
`

export const SoilDataRows = styled.div<{ withMargin: boolean }>(
  ({ withMargin }) => css`
  margin: ${withMargin ? theme.space.md : 0} 0 0  0;

  .statsRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${theme.space.sm};
    margin: ${theme.space.md} 0 0 0;
    font-size: ${theme.fontSizes.md};
    color: ${theme.colors.grayDark};

    &:first-child {
      margin: 0;
    }

    .statValue {
      display: inline-block;
      padding: ${theme.space.sm};
      border-radius: 10px;
      background: ${theme.colors.blueBGUILight};
      font-size: ${theme.fontSizes.lg};
      white-space: nowrap;
    }
  }
`,
)

export const TimelineHolder = styled.div`
    display: grid;
    grid-template-columns: 90px 1fr;
    column-gap: ${theme.space.md};
    grid-template-rows: auto;
    margin-bottom: ${theme.space.md};
    background: rgba(6, 192, 226, 0.14);
    border-radius: 10px;
    padding: ${theme.space.sm};
`

export const Data = styled.div`
    display: grid;
    grid-template-columns: 30px 1fr 30px;
    column-gap: ${theme.space.md};
    border-radius: 10px;
    background: rgba(6, 192, 226, 0.14);

    .timelineControl {
      background: ${theme.colors.blueUI};
      border: none;
      border-radius: 10px;
      cursor: pointer;
    }
`

export const TimelineContent = styled.div`
    display: flex;
    width: 100%;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    overflow-x: scroll;
    transition: all 0.2s ease-in;

    &::-webkit-scrollbar {
      display: none;
    }
`

export const TimelineItemHolder = styled.button<{ isSelected: boolean }>(
  ({ isSelected }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.space.sm};
    padding: ${theme.space.sm};
    justify-content: center;
    width: 105px;
    box-sizing: border-box;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    border: none;
    border-right: 1px solid ${theme.colors.blueUI};
    height: 100px;
    color: ${theme.colors.blueUI};
    font-size: ${theme.fontSizes.lg};
    scroll-snap-align: start;
    background: ${isSelected ? 'rgba(6, 192, 226, 0.24)' : 'none'};
    cursor: pointer;

    &:first-child {
        border-left: 1px solid ${theme.colors.blueUI};
    }

    .cloudCoverageContainer {
      background: rgba(6, 192, 226, 0.14);
      border-radius: 10px;
      padding: ${theme.space.sm};
      font-size: ${theme.fontSizes.md};

      svg {
        margin-right: ${theme.space.sm};
      }
    }
`,
)

export const Calendar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    width: 90px;
    padding: ${theme.space.sm};
    background: #FFF;
    border-radius: 10px;

    .calendarButton {
      border: none;
      background: none;
      cursor: pointer;
      color: ${theme.colors.blueUI};

      svg {
        width: 55px;
        height: 55px;
        
      }
    }
`

export const ChartHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    h2 {
      margin: 0;
      font-weight: lighter;
      text-align: left;
      font-size: ${theme.fontSizes.xxl};
      color: ${theme.colors.grayDark};
      
      span {
        font-size: 0.6em;
      }
    }
`

export const CompareGrid = styled.div`
    display: grid;
    gap: ${theme.space.md};
    grid-template-columns: 1fr 1fr;

    ${theme.mediaQueries.mdMax} {
        grid-template-columns: 1fr;
    }

`

export const CompareTopHolder = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(170px, 1fr) );
    gap: ${theme.space.md};
`

export const ConnectedFields = styled.div`

        display: flex;
        align-items: center;
        background: white;
        border-radius: 10px;
        padding-right: ${theme.space.md};
        color: black;
        overflow: hidden;
        width: 100%;

        .title {
            flex-shrink: 0;
            color: white;
            height: 45px;
            line-height: 45px;
            padding: 0 ${theme.space.md};
            margin-right: ${theme.space.md};
            background: ${theme.colors.agroOrange};
            border-radius: 10px 0 0 10px;
        }

        .fieldNames {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            dislay: block;
        }

        ${theme.mediaQueries.lgMax} {
       
            display: block;
            height: auto;
            padding: 0 0 ${theme.space.sm} 0;

            .title {
                width: 100%;
                display: block;
                border-radius: 10px 10px 0 0;
                margin-bottom: ${theme.space.sm};
            }
            .fieldNames {
                display: block;
                padding: 0 ${theme.space.md};
                white-space: normal;
                overflow: visible;
                text-overflow: unset;

            }
    }
`

export const NdviPaletteHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF;
    padding: ${theme.space.lg} ${theme.space.md} ${theme.space.md} ${theme.space.md};
    margin-bottom: ${theme.space.lg};
    border-radius: 10px;
    color: ${theme.colors.blueBGUI};

    .label {
      font-size: ${theme.fontSizes.md};
      width: 150px;
      font-size: 1.1em;
      font-weight: 500;
    }

    .palette {
      flex-grow: 1;
      position: relative;
      height: 22px;
      background: linear-gradient(90deg, #FCFED8 0%, #004E2C 100%);

      .breakpoint {
        position: absolute;
        bottom: 100%;
        width: 20px;
        height: 22px;
        transform: translateX(-10px);
        text-align: center;
        color: ${theme.colors.blueBGUI};
        font-size: ${theme.fontSizes.sm};

        &:last-child {
          right: 0;
          transform: translateX(10px);
        }

        &::after {
          content: '';
          position: absolute;
          bottom: -100%;
          left: 50%;
          width: 1px;
          height: 27px;
          background: ${theme.colors.blueBGUI};
        }
      }
    }

    ${theme.mediaQueries.mdMax} {
        display: block;
        padding: ${theme.space.md};


        .label {
          width: auto;
          text-align: center;
        }

        .palette {
          margin-top: ${theme.space.lg};
        }
    }
`
