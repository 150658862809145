import React from 'react'
import { LoadingContainer } from './Loading.styles'

export const Loading: React.FC = () => (
  <LoadingContainer>
    <div />
    <div />
    <div />
    <div />
  </LoadingContainer>
)
