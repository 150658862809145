import React, {
  useContext, useEffect, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import * as ls from 'local-storage'
import Button from '@mui/material/Button'
import FieldMap from '../components/FieldMap/FieldMap'
import { FieldsListing } from '../components/FieldsListing/FieldsListing'
import { TopContainer } from '../components/FieldsListing/FieldsListing.styles'
import { FieldItem } from '../components/FieldsListing/Fields.types'
import { InfoNotification } from '../components/SuccessNotification/SuccessNotification.styles'
import { API } from '../providers/API'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { Loading } from '../components/Loading/Loading'
import PageHeading from '../components/PageHeading/PageHeading'

// const falls = require('../utils/falls.json')

export const FieldsPage = () => {
  const {
    userInfo, isLoading, setIsLoading,
  } = useContext(GlobalStateContext)
  const [fields, setFields] = useState<FieldItem[]>([])
  const [sortedData, setSortedData] = useState<FieldItem[]>()
  const [clusterPoints, setClusterPoints] = useState<any>([])
  const history = useHistory()
  const { t } = useTranslation()

  const generateClusterPoints = (fieldsData:FieldItem[]) => {
    const geoJsonData = fieldsData.map((item:FieldItem) => {
      const data = JSON.parse(item.coordinates)
      const returnData = {
        ...data,
        fieldId: item.id,
      }
      return returnData
    })

    const clusterPointsTemp = geoJsonData.map((item: any) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        // take second [0] to get the first point of the polygon
        coordinates: item.geometry.coordinates[0][0],
      },
      fieldCoordinates: item.geometry.coordinates[0],
      fieldId: item.fieldId,
    }))
    setClusterPoints(clusterPointsTemp)
  }

  useEffect(() => {
    setIsLoading(true)
    API.get(`/fields/search/${userInfo.id}  `, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setFields(response.data)
      generateClusterPoints(response.data)
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    if (sortedData && sortedData.length > 0) {
      generateClusterPoints(sortedData)
    }
  }, [sortedData])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <PageHeading
        title={t('createField.myFieldsTitle')}
        sortingData={fields}
        searchByName
        nameKey="name"
        uidKey="fieldUID"
        areaKey="area"
        hasMixKey="mix"
        dateKey="created_at"
        cacheDataKey="myFields"
        setSortedData={setSortedData}
        sortedData={sortedData}
      />
      {fields && fields.length === 0 ? (
        <>
          <InfoNotification>{t('createField.noFields')}</InfoNotification>
          <Button
            variant="contained"
            type="button"
            color="primary"
            onClick={() => {
              history.push('/createField')
            }}
          >
            {t('createField.createField')}
          </Button>
        </>
      ) : (
        <>
          {((sortedData && sortedData.length > 0)
          || (!sortedData && fields && fields.length > 0)) && clusterPoints.length > 0 && (
          <TopContainer>
            <FieldMap fields={sortedData || fields} clusterPoints={clusterPoints} clickableFields height="100%" />
          </TopContainer>
          )}

          <FieldsListing fields={sortedData || fields} isSorted={!!sortedData} />
        </>
      )}
    </>
  )
}
