import React from 'react'
import { CropItem } from './CropItem'
import { MixOrderCropsTypes } from './MixElement.types'
import { FullCropsAndEffectsContainer } from './MixElements.styles'

export const MixOrderAdditionalCrops: React.FC<MixOrderCropsTypes> = ({
  title, cropsData,
}) => (
  <>
    <h2>{title}</h2>
    <FullCropsAndEffectsContainer>
      {cropsData && (
        cropsData.map((item: any, index: any) => (
          <CropItem item={item} cropType="additionalCrops" isLast={cropsData.length === index + 1} isFirst={index === 0} detailed key={`result_${index}`} />
        ))
      )}
    </FullCropsAndEffectsContainer>
  </>
)
