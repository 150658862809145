import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react'
import * as ls from 'local-storage'
import { useTranslation } from 'react-i18next'
import { API } from '../../providers/API'
import { Loading } from '../Loading/Loading'
import { SuccessNotification, FailureNotification } from '../SuccessNotification/SuccessNotification.styles'

interface RequestAnalysisProps {
  setStepTitle: Dispatch<SetStateAction<string>>
  fieldId: string
  newMixId: number
}

export const RequestAnalysis: React.FC<RequestAnalysisProps> = (
  { setStepTitle, fieldId, newMixId },
) => {
  setStepTitle('Почени анализи')
  const [submitState, setSubmitState] = useState<null | boolean>(null)
  const { t } = useTranslation()

  const requestData = {
    mixId: newMixId,
    fieldId,
  }

  useEffect(() => {
    API.post('/soilCTA', requestData, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      if (response.data.message === 'Благодарим Ви за запитването за почвен анализ, наш представител ще се свърже с Вас!') {
        setSubmitState(true)
      } else {
        setSubmitState(false)
      }
    })
  }, [])

  if (submitState === null) return <Loading />

  if (submitState === false) {
    return (
      <FailureNotification>{t('analysis.requestAnalysisFail')}</FailureNotification>
    )
  }

  return (
    <SuccessNotification>{t('analysis.requestAnalysisSuccess')}</SuccessNotification>
  )
}
