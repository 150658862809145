import styled from 'styled-components'
import { theme } from '../../theme/theme'

export const PageHeadingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.space.sm} ${theme.space.md};
    margin: 0 0 ${theme.space.md};
    background: rgba(6, 192, 226, 0.14);
    border-radius: 10px;

    ${theme.mediaQueries.md} {
    h1 {
      color: #FFF;
      font-weight: normal;
      font-size: ${theme.fontSizes.xxl};
      background: none;
      margin: 0;
      padding: 0;
      border-radius: 0;
    }
  }

    ${theme.mediaQueries.mdMax} {
      display: block;
      background: none;
      margin: 0 0 ${theme.space.md};

      h1 {
        margin-bottom: ${theme.space.sm};
      }
    }
`

export const FiltersHolder = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${theme.space.sm};
`

export const FiltersControls = styled.div`
  display: flex;
  gap: ${theme.space.sm};

  .MuiTextField-root {
    margin: 0;  
  }

  ${theme.mediaQueries.lgMax} {
    flex-direction: column;
    gap: ${theme.space.md};
  }
`

export const UnstyledButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    color: #FFF;

    &.clearFilters {
      display: flex;
      align-items: center;
      justify-content: right;

      ${theme.mediaQueries.mdMax} {
        justify-content: left;
      }

      span {
        margin-right: ${theme.space.sm};
      }
    }
`
