import ReactMapboxGl from 'react-mapbox-gl'

export const Map = ReactMapboxGl({
  accessToken:
      'pk.eyJ1IjoiY2h1c2hldiIsImEiOiJmNzAzNWNkZmYyN2M4NjdjZjg5MmIxYTY5ODZiMWQ3OSJ9.fyX0tsB68PhMdTF1yLnnlQ',
})

export const StaticMap = ReactMapboxGl({
  accessToken:
      'pk.eyJ1IjoiY2h1c2hldiIsImEiOiJmNzAzNWNkZmYyN2M4NjdjZjg5MmIxYTY5ODZiMWQ3OSJ9.fyX0tsB68PhMdTF1yLnnlQ',
  interactive: false,
})
