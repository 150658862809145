import React, {
  useContext, useEffect, useState,
} from 'react'
import { Link, useParams } from 'react-router-dom'
import * as ls from 'local-storage'
import { useTranslation } from 'react-i18next'
import { FieldItem } from '../components/FieldsListing/Fields.types'
import { API } from '../providers/API'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import { AgromonStateProvider } from '../providers/AgromonStateProvider'
import { FieldStats } from '../components/NdviFIeld/FieldStats'
import { getAgromonData } from '../utils/getAgromonData'
import { HistoryChart } from '../components/NdviFIeld/HistoryChart'
import { Loading } from '../components/Loading/Loading'
import { HeaderInfo } from '../components/MixElements/HeaderInfo/HeaderInfo'
import { HeaderInfoTypes } from '../components/MixElements/MixElement.types'

export const NdviField = () => {
  const {
    isLoading, setIsLoading,
  } = useContext(GlobalStateContext)
  const { t } = useTranslation()

  const [field, setField] = useState<FieldItem>()
  const [mixHeaderInfo, setMixHeaderInfo] = useState<HeaderInfoTypes | null>(null)
  const [agromonData, setAgromonData] = useState<any>()
  // @ts-ignore
  const { fieldId } = useParams<number>()

  useEffect(() => {
    setIsLoading(true)
    API.get(`/fields/${fieldId}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response: {data: FieldItem}) => {
      setField(response.data)

      // take current time as one minute ago, to avoid getting error on agromon response
      const unixTime = Math.round((new Date()).getTime() / 1000) - 60
      const unixTimeMinusOneYear = unixTime - 31536000
      getAgromonData(
        unixTimeMinusOneYear.toString(), unixTime.toString(), response.data.OWid!,
      ).then((res: any) => {
        setAgromonData(res.data.reverse())
        setIsLoading(false)
      })

      if (response.data.mix) {
        setMixHeaderInfo({
          title: response.data.mix?.mixName,
          fieldData: response.data,
          mixData: {
            seedingDate: response.data.mix.seedingDate,
            terminationDate: response.data.mix.terminationDate,
            totalMixArea: response.data.area!,
            mixName: response.data.mix.mixName,
          },
          connectedFieldsData: null,
        })
      }
    })
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <AgromonStateProvider>
      {field && (

      <>
        <h1>{field.name}</h1>
        {mixHeaderInfo && (
        <HeaderInfo {...mixHeaderInfo} />
        )}
        <FieldStats field={field} agromonData={agromonData} />
        <HistoryChart field={field} />
        <Link style={{ marginTop: '16px', display: 'inline-block' }} to="/ndviFieldsListing">&lt; {t('createField.backToMyFields')}</Link>
      </>
      )}
    </AgromonStateProvider>
  )
}
