import React, { useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { API } from '../providers/API'
import { InputField } from '../components/FormElements/InputField'
import { CenteredContainer } from '../App.styles'
import { FailureNotification, SuccessNotification } from '../components/SuccessNotification/SuccessNotification.styles'
import { theme } from '../theme/theme'
import logo from '../media/agrovar-logo.svg'

export const ResetPassword = () => {
  const [resetPassErrors, setResetPassErrors] = useState<string[]>([])
  const [resetPassSuccess, setResetPassSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const token = params.get('token')

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('formErrors.invalidEmail'))
      .required(t('formErrors.enterEmail')),
    password: Yup.string().min(8, t('formErrors.passMin8')).required(t('formErrors.enterPass')),
    password_confirmation: Yup.string().required(t('formErrors.confirmPass')).oneOf([Yup.ref('password')], t('formErrors.passwordsMustMatch')),
  })

  return (
    <CenteredContainer>
      <img src={logo} alt="Agrovar" />
      <h1>{t('resetPass.title')}</h1>
      <Formik
        initialValues={{
          email: '', password: '', password_confirmation: '',
        }}
        validationSchema={ValidationSchema}
        enableReinitialize
        onSubmit={(values) => {
          setIsLoading(true)
          const resetData = {
            ...values,
            token,
          }
          API.post('/resetPassword', resetData).then((response) => {
            setIsLoading(false)
            if (response.data.message === 'Password reset successfully') {
              setResetPassSuccess(true)
            }
          }).catch((error) => {
            setIsLoading(false)
            if (error.response.data.errors) {
              const errorsArray = Object.keys(error.response.data.errors)
              const errors:any = errorsArray.map(() => error.response.data.errors[errorsArray[0]])
              setResetPassErrors(errors)
            } else if (error.response.data.message) {
              setResetPassErrors([error.response.data.message])
            } else {
              setResetPassErrors(['Възникна грешка при смяната на пролата ви. Моля опитайте отново'])
            }
          })
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <InputField label={t('general.email')} name="email" id="email" type="text" />
            <InputField label={t('general.password')} name="password" id="password" type="password" />
            <InputField label={t('general.passwordConfirm')} name="password_confirmation" id="password_confirmation" type="password" />
            <LoadingButton
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
              loading={isLoading}
              loadingPosition="start"
            >
              {t('resetPass.changePassBtn')}
            </LoadingButton>
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              <Link to="/register">{t('general.register')}</Link>
              {' '}
              |
              {' '}
              <Link to="/login">{t('resetPass.goToLogin')}</Link>
            </div>
            {resetPassSuccess && (
              <>
                <SuccessNotification>{t('resetPass.success')}</SuccessNotification>
                <div style={{ marginTop: theme.space.md }}>
                  <Link to="/login">{t('resetPass.goToLogin')}</Link>
                </div>
              </>
            )}
            {resetPassErrors && (
              resetPassErrors.map((item: any) => (
                <FailureNotification>{t(`submitErrors.${item}`)}</FailureNotification>
              ))
            )}
          </form>
        )}
      </Formik>
    </CenteredContainer>
  )
}
