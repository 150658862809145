import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { MapDataContext } from '../../../../providers/MapDataProvider'
import { getAgromonData } from '../../../../utils/getAgromonData'
import { AccordionItemHeader } from '../accordionElements/AccordionItemHeader'
import { AccordionItemContent } from '../accordionElements/AccordionElements.styles'

type dataFetchingProps = 'median' | 'max' | 'min'
type dataOptionsType = { value: dataFetchingProps, name: string }

export const NDVIDataLayers = () => {
  const {
    allVisibleFieldIds, allAccountFields, setDataLayerContent, activeDataSection,
    activeDataLayer, setActiveDataLayer, setMapOverlayLoading,
  } = useContext(MapDataContext)

  const [ndviData, setNdviData] = useState<any>([])
  const [dataFetchingType, setDataFetchingType] = useState<dataFetchingProps>()

  const ndviDataOptions: dataOptionsType[] = [
    { value: 'median', name: 'Median' },
    { value: 'max', name: 'Maximum' },
    { value: 'min', name: 'Minimum' }]

  const fetchNdviData = ({ ndviType } : {ndviType: dataFetchingProps}) => {
    setMapOverlayLoading(true)
    setNdviData([])
    setActiveDataLayer(ndviType)
    const unixTime = Math.round((new Date()).getTime() / 1000) - 60
    const unixTimeMinusOneYear = unixTime - 2592000

    const requests = allVisibleFieldIds.map((fieldId) => new Promise((resolve) => {
      const fieldOWId = allAccountFields.find(
        (accountField) => accountField.id === fieldId,
      )?.OWid || 0
      getAgromonData(
        unixTimeMinusOneYear.toString(), unixTime.toString(), fieldOWId.toString(),
      ).then((res: any) => {
        const agromonStatUrl = res.data[res.data.length - 1].stats.ndvi.replace('http://', 'https://')
        axios.get(agromonStatUrl).then((agroStatResponse) => {
          resolve({ fieldId, ndviData: agroStatResponse.data[ndviType].toFixed(2), ndviType })
        }).catch(() => {
          resolve({ fieldId, ndviData: null, ndviType })
        })
      }).catch(() => {
        resolve({ fieldId, ndviData: null, ndviType })
      })
    }))

    Promise.all(requests).then((values) => {
      setMapOverlayLoading(false)
      setNdviData(values)
    })
  }

  const handleDataFetching = ({ dataType } : {dataType: dataFetchingProps}) => {
    fetchNdviData({ ndviType: dataType })
    setDataFetchingType(dataType)
  }

  useEffect(() => {
    const ndviDataContent = ndviData.map((item: any) => {
      const name = ndviDataOptions.find(
        (option: dataOptionsType) => option.value === item.ndviType,
      )?.name

      return ({
        fieldId: item.fieldId,
        title: `NDVI ${name}`,
        data: item.ndviData ? item.ndviData : 'No data',
        isColorCoded: item.ndviData,
        type: 'ndvi',
      })
    })
    setDataLayerContent(ndviDataContent)
  }, [ndviData])

  useEffect(() => {
    if (dataFetchingType === undefined || activeDataSection !== 'ndvi') return
    fetchNdviData({ ndviType: dataFetchingType })
  }, [allVisibleFieldIds])

  return (
    <>
      <AccordionItemHeader title="NDVI" value="ndvi" type="data" />
      <AccordionItemContent isOpen={activeDataSection === 'ndvi'}>
        <RadioGroup
          name="dataLayers"
          value={activeDataLayer}
          onChange={(e) => {
            handleDataFetching({ dataType: e.target.value as dataFetchingProps })
          }}
        >
          {ndviDataOptions.map((item: dataOptionsType) => (
            <FormControlLabel key={`ndviDataOption_${item.value}`} value={item.value} control={<Radio size="small" />} label={item.name} />
          ))}
        </RadioGroup>
      </AccordionItemContent>
    </>
  )
}
