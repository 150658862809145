import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  MainWeatherData, WeatherDateHolder, WeatherItem, WeatherTable,
} from './WeatherListing.styles'
import { DegreesSymbol } from './DegreesSymbol'

interface FieldsItemProps {
  weatherData: any
}

export const WeatherListingItemDaily: React.FC<FieldsItemProps> = ({
  weatherData,
}) => {
  const { t } = useTranslation()

  return (
    <WeatherItem>

      <WeatherDateHolder>
        {weatherData?.dateOfRecord}
      </WeatherDateHolder>

      <MainWeatherData>
        <div className="icon">
          <img src={`https://openweathermap.org/img/wn/${weatherData?.icon}@4x.png`} alt={weatherData?.description} />
        </div>
        <div className="temperature">
          <strong className="mainTemp">
            {parseFloat(weatherData?.airTemp).toFixed(2)}
            <DegreesSymbol />
          </strong>
          <span>{weatherData?.description}</span>
        </div>
      </MainWeatherData>
      <WeatherTable>
        <div>
          {t('weatherItem.minTemp')}:
          {' '}
          {parseFloat(weatherData?.temp_min).toFixed(2)}
          <DegreesSymbol />
        </div>
        <div>
          {t('weatherItem.maxTemp')}:
          {' '}
          {parseFloat(weatherData?.temp_max).toFixed(2)}
          <DegreesSymbol />
        </div>
        <div>
          {t('weatherItem.precipication')}:
          {' '}
          {parseFloat(weatherData?.rain).toFixed(2)}
          {' '}
          мм.
        </div>
      </WeatherTable>

    </WeatherItem>
  )
}
