export const colors = {
  white: '#ffffff',
  black: '#000000',
  agroOrange: '#EA5B0C',
  barsGreen: '#56C568',
  barsOrange: '#E5A90D',
  barsPuke: '#C7C972',
  grayDark: '#394867',
  greyLight: '#9ba4b4',
  orangeUI: '#F26522',
  blueUI: '#06C0E2',
  blueBGUI: '#02687d',
  blueBGUIMedium: '#17859c',
  blueBGUILight: '#dcf6fb',
  lineChartColors: ['#EA5B0C', '#06C0E2', '#02687d', '#C7C972', '#56C568', '#F26522'],
  progressColors: ['#DA4232', '#DA6935', '#DD8A39', '#E1AA3D', '#E7CC41', '#B5B345', '#879F48', '#5A8E4A', '#13804A', '#036c37'],
  soilCategoryColors: {
    chem: '#F26522',
    phys: '#E1AA3D',
    bio: '#879F48',
  },
  ndviColors: {
    0: '#dff7c9',
    1: '#a6e5ac',
    2: '#77d892',
    3: '#43ca78',
    4: '#25b364',
    5: '#1c9c56',
    6: '#128149',
    7: '#03522d',
    8: '#03522d',
    9: '#03522d',
  },
}
