import { useEffect, useState } from 'react'
import * as ls from 'local-storage'

import i18n from '../i18n'
import { API } from '../providers/API'

export interface CashCropTypes {
  cropName: string
  id: number
}

export interface CashCropOptionsTypes {
  label: string
  id: number
}

export function updateCropLabels(cropsArray: CashCropTypes[]) {
  const cashCropSelectOptions: CashCropOptionsTypes[] = cropsArray.map(
    (item: CashCropTypes) => ({
      label: i18n.t(`crops.${item.cropName}`),
      id: item.id,
    }),
  )
  return cashCropSelectOptions
}

export const useCashCropsData = () => {
  const [cashCropSelectOptions, setCashCropSelectOptions] = useState<CashCropOptionsTypes[]>([])
  const [cashCrops, setCashCrops] = useState<CashCropTypes[]>([])

  useEffect(() => {
    API.get('/cashCrops', { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setCashCropSelectOptions(updateCropLabels(response.data))
      setCashCrops(response.data)
    })
  }, [])

  return {
    cashCrops,
    cashCropSelectOptions,
  }
}
