export const compareByName = (a: any, b: any, sortingOrder: string, nameKey: string) => {
  if (nameKey && (a[nameKey] === undefined || b[nameKey] === undefined)) {
    return 0
  }

  const nameA = a[nameKey!].toUpperCase()
  const nameB = b[nameKey!].toUpperCase()

  let comparison = 0
  if (nameA > nameB) {
    comparison = 1
  } else if (nameA < nameB) {
    comparison = -1
  }

  if (sortingOrder === 'ascending') {
    return comparison
  }

  return comparison * -1
}

export const compareByNumbers = (a: any, b: any, itemKey: any, sortingOrder: string) => {
  const itemKeyParts = itemKey.split('.')
  let itemA
  let itemB

  if (itemKeyParts.length > 1) {
    if (itemKey && (a[itemKeyParts[0]][itemKeyParts[1]] === undefined
        || b[itemKeyParts[0]][itemKeyParts[1]] === undefined)) {
      return 0
    }

    itemA = a[itemKeyParts[0]][itemKeyParts[1]!]
    itemB = b[itemKeyParts[0]][itemKeyParts[1]!]
  } else {
    if (itemKey && (a[itemKey] === undefined || b[itemKey] === undefined)) {
      return 0
    }

    itemA = a[itemKey!]
    itemB = b[itemKey!]
  }

  let comparison = 0
  if (itemA > itemB) {
    comparison = 1
  } else if (itemA < itemB) {
    comparison = -1
  }

  if (sortingOrder === 'ascending') {
    return comparison
  }

  return comparison * -1
}

export const compareByTruthy = (arrayToSort: any, itemKey: any, sortingOrder: string) => {
  function isntZero(element: any) {
    const elementValue = !!element === true ? 1 : 0
    return elementValue > 0
  }
  function isZero(element: any) {
    const elementValue = !!element === true ? 1 : 0
    return elementValue === 0
  }

  const ascendingResult = arrayToSort.filter(
    (item: any) => isntZero(item[itemKey]),
  ).concat(arrayToSort.filter(
    (item: any) => isZero(item[itemKey]),
  ))

  if (sortingOrder === 'descending') {
    return ascendingResult.reverse()
  }

  return ascendingResult
}

export const compareDates = (dateString1: string, dateString2: string, sortingOrder: string) => {
  const date1 = new Date(Date.parse(dateString1))
  const date2 = new Date(Date.parse(dateString2))
  const comparison = date1.getTime() - date2.getTime()

  if (sortingOrder === 'descending') {
    return comparison * -1
  }

  return comparison
}
