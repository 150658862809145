import styled from 'styled-components'

import { theme } from '../../theme/theme'

export const DaysCountContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.space.lg};

    ${theme.mediaQueries.smMax} {
      display: block;
    }
  }
`

export const DaysCountItem = styled.div`
    flex-basis: 32%;
    background: ${theme.colors.white};
    border-radius: 5px;
    padding: 10px;
    color: ${theme.colors.black};
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    ${theme.mediaQueries.xlMax} {
      flex-direction: column;
    }

    ${theme.mediaQueries.smMax} {
      margin-bottom: ${theme.space.md};
    }

    .iconHolder {
      width: 90px;
      height: 90px;
    }

    .tableHolder {
      flex-grow: 1;
      margin-left: ${theme.space.md};

      ${theme.mediaQueries.xlMax} {
        width: 100%;
        text-align: center;
        margin: 0;
      }

      h3 {
        color: ${theme.colors.blueBGUI};
        margin-bottom: ${theme.space.sm};
      }
    }
`

export const DaysTable = styled.div`
    display: flex;
    justify-content: space-evenly;

    ${theme.mediaQueries.lgMax} {
      flex-direction: column;
    }

    ${theme.mediaQueries.mdMax} {
      flex-direction: row;
    }

    .tableItem {
      display: inline-block;
      text-align: center;
      flex-grow: 1;
      padding: ${theme.space.sm} 0;
      font-size: ${theme.fontSizes.sm};

      &:nth-child(odd) {
        background: ${theme.colors.blueBGUILight};
      }
    }

    .dayCount {
      display: block;
      margin-top: ${theme.space.sm};
      font-size: ${theme.fontSizes.lg};
    }
`
