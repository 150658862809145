import React, { useState, useRef } from 'react'
import * as ls from 'local-storage'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BlockPicker } from 'react-color'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaintbrush, faPencil } from '@fortawesome/free-solid-svg-icons'
import Button from '@mui/material/Button'
import { FieldGridItem } from '../FieldsListing/FieldsListing.styles'
import { theme } from '../../theme/theme'
import { ColorPickerHolder } from './ZonesListing.styles'
import { API } from '../../providers/API'

export const ZoneGridItemCreateNew = () => {
  const editableTitleRef = useRef<any>(null)
  const { t } = useTranslation()
  const [zoneColor, setZoneColor] = useState<string>('#000')
  const [zoneName, setZoneName] = useState<string>(t('zones.chooseZoneName'))
  const [isColorPickerOpen, setIsColorPickerOpen] = useState<boolean>(false)
  const history = useHistory()

  const goToFieldPage = (fieldId: number) => {
    history.push(`/fieldZoneDetails/${fieldId}`)
  }

  const handleColorChange = (value: any) => {
    setZoneColor(value.hex)
  }

  const handleEditTitle = (e: any) => {
    setZoneName(e.target.innerText)
  }

  const handleTitleClick = (e: any) => {
    const title = e.target.innerText
    if (title === t('zones.chooseZoneName')) {
      e.target.innerText = ''
    }
  }

  const handleTitleBlur = (e: any) => {
    const title = e.target.innerText
    if (title === '') {
      e.target.innerText = t('zones.chooseZoneName')
    }
  }

  const focusEditableHeader = () => {
    editableTitleRef.current.focus()
    const title = editableTitleRef.current.innerText
    if (title === t('zones.chooseZoneName')) {
      editableTitleRef.current.innerText = ''
    }
  }

  const saveNewZone = () => {
    const createZoneData = {
      name: zoneName,
      colour: zoneColor,
    }

    API.post('/createPersonalZone', createZoneData, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      goToFieldPage(response.data.id)
    })
  }

  return (
    <FieldGridItem notClickable>
      <div
        className="fieldStats"
      >
        <h3
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="textbox"
          tabIndex={0}
          contentEditable
          ref={editableTitleRef}
          onKeyUp={handleEditTitle}
          onClick={handleTitleClick}
          onBlur={handleTitleBlur}
          style={{ margin: `0 ${theme.space.sm} 0 0` }}
        >{t('zones.chooseZoneName')}
        </h3>
        <FontAwesomeIcon
          color={theme.colors.blueUI}
          icon={faPencil}
          onClick={focusEditableHeader}
          style={{ cursor: 'pointer' }}
        />
      </div>
      <div
        className="fieldStats"
      >
        <FontAwesomeIcon icon={faPaintbrush} />
        <ColorPickerHolder
          isOpen={isColorPickerOpen}
          color={zoneColor}
          position="left"
          onClick={() => { setIsColorPickerOpen((value: boolean) => !value) }}
        >
          <BlockPicker
            color={zoneColor}
            onChange={handleColorChange}
            triangle="hide"
          />
        </ColorPickerHolder>
      </div>
      <div
        className="fieldStats"
      >
        <Button
          variant="contained"
          type="button"
          color="primary"
          fullWidth
          onClick={saveNewZone}
        >
          {t('zones.saveZone')}
        </Button>
      </div>
    </FieldGridItem>
  )
}
