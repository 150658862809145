import React, {
  useContext, useEffect, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import * as ls from 'local-storage'
import { API } from '../providers/API'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import { Loading } from '../components/Loading/Loading'
import { PackageItem } from '../components/tokenPackages/PackageItem'
import { PackageItemTypes } from '../components/tokenPackages/Package.types'
import { SimpleGrid } from '../App.styles'

// const falls = require('../utils/falls.json')

export const TokenPackages = () => {
  const {
    isLoading, setIsLoading,
  } = useContext(GlobalStateContext)
  const [packages, setPackages] = useState<any>([])
  const { t } = useTranslation()

  useEffect(() => {
    setIsLoading(true)
    API.get('/pricingCreditPacks', { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setPackages(response.data)
      setIsLoading(false)
    })
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <h1>{t('tokenPackages.title')}</h1>
      {packages && packages.length !== 0 ? (
        <SimpleGrid>
          {packages && packages.map((item: PackageItemTypes, index: number) => (
            <PackageItem item={item} iterationNumber={index} />
          ))}
        </SimpleGrid>
      ) : (
        null
      )}
    </>
  )
}
