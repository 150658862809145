import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import * as ls from 'local-storage'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { API } from '../../providers/API'
import { Loading } from '../Loading/Loading'
import { MixTypes } from '../MixesListing/Mix.types'
import { FieldItem } from '../FieldsListing/Fields.types'
import { StatsBox } from './Account.styles'
import { IconMyFields, IconMyMixes } from '../../icons'
import { theme } from '../../theme/theme'

export const Statistics: React.FC = () => {
  const {
    userInfo,
  } = useContext(GlobalStateContext)
  const { t } = useTranslation()

  const [mixes, setMixes] = useState<MixTypes[]>([])
  const [fields, setFields] = useState<FieldItem[]>([])

  useEffect(() => {
    API.get(`/userMixes/${userInfo.id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setMixes(response.data)
    })

    API.get(`/fields/search/${userInfo.id}  `, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setFields(response.data)
    })
  }, [])

  if (!mixes || !fields) {
    return <Loading />
  }

  return (
    <>
      <StatsBox>
        <IconMyMixes size="60" color={theme.colors.agroOrange} />
        {t('statistics.generatedMixes')}<br />
        <strong className="statNumber">{mixes.length}</strong>
      </StatsBox>
      <StatsBox>
        <IconMyFields size="60" color={theme.colors.blueUI} />
        {t('statistics.fieldsCount')}<br />
        <strong className="statNumber">{fields.length}</strong>
      </StatsBox>
    </>
  )
}
