import React from 'react'

export const IconCreditsLeafCenter = ({ size = '24' }) => (
  <svg width={size} height={size} viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_717_5533)">
      <path d="M19.6725 38.5391C19.6725 38.5391 13.6362 34.485 12.9688 31.6959C12.3015 28.9068 12.3015 24.793 12.5007 24.0559C12.6999 23.3287 13.8255 20.6691 14.3634 20.0018C14.8913 19.3344 17.9493 17.4816 18.4772 17.143C19.0052 16.8143 19.4036 16.2764 21.5352 15.8779C23.6669 15.4795 36.9448 14.2842 36.9448 14.2842C36.9448 14.2842 35.0821 29.7535 34.4845 30.8891C33.8868 32.0147 33.0202 34.2758 31.4265 35.6006C29.8327 36.9254 30.7591 37.3936 27.5716 38.1207C24.3841 38.8479 19.6725 38.5391 19.6725 38.5391Z" fill="#F26522" />
      <path d="M43.5293 7.4707C38.7182 2.64961 32.3133 0 25.5 0C18.6867 0 12.2818 2.64961 7.4707 7.4707C2.65957 12.2918 0 18.6867 0 25.5C0 32.3133 2.64961 38.7182 7.4707 43.5293C12.2918 48.3404 18.6867 51 25.5 51C32.3133 51 38.7182 48.3504 43.5293 43.5293C48.3404 38.7082 51 32.3133 51 25.5C51 18.6867 48.3504 12.2818 43.5293 7.4707ZM25.5 49.0078C12.5408 49.0078 1.99219 38.4592 1.99219 25.5C1.99219 12.5408 12.5408 1.99219 25.5 1.99219C38.4592 1.99219 49.0078 12.5408 49.0078 25.5C49.0078 38.4592 38.4592 49.0078 25.5 49.0078Z" fill="#004D5F" />
      <path d="M31.0578 4.71154C30.5299 4.57208 29.982 4.89083 29.8426 5.41876C29.7031 5.94669 30.0219 6.49454 30.5498 6.634C39.0664 8.91505 45.0131 16.6647 45.0131 25.5C45.0131 36.2578 36.2574 45.0135 25.4996 45.0135C14.7418 45.0135 5.98613 36.2578 5.98613 25.5C5.98613 16.6149 11.9826 8.84533 20.5689 6.61408C21.0969 6.47462 21.4156 5.92677 21.2861 5.39884C21.1467 4.87091 20.5988 4.55216 20.0709 4.68165C10.608 7.15197 4.00391 15.7084 4.00391 25.5C4.00391 31.2475 6.24512 36.6463 10.2992 40.7104C14.3533 44.7744 19.7621 47.0057 25.5096 47.0057C31.257 47.0057 36.6559 44.7645 40.7199 40.7104C44.784 36.6563 47.0152 31.2475 47.0152 25.5C47.0053 15.7682 40.451 7.22169 31.0578 4.71154Z" fill="#004D5F" />
      <path d="M25.4602 5.98633H25.5C26.0479 5.98633 26.4961 5.53809 26.4961 4.99023C26.4961 4.44238 26.0479 3.99414 25.5 3.99414H25.4502C24.9023 3.99414 24.4541 4.44238 24.4541 4.99023C24.4641 5.53809 24.9123 5.98633 25.4602 5.98633Z" fill="#004D5F" />
      <path d="M10.9766 27.1035C10.9766 30.4404 12.2815 33.5781 14.6522 35.9389C14.7419 36.0285 14.8415 36.1182 14.9311 36.2078C14.6323 37.2238 14.4829 38.2897 14.4829 39.3854C14.4829 39.9332 14.9311 40.3815 15.479 40.3815C16.0268 40.3815 16.4751 39.9332 16.4751 39.3854C16.4751 38.7678 16.5348 38.1602 16.6544 37.5625C18.6665 38.8873 21.0272 39.6045 23.4975 39.6045H23.5174C26.8444 39.6045 29.9821 38.3096 32.3329 35.9588C34.345 33.9467 35.62 31.2672 35.9188 28.4383V28.4283L37.7018 14.0647C37.7417 13.7559 37.6321 13.457 37.4229 13.2379C37.2038 13.0188 36.9049 12.9192 36.5962 12.949L22.2524 14.6623C22.2225 14.6623 22.2026 14.6723 22.1727 14.6723C19.3338 14.9611 16.6643 16.2361 14.6422 18.2582C12.2616 20.6289 10.9667 23.7666 10.9766 27.1035ZM22.4516 16.6545C22.4915 16.6545 22.5313 16.6445 22.5811 16.6346L35.5602 15.0807L33.9366 28.1395C33.9366 28.1594 33.9266 28.1793 33.9266 28.1992C33.6876 30.5899 32.6118 32.851 30.9184 34.5543C28.9362 36.5266 26.3165 37.6223 23.5075 37.6223H23.4975C21.2364 37.6223 19.0749 36.9051 17.2819 35.5803C17.7401 34.5543 18.3876 33.618 19.2044 32.8012L19.5131 32.4924H27.5915C28.1393 32.4924 28.5876 32.0442 28.5876 31.4963C28.5876 30.9485 28.1393 30.5002 27.5915 30.5002H21.4954L24.7327 27.2629H30.1813C30.7292 27.2629 31.1774 26.8147 31.1774 26.2668C31.1774 25.719 30.7292 25.2707 30.1813 25.2707H26.7249L32.4225 19.5731C32.811 19.1846 32.811 18.557 32.4225 18.1686C32.034 17.7801 31.4065 17.7801 31.018 18.1686L25.3204 23.8662V20.4098C25.3204 19.8619 24.8721 19.4137 24.3243 19.4137C23.7764 19.4137 23.3282 19.8619 23.3282 20.4098V25.8584L20.0909 29.0957V25.46C20.0909 24.9121 19.6426 24.4639 19.0948 24.4639C18.5469 24.4639 18.0987 24.9121 18.0987 25.46V31.0879L17.7899 31.3967C16.9532 32.2334 16.2659 33.1797 15.7379 34.1957C13.9549 32.2533 12.9788 29.7531 12.9688 27.1035C12.9688 24.2945 14.0545 21.6649 16.0368 19.6826C17.7501 17.9594 20.0311 16.8836 22.4516 16.6545Z" fill="#004D5F" />
      <path d="M19.085 22.7109C19.3439 22.7109 19.6029 22.6014 19.7922 22.4221C19.9814 22.2328 20.0811 21.9838 20.0811 21.7148C20.0811 21.4459 19.9715 21.1969 19.7922 21.0076C19.6029 20.8184 19.3539 20.7188 19.085 20.7188C18.826 20.7188 18.567 20.8283 18.3777 21.0076C18.1885 21.1969 18.0889 21.4459 18.0889 21.7148C18.0889 21.9838 18.1984 22.2328 18.3777 22.4221C18.567 22.6014 18.826 22.7109 19.085 22.7109Z" fill="#004D5F" />
      <path d="M10.9766 27.1035C10.9766 30.4404 12.2815 33.5781 14.6522 35.9389C14.7419 36.0285 14.8415 36.1182 14.9311 36.2078C14.6323 37.2238 14.4829 38.2897 14.4829 39.3854C14.4829 39.9332 14.9311 40.3815 15.479 40.3815C16.0268 40.3815 16.4751 39.9332 16.4751 39.3854C16.4751 38.7678 16.5348 38.1602 16.6544 37.5625C18.6665 38.8873 21.0272 39.6045 23.4975 39.6045H23.5174C26.8444 39.6045 29.9821 38.3096 32.3329 35.9588C34.345 33.9467 35.62 31.2672 35.9188 28.4383V28.4283L37.7018 14.0647C37.7417 13.7559 37.6321 13.457 37.4229 13.2379C37.2038 13.0188 36.9049 12.9192 36.5962 12.949L22.2524 14.6623C22.2225 14.6623 22.2026 14.6723 22.1727 14.6723C19.3338 14.9611 16.6643 16.2361 14.6422 18.2582C12.2616 20.6289 10.9667 23.7666 10.9766 27.1035ZM22.4516 16.6545C22.4915 16.6545 22.5313 16.6445 22.5811 16.6346L35.5602 15.0807L33.9366 28.1395C33.9366 28.1594 33.9266 28.1793 33.9266 28.1992C33.6876 30.5899 32.6118 32.851 30.9184 34.5543C28.9362 36.5266 26.3165 37.6223 23.5075 37.6223H23.4975C21.2364 37.6223 19.0749 36.9051 17.2819 35.5803C17.7401 34.5543 18.3876 33.618 19.2044 32.8012L19.5131 32.4924H27.5915C28.1393 32.4924 28.5876 32.0442 28.5876 31.4963C28.5876 30.9485 28.1393 30.5002 27.5915 30.5002H21.4954L24.7327 27.2629H30.1813C30.7292 27.2629 31.1774 26.8147 31.1774 26.2668C31.1774 25.719 30.7292 25.2707 30.1813 25.2707H26.7249L32.4225 19.5731C32.811 19.1846 32.811 18.557 32.4225 18.1686C32.034 17.7801 31.4065 17.7801 31.018 18.1686L25.3204 23.8662V20.4098C25.3204 19.8619 24.8721 19.4137 24.3243 19.4137C23.7764 19.4137 23.3282 19.8619 23.3282 20.4098V25.8584L20.0909 29.0957V25.46C20.0909 24.9121 19.6426 24.4639 19.0948 24.4639C18.5469 24.4639 18.0987 24.9121 18.0987 25.46V31.0879L17.7899 31.3967C16.9532 32.2334 16.2659 33.1797 15.7379 34.1957C13.9549 32.2533 12.9788 29.7531 12.9688 27.1035C12.9688 24.2945 14.0545 21.6649 16.0368 19.6826C17.7501 17.9594 20.0311 16.8836 22.4516 16.6545Z" fill="#004D5F" />
    </g>
    <defs>
      <clipPath id="clip0_717_5533">
        <rect width="51" height="51" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
