import React, {
  Dispatch, SetStateAction, useContext, useState,
} from 'react'
import * as ls from 'local-storage'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark, faAnglesLeft } from '@fortawesome/free-solid-svg-icons'
import { CreditsPromptLayoutHolder } from './CreditsPrompt.styles'
import { OverlayContainer, SimpleBox } from '../../App.styles'
import { IconCreditsPrompt } from '../../icons'
import { API } from '../../providers/API'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { theme } from '../../theme/theme'
import { FailureNotification } from '../SuccessNotification/SuccessNotification.styles'

interface SoilWarningProps {
  setShowPrompt: Dispatch<SetStateAction<boolean>>
  setPromptRejected?: Dispatch<SetStateAction<boolean>>
  showPrompt: boolean
  title: string
  textContent: string
  pricingData: PricingData
  fieldId: number
  callback?: () => void
  modal?: boolean
}

interface PricingData {
  price: number
  description: string
}

export const CreditsPrompt: React.FC<SoilWarningProps> = (
  {
    setShowPrompt,
    showPrompt, title, textContent, pricingData, fieldId, callback, setPromptRejected, modal,
  },
) => {
  const { t } = useTranslation()

  const [isPurchasing, setIsPurchasing] = useState(false)
  const [error, setError] = useState<null | string>(null)
  const { userInfo, setUserInfo } = useContext(GlobalStateContext)

  const purchaseParams = {
    creditCost: pricingData.price,
    description: pricingData.description,
    fieldId,
  }

  const purchaseCredits = () => {
    setIsPurchasing(true)
    API.post('/purchase', purchaseParams, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      if (response.data.message === 'Purchase successful!') {
        setUserInfo({ ...userInfo, totalCredits: response.data.userCredits })
        ls.set('userInfo', { ...userInfo, totalCredits: response.data.userCredits })
        setShowPrompt(false)
        if (callback !== undefined) {
          callback()
        }
      } else {
        setError(response.data.message)
      }
      setIsPurchasing(false)
    })
  }

  const renderFunction = () => (
    <SimpleBox>
      <CreditsPromptLayoutHolder>
        <div className="promptConditions">
          <h2>{title}</h2>
          <p>{textContent}</p>
        </div>
        <div className="promptIcon">
          <div className="container">
            <IconCreditsPrompt size="170" />
            <strong className="amount">{pricingData.price}cr</strong>
          </div>
        </div>
      </CreditsPromptLayoutHolder>
      <div style={{ maxWidth: '600px', margin: '0 auto' }}>

        <>
          {error ? (
            <>
              <FailureNotification>
                {t(`weather.errors.${error}`)}
              </FailureNotification>
              {modal && (
              <Button
                className="rejectButton"
                type="button"
                variant="contained"
                color="primary"
                startIcon={<FontAwesomeIcon icon={faAnglesLeft} />}
                onClick={() => {
                  if (setPromptRejected !== undefined) {
                    setPromptRejected(true)
                  }
                  setShowPrompt(false)
                }}
              >
                Обратно към микса
              </Button>
              )}
            </>
          ) : (
            <>
              <LoadingButton
                type="button"
                variant="contained"
                color="primary"
                onClick={purchaseCredits}
                endIcon={<FontAwesomeIcon icon={faCheck} />}
                loading={isPurchasing}
              >
                {t('createMix.creditsPromptButtonConfirm')}
              </LoadingButton>
              {modal && (
              <Button
                className="rejectButton"
                type="button"
                variant="contained"
                color="secondary"
                onClick={() => {
                  if (setPromptRejected !== undefined) {
                    setPromptRejected(true)
                  }
                  setShowPrompt(false)
                }}
                style={{ minWidth: '0', marginLeft: theme.space.md }}
              >
            &nbsp;<FontAwesomeIcon icon={faXmark} size="lg" />&nbsp;
              </Button>

              )}
            </>
          )}
        </>
      </div>
    </SimpleBox>
  )

  if (showPrompt) {
    if (modal) {
      return (
        <OverlayContainer>
          {renderFunction()}
        </OverlayContainer>
      )
    }
    return (
      <>{renderFunction()}</>
    )
  }

  return null
}
