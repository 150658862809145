import React, {
  Dispatch, createContext, useState, SetStateAction,
} from 'react'
import { FieldItem } from '../components/FieldsListing/Fields.types'

type mapOverlayStateType = {
  isOpen: boolean,
  activeSection: 'filters' | 'data',
}
interface MapDataContextProps {
  mapOverlayState: mapOverlayStateType,
  setMapOverlayState: Dispatch<SetStateAction<mapOverlayStateType>>
  fieldFill: boolean
  setFieldFill: Dispatch<SetStateAction<boolean>>
  zoneVisibility: any
  setZoneVisibility: Dispatch<SetStateAction<any>>
  nonZonedFieldsVisibility: boolean
  setNonZonedFieldsVisibility: Dispatch<SetStateAction<boolean>>
  cashCropVisibilityFilter: number | null
  setCashCropVisibilityFilter: Dispatch<SetStateAction<number | null>>
  allVisibleFieldIds: number[]
  setAllVisibleFieldIds: Dispatch<SetStateAction<number[]>>
  allAccountFields: FieldItem[]
  setAllAccountFields: Dispatch<SetStateAction<FieldItem[]>>
  dataLayerContent: any[]
  setDataLayerContent: Dispatch<SetStateAction<any[]>>
  activeFilterSection: string
  setActiveFilterSection: Dispatch<SetStateAction<string>>
  activeDataSection: string
  setActiveDataSection: Dispatch<SetStateAction<string>>
  activeDataLayer: string
  setActiveDataLayer: Dispatch<SetStateAction<string>>
  mapOverlayLoading: boolean
  setMapOverlayLoading: Dispatch<SetStateAction<boolean>>
}

export const MapDataContext = createContext<MapDataContextProps>({
  mapOverlayState: { isOpen: false, activeSection: 'filters' },
  setMapOverlayState: () => {},
  fieldFill: false,
  setFieldFill: () => {},
  zoneVisibility: {},
  setZoneVisibility: () => {},
  nonZonedFieldsVisibility: true,
  setNonZonedFieldsVisibility: () => {},
  cashCropVisibilityFilter: null,
  setCashCropVisibilityFilter: () => {},
  allVisibleFieldIds: [],
  setAllVisibleFieldIds: () => {},
  allAccountFields: [],
  setAllAccountFields: () => {},
  dataLayerContent: [],
  setDataLayerContent: () => {},
  activeFilterSection: '',
  setActiveFilterSection: () => {},
  activeDataSection: '',
  setActiveDataSection: () => {},
  activeDataLayer: '',
  setActiveDataLayer: () => {},
  mapOverlayLoading: false,
  setMapOverlayLoading: () => {},
})

export const MapDataProvider = ({ children }: {children: React.ReactNode}) => {
  const [mapOverlayState, setMapOverlayState] = useState<mapOverlayStateType>({ isOpen: false, activeSection: 'filters' })
  const [fieldFill, setFieldFill] = useState(false)
  const [zoneVisibility, setZoneVisibility] = useState<any>()
  const [nonZonedFieldsVisibility, setNonZonedFieldsVisibility] = useState<boolean>(true)
  const [allVisibleFieldIds, setAllVisibleFieldIds] = useState<number[]>([])
  const [allAccountFields, setAllAccountFields] = useState<FieldItem[]>([])
  const [dataLayerContent, setDataLayerContent] = useState<any[]>([])
  const [cashCropVisibilityFilter, setCashCropVisibilityFilter] = useState<number | null>(null)
  const [activeFilterSection, setActiveFilterSection] = useState<string>('')
  const [activeDataSection, setActiveDataSection] = useState<string>('')
  const [activeDataLayer, setActiveDataLayer] = useState<string>('')
  const [mapOverlayLoading, setMapOverlayLoading] = useState(false)

  const memoizedValue = React.useMemo(() => ({
    mapOverlayState,
    setMapOverlayState,
    fieldFill,
    setFieldFill,
    zoneVisibility,
    setZoneVisibility,
    nonZonedFieldsVisibility,
    setNonZonedFieldsVisibility,
    cashCropVisibilityFilter,
    setCashCropVisibilityFilter,
    allVisibleFieldIds,
    setAllVisibleFieldIds,
    allAccountFields,
    setAllAccountFields,
    dataLayerContent,
    setDataLayerContent,
    activeFilterSection,
    setActiveFilterSection,
    activeDataSection,
    setActiveDataSection,
    activeDataLayer,
    setActiveDataLayer,
    mapOverlayLoading,
    setMapOverlayLoading,
  }), [
    mapOverlayState,
    setMapOverlayState,
    fieldFill,
    setFieldFill,
    zoneVisibility,
    setZoneVisibility,
    nonZonedFieldsVisibility,
    setNonZonedFieldsVisibility,
    cashCropVisibilityFilter,
    setCashCropVisibilityFilter,
    allVisibleFieldIds,
    setAllVisibleFieldIds,
    allAccountFields,
    setAllAccountFields,
    dataLayerContent,
    setDataLayerContent,
    activeFilterSection,
    setActiveFilterSection,
    activeDataSection,
    setActiveDataSection,
    activeDataLayer,
    setActiveDataLayer,
    mapOverlayLoading,
    setMapOverlayLoading,
  ])

  return (
    <MapDataContext.Provider value={memoizedValue}>
      {children}
    </MapDataContext.Provider>
  )
}
