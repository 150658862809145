import React, { useEffect, useState } from 'react'
import * as ls from 'local-storage'
import { useHistory } from 'react-router-dom'
import { FieldItem as FieldItemTypes } from '../FieldsListing/Fields.types'
import { FieldGridItem } from '../FieldsListing/FieldsListing.styles'
import { FieldPolygonHeader } from '../FieldsListing/FieldPolygonHeader'
import { Loading } from '../Loading/Loading'
import { FieldSoilData } from '../NdviFIeld/FieldSoilData'
import { API } from '../../providers/API'

export const FieldItem: React.FC<{field: FieldItemTypes}> = ({
  field,
}) => {
  const [soilData, setSoilData] = useState<any>()

  const history = useHistory()

  const goToFieldPage = (fieldId: number) => {
    history.push(`/sateliteSoilHistoryDetails/${fieldId}`)
  }

  useEffect(() => {
    API.get(`/currentFieldSoil/${field.id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setSoilData(response.data.soilData)
    })
  }, [])

  return (
    <FieldGridItem>
      <div
        tabIndex={0}
        role="button"
        onKeyUp={() => {
          goToFieldPage(field.id)
        }}
        onClick={
            () => {
              goToFieldPage(field.id)
            }
          }
      >
        <FieldPolygonHeader
          field={field}
          key={field.id}
          showArea={false}
        />
      </div>
      {soilData ? (
        <div
          tabIndex={0}
          role="button"
          onKeyUp={() => {
            goToFieldPage(field.id)
          }}
          onClick={
            () => {
              goToFieldPage(field.id)
            }
          }
        >
          <FieldSoilData soilData={soilData} withMargin />
        </div>
      ) : (
        <Loading />
      )}
    </FieldGridItem>
  )
}
