import React, { useContext, useState, useEffect } from 'react'
import * as ls from 'local-storage'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FieldPolygonHeader } from './FieldPolygonHeader'
import { FieldGridItem } from './FieldsListing.styles'
import { FieldItem } from './Fields.types'
import { IconArea } from '../../icons'
import { API } from '../../providers/API'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { MixTypes } from '../MixesListing/Mix.types'
import { Loading } from '../Loading/Loading'
import { FieldMixSelect } from './FieldMixSelect'
import { SimpleGrid } from '../../App.styles'
import { InfoNotification } from '../SuccessNotification/SuccessNotification.styles'
// import areaIcon from '../../icons/area.svg'

interface FieldsListingProps {
  fields: FieldItem[]
  isSorted?: boolean
}

interface MixSelectProps {
  label: string
  id: number
}

export const FieldsListing: React.FC<FieldsListingProps> = ({ fields, isSorted }) => {
  const [mixOptions, setMixOptions] = useState<MixSelectProps[]>([])
  const {
    userInfo, isLoading,
  } = useContext(GlobalStateContext)
  const history = useHistory()
  const { t } = useTranslation()
  const goToFieldPage = (fieldId: number) => {
    history.push(`/fieldDetails/${fieldId}`)
  }

  useEffect(() => {
    // setup options for field autocomplete
    API.get(`/userMixes/${userInfo.id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      const mixOptionsArray:any = []
      mixOptionsArray.push({ label: t('general.noAssignedMix'), id: 0 })
      response.data.forEach((item:MixTypes) => {
        mixOptionsArray.push({ label: item.mix.mixName, id: item.mix.id })
      })

      setMixOptions(mixOptionsArray)
    })
  }, [])

  if (isLoading) {
    return <Loading />
  }

  if (fields.length === 0 && isSorted) {
    return <InfoNotification>{t('general.noResults')}</InfoNotification>
  }

  return (
    <SimpleGrid>
      {fields && fields.map((item: FieldItem) => (
        <FieldGridItem
          key={`fieldItem_${item.id}`}
        >
          <div
            tabIndex={0}
            role="button"
            onKeyUp={() => {
              goToFieldPage(item.id)
            }}
            onClick={
            () => {
              goToFieldPage(item.id)
            }
          }
          >
            <FieldPolygonHeader
              field={item}
              key={item.id}
              showArea={false}
            />
          </div>
          <div
            className="fieldStats"
            tabIndex={0}
            role="button"
            onKeyUp={() => {
              goToFieldPage(item.id)
            }}
            onClick={
            () => {
              goToFieldPage(item.id)
            }
          }
          >
            <IconArea size="22" color="#56CCF2" />
            {item.area} {t('createField.dka')}
          </div>
          <FieldMixSelect field={item} mixOptions={mixOptions} />
        </FieldGridItem>
      ))}
    </SimpleGrid>
  )
}
