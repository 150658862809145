import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import TextField from '@mui/material/TextField'
import { OrderMixContext } from '../../providers/OrderMixProvider'
import { CropItemTypes } from './MixElement.types'
import { CropItemRow, OrderDetailColumns } from './MixElements.styles'

export const CropItem: React.FC<CropItemTypes> = ({
  item, isLast, isFirst, detailed, cropType,
}) => {
  const { t } = useTranslation()
  const {
    changeSeedingNorms,
    mixData,
    setMixData,
  } = useContext(OrderMixContext)

  const handleSeedingNormsChange = (e: any) => {
    const cropId = e.target.getAttribute('data-crop-id')
    const objIndex = mixData[`${cropType}`].findIndex(((obj: any) => obj.id === parseInt(cropId)))

    const temporaryMixData = mixData
    temporaryMixData[`${cropType}`][objIndex].percentSeedingNorm = e.target.value

    setMixData({
      ...temporaryMixData,
    })
  }

  if (item === null) {
    return null
  }

  return (
    <CropItemRow className={detailed === true ? 'detailed' : ''}>
      <div className="imageContainer">
        <img src={`/images/crops/${item.URL}`} alt="" />
      </div>
      <div
        className={`nameContainer${isLast === true ? ' lastItem' : ''}`}
      >
        {t(`crops.${item.cropName}`)}
      </div>
      {detailed && (
      <OrderDetailColumns className={`${isLast === true ? 'lastItem' : ''}${isFirst === true ? ' firstItem' : ''}`}>
        <div className="item">
          <span className="header">
            {t('orderMix.percentSeedingNorm')}
          </span>
          {changeSeedingNorms ? (
            <TextField
              name={`percentSeedingNorm_${item.id}`}
              defaultValue={parseFloat(item.percentSeedingNorm).toFixed(2)}
              onChange={handleSeedingNormsChange}
              inputProps={{ 'data-crop-id': item.id }}
            />
          ) : (
            parseFloat(item.percentSeedingNorm).toFixed(2)
          )}
        </div>
        <div className="item">
          <span className="header">{t('orderMix.seedingNormFinal')}</span>
          {item.seedingNormFinal}
        </div>
        <div className="item">
          <span className="header">{t('orderMix.amount')}</span>
          {parseFloat(item.kg).toFixed(2)}
          {t('general.kg')}
        </div>
      </OrderDetailColumns>
      )}
    </CropItemRow>
  )
}
