import React, {
  useContext, useState, useEffect, Dispatch, SetStateAction,
} from 'react'
import * as ls from 'local-storage'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { API } from '../../providers/API'
import { MainDataContainer } from '../MixElements/MixElements.styles'
import { MainEffects } from '../MixElements/MainEffects'
import { MainRecommendedCrops } from '../MixElements/MainRecommendedCrops'
import { FullCropsAndEffects } from '../MixElements/FullCropsAndEffects'
import { theme } from '../../theme/theme'
import { ProcessButtonsHolder } from './CreateMix.styles'
import { LoadingSteps } from '../Loading/LoadingSteps'
import { HeaderInfoTypes } from '../MixElements/MixElement.types'
import i18n from '../../i18n'
import { FailureNotification } from '../SuccessNotification/SuccessNotification.styles'

interface MixResultsProps {
  fieldId: string
  zoneId: string
  fieldValues: any
  newMixId: number
  setStepTitle: Dispatch<SetStateAction<string>>
  soilAnalysis: string
  processStep: number
  setProcessStep: Dispatch<SetStateAction<number>>
  setMixHeaderInfo: Dispatch<SetStateAction<HeaderInfoTypes | undefined>>
}

interface CompatibleCoverCropsTypes {
  cropName: string
  id: number
}

interface CompatibleCoverCropsOptionsTypes {
  label: string
  id: number
}

export const MixResults: React.FC<MixResultsProps> = (
  {
    fieldId, zoneId, fieldValues,
    newMixId, setStepTitle, soilAnalysis, processStep, setProcessStep, setMixHeaderInfo,
  },
) => {
  const { t } = useTranslation()
  setStepTitle(t('mixResults.resultsTitle'))

  const { resultsLoading } = useContext(GlobalStateContext)
  const [mixData, setMixData] = useState<any>({})
  const [selectedCustomCrops, setSelectedCustomCrops] = useState<any[]>([])
  const [mixCreationError, setMixCreationError] = useState<string>('')
  const [
    compatibleCoverCrops,
    setCompatibleCoverCrops,
  ] = useState<CompatibleCoverCropsOptionsTypes[]>([])
  const [availabeCrops, setAvailableCrops] = useState<CompatibleCoverCropsTypes[]>([])
  const [recalculatedMix, setRecalculatedMix] = useState<any>()

  function updateCropLabels(cropsArray: CompatibleCoverCropsTypes[]) {
    const updatededCompatibleCoverCrops:
     CompatibleCoverCropsOptionsTypes[] = cropsArray.map(
       (item: CompatibleCoverCropsTypes) => ({
         label: t(`crops.${item.cropName}`),
         id: item.id,
       }),
     )
    setCompatibleCoverCrops(updatededCompatibleCoverCrops)
  }

  useEffect(() => {
    let soilData = {}

    if (soilAnalysis === 'yes') {
      soilData = {
        analysisId: fieldValues.analysisId,
        fieldId: fieldValues.fieldId,
        zoneId: fieldValues.zoneId,
        areaType: fieldValues.areaType,
        pH: fieldValues.pH,
        N: fieldValues.N,
        TMN: fieldValues.TMN,
        P: fieldValues.P,
        K: fieldValues.K,
        Ca: fieldValues.Ca,
        Mg: fieldValues.Mg,
        S: fieldValues.S,
        B: fieldValues.B,
        Cu: fieldValues.Cu,
        Fe: fieldValues.Fe,
        Mn: fieldValues.Mn,
        Zn: fieldValues.Zn,
        EC: fieldValues.EC,
        Carbonates: fieldValues.Carbonates,
        bulkDensity: fieldValues.bulkDensity,
        aggregateStability: fieldValues.aggregateStability,
        mineralizableNitrogen: fieldValues.mineralizableNitrogen,
        organicCarbon: fieldValues.organicCarbon,
        hummus: fieldValues.hummus,
        soilRespiration: fieldValues.soilRespiration,
        activeCarbon: fieldValues.activeCarbon,
        TOCTN: fieldValues.TOCTN,
        sand: fieldValues.sand,
        silt: fieldValues.silt,
        clay: fieldValues.clay,
        coordinates: fieldValues.coordinates,
      }
    } else if (soilAnalysis === 'no') {
      soilData = {
        id: fieldValues.fieldId,
        soilStruc: fieldValues.soilStruc,
        soilAcidity: fieldValues.soilAcidity,
        soilColour: fieldValues.soilColour,
      }
    }

    const calculateMix = () => {
      let endpointURL
      if (fieldValues.areaType === 'field') {
        endpointURL = `/mixCalculator?fieldId=${fieldId}&mixId=${newMixId}&hasAnalysis=${soilAnalysis === 'yes' ? '1' : '0'}`
      } else {
        endpointURL = `/mixCalculatorZone?zoneId=${zoneId}&mixId=${newMixId}&hasAnalysis=${soilAnalysis === 'yes' ? '1' : '0'}`
      }

      API.post(endpointURL, {}, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
        setMixData(response.data)
        setMixHeaderInfo({
          title: t('mixResults.resultsTitle'),
          fieldData: response.data.mainField,
          zoneName: response.data.zone ? response.data.zone.name : undefined,
          mixData: {
            seedingDate: response.data.seedingDate,
            terminationDate: response.data.terminationDate,
            totalMixArea: response.data.totalMixArea,
          },
          connectedFieldsData: response.data.connectedFields,
        })

        API.get(`/listCompatibleCoverCrops/${fieldId}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((compatibleCropsResponse) => {
          setAvailableCrops(compatibleCropsResponse.data)
          updateCropLabels(compatibleCropsResponse.data)
        })
      }).catch((error) => {
        setMixCreationError(error.response.data.message)
      })
    }

    if (soilAnalysis === 'yes' && fieldValues.areaType === 'field') {
      API.post('/fieldSoilData', soilData, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(() => {
        calculateMix()
      })
    } else if (soilAnalysis === 'no' && fieldValues.areaType === 'field') {
      API.post('/fixedSoilScore', soilData, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(() => {
        calculateMix()
      })
    } else {
      calculateMix()
    }
  }, [])

  useEffect(() => {
    updateCropLabels(availabeCrops)
  }, [i18n.language])

  const recalculateMix = () => {
    API.post(`/mixRecalc?crops=${selectedCustomCrops}&mixId=${newMixId}`, {}, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setRecalculatedMix(response.data)
    })
  }

  if (mixCreationError) {
    return (<FailureNotification>{t(`createMix.errors.${mixCreationError}`)}</FailureNotification>)
  }

  return (
    <>
      <LoadingSteps />
      {mixData && Object.keys(mixData).length > 0 && !resultsLoading && (
      <>
        <MainDataContainer>
          <MainEffects mixData={mixData} effectsData={mixData.effectsAvg} title={t('mixResults.mainEffects')} />
          <MainRecommendedCrops mixData={mixData} crops={mixData.crops} title={t('mixResults.mainCrops')} />
        </MainDataContainer>

        <h3>{t('mixResults.enrichMix')}</h3>

        <Autocomplete
          multiple
          options={compatibleCoverCrops}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('mixResults.chooseCrops')}
            />
          )}
          onChange={(event, newValue) => {
            const cropsArray = newValue.map((item:any) => item.id.toString())
            setSelectedCustomCrops([...cropsArray])
          }}
        />

        <Button
          variant="contained"
          type="button"
          color="primary"
          onClick={
          () => recalculateMix()
        }
          style={{ marginBottom: theme.space.md }}
        >
          {t('mixResults.recalculateMix')}
        </Button>

        {recalculatedMix && (
        <FullCropsAndEffects mixData={recalculatedMix} effectsData={recalculatedMix?.additionalEffects} cropsData={recalculatedMix?.additionalCrops} title={t('mixResults.extraCropsAndEffects')} />
        )}

        <ProcessButtonsHolder>
          <Button
            variant="contained"
            type="button"
            color="primary"
            onClick={
        () => {
          setProcessStep(processStep + 1)
        }
}
            endIcon={<FontAwesomeIcon icon={faAnglesRight} />}
          >
            {t('mixResults.previewMix')}

          </Button>

        </ProcessButtonsHolder>
      </>
      )}
    </>
  )
}
