import React, {
  useContext, useEffect, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import * as ls from 'local-storage'
import { API } from '../providers/API'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import { Loading } from '../components/Loading/Loading'
import { FieldItem } from '../components/FieldsListing/Fields.types'
import { SoilListingItem } from '../components/SoilAnalysis/SoilListingItem/SoilListingItem'
import { SimpleGrid } from '../App.styles'
import PageHeading from '../components/PageHeading/PageHeading'

export const SoilAnalysisListing = () => {
  const {
    isLoading, setIsLoading, userInfo,
  } = useContext(GlobalStateContext)
  const [fields, setFields] = useState<FieldItem[]>([])
  const [fieldsDataSource, setFieldsDataSource] = useState<FieldItem[]>([])
  const [sortedData, setSortedData] = useState<any[]>()
  const { t } = useTranslation()

  useEffect(() => {
    setIsLoading(true)
    API.get(`/fields/search/${userInfo.id}  `, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setFields(response.data)
      setFieldsDataSource(response.data)
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    if (sortedData) {
      setFieldsDataSource(sortedData)
    }
  }, [sortedData])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <PageHeading
        title={t('general.soilAnalysis')}
        sortingData={fields}
        searchByName
        nameKey="name"
        softScoringKey="softScoring"
        setSortedData={setSortedData}
        cacheDataKey="soilAnalysisListing"
        initialSortingOrder="descending"
      />
      {fieldsDataSource && fieldsDataSource.length !== 0 ? (
        <SimpleGrid>
          {fieldsDataSource.map((item: FieldItem) => (
            <SoilListingItem item={item} />
          ))}
        </SimpleGrid>
      ) : (
        null
      )}
    </>
  )
}
