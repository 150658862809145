import React, { useContext } from 'react'
import * as ls from 'local-storage'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { OverlayContainer } from '../../App.styles'
import { FailureNotification } from '../SuccessNotification/SuccessNotification.styles'

export const AuthFailureNotification: React.FC = () => {
  const {
    setUserAccess, setAuthError,
  } = useContext(GlobalStateContext)
  const { t } = useTranslation()

  return (
    <OverlayContainer>
      <FailureNotification style={{ background: '#FFF' }}>
        <p>{t('general.authExpired')}</p>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={() => {
            setUserAccess(0)
            ls.set('userAccess', 0)
            setAuthError(false)
          }}
        >
          {t('general.toHomePage')}
        </Button>
      </FailureNotification>
    </OverlayContainer>
  )
}
