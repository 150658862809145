import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as ls from 'local-storage'
import { Formik } from 'formik'
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons'
import { InputField } from '../FormElements/InputField'
import { SimpleBox } from '../../App.styles'

import { API } from '../../providers/API'

import { FieldItem } from '../FieldsListing/Fields.types'
import FieldMap from '../FieldMap/FieldMap'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { SuccessNotification } from '../SuccessNotification/SuccessNotification.styles'
import { DeleteFieldPrompt } from '../DeleteFieldPrompt/DeleteFieldPrompt'
import { BaseInfoActionButtons, FieldBaseDetailsContainer } from './FIeldDetails.styles'
import { theme } from '../../theme/theme'

interface BaseDetailsProps {
  field: FieldItem
}

export const BaseDetails = ({ field }: BaseDetailsProps) => {
  const {
    userInfo,
  } = useContext(GlobalStateContext)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [showDeletePrompt, setShowDeletePrompt] = useState<boolean>(false)
  const [editFieldSuccess, setEditFieldSuccess] = useState<boolean>(false)
  const { t } = useTranslation()

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required('Моля въведете име'),
  })

  return (
    <FieldBaseDetailsContainer>
      <FieldMap
        fields={[field]}
        height="100%"
        containerMargin={false}
      />
      <Formik
        initialValues={{
          name: field.name,
          description: field.description,
          ENP: field.ENP,
          organic: field.organic,
          seedingType: field.seedingType,
        }}
        validationSchema={ValidationSchema}
        enableReinitialize
        onSubmit={(values) => {
          setIsSaving(true)
          const submitData = {
            ...values,
            coordinates: field.coordinates,
            userId: userInfo.id,
          }
          API.put(`/fields/${field.id}`, submitData, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(() => {
            setEditFieldSuccess(true)
            setIsSaving(false)
            setTimeout(() => {
              setEditFieldSuccess(false)
            }, 3000)
          })
        }}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <InputField label={t('general.name')} name="name" id="name" type="text" />
            <InputField label={t('general.description')} name="description" id="description" type="text" multiline />
            <div>
              <h2>{t('createField.information')}</h2>
              <SimpleBox blueBG style={{ marginBottom: theme.space.md }}>
                <div className="fieldsToggleHolder">
                  <FormControlLabel
                    value="ENP"
                    control={<Switch color="success" />}
                    label={t('createField.isENP')}
                    labelPlacement="end"
                    onChange={(event: any) => setFieldValue('ENP', event.target.checked)}
                    checked={values.ENP === 1}
                  />
                  <FormControlLabel
                    value="organic"
                    control={<Switch color="success" />}
                    label={t('createField.organic')}
                    labelPlacement="end"
                    onChange={(event: any) => setFieldValue('organic', event.target.checked)}
                    checked={values.organic === 1}
                  />
                </div>

                {t('createField.seedingType')}:
                <RadioGroup
                  aria-label={t('createField.seedingType')}
                  name="seedingType"
                  value={values.seedingType}
                  onChange={(event) => {
                    setFieldValue('seedingType', event.currentTarget.value)
                  }}
                  className="seedingTypeRadioGroup"
                >
                  <FormControlLabel value="drilling" control={<Radio />} label={t('createField.drilled')} />
                  <FormControlLabel value="broadcast" control={<Radio />} label={t('createField.broadcast')} />
                  <FormControlLabel value="combined" control={<Radio />} label={t('createField.combined')} />

                </RadioGroup>
              </SimpleBox>
            </div>
            <BaseInfoActionButtons>
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                fullWidth
                loading={isSaving}
                loadingPosition="start"
                disabled={isSaving}
                endIcon={<FontAwesomeIcon icon={faCheck} />}
              >
                {t('createField.saveField')}
              </LoadingButton>
              <Button
                variant="contained"
                type="button"
                color="error"
                fullWidth
                onClick={() => setShowDeletePrompt(true)}
                endIcon={<FontAwesomeIcon icon={faTrash} />}
              >
                {t('createField.deleteField')}
              </Button>
            </BaseInfoActionButtons>
            <div>
              {editFieldSuccess && (
              <SuccessNotification>
                {t('createField.fieldEdited')}
              </SuccessNotification>
              )}
              {showDeletePrompt && (
              <DeleteFieldPrompt
                itemId={field.id}
                itemName={field.name}
                setShowDeletePrompt={setShowDeletePrompt}
                itemToDelete="field"
              />
              )}
            </div>
          </form>
        )}
      </Formik>
    </FieldBaseDetailsContainer>
  )
}
