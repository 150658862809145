import React, { useEffect, useState } from 'react'
import * as ls from 'local-storage'
import { API } from '../../providers/API'
import { Loading } from '../Loading/Loading'
import { WeatherListingItemHourly } from './WeatherListingItemHourly'

interface FieldsItemProps {
  fieldId: string
}

export const HourlyWeather: React.FC<FieldsItemProps> = ({
  fieldId,
}) => {
  const [forecastWeatherHourly, setForecastWeatherHourly] = useState([])
  const [isLoading, setIsLoading] = useState<any>()

  useEffect(() => {
    setIsLoading(true)
    API.get(`/forecastWeatherHourly/${fieldId}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setForecastWeatherHourly(response.data)
      setIsLoading(false)
    })
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {forecastWeatherHourly && forecastWeatherHourly.map((item: any) => (
        <WeatherListingItemHourly
          weatherData={item}
          key={item.id}
        />
      ))}
    </>
  )
}
