import styled, { css } from 'styled-components'
import { theme } from '../../theme/theme'
import { FieldItemRow } from '../FieldsListing/FieldsListing.styles'

export const MixListinItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.space.sm};
    border: 3px solid ${theme.colors.blueUI};
    border-radius: 10px;    
    margin-bottom: ${theme.space.md};
    cursor: pointer;
    transition: all 0.2s ease-in;

    &:hover {
        background: rgba(6, 192, 226, 0.44);
        box-shadow: 0px 5px 8px rgb(6 192 226 / 30%);
    }

    ${FieldItemRow} {
        margin: 0 0 0 ${theme.space.md};
        min-width: 200px;

        &:hover {
            box-shadow: none;
            background: #FFF;
        }

        & .svgHolder {
            height: 45px;
            width: 60px;
        }

        & .name {
            padding: 0 ${theme.space.md};
            font-size: 0.8em;
        }
    }

    ${theme.mediaQueries.smMax} {
        flex-direction: column;

        ${FieldItemRow} {
            margin-top: ${theme.space.sm};
            width: 100%;
        }
    }
`

export const MixStatus = styled.span<{type: string}>(
  ({ type }) => css`
    display: block;
    border-radius: 10px;
    padding: ${theme.space.sm} ${theme.space.md};

    span {
        text-transform: uppercase;
    }

    ${(type === 'demo' || type === '0') && (
    `background: ${theme.colors.blueBGUI};`
  )}

    ${type === 'pending' && (
    `
    background: white;
    color: black;
    `
  )}

    ${type === 'complete' && (
    `background: ${theme.colors.agroOrange};`
  )}
        
    `,
)

export const MixInfo = styled.div`
    display: flex;
    align-items: center;
`
