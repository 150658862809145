import React, {
  useContext, useEffect, useState,
} from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import axios from 'axios'
import { AgromonContext } from '../../providers/AgromonStateProvider'
import { FieldMap } from '../FieldMap/FieldMap'
import { Timeline } from './Timeline'
import { DataHolder, NdviMapAndDataHolder } from './NdviField.styles'
import { AgromonData } from './NdviField.types'
import { FieldItem } from '../FieldsListing/Fields.types'
import { SimpleBox } from '../../App.styles'
import { StatRow } from './StatRow'
import { FailureNotification } from '../SuccessNotification/SuccessNotification.styles'
import { getNdviImageCoords } from '../../utils/getNdviImageCoords'

export const FieldStats: React.FC<{field: FieldItem, agromonData: any}> = ({
  field, agromonData,
}) => {
  const { selectedDate, setDateItemSelector } = useContext(AgromonContext)
  const [currentAgroStats, setCurrentAgroStats] = useState<any>()
  const [currentDataType, setCurrentDataType] = useState<string>('NDVI')
  const [ndviMapSource, setNdviMapSource] = useState<any>()

  interface agromonDataTypeOptionsProps {
    label: string
    id: number
  }

  const agromonDataTypeOptions = [
    { label: 'NDVI', id: 1 },
    { label: 'NDWI', id: 2 },
    { label: 'NRI', id: 3 },
    { label: 'DSWI', id: 4 },
    { label: 'EVI', id: 5 },
    { label: 'EVI2', id: 6 },

  ]

  const getAgromonCurrentData = (dataName: string, date: number | undefined) => {
    let dataObject: AgromonData
    if (date === undefined) {
      // if date hasn't been selected, use the first item in the array
      // eslint-disable-next-line prefer-destructuring
      dataObject = agromonData[0]
      const defaultFirstItemDate = new Date(agromonData[0].dt * 1000)
      const day = defaultFirstItemDate.getDate()
      const month = defaultFirstItemDate.getMonth() + 1
      const year = defaultFirstItemDate.getFullYear()
      setDateItemSelector(`date_${day}-${month}-${year}`)
    } else {
      dataObject = agromonData.find(
        (agromonDataObject: AgromonData) => agromonDataObject.dt === date,
      )!
    }
    const lowerCaseDataName = dataName.toLowerCase()

    let ndviImage
    try {
    // @ts-ignore
      ndviImage = dataObject.image[lowerCaseDataName].replace('http://', 'https://')
    } catch {
      setCurrentAgroStats(undefined)
    }

    let agromonStatUrl
    try {
    // @ts-ignore
      agromonStatUrl = dataObject.stats[lowerCaseDataName].replace('http://', 'https://')
    } catch {
      setCurrentAgroStats(undefined)
    }

    axios.get(agromonStatUrl).then((agroStatResponse) => {
      const rearrangedData = Object.keys(agroStatResponse.data).filter(
        (item: string) => item !== 'min'
        && item !== 'max'
        && item !== 'mean'
        && item !== 'median'
        && item !== 'p25'
        && item !== 'p75',
      )
      rearrangedData.unshift('min', 'max', 'mean', 'median', 'p25', 'p75')
      const finalData = Object.fromEntries(
        rearrangedData.map((item: string) => [item, agroStatResponse.data[item]]),
      )
      setCurrentAgroStats(finalData)
    }).catch(() => {
      setCurrentAgroStats(undefined)
    })

    const sourceCoordinates = getNdviImageCoords(field)

    setNdviMapSource({
      type: 'image',
      url: ndviImage,
      coordinates: sourceCoordinates,
    })
  }

  useEffect(() => {
    if (agromonData && field) {
      getAgromonCurrentData(currentDataType, selectedDate)
    }
  }, [agromonData, field, selectedDate, currentDataType])

  return (
    <>
      <NdviMapAndDataHolder>
        <FieldMap
          fields={[field]}
          ndviData={ndviMapSource}
          expectNdviData
          containerMargin={false}
          height="400px"
        />
        <DataHolder>
          <Autocomplete
            disablePortal
            options={agromonDataTypeOptions}
            defaultValue={agromonDataTypeOptions[0]}
            renderInput={(params) => <TextField {...params} />}
            fullWidth
            onChange={(event, newValue: agromonDataTypeOptionsProps | null) => {
              setCurrentDataType(newValue!.label)
            }}
          />
          {currentAgroStats === undefined ? (
            <FailureNotification>There was a problem fetching the data</FailureNotification>
          )
            : (
              <SimpleBox>
                {currentAgroStats && Object.keys(currentAgroStats).map((stat: any) => (
                  <StatRow statName={stat} currentAgroStats={currentAgroStats} />
                ))}
              </SimpleBox>
            )}
        </DataHolder>
      </NdviMapAndDataHolder>
      <Timeline agromonData={agromonData} />
    </>
  )
}
