import React, { useContext, useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import * as ls from 'local-storage'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { API } from '../../providers/API'
import { InputField } from '../FormElements/InputField'
import { FailureNotification, SuccessNotification } from '../SuccessNotification/SuccessNotification.styles'
import { SimpleBox } from '../../App.styles'

export const Profile: React.FC = () => {
  const {
    userInfo, setUserInfo, isLoading, setIsLoading,
  } = useContext(GlobalStateContext)
  const [registerErrors, setRegisterErrors] = useState<string[]>([])
  const [formSuccess, setFormSuccess] = useState(false)
  const { t } = useTranslation()

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required(t('formErrors.enterName')),
    email: Yup.string()
      .email(t('formErrors.invalidEmail'))
      .required(t('formErrors.enterEmail')),
    telephone: Yup.string().required(t('formErrors.enterPhone')),
    companyName: Yup.string().required(t('formErrors.enterCompanyName')),
    headquaters: Yup.string().required(t('formErrors.enterHeadquarters')),
  })

  return (
    <SimpleBox>
      <Formik
        initialValues={{
          name: userInfo.name,
          email: userInfo.email,
          telephone: userInfo.telephone,
          companyName: userInfo.companyName,
          headquaters: userInfo.headquaters,
        }}
        validationSchema={ValidationSchema}
        enableReinitialize
        onSubmit={(values) => {
          setIsLoading(true)
          const submitData = {
            ...values,
            id: userInfo.id,
          }
          API.post('/editUser', submitData, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(() => {
            setUserInfo({ ...userInfo, ...values })
            ls.set('userInfo', { ...userInfo, ...values })
            setFormSuccess(true)
            setIsLoading(false)
          }).catch((error) => {
            setIsLoading(false)
            if (error.response.data.errors) {
              const errorsArray = Object.keys(error.response.data.errors)
              const errors:any = errorsArray.map(
                () => error.response.data.errors[errorsArray[0]],
              )
              setRegisterErrors(errors)
            } else if (error.response.data.message) {
              setRegisterErrors([error.response.data.message])
            } else {
              setRegisterErrors([t('submitErrors.generic')])
            }
            const errorsArray = Object.keys(error.response.data.errors)
            const errors:any = errorsArray.map(
              () => error.response.data.errors[errorsArray[0]],
            )
            setRegisterErrors(errors)
          })
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <InputField label={t('general.name')} name="name" id="name" type="text" />
            <InputField label={t('general.email')} name="email" id="email" type="text" />
            <InputField label={t('registration.phone')} name="telephone" id="telephone" type="text" />
            <InputField label={t('registration.companyName')} name="companyName" id="company_name" type="text" />
            <InputField label={t('registration.headquarters')} name="headquaters" id="headquaters" type="text" />

            <LoadingButton
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
              loading={isLoading}
              loadingPosition="start"
            >
              {t('account.submit')}
            </LoadingButton>
            {formSuccess && <SuccessNotification>{t('account.editSuccess')}</SuccessNotification>}
            {registerErrors && (
              registerErrors.map((item: any) => (
                <FailureNotification>{t(`submitErrors.${item}`)}</FailureNotification>
              ))
            )}
          </form>
        )}
      </Formik>
    </SimpleBox>
  )
}
