import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaintbrush, faTrash, faHourglass } from '@fortawesome/free-solid-svg-icons'
import { ZoneListingType } from './ZonesListing.types'
import { FieldGridItem } from '../FieldsListing/FieldsListing.styles'
import { IconArea, IconMyFields } from '../../icons'
import { theme } from '../../theme/theme'
import { ColorPickerHolder, DeleteGridItemButton } from './ZonesListing.styles'
import { ZoneGridItemCreateNew } from './ZoneGridItemCreateNew'
import { DeleteFieldPrompt } from '../DeleteFieldPrompt/DeleteFieldPrompt'

interface ZoneGridItemProps {
  zoneData: ZoneListingType | null
  createZone?: boolean
  zones?: ZoneListingType[]
  setZones?: React.Dispatch<React.SetStateAction<ZoneListingType[]>>
  setZonesListingSource?: React.Dispatch<React.SetStateAction<ZoneListingType[]>>
}

export const ZoneGridItem = (
  {
    zoneData, createZone = false, zones, setZones, setZonesListingSource,
  }: ZoneGridItemProps,
) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showDeletePrompt, setShowDeletePrompt] = useState(false)
  const { t } = useTranslation()
  const history = useHistory()

  const goToFieldPage = (fieldId: number) => {
    history.push(`/fieldZoneDetails/${fieldId}`)
  }

  const deleteField = (fieldId: number) => {
    if (zones && setZones) {
      const remainingZones = zones.filter((item) => item.id !== fieldId)
      setZones(remainingZones)
      if (setZonesListingSource) {
        setZonesListingSource(remainingZones)
      }
    }
  }

  function outputFieldCount() {
    if (zoneData!.containedFields && zoneData!.containedFields.length === 1) {
      return `${zoneData!.containedFields.length} ${t('general.fieldLowerCase')}`
    } if (zoneData!.containedFields && zoneData!.containedFields.length > 1) {
      return `${zoneData!.containedFields.length} ${t('general.fields')}`
    }
    return `0 ${t('general.fields')}`
  }

  if (createZone) {
    return (
      <ZoneGridItemCreateNew />
    )
  }

  if (!zoneData) {
    return null
  }

  return (
    <>
      {showDeletePrompt && (
      <DeleteFieldPrompt
        itemId={zoneData.id}
        itemName={zoneData.name}
        setShowDeletePrompt={setShowDeletePrompt}
        itemToDelete="zone"
        callback={() => deleteField(zoneData.id)}
        onCancel={() => setIsLoading(false)}
      />
      )}
      <FieldGridItem>
        {zoneData.type !== 'smart' && (
        <DeleteGridItemButton
          type="button"
          disabled={isLoading}
          onClick={() => {
            setIsLoading(true)
            setShowDeletePrompt(true)
          }}
        ><FontAwesomeIcon
          color="#FFF"
          icon={isLoading ? faHourglass : faTrash}
        />
        </DeleteGridItemButton>
        )}
        <div
          className="fieldStats"
          tabIndex={0}
          role="button"
          onKeyUp={() => {
            goToFieldPage(zoneData.id)
          }}
          onClick={
            () => {
              goToFieldPage(zoneData.id)
            }
          }
        >
          <h3 style={{ margin: 0 }}>{zoneData.name}</h3>
        </div>
        <div
          className="fieldStats"
          tabIndex={0}
          role="button"
          onKeyUp={() => {
            goToFieldPage(zoneData.id)
          }}
          onClick={
            () => {
              goToFieldPage(zoneData.id)
            }
          }
        >
          <IconArea color={theme.colors.blueUI} />
          <span>{zoneData.area ? zoneData.area : '0'} {t('general.dka')}</span>
        </div>
        <div
          className="fieldStats"
          tabIndex={0}
          role="button"
          onKeyUp={() => {
            goToFieldPage(zoneData.id)
          }}
          onClick={
            () => {
              goToFieldPage(zoneData.id)
            }
          }
        >
          <IconMyFields color={theme.colors.blueUI} />
          <span>{outputFieldCount()}</span>
        </div>
        <div
          className="fieldStats"
          tabIndex={0}
          role="button"
          onKeyUp={() => {
            goToFieldPage(zoneData.id)
          }}
          onClick={
            () => {
              goToFieldPage(zoneData.id)
            }
          }
        >
          <FontAwesomeIcon icon={faPaintbrush} color={theme.colors.blueUI} />
          <ColorPickerHolder
            isOpen={false}
            color={
            zoneData.colour
          }
          />
        </div>
      </FieldGridItem>
    </>
  )
}
