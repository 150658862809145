import React, {
  useContext, useEffect, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import * as ls from 'local-storage'
import Button from '@mui/material/Button'
import { FieldItem as FieldItemTypes } from '../components/FieldsListing/Fields.types'
import { InfoNotification } from '../components/SuccessNotification/SuccessNotification.styles'
import { API } from '../providers/API'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { Loading } from '../components/Loading/Loading'
import { FieldItem } from '../components/NdviFieldsListing/FieldItem'
import { SimpleGrid } from '../App.styles'
import { NdviPalette } from '../components/NdviFIeld/NdviPalette'
import PageHeading from '../components/PageHeading/PageHeading'

// const falls = require('../utils/falls.json')

export const NdviFieldsListing = () => {
  const {
    userInfo, isLoading, setIsLoading,
  } = useContext(GlobalStateContext)
  const [fields, setFields] = useState<FieldItemTypes[]>()
  const [fieldsDataSource, setFieldsDataSource] = useState<FieldItemTypes[]>()
  const [sortedData, setSortedData] = useState<any[]>()
  const [agroDataAvailable, setAgroDataAvailable] = useState<boolean>(false)
  const history = useHistory()
  const { t } = useTranslation()

  const agromonData: any[] = []
  const mergeFieldAgromonData = (
    fieldsArray: any, agromonDataArray: any,
  ) => fieldsArray.map((itm: any) => ({
    agroData: { ...agromonDataArray.find((item: any) => (item.id === itm.id)) },
    ...itm,
  }))

  const setFieldsWithAgromonData = (fieldId: any, data: object) => {
    agromonData.push({ id: fieldId, ...data })
    if (agromonData.length === fields?.length) {
      const merged = mergeFieldAgromonData(fields, agromonData)
      setFields(merged)
      setAgroDataAvailable(true)
    }
  }

  const fetchFieldData = () => {
    setIsLoading(true)
    API.get(`/fields/search/${userInfo.id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      const fieldsWithNdviData = response.data.filter((item:any) => item.OWid)
      setFields(fieldsWithNdviData)
      setFieldsDataSource(fieldsWithNdviData)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    fetchFieldData()
  }, [])

  useEffect(() => {
    if (sortedData) {
      setFieldsDataSource(sortedData)
    }
  }, [sortedData])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <PageHeading
        title={t('ndviListing.title')}
        sortingData={fields}
        nameKey="name"
        areaKey="area"
        hasMixKey="mix"
        dateKey="created_at"
        cacheDataKey="ndviFieldsListing"
        agromonDataKey={agroDataAvailable ? 'agroData' : undefined}
        setSortedData={setSortedData}
      />
      {fieldsDataSource && fieldsDataSource.length === 0 ? (
        <>
          <InfoNotification>{t('createField.noFields')}</InfoNotification>
          <Button
            variant="contained"
            type="button"
            color="primary"
            onClick={() => {
              history.push('/createField')
            }}
          >
            {t('createField.createField')}
          </Button>
        </>
      ) : (
        <>
          <NdviPalette />
          <SimpleGrid>
            {fieldsDataSource?.map((field: FieldItemTypes) => (
              <FieldItem
                field={field}
                key={field.id}
                setFieldWithAgromonData={setFieldsWithAgromonData}
              />
            ))}
          </SimpleGrid>
        </>
      )}
    </>
  )
}
