import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SoilItemWrap } from './SoilListingItem.styles'
import { GlobalStateContext } from '../../../providers/GlobalStateProvider'
import { Loading } from '../../Loading/Loading'
import { SoilListingItemProps } from './SoilListingItem.types'
import { FieldPolygonHeader } from '../../FieldsListing/FieldPolygonHeader'
import { NvdiColorSwatch } from '../../NdviFieldsListing/NdviField.styles'
import { theme } from '../../../theme/theme'

export const SoilListingItem: React.FC<SoilListingItemProps> = (
  { item, inFieldDetails, showFieldPolygonHeader = true },
) => {
  const {
    isLoading,
  } = useContext(GlobalStateContext)
  const { t } = useTranslation()
  const history = useHistory()

  const navigateToFieldData = (fieldId: number) => {
    history.push(`/soilAnalysisField/${fieldId}${inFieldDetails ? '?inFieldDetails=true' : ''}`)
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <SoilItemWrap>
      <div
        tabIndex={0}
        role="button"
        onKeyUp={() => {
          navigateToFieldData(item.id)
        }}
        onClick={
          () => {
            navigateToFieldData(item.id)
          }
        }
      >
        {showFieldPolygonHeader && (
        <FieldPolygonHeader
          field={item}
          key={item.id}
          showArea={false}
          blueBG
          fullWidth
        />
        )}
        <div className="infoRow">
          <span>{t('soilAnalysis.chemicalHealthShort')}</span>
          <div className="scoreWrap">
            {item.softScoring?.chemScore ? t(`analysis.softScore.chem.${Math.round(item.softScoring?.chemScore)}`) : t('general.noData')}
            <NvdiColorSwatch
            // @ts-ignore
              color={theme.colors.progressColors[Math.round(item.softScoring?.chemScore) - 1]}
              noData={item.softScoring?.physScore === null}
            />
          </div>
        </div>
        <div className="infoRow">
          <span>{t('soilAnalysis.physicalHealthShort')}</span>
          <div className="scoreWrap">
            {item.softScoring?.physScore ? t(`analysis.softScore.phys.${Math.round(item.softScoring?.physScore)}`) : t('general.noData')}
            <NvdiColorSwatch
            // @ts-ignore
              color={theme.colors.progressColors[Math.round(item.softScoring?.physScore) - 1]}
              noData={item.softScoring?.physScore === null}
            />
          </div>
        </div>
        <div className="infoRow">
          <span>{t('soilAnalysis.bioHealthShort')}</span>
          <div className="scoreWrap">
            {item.softScoring?.bioScore ? t(`analysis.softScore.bio.${Math.round(item.softScoring?.bioScore)}`) : t('general.noData')}
            <NvdiColorSwatch
            // @ts-ignore
              color={theme.colors.progressColors[Math.round(item.softScoring?.bioScore) - 1]}
              noData={item.softScoring?.physScore === null}
            />
          </div>
        </div>
      </div>
    </SoilItemWrap>
  )
}
