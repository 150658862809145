import React from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { SimpleGrid } from '../../App.styles'

import { FieldItem } from '../FieldsListing/Fields.types'
import { SoilListingItem } from '../SoilAnalysis/SoilListingItem/SoilListingItem'
import { FieldItem as NdviFieldItem } from '../NdviFieldsListing/FieldItem'
import { WeatherListingItem } from '../WeatherListing/WeatherListingItem'
import { FieldDetailsAddButton, FieldServiceItem } from './FIeldDetails.styles'

interface BaseDetailsProps {
  field: FieldItem
}

export const FieldServiceData = ({ field }: BaseDetailsProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <SimpleGrid columnsSplit="1fr 1fr 1fr">
      <FieldServiceItem>
        <h2>{t('createField.soilAnalysis')}</h2>
        <SoilListingItem item={field} inFieldDetails showFieldPolygonHeader={false} />
        <FieldDetailsAddButton
          type="button"
          className="addButton"
          onClick={() => history.push(`/addAnalysisToField/${field.id}`)}
        >
          +
        </FieldDetailsAddButton>
      </FieldServiceItem>
      <FieldServiceItem>
        <h2>NDVI</h2>
        <NdviFieldItem
          field={field}
          key={field.id}
          showFieldPolygonHeader={false}
        />
      </FieldServiceItem>
      <FieldServiceItem>
        <h2>{t('navigation.meteoData')}</h2>
        <WeatherListingItem
          field={field}
          type="current"
          showDetailedInfo={false}
        />
      </FieldServiceItem>
    </SimpleGrid>
  )
}
