import React from 'react'
import { EffectItem } from './EffectItem'
import { MixMainEffectsTypes } from './MixElement.types'

export const MainEffects: React.FC<MixMainEffectsTypes> = ({
  mixData, title, effectsData,
}) => (
  <div className="container">
    <h2>{title}</h2>
    <div className="dataBox effects">
      <div>
        {mixData && Object.keys(effectsData).map((item: string, key: number) => (
          <EffectItem item={item} effectData={effectsData} key={key} />
        ))}
      </div>
    </div>
  </div>
)
