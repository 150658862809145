import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'
import { theme } from '../../../../theme/theme'
import { MapDataContext } from '../../../../providers/MapDataProvider'
import { FilterOptionRow } from '../MapOverlayFilters.styles'
import { AccordionItemHeader } from '../accordionElements/AccordionItemHeader'
import { AccordionItemContent } from '../accordionElements/AccordionElements.styles'

export const ZoneFilters = () => {
  const { t } = useTranslation()
  const {
    zoneVisibility,
    setZoneVisibility,
    nonZonedFieldsVisibility,
    setNonZonedFieldsVisibility,
    activeFilterSection,
  } = useContext(MapDataContext)

  return (
    <>
      <AccordionItemHeader title={t('mapOverlay.zoneVisibilityFilter')} value="zones" type="filter" />
      <AccordionItemContent isOpen={activeFilterSection === 'zones'}>
        <FilterOptionRow>
          <Checkbox
            size="small"
            sx={{
              color: '#838383',
              '&.Mui-checked': {
                color: '#838383',
              },
              '& .MuiSvgIcon-root': {
                color: 'inherit',
              },
            }}
            style={{
              backgroundColor: '#FF0',
              borderRadius: '5px',
              padding: theme.space.xxs,
            }}
            checked={nonZonedFieldsVisibility}
            onChange={() => {
              setNonZonedFieldsVisibility((prevState: boolean) => !prevState)
            }}
          /> {t('mapOverlay.nonZonedFields')}
        </FilterOptionRow>
        {zoneVisibility?.map((item: any, index: number) => (
          <FilterOptionRow key={item.id}>
            <Checkbox
              size="small"
              style={{
                backgroundColor: item.colour,
                borderRadius: '5px',
                padding: theme.space.xxs,
              }}
              checked={item.visible}
              onChange={() => {
                setZoneVisibility((prevState: any) => {
                  const updatedVisibility = [...prevState]
                  updatedVisibility[index].visible = !updatedVisibility[index].visible
                  return updatedVisibility
                })
              }}
            /> {item.name}
          </FilterOptionRow>
        ))}
      </AccordionItemContent>
    </>
  )
}
