import React, {
  useContext, useEffect, useRef, useState,
} from 'react'
import proj4 from 'proj4'
import shp from 'shpjs'
import ReactMapboxGl, { GeoJSONLayer } from 'react-mapbox-gl'
import DrawControl from 'react-mapbox-gl-draw'
import area from '@turf/area'
import * as ls from 'local-storage'
import { useTranslation } from 'react-i18next'
import { FieldArray, Formik } from 'formik'
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { FieldPolygonHeader } from '../components/FieldsListing/FieldPolygonHeader'

import { InputField } from '../components/FormElements/InputField'
import { API } from '../providers/API'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { theme } from '../theme/theme'
import { SuccessNotification, FailureNotification } from '../components/SuccessNotification/SuccessNotification.styles'
import { FieldAreaHolder, FieldAreaStat } from '../components/FieldCreation/FieldCreation.styles'
import { SimpleBox } from '../App.styles'
import areaIcon from '../icons/area.svg'
import { calcBoundsFromCoordinates } from '../utils/calcBounds'
import { getAllCoordinates } from '../utils/getAllCoordsFromMap'

const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoiY2h1c2hldiIsImEiOiJmNzAzNWNkZmYyN2M4NjdjZjg5MmIxYTY5ODZiMWQ3OSJ9.fyX0tsB68PhMdTF1yLnnlQ',
})

export const CreateFieldPage = () => {
  const { userInfo } = useContext(GlobalStateContext)
  const [center] = useState<[number, number]>([25.181873, 42.229602])
  const [zoom] = useState<[number]>([6])
  const [geojsonFields, setGeojsonFields] = useState<any>([])
  const [success, setSuccess] = useState<boolean | null>(null)
  const [failureMessage, setFailureMessage] = useState<string>('')
  const [noFieldDrawn, setNoFieldDrawn] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [fieldArea, setFieldArea] = useState<string>('')
  const [fieldTooSmall, setFieldTooSmall] = useState<boolean>(false)
  const [fileUpload, setFileUpload] = useState<boolean>(false)
  const [fieldValues, setFieldValues] = useState({
    fields: [
      {
        id: 0,
        coordinates: '',
        name: '',
        description: '',
        ENP: false,
        organic: false,
        seedingType: 'drilling',
        userId: userInfo.id,
        area: 0,
      },
    ],
  })
  const { t } = useTranslation()

  const minSizeField = 5

  const ValidationSchema = Yup.object().shape({
    fields: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required(t('createField.messages.enterName')),
        }),
      ),
  })

  const DrawRef = useRef<any>(null)
  const MapRef = useRef<any>(null)
  const InputUploadFieldRef = useRef<any>(null)

  const calculateArea = (e:any) => {
    const currentFieldArea = (Math.round(area(
      e.features ? e.features[0] : e,
    )) / 1000).toFixed(2)

    return currentFieldArea
  }

  proj4.defs('EPSG:32635', '+proj=utm +zone=35 +datum=WGS84 +units=m +no_defs +type=crs')
  // @ts-ignore
  const source = new proj4.Proj('EPSG:32635')
  // @ts-ignore
  const dest = new proj4.Proj('WGS84')

  const convertCoords = (coords: any) => {
    // console.log('coords', coords)
    const innerMap = coords.map((coord: any) => (
      coord.map((innerCoord: any) => {
        const transformedCoord = proj4.transform(source, dest, innerCoord)
        const transformedCoordArray = Object.entries(transformedCoord).map(([, value]) => value)

        return transformedCoordArray.slice(0, -1)
      })
    ))

    return innerMap
  }

  function handleFiles(e:any) {
    const fileUrl = URL.createObjectURL(e.target.files[0])
    shp(fileUrl).then((geojson: any) => {
      let coordsObject = geojson.features

      if (geojson.features[0].geometry.coordinates[0][0][0] > 90) {
        coordsObject = geojson.features.reduce(
          (finalFeatures:any, currentFeature: any) => {
            const thisCoords = convertCoords(currentFeature.geometry.coordinates)
            finalFeatures.push(
              {
                ...currentFeature,
                geometry: {
                  coordinates: [
                    ...thisCoords,
                  ],
                  type: 'Polygon',
                },
              },
            )

            return finalFeatures
          }, [],
        )
      }

      setGeojsonFields(coordsObject)
      const allFieldValues = coordsObject.map((field: any, index: number) => (
        {
          name: `Поле ${index + 1}`,
          description: '',
          ENP: false,
          organic: false,
          seedingType: 'drilling',
          coordinates: JSON.stringify(field),
          userId: userInfo.id,
          area: calculateArea(field),
        }
      ))

      setFieldValues(
        {
          fields: [...allFieldValues],
        },
      )
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
        MapRef.current.state.map.fitBounds(
          calcBoundsFromCoordinates(getAllCoordinates(MapRef)), { padding: 50 },
        )
      }, 50)

      setFileUpload(true)
    })
  }

  useEffect(() => {
    if (parseInt(fieldArea) <= minSizeField) {
      setFieldTooSmall(true)
    } else {
      setFieldTooSmall(false)
    }
  }, [fieldArea])

  return (
    <>
      <h1>{t('createField.createFieldTitle')}</h1>
      <Map
        // eslint-disable-next-line react/style-prop-object
        style="mapbox://styles/mapbox/satellite-streets-v11"
        containerStyle={{
          height: '350px',
          width: '100%',
          border: `3px solid ${theme.colors.blueUI}`,
          borderRadius: '5px',
          overflow: 'hidden',
        }}
        zoom={zoom}
        center={center}
        ref={MapRef}
      >
        <GeoJSONLayer
          fillPaint={{
            'fill-color': '#FF0',
            'fill-opacity': 0.4,
          }}
          data={{
            type: 'FeatureCollection',
            features: geojsonFields,
          }}
        />

        <DrawControl
          controls={{
            point: false,
            line_string: false,
            polygon: true,
            trash: true,
            combine_features: false,
            uncombine_features: false,
          }}
          ref={DrawRef}
          onDrawUpdate={(e) => setFieldArea(calculateArea(e))}
          onDrawCreate={(e) => setFieldArea(calculateArea(e))}
        />

      </Map>

      {fieldArea !== '' && (
        <FieldAreaHolder>
          <FieldAreaStat>
            <img src={areaIcon} alt="" />
            <span>
              {t('createField.fieldArea')}
              :
              {' '}
              {fieldArea}
              {' '}
              {t('createField.dka')}
            </span>
          </FieldAreaStat>
          {fieldTooSmall && (
          <FailureNotification>{t('createField.messages.fieldTooSmallError')}</FailureNotification>
          )}
        </FieldAreaHolder>
      )}

      <input
        type="file"
        className="visuallyHidden"
        onChange={
        (e: any) => handleFiles(e)
}
        ref={InputUploadFieldRef}
      />
      <Button
        variant="contained"
        type="button"
        color="primary"
        fullWidth
        onClick={() => {
          InputUploadFieldRef.current.click()
        }}
        style={{ marginTop: theme.space.md }}
      >
        {t('createField.uploadField')}
      </Button>

      <Formik
        initialValues={fieldValues}
        validationSchema={ValidationSchema}
        enableReinitialize
        validateOnChange={false}
        onSubmit={(values) => {
          let submitData = {}

          if (!fileUpload) {
            submitData = {
              fields: [
                {
                  ...values.fields[0],
                  coordinates: JSON.stringify(DrawRef.current.draw.getAll().features[0]),
                  area: fieldArea,
                },
              ],
            }
          } else {
            const finalFieldValues = values.fields.reduce(
              (finalFields:any, currentField: any) => {
                if (currentField.area >= minSizeField) {
                  finalFields.push(currentField)
                }

                return finalFields
              }, [],
            )
            submitData = {
              ...values,
              fields: finalFieldValues,
            }
          }

          if (
            // @ts-ignore
            (fileUpload === false && submitData.fields[0].coordinates !== undefined)
            || geojsonFields.length > 0
          ) {
            setIsLoading(true)
            API.post('/massStore', submitData, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
              if (response.data.message) {
                setSuccess(false)
                setFailureMessage(response.data.message)
              }
              setIsLoading(false)
              setSuccess(true)
              setNoFieldDrawn(false)
            }).catch(() => {
              setIsLoading(false)
              setSuccess(false)
            })
          } else {
            setNoFieldDrawn(true)
          }
        }}
      >
        {({
          handleSubmit, values, setFieldValue, errors, touched,
        }) => (
          <form onSubmit={handleSubmit} style={{ marginTop: theme.space.md }}>
            <>
              <FieldArray
                name="fields"
                render={() => (
                  <>
                    {values.fields.map((asdasd: any, index: number) => (
                      <SimpleBox key={index} style={{ marginBottom: theme.space.md }}>
                        <>
                          {values.fields[index].coordinates && (
                          <FieldPolygonHeader field={values.fields[index]} key={index} showArea />
                          )}
                          {values.fields[index].area < minSizeField && fileUpload ? (
                            <FailureNotification>{t('createField.messages.fieldTooSmallWillBeSkipped')}</FailureNotification>
                          ) : (
                            <>
                              {values.fields.length > 1 && <h3>Поле {index + 1}</h3>}
                              <InputField label={t('general.name')} name={`fields[${index}].name`} id={`fields[${index}].name`} type="text" />
                              <InputField label={t('general.description')} name={`fields[${index}].description`} id={`fields[${index}].description`} type="text" multiline />
                              <div>
                                <FormControlLabel
                                  value="ENP"
                                  control={<Switch color="success" />}
                                  label={t('createField.isENP')}
                                  labelPlacement="start"
                                  onChange={(event: any) => setFieldValue(`fields[${index}].ENP`, event.target.checked)}
                                  checked={values.fields[index].ENP}
                                />
                              </div>
                              <div>
                                <FormControlLabel
                                  value="organic"
                                  control={<Switch color="success" />}
                                  label={t('createField.organic')}
                                  labelPlacement="start"
                                  onChange={(event: any) => setFieldValue(`fields[${index}].organic`, event.target.checked)}
                                  checked={values.fields[index].organic}
                                />
                              </div>
                              {/* @ts-ignore */}
                              <div style={{ border: touched.fields && touched.fields[index] && touched.fields[index].seedingType && errors.fields && errors.fields[index] && errors.fields[index].seedingType && touched.fields[index].seedingType ? `3px solid ${theme.colors.agroOrange}` : 'none', borderRadius: '5px', padding: '10px 5px' }}>
                                <div>
                                  {t('createField.seedingType')}
                                  :
                                </div>
                                <RadioGroup
                                  row
                                  aria-label="gender"
                                  name={`fields[${index}].seedingType`}
                                  value={values.fields[index].seedingType}
                                  onChange={(event) => {
                                    setFieldValue(`fields[${index}].seedingType`, event.currentTarget.value)
                                  }}
                                >
                                  <FormControlLabel value="drilling" control={<Radio />} label={t('createField.drilled')} />
                                  <FormControlLabel value="broadcast" control={<Radio />} label={t('createField.broadcast')} />
                                  <FormControlLabel value="combined" control={<Radio />} label={t('createField.combined')} />
                                </RadioGroup>
                              </div>

                              <div style={{
                                color: theme.colors.agroOrange,
                                fontWeight: '400',
                                fontSize: '0.75rem',
                                lineHeight: '1.66',
                                letterSpacing: '0.03333em',
                                margin: '3px 14px 0',
                                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                              }}
                              >
                                {
                                  errors.fields
                                  // @ts-ignore
                                  && errors.fields[index] && errors.fields[index].seedingType
                                  && touched.fields
                                  && touched.fields[index]
                                  && touched.fields[index].seedingType && ((
                                  // @ts-ignore
                                    errors.fields[index].seedingType
                                  ))
                              }
                              </div>
                            </>
                          )}
                        </>
                      </SimpleBox>
                    ))}
                  </>
                )}
              />

              {success === true && (
              <SuccessNotification>{t('createField.messages.success')}</SuccessNotification>
              )}
              {success === false && (
                <>
                  {failureMessage ? (
                    <FailureNotification>{failureMessage}</FailureNotification>
                  ) : (
                    <FailureNotification>
                      {t('createField.messages.failure')}
                    </FailureNotification>
                  )}
                </>
              )}
              {noFieldDrawn === true && (
              <FailureNotification>{t('createField.messages.noFieldDrawn')}</FailureNotification>
              )}
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={isLoading}
                loadingPosition="start"
                fullWidth
                disabled={fieldTooSmall}
              >
                {t('createField.saveField')}
              </LoadingButton>
            </>
          </form>
        )}
      </Formik>
    </>
  )
}
