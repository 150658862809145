import React, {
  useEffect, useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as ls from 'local-storage'
import { useHistory, useParams } from 'react-router'
import Button from '@mui/material/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons'
import { API } from '../providers/API'
import { Loading } from '../components/Loading/Loading'
import { SimpleBox } from '../App.styles'
import { SoftScoreCell, SummaryTable } from '../components/SoilAnalysis/SoilAnalysis.styles'
import { theme } from '../theme/theme'
import { FieldItem } from '../components/FieldsListing/Fields.types'
import FieldMap from '../components/FieldMap/FieldMap'
import { SoilAnalysisSummaryProps } from '../components/SoilAnalysis/SoilAnalysis.types'
import { IconSoilAnalysis } from '../icons'

export const SoilAnalysisSummary = () => {
  const [soilSummaryData, setSoilSummaryData] = useState<SoilAnalysisSummaryProps[]>([])
  const [coordinatesList, setCoordinatesList] = useState<[]>([])
  const [field, setField] = useState<FieldItem>()
  const { t } = useTranslation()
  const history = useHistory()
  const { id } = useParams<any>()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const inFieldDetails = searchParams.get('inFieldDetails')

  useEffect(() => {
    API.get(`/getFieldSoilAnalysis/${id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setSoilSummaryData(response.data)
      const coordinates = response.data.map((item: SoilAnalysisSummaryProps) => ({
        id: item.soilDataId,
        coordinates: item.coordinates,
      }))
      const coordinatesFiltered = coordinates.filter((item: any) => item.coordinates !== null)
      setCoordinatesList(coordinatesFiltered)
    })

    API.get(`/fields/${id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setField(response.data)
    })
  }, [])

  const formatDate = (date: string) => {
    const newDate = new Date(date)
    newDate.toLocaleDateString()
    return `${newDate.getMonth() + 1}.${newDate.getDate()}.${newDate.getFullYear()}`
  }

  if (!soilSummaryData || !field) {
    return <Loading />
  }

  return (
    <>
      <h1>{t('general.soilAnalysis')} - {field?.name}</h1>
      <FieldMap fields={[field]} flagsList={coordinatesList} />
      <SimpleBox>
        {soilSummaryData && soilSummaryData.length !== 0 && (
          <>
            <SummaryTable width="100%" cellPadding={0} cellSpacing={0}>
              <tr>
                <th>&nbsp;</th>
                <th><span
                  className="colorIndicator"
                  style={{ backgroundColor: theme.colors.soilCategoryColors.chem }}
                /> {t('soilAnalysis.chemicalHealth')}
                </th>
                <th><span
                  className="colorIndicator"
                  style={{ backgroundColor: theme.colors.soilCategoryColors.phys }}
                /> {t('soilAnalysis.physicalHealth')}
                </th>
                <th><span
                  className="colorIndicator"
                  style={{ backgroundColor: theme.colors.soilCategoryColors.bio }}
                /> {t('soilAnalysis.bioHealth')}
                </th>
              </tr>
              {soilSummaryData.map((item: SoilAnalysisSummaryProps) => (

                <tr
                  onClick={() => history.push(`/SoilAnalysisDetails/${item.soilDataId}`)}
                  onMouseEnter={() => {
                    const targetFlag = document.getElementById(`flag_${item.soilDataId}`)
                    if (targetFlag) {
                      targetFlag.style.transform = 'translate(5px, 4px) scale(2)'
                    }
                  }}
                  onMouseLeave={() => {
                    const targetFlag = document.getElementById(`flag_${item.soilDataId}`)
                    if (targetFlag) {
                      targetFlag.style.transform = 'translate(5px, 4px) scale(1)'
                    }
                  }}
                >
                  <SoftScoreCell data-responseive-title={`${t('general.createdAt')}: `}>
                    {formatDate(item.created_at)}
                  </SoftScoreCell>
                  <SoftScoreCell
                    data-responseive-title={`${t('soilAnalysis.chemicalHealth')}: `}
                    contrastingColor
                    style={{
                      background: theme.colors.progressColors[Math.round(item.chemScore) - 1],
                    }}
                  >
                    {t(`analysis.softScore.chem.${Math.round(item.chemScore)}`)}
                  </SoftScoreCell>
                  <SoftScoreCell
                    data-responseive-title={`${t('soilAnalysis.physicalHealth')}: `}
                    contrastingColor
                    style={{
                      background: theme.colors.progressColors[Math.round(item.physScore) - 1],
                    }}
                  >
                    {t(`analysis.softScore.phys.${Math.round(item.physScore)}`)}
                  </SoftScoreCell>
                  <SoftScoreCell
                    data-responseive-title={`${t('soilAnalysis.bioHealth')}: `}
                    contrastingColor
                    style={{
                      background: theme.colors.progressColors[Math.round(item.bioScore) - 1],
                    }}
                  >
                    {t(`analysis.softScore.bio.${Math.round(item.bioScore)}`)}
                  </SoftScoreCell>
                </tr>

              ))}
            </SummaryTable>
          </>
        )}
        <Button
          variant="contained"
          type="button"
          color="primary"
          fullWidth
          onClick={() => history.push(`/addAnalysisToField/${field?.id}`)}
          style={{ marginTop: theme.space.md }}
          startIcon={<IconSoilAnalysis />}
        >
          {t('createField.addAnalysis')}
        </Button>
      </SimpleBox>

      {!inFieldDetails ? (
        <Button
          variant="contained"
          type="button"
          color="primary"
          onClick={
                          () => {
                            history.push('/soilAnalysisListing')
                          }
                        }
          startIcon={<FontAwesomeIcon icon={faAnglesLeft} />}
          style={{ marginTop: theme.space.md }}
        >
          {t('createField.backToMyFields')}

        </Button>
      ) : (
        <Button
          variant="contained"
          type="button"
          color="primary"
          onClick={
              () => {
                history.push(`/fieldDetails/${field.id}`)
              }
            }
          startIcon={<FontAwesomeIcon icon={faAnglesLeft} />}
          style={{ marginTop: theme.space.md }}
        >
          {t('createField.backToTheField')}

        </Button>
      )}

    </>
  )
}
