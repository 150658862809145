import { breakpoints } from './breakpoints'
import { colors } from './colors'
import { FontSize, fontSizes, fonts } from './fonts'
import { lineHeights } from './lineHeights'
import { mediaQueries } from './mediaQueries'
import { radii } from './radii'
import { shadows } from './shadows'
import { Space, space } from './space'

export const theme = {
  breakpoints,
  colors,
  fonts,
  fontSizes: fontSizes as Record<FontSize, string>,
  lineHeights,
  mediaQueries,
  radii,
  shadows,
  space: space as unknown as Record<Space, number>,
}
