import React from 'react'
import { CropItem } from './CropItem'
import { EffectItem } from './EffectItem'
import { MixFullCropsAndEffectsTypes } from './MixElement.types'
import { FullCropsAndEffectsContainer } from './MixElements.styles'

export const FullCropsAndEffects: React.FC<MixFullCropsAndEffectsTypes> = ({
  mixData, title, effectsData, cropsData,
}) => (
  <>
    <h2>{title}</h2>
    <FullCropsAndEffectsContainer>
      {mixData && cropsData && (
        cropsData.map((item: any, index: any) => (
          <CropItem item={item} isLast={cropsData.length === index + 1} key={`result_${index}`} />
        ))
      )}

      <div className="effectsContainer">
        {mixData && Object.keys(effectsData).map((item: string) => (
          <EffectItem item={item} effectData={effectsData} />
        ))}
      </div>
    </FullCropsAndEffectsContainer>
  </>
)
