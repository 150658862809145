import React, { useEffect, useState, useRef } from 'react'
import {
  Area, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis, Brush,
} from 'recharts'
import * as ls from 'local-storage'
import { useTranslation } from 'react-i18next'
import { API } from '../../providers/API'
import { CustomChartTooltip } from '../WeatherListing/HistoricalWeatherChart.styles'
import { SimpleBox } from '../../App.styles'
import { RangeDatePickers } from './RangeDatePickers'
import { ChartHeader } from './NdviField.styles'
import { SoilHistoryTypes } from './NdviField.types'
import { getFormattedTime } from '../../utils/getFormattedTime'
import { theme } from '../../theme/theme'
import { DegreesSymbol } from '../WeatherListing/DegreesSymbol'
import { Loading } from '../Loading/Loading'
import { InfoNotification } from '../SuccessNotification/SuccessNotification.styles'

const formattedStringDatesData = (fullData: SoilHistoryTypes[]) => {
  // formated date with string dates
  let formattedData = fullData.map((item: SoilHistoryTypes) => ({
    ...item,
    dt: getFormattedTime(new Date(item.dt * 1000)),
  }))

  formattedData = formattedData.filter((item, index, self) => {
    if (item.dt === self[index - 1]?.dt) {
      return false
    }

    return true
  })

  return formattedData
}

const CustomTooltip: React.FC<any> = ({
  active, payload, label,
}) => {
  const { t } = useTranslation()

  if (active && payload && payload.length) {
    return (
      <CustomChartTooltip>
        <strong>{label}</strong>
        <p>
          {`${t('satSoilHistory.t0Short')} : ${payload[0].payload.t0.toFixed(1)}`}<DegreesSymbol />
        </p>
        <p>
          {`${t('satSoilHistory.t10Short')} : ${payload[0].payload.t10.toFixed(1)}`}<DegreesSymbol />
        </p>
        <p>
          {`${t('satSoilHistory.moistureShort')} : ${(payload[0].payload.moisture[1]).toFixed(1)}%`}
        </p>

      </CustomChartTooltip>
    )
  }

  return null
}

export const SoilHistoryChart: React.FC<{fieldId: string}> = ({
  fieldId,
}) => {
  const [fullReferenceData, setFullReferenceData] = useState<SoilHistoryTypes[]>()
  const [formattedData, setFormattedData] = useState<any>()
  const [historyData, setHistoryData] = useState<any>()
  const [startDate, setStartDate] = useState<any>()
  const [endDate, setEndDate] = useState<any>()
  const [allDates, setAllDates] = useState<string[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const { t } = useTranslation()

  const firstRun = useRef(true)

  const formatChartData = (data: any) => {
    // format data for chart
    const chartDataFormated = data.map((item: any) => ({
      day: item.dt,
      t0: item.t0,
      t10: item.t10,
      moisture: [
        0,
        item.moisture * 100,
      ],
    }))

    return chartDataFormated
  }

  const fetchSoilHistory = (startDateParam: string, endDateParam: string) => {
    setLoading(true)
    const soilHistoryParams = {
      fieldId,
      startDate: startDateParam,
      endDate: endDateParam,
    }

    API.post('/fieldSoilHistory', soilHistoryParams, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setFullReferenceData(response.data)
      setFormattedData(formattedStringDatesData(response.data))
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchSoilHistory(
      getFormattedTime(new Date(Date.now()), 94 * 24 * 60 * 60 * 1000),
      getFormattedTime(new Date(Date.now()), 4 * 24 * 60 * 60 * 1000),
    )
  }, [])

  useEffect(() => {
    if (fullReferenceData && formattedData) {
      // get all dates for date picker
      let allDatesData = fullReferenceData.map(
        (item: SoilHistoryTypes) => getFormattedTime(new Date(item.dt * 1000)),
      )

      allDatesData = allDatesData.filter((item: any, index: number, self: any) => {
        if (item === self[index - 1]) {
          return false
        }

        return true
      })

      setAllDates(allDatesData)
      setStartDate(allDatesData[0])
      setEndDate(allDatesData[allDatesData.length - 1])
      setHistoryData(formatChartData(formattedData))
    }
  }, [fullReferenceData, formattedData])

  useEffect(() => {
    if (allDates && startDate && endDate) {
      // get index of start and end date from all dates array
      // and apply slice to full data to use in chart
      const startDateIndex = allDates.indexOf(startDate)
      const endDateIndex = allDates.indexOf(endDate)
      const slicedData = formattedData.slice(startDateIndex, endDateIndex + 1)
      setHistoryData(formatChartData(slicedData))

      if (firstRun.current) {
        firstRun.current = false
        return // skip the rest on first run
      }

      fetchSoilHistory(
        startDate,
        endDate,
      )
    }
  }, [startDate, endDate])

  return (
    <SimpleBox>
      <ChartHeader>
        <h2><span>{t('general.historical')}</span><br />{t('general.soil')}</h2>
        <div>
          {allDates && (
          <RangeDatePickers
          // @ts-ignore
            allDates={allDates}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            ignoreRanges
          />
          )}
        </div>
      </ChartHeader>
      {historyData && historyData.length > 0 && !loading ? (
        <ResponsiveContainer width="100%" height={300} debounce={1}>
          <ComposedChart
            data={historyData}
            margin={{
              top: 20,
              right: 20,
              left: 0,
              bottom: 10,
            }}
          >
            <XAxis dataKey="day" />
            <YAxis yAxisId="moistureAxis" orientation="right" stroke={theme.colors.blueBGUI} />
            <YAxis yAxisId="tempAxis" orientation="left" stroke={theme.colors.barsOrange} />
            <Tooltip content={<CustomTooltip />} />

            <Line
              type="monotone"
              dataKey="t10"
              stroke={theme.colors.barsOrange}
              yAxisId="tempAxis"
            />
            <Line
              type="monotone"
              dataKey="t0"
              stroke={theme.colors.barsGreen}
              yAxisId="tempAxis"
            />
            <Area dataKey="moisture" stroke={theme.colors.blueBGUI} fill={theme.colors.blueBGUILight} yAxisId="moistureAxis" />
            {historyData.length > 100 && (
            <Brush dataKey="day" height={30} stroke="#8884d8" endIndex={100} />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <>
          <Loading />
          <InfoNotification>
            {t('satSoilHistory.longPeriodChartWarning')}
          </InfoNotification>
        </>
      )}
    </SimpleBox>
  )
}
