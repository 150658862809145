import React, { useState, useEffect } from 'react'
import * as ls from 'local-storage'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { IconMyMixes } from '../../icons'
import { API } from '../../providers/API'
import { ZoneListingType } from './ZonesListing.types'

interface FieldProps {
    zone: ZoneListingType
    mixOptions: any
}

interface MixSelectProps {
  label: string
  id: number
}

export const ZoneMixChoice: React.FC<FieldProps> = ({ zone, mixOptions }) => {
  const [selectedMixOption, setSelectedMixOption] = useState<MixSelectProps>()

  useEffect(() => {
    // pre-fill field autocomplete value with already selected one
    // or pre-fill with first in fields array
    if (mixOptions.length > 0) {
      const selectedOption = mixOptions?.filter((item: any) => item.id === zone.currentMix).pop()
      if (selectedOption) {
        setSelectedMixOption(selectedOption)
      } else {
        setSelectedMixOption(mixOptions[0])
      }
    }
  }, [mixOptions])

  if (!selectedMixOption) {
    return null
  }

  return (
    <>
      <IconMyMixes size="24" />
      {mixOptions.length > 0 && selectedMixOption !== undefined && (
      <Autocomplete
        disablePortal
        options={mixOptions}
        defaultValue={selectedMixOption}
        renderInput={(params) => <TextField {...params} />}
        fullWidth
        onChange={(event, newValue: MixSelectProps | null) => {
          const assignMixData = {
            zoneId: zone.id,
            mixId: newValue?.id,
          }
          API.post('/assignMixZone', assignMixData, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } })
        }}
      />
      )}
    </>
  )
}
