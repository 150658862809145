import React, { useEffect, useState } from 'react'
import * as ls from 'local-storage'
import { API } from '../../providers/API'
import { Loading } from '../Loading/Loading'
import { WeatherListingItemDaily } from './WeatherListingItemDaily'
import { SimpleGrid } from '../../App.styles'

interface FieldsItemProps {
  fieldId: string
}

export const DailyWeather: React.FC<FieldsItemProps> = ({
  fieldId,
}) => {
  const [forecastWeather, setForecastWeather] = useState([])
  const [isLoading, setIsLoading] = useState<any>()

  useEffect(() => {
    setIsLoading(true)
    API.get(`/forecastWeatherDaily/${fieldId}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setForecastWeather(response.data)
      setIsLoading(false)
    })
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <SimpleGrid columnsSplit="1fr 1fr 1fr">
      {forecastWeather && forecastWeather.map((item: any, index: number) => (
        <WeatherListingItemDaily
          weatherData={item}
          key={index}
        />
      ))}
    </SimpleGrid>
  )
}
