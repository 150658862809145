import styled from 'styled-components'
import { theme } from '../../../../theme/theme'

export const AccordionItemHeaderContainer = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${theme.space.sm} ${theme.space.sm};
  background: ${theme.colors.agroOrange};
  color: #FFF;
  border: none;
  cursor: pointer;
  border-radius: 5px;

  h3 {
    margin: 0;
  }
  
  svg {
    color: #FFF;
  }
`

export const AccordionItemContent = styled.div<{isOpen: boolean}>(
  ({ isOpen }) => `
    display: ${isOpen ? 'block' : 'none'};
    padding: ${theme.space.sm};

    [class*="MuiRadio-root"] {
        padding: 0;
        margin-right: ${theme.space.xs};
    }

    [class*="MuiFormControlLabel-root"] {
        margin: 0 0 ${theme.space.xs} 0;
    }
  `,
)
