import React, {
  useContext, useEffect, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import * as ls from 'local-storage'

import Button from '@mui/material/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons'

import { FieldItem } from '../components/FieldsListing/Fields.types'
import { API } from '../providers/API'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { Loading } from '../components/Loading/Loading'
import { ProcessButtonsHolder } from '../components/MixCreation/CreateMix.styles'

import { BaseDetails } from '../components/FieldDetails/BaseDetails'
import { FieldServiceData } from '../components/FieldDetails/FIeldServiceData'
import { PageHeadingContainer } from '../components/PageHeading/PageHeading.styles'
import { CropRotation } from '../components/CropRotation/CropRotation'

export const FieldDetails = () => {
  const {
    isLoading, setIsLoading,
  } = useContext(GlobalStateContext)
  const [field, setField] = useState<FieldItem>()

  // @ts-ignore
  const { fieldId } = useParams<number>()
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    setIsLoading(true)
    API.get(`/fields/${fieldId}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setField(response.data)

      setIsLoading(false)
    })
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {field && (

      <>
        <PageHeadingContainer>
          <h1>{field.name}</h1>
          {field.fieldUID && <div>Field UID: {field.fieldUID}</div>}
        </PageHeadingContainer>
        <BaseDetails field={field} />
        <FieldServiceData field={field} />
        <CropRotation fieldId={field.id} />
        <ProcessButtonsHolder>
          <div />
          <Button
            variant="contained"
            type="button"
            color="primary"
            onClick={
              () => {
                history.push('/fields')
              }
            }
            startIcon={<FontAwesomeIcon icon={faAnglesLeft} />}
          >
            {t('createField.backToMyFields')}

          </Button>
        </ProcessButtonsHolder>
      </>
      )}
    </>
  )
}
