import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaintBrush } from '@fortawesome/free-solid-svg-icons'
import { MapDataContext } from '../../../../providers/MapDataProvider'
import { FilterOptionRow } from '../MapOverlayFilters.styles'

export const PolygonsFillToggle = () => {
  const { t } = useTranslation()
  const {
    fieldFill,
    setFieldFill,
  } = useContext(MapDataContext)

  return (
    <FilterOptionRow>
      <Checkbox
        icon={<FontAwesomeIcon icon={faPaintBrush} color="#06C0E2" />}
        checkedIcon={<FontAwesomeIcon icon={faPaintBrush} color="#FFF" />}
        style={{
          borderRadius: '5px',
          padding: '5px',
          backgroundColor: fieldFill ? '#06C0E2' : '#FFF',
        }}
        size="small"
        checked={fieldFill}
        onChange={() => setFieldFill((value) => !value)}
      /> {t('mapOverlay.fieldFill')}
    </FilterOptionRow>
  )
}
