import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { OverlayContainer, SimpleBox } from '../../App.styles'

interface SoilWarningProps {
  setShowWarning: Dispatch<SetStateAction<boolean>>
  showWarning: boolean
  children: React.ReactNode
}

export const SoilWarning: React.FC<SoilWarningProps> = (
  { setShowWarning, showWarning, children },
) => {
  const { t } = useTranslation()

  if (showWarning) {
    return (
      <OverlayContainer>
        <SimpleBox>
          {children}
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={() => {
              setShowWarning(false)
            }}
          >
            {t('general.understand')}
          </Button>
        </SimpleBox>
      </OverlayContainer>
    )
  }

  return null
}
