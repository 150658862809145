import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled'
import { Tab, TabsList } from '../components/WeatherListing/WeatherListing.styles'
import { Profile } from '../components/Account/Profile'
import { Invoices } from '../components/Account/Invoices'
import { Statistics } from '../components/Account/Statistics'

export const Account = () => {
  const { section } = useParams<any>()
  const { t } = useTranslation()

  const activeSection = () => {
    switch (section) {
      case 'profile': {
        const result = 0
        return result
      }
      case 'invoices': {
        const item = 1
        return item
      }
      case 'statistics': {
        const result = 2
        return result
      }
      default:
        return 0
    }
  }

  return (
    <>
      <h1>{t('account.title')}</h1>
      <TabsUnstyled defaultValue={activeSection()}>
        <TabsList>
          <Tab>{t('account.profileData')}</Tab>
          <Tab>{t('account.invoices')}</Tab>
          <Tab>{t('account.statistics')}</Tab>
        </TabsList>
        <TabPanelUnstyled value={0}>
          <Profile />
        </TabPanelUnstyled>
        <TabPanelUnstyled value={1}>
          <Invoices />
        </TabPanelUnstyled>
        <TabPanelUnstyled value={2}>
          <Statistics />
        </TabPanelUnstyled>
      </TabsUnstyled>

    </>
  )
}
