import React, {
  useEffect, useState, useContext, Dispatch, SetStateAction,
} from 'react'
import { useTranslation } from 'react-i18next'
import * as ls from 'local-storage'
import Button from '@mui/material/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { CropRotationResponse, CropRotationItem } from './CropRotation.types'
import { API } from '../../providers/API'
import { Loading } from '../Loading/Loading'
import {
  CropRorationContainer, CropRotationEditFormHolder, ResponsiveTableWrap,
} from './CropRotation.styles'
import { CropRotationRow } from './CropRotationRow'
import {
  CashCropOptionsTypes, CashCropTypes, useCashCropsData, updateCropLabels,
} from '../../utils/useCashCropsData'
import i18n from '../../i18n'
import { CropRotationEditForm } from './CropRotationEditForm'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { InfoNotification } from '../SuccessNotification/SuccessNotification.styles'
import { theme } from '../../theme/theme'

interface CropRotationProps {
  fieldId?: number
  handleCropRotationData?: Dispatch<SetStateAction<CropRotationResponse | undefined>>
}

export const CropRotation = ({ fieldId, handleCropRotationData }: CropRotationProps) => {
  const { t } = useTranslation()
  const { userInfo } = useContext(GlobalStateContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isAddPrevFormOpen, setIsAddPrevFormOpen] = useState<boolean>(false)
  const [isAddNextFormOpen, setIsAddNextFormOpen] = useState<boolean>(false)
  const [cropRotationData, setCropRotationData] = useState<CropRotationResponse | undefined>()
  const [cropDropdownOptions, setCropDropdownOptions] = useState<CashCropOptionsTypes[]>([])
  // eslint-disable-next-line no-undef
  const [availableCrops, setAvailableCrops] = useState<CashCropTypes[]>([])
  const {
    cashCrops,
    cashCropSelectOptions,
  } = useCashCropsData()

  const loadCropRotationData = () => {
    setIsLoading(true)
    API.get(`/cropRotationField/${fieldId}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(({ data }: {data: CropRotationResponse}) => {
      setCropRotationData(data)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    loadCropRotationData()
  }, [fieldId])

  useEffect(() => {
    if (cropRotationData && handleCropRotationData) {
      handleCropRotationData(cropRotationData)
    }
  }, [cropRotationData])

  useEffect(() => {
    if (cashCrops.length === 0 || cashCropSelectOptions.length === 0) return
    setAvailableCrops(cashCrops)
    setCropDropdownOptions(cashCropSelectOptions)
  }, [cashCrops, cashCropSelectOptions])

  const handleReplaceRowData = (
    { newData, cropTypes } : {newData: CropRotationItem, cropTypes: 'nextCrop' | 'previousCrops'},
  ) => {
    let updatedData = {}
    if (Array.isArray(cropRotationData![cropTypes])) {
      // @ts-ignore
      updatedData = cropRotationData![cropTypes].map((item: CropRotationItem) => {
        if (item.id === newData.id) {
          return newData
        }
        return item
      })
    } else {
      updatedData = newData
    }
    setCropRotationData((prev) => ({ ...prev!, [cropTypes]: updatedData }))
  }

  const handleAddPrevItemFormDisplayToggle = () => {
    setIsAddPrevFormOpen((prev) => !prev)
  }

  const handleAddNextItemFormDisplayToggle = () => {
    setIsAddNextFormOpen((prev) => !prev)
  }

  useEffect(() => {
    setCropDropdownOptions(updateCropLabels(availableCrops))
  }, [i18n.language])

  if (cropDropdownOptions.length === 0 || isLoading) {
    return <Loading />
  }

  return (
    <>
      <h2>{t('cropRotation.cropRotation')}</h2>
      <CropRorationContainer>
        <ResponsiveTableWrap>
          <Table sx={{ minWidth: 650, borderCollapse: 'collapse' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('cropRotation.cropName')}</TableCell>
                <TableCell align="center">{t('cropRotation.year')}</TableCell>
                <TableCell align="center">{t('cropRotation.seedingDate')}</TableCell>
                <TableCell align="center">{t('cropRotation.harvestDate')}</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={5} sx={{ backgroundColor: theme.colors.blueBGUIMedium, paddingY: theme.space.sm }}>{t('cropRotation.nextCrop')}</TableCell>
              </TableRow>

              {cropRotationData && cropRotationData.nextCrop ? (
                <CropRotationRow
                  rowData={cropRotationData.nextCrop}
                  key="nextcrop-row-key"
                  cashCropOptions={cropDropdownOptions}
                  replaceRowData={handleReplaceRowData}
                  reloadCropRotationData={loadCropRotationData}
                />
              ) : (
                <>
                  <TableRow>
                    <TableCell colSpan={5} sx={{ padding: 0, border: 'none !important' }}>
                      <InfoNotification marginBottom="0px">{t('cropRotation.noNextCropData')}</InfoNotification>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={5} sx={{ padding: 0, border: 'none !important' }}>
                      <CropRotationEditFormHolder isAddFormOpen={isAddNextFormOpen}>
                        {!isAddNextFormOpen && (
                        <Button
                          variant="contained"
                          type="button"
                          color="primary"
                          endIcon={<FontAwesomeIcon icon={faPlus} />}
                          onClick={handleAddNextItemFormDisplayToggle}
                          style={{ marginTop: theme.space.md, marginBottom: theme.space.md }}
                        >
                          {t('cropRotation.addNextCrop')}
                        </Button>
                        )}
                        {isAddNextFormOpen && (
                        <CropRotationEditForm
                          rowData={null}
                          cropType="nextCrop"
                          cashCropOptions={cropDropdownOptions}
                          reloadCropRotationData={loadCropRotationData}
                          fieldId={fieldId}
                          userId={userInfo.id}
                          toggleAddForm={handleAddNextItemFormDisplayToggle}
                        />
                        )}
                      </CropRotationEditFormHolder>
                    </TableCell>
                  </TableRow>
                </>
              )}
              <TableRow>
                <TableCell colSpan={5} sx={{ backgroundColor: theme.colors.blueBGUIMedium, paddingY: theme.space.sm }}>{t('cropRotation.previousCrops')}</TableCell>
              </TableRow>
              {cropRotationData && cropRotationData.previousCrops.length > 0 ? (
                cropRotationData?.previousCrops.map((row: CropRotationItem) => (
                  <CropRotationRow
                    rowData={row}
                    key={`row-key-${row.id}`}
                    cashCropOptions={cropDropdownOptions}
                    replaceRowData={handleReplaceRowData}
                    reloadCropRotationData={loadCropRotationData}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} sx={{ padding: 0, border: 'none !important' }}>
                    <InfoNotification marginBottom="0px">{t('cropRotation.noPrevCropData')}</InfoNotification>
                  </TableCell>
                </TableRow>

              )}
              <TableRow>
                <TableCell colSpan={5} sx={{ padding: 0, border: 'none !important' }}>
                  <CropRotationEditFormHolder isAddFormOpen={isAddPrevFormOpen}>
                    {!isAddPrevFormOpen && (
                    <Button
                      variant="contained"
                      type="button"
                      color="primary"
                      endIcon={<FontAwesomeIcon icon={faPlus} />}
                      onClick={handleAddPrevItemFormDisplayToggle}
                      style={{ marginTop: theme.space.md, marginBottom: theme.space.md }}
                    >
                      {t('cropRotation.addPrevCrop')}
                    </Button>
                    )}
                    {isAddPrevFormOpen && (
                    <CropRotationEditForm
                      rowData={null}
                      cropType="prevCrop"
                      cashCropOptions={cropDropdownOptions}
                      reloadCropRotationData={loadCropRotationData}
                      fieldId={fieldId}
                      userId={userInfo.id}
                      toggleAddForm={handleAddPrevItemFormDisplayToggle}
                    />
                    )}
                  </CropRotationEditFormHolder>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </ResponsiveTableWrap>
      </CropRorationContainer>
    </>
  )
}
