import styled from 'styled-components'
import { theme } from '../../theme/theme'

export const StatsBox = styled.div`
    display: inline-block;
    margin-right: ${theme.space.lg};
    padding: ${theme.space.md};
    color: ${theme.colors.blueBGUI};
    background-color: #FFF;
    border-radius: 10px;
    break-word: break-word;
    text-align: left;

    svg {
        float: left;
        margin-right: ${theme.space.md};
    }

    .statNumber {
        font-size: 36px;
    }
`
