import React, {
  Dispatch, SetStateAction, useState, useEffect,
} from 'react'
import * as ls from 'local-storage'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faAnglesLeft } from '@fortawesome/free-solid-svg-icons'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { API } from '../../providers/API'
import { FieldMap } from '../FieldMap/FieldMap'
import { ProcessButtonsHolder } from './CreateMix.styles'
import { soilDataRanges } from '../../utils/soilDataRanges'
import { SoilWarning } from './SoilDataWarning'
import { SoilAnalysisSummaryProps } from '../SoilAnalysis/SoilAnalysis.types'
import { Loading } from '../Loading/Loading'
import { SoilGroupsContainer } from '../SoilAnalysis/SoilGroupsContainer/SoilGroupsContainer'
import { theme } from '../../theme/theme'
import { FieldItem } from '../FieldsListing/Fields.types'

interface SoilDataProps {
  setProcessStep?: Dispatch<SetStateAction<number>>
  setStepTitle?: Dispatch<SetStateAction<string>>
  processStep?: number
  fieldValues: any
  formHandleChange: any
  validateForm: any
  formErrors: any
  setFieldValue: any
}

interface ExistingSoilDataSelectProps {
  label: string
  id: number
}

export const SoilData: React.FC<SoilDataProps> = (
  {
    processStep,
    setProcessStep, setFieldValue,
    setStepTitle, fieldValues, formHandleChange, validateForm, formErrors,
  },
) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [soilWarning, setSoilWarning] = useState<any>({})
  const [showWarning, setShowWarning] = useState<boolean>(false)
  const [field, setField] = useState<FieldItem>()
  const [selectedPoint, setSelectedPoint] = useState<number[] | undefined>()
  const [soilSummaryData, setSoilSummaryData] = useState<SoilAnalysisSummaryProps[]>([])
  const [existingAnalysisLoading, setExistingAnalysisLoading] = useState<boolean>(false)
  const [
    existingSoilDataOptions, setExistingSoilDataOptions,
  ] = useState<ExistingSoilDataSelectProps[]>([])

  if (setStepTitle) {
    setStepTitle(t('analysis.analysisTitle'))
  }

  useEffect(() => {
    API.get(`/getFieldSoilAnalysis/${fieldValues.fieldId}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setSoilSummaryData(response.data)

      const existingSoilDataOptionsArray:any = []
      existingSoilDataOptionsArray.push({ label: t('general.choseExistingSoilAnalysis'), id: 0 })
      response.data.forEach((item:SoilAnalysisSummaryProps) => {
        existingSoilDataOptionsArray.push({ label: `${t('general.analysis')} ${item.soilDataId}`, id: item.soilDataId })
      })

      setExistingSoilDataOptions(existingSoilDataOptionsArray)
    })

    API.get(`/fields/${fieldValues.fieldId}  `, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((fieldResponse) => {
      setField(fieldResponse.data)
    })
  }, [])

  useEffect(() => {
    setFieldValue('coordinates', selectedPoint || null)
  }, [selectedPoint])

  const validateSoilRanges = (itemName: string, itemValue: any) => {
    // @ts-ignore
    if (itemValue < soilDataRanges[itemName].minWarning && !formErrors[itemName]) {
      setSoilWarning({
        item: itemName,
        type: 'minWarning',
      })
      setShowWarning(true)
      // @ts-ignore
    } else if (itemValue > soilDataRanges[itemName].maxWarning && !formErrors[itemName]) {
      setSoilWarning({
        item: itemName,
        type: 'maxWarning',
      })
      setShowWarning(true)
    }
  }

  return (
    <>
      {field && (
      <FieldMap
        fields={[field]}
        displayPoint
        allowSetPoint
        staticMap
        eixstingPoint={fieldValues.coordingates || undefined}
        setSelectedPoint={setSelectedPoint}
      />
      )}
      {soilSummaryData.length > 0 && existingSoilDataOptions.length > 0 && (
      <Autocomplete
        disablePortal
        options={existingSoilDataOptions}
        defaultValue={existingSoilDataOptions[0]}
        renderInput={(params) => <TextField {...params} />}
        fullWidth
        onChange={(event, newValue: ExistingSoilDataSelectProps | null) => {
          if (newValue?.id !== 0) {
            setExistingAnalysisLoading(true)
            API.get(`/showSpecific/${newValue?.id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
              const {
                id, ...valuesOnly
              } = response.data
              setFieldValue('analysisId', id)
              Object.keys(valuesOnly).forEach((item:any) => {
                setFieldValue(item, valuesOnly[item])
              })

              setExistingAnalysisLoading(false)
            })
          }
        }}
      />
      )}

      {!existingAnalysisLoading ? (
        <>
          <SoilGroupsContainer
            title={t('soilAnalysis.chemicalHealth')}
            titleColor={theme.colors.soilCategoryColors.chem}
          >
            <div>
              <TextField
                label={t('soilData.pH')}
                name="pH"
                id="pH"
                value={fieldValues.pH}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.pH}
                helperText={formErrors.pH ? formErrors.pH : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.N')}
                name="N"
                id="N"
                value={fieldValues.N}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.N}
                helperText={formErrors.N ? formErrors.N : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.TMN')}
                name="TMN"
                id="TMN"
                value={fieldValues.TMN}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.TMN}
                helperText={formErrors.TMN ? formErrors.TMN : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.P')}
                name="P"
                id="P"
                value={fieldValues.P}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.P}
                helperText={formErrors.P ? formErrors.P : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.K')}
                name="K"
                id="K"
                value={fieldValues.K}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.K}
                helperText={formErrors.K ? formErrors.K : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.S')}
                name="S"
                id="S"
                value={fieldValues.S}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.S}
                helperText={formErrors.S ? formErrors.S : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.Mg')}
                name="Mg"
                id="Mg"
                value={fieldValues.Mg}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.Mg}
                helperText={formErrors.Mg ? formErrors.Mg : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.Ca')}
                name="Ca"
                id="Ca"
                value={fieldValues.Ca}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.Ca}
                helperText={formErrors.Ca ? formErrors.Ca : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.B')}
                name="B"
                id="B"
                value={fieldValues.B}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.B}
                helperText={formErrors.B ? formErrors.B : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.Cu')}
                name="Cu"
                id="Cu"
                value={fieldValues.Cu}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.Cu}
                helperText={formErrors.Cu ? formErrors.Cu : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.Fe')}
                name="Fe"
                id="Fe"
                value={fieldValues.Fe}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.Fe}
                helperText={formErrors.Fe ? formErrors.Fe : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.Mn')}
                name="Mn"
                id="Mn"
                value={fieldValues.Mn}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.Mn}
                helperText={formErrors.Mn ? formErrors.Mn : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.Zn')}
                name="Zn"
                id="Zn"
                value={fieldValues.Zn}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.Zn}
                helperText={formErrors.Zn ? formErrors.Zn : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.Carbonates')}
                name="Carbonates"
                id="Carbonates"
                value={fieldValues.Carbonates}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.Carbonates}
                helperText={formErrors.Carbonates ? formErrors.Carbonates : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.EC')}
                name="EC"
                id="EC"
                value={fieldValues.EC}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.EC}
                helperText={formErrors.EC ? formErrors.EC : ''}
              />
            </div>
          </SoilGroupsContainer>

          <SoilGroupsContainer
            title={t('soilAnalysis.physicalHealth')}
            titleColor={theme.colors.soilCategoryColors.phys}
          >
            <div>
              <TextField
                label={t('soilData.sand')}
                name="sand"
                id="sand"
                value={fieldValues.sand}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.sand}
                helperText={formErrors.sand ? formErrors.sand : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.silt')}
                name="silt"
                id="silt"
                value={fieldValues.silt}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.silt}
                helperText={formErrors.silt ? formErrors.silt : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.clay')}
                name="clay"
                id="clay"
                value={fieldValues.clay}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.clay}
                helperText={formErrors.clay ? formErrors.clay : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.bulkDensity')}
                name="bulkDensity"
                id="bulkDensity"
                value={fieldValues.bulkDensity}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.bulkDensity}
                helperText={formErrors.bulkDensity ? formErrors.bulkDensity : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.aggregateStability')}
                name="aggregateStability"
                id="aggregateStability"
                value={fieldValues.aggregateStability}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.aggregateStability}
                helperText={formErrors.aggregateStability ? formErrors.aggregateStability : ''}
              />
            </div>
          </SoilGroupsContainer>

          <SoilGroupsContainer
            title={t('soilAnalysis.bioHealth')}
            titleColor={theme.colors.soilCategoryColors.bio}
          >
            <div>
              <TextField
                label={t('soilData.mineralizableNitrogen')}
                name="mineralizableNitrogen"
                id="mineralizableNitrogen"
                value={fieldValues.mineralizableNitrogen}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.mineralizableNitrogen}
                helperText={formErrors.mineralizableNitrogen ? formErrors.mineralizableNitrogen : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.hummus')}
                name="hummus"
                id="hummus"
                value={fieldValues.hummus}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.hummus}
                helperText={formErrors.hummus ? formErrors.hummus : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.organicCarbon')}
                name="organicCarbon"
                id="organicCarbon"
                value={fieldValues.organicCarbon}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.organicCarbon}
                helperText={formErrors.organicCarbon ? formErrors.organicCarbon : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.soilRespiration')}
                name="soilRespiration"
                id="soilRespiration"
                value={fieldValues.soilRespiration}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.soilRespiration}
                helperText={formErrors.soilRespiration ? formErrors.soilRespiration : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.activeCarbon')}
                name="activeCarbon"
                id="activeCarbon"
                value={fieldValues.activeCarbon}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.activeCarbon}
                helperText={formErrors.activeCarbon ? formErrors.activeCarbon : ''}
              />
            </div>
            <div>
              <TextField
                label={t('soilData.TOCTN')}
                name="TOCTN"
                id="TOCTN"
                value={fieldValues.TOCTN}
                onChange={formHandleChange}
                onBlur={(e: any) => {
                  validateForm()
                  validateSoilRanges(e.target.name, e.target.value)
                }}
                fullWidth
                error={formErrors?.TOCTN}
                helperText={formErrors.TOCTN ? formErrors.TOCTN : ''}
              />
            </div>
          </SoilGroupsContainer>

          {showWarning && (
          <SoilWarning setShowWarning={setShowWarning} showWarning={showWarning}>
            <p>{t(`soilRangeMessages.${soilWarning.type}.${soilWarning.item}`)}</p>
          </SoilWarning>
          )}
          <ProcessButtonsHolder>

            <Button
              variant="contained"
              type="submit"
              color="primary"
              endIcon={<FontAwesomeIcon icon={faAnglesRight} />}
            >
              {processStep ? (
                t('general.nextStep')
              ) : (
                t('createField.saveAnalysis')
              )}

            </Button>

            {processStep ? (
              <Button
                variant="contained"
                type="button"
                color="primary"
                onClick={
              () => {
                if (setProcessStep && processStep) {
                  setProcessStep(processStep - 1)
                }
              }
              }
                startIcon={<FontAwesomeIcon icon={faAnglesLeft} />}
              >
                {t('general.prevStep')}
              </Button>
            ) : (
              <Button
                variant="contained"
                type="button"
                color="primary"
                onClick={
              () => {
                history.push(`/fieldDetails/${fieldValues.fieldId}`)
              }
              }
                startIcon={<FontAwesomeIcon icon={faAnglesLeft} />}
              >
                {t('createField.backToTheField')}
              </Button>
            )}
          </ProcessButtonsHolder>
        </>
      ) : (
        <Loading />
      )}
    </>
  )
}
