import React, {
  Dispatch, createContext, useState, SetStateAction,
} from 'react'

interface AgromonContextProps {
  selectedDate: number | undefined,
  setSelectedDate: Dispatch<SetStateAction<number | undefined>>,
  dateItemSelector: string | undefined,
  setDateItemSelector: Dispatch<SetStateAction<string | undefined>>,
}

export const AgromonContext = createContext<AgromonContextProps>({
  selectedDate: undefined,
  setSelectedDate: () => {},
  dateItemSelector: undefined,
  setDateItemSelector: () => {},
})

export const AgromonStateProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [selectedDate, setSelectedDate] = useState<number>()
  const [dateItemSelector, setDateItemSelector] = useState<string>()

  return (
    <AgromonContext.Provider value={{
      selectedDate,
      setSelectedDate,
      dateItemSelector,
      setDateItemSelector,
    }}
    >
      {children}
    </AgromonContext.Provider>
  )
}
