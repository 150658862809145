import React, { useState } from 'react'
import * as ls from 'local-storage'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faAnglesLeft } from '@fortawesome/free-solid-svg-icons'
import Button from '@mui/material/Button'
import { SoilData } from '../components/MixCreation/SoilData'
import { API } from '../providers/API'
import { SuccessNotification } from '../components/SuccessNotification/SuccessNotification.styles'
import { SimpleBox } from '../App.styles'
import { ProcessButtonsHolder } from '../components/MixCreation/CreateMix.styles'

export const AddAnalysisToField = () => {
  const [addAnalysisSuccess, setAddAnalysisSuccess] = useState<boolean>(false)

  const history = useHistory()
  const { t } = useTranslation()
  const { fieldId } = useParams<any>()

  const ValidationSchema = Yup.object().shape({
    pH: Yup.number().required(t('createMix.errors.noValue')).min(1, `${t('createMix.errors.valueMustBeMin')} 1`).max(13, `${t('createMix.errors.valueMustBeMax')} 13`),
    N: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(10, `${t('createMix.errors.valueMustBeMax')} 10`),
    TMN: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 1`).max(200, `${t('createMix.errors.valueMustBeMax')} 200`),
    P: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(500, `${t('createMix.errors.valueMustBeMax')} 500`),
    K: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(2000, `${t('createMix.errors.valueMustBeMax')} 2000`),
    Ca: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(50000, `${t('createMix.errors.valueMustBeMax')} 50000`),
    Mg: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(2000, `${t('createMix.errors.valueMustBeMax')} 2000`),
    S: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(100, `${t('createMix.errors.valueMustBeMax')} 100`),
    B: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(20, `${t('createMix.errors.valueMustBeMax')} 20`),
    Cu: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(50, `${t('createMix.errors.valueMustBeMax')} 50`),
    Fe: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(2000, `${t('createMix.errors.valueMustBeMax')} 2000`),
    Mn: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(800, `${t('createMix.errors.valueMustBeMax')} 800`),
    Zn: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(1000, `${t('createMix.errors.valueMustBeMax')} 1000`),
    EC: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(2000, `${t('createMix.errors.valueMustBeMax')} 2000`),
    Carbonates: Yup.number().required('Моля въведете истойностме').min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(100, `${t('createMix.errors.valueMustBeMax')} 100`),
    bulkDensity: Yup.number().required(t('createMix.errors.noValue')).min(0.1, `${t('createMix.errors.valueMustBeMin')} 0.1`).max(2, `${t('createMix.errors.valueMustBeMax')} 2`),
    aggregateStability: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(100, `${t('createMix.errors.valueMustBeMax')} 100`),
    mineralizableNitrogen: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(100, `${t('createMix.errors.valueMustBeMax')} 100`),
    organicCarbon: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(25, `${t('createMix.errors.valueMustBeMax')} 25`),
    hummus: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(35, `${t('createMix.errors.valueMustBeMax')} 35`),
    soilRespiration: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(20, `${t('createMix.errors.valueMustBeMax')} 20`),
    sand: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(100, `${t('createMix.errors.valueMustBeMax')} 100`),
    silt: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(100, `${t('createMix.errors.valueMustBeMax')} 100`),
    clay: Yup.number().required(t('createMix.errors.noValue')).min(0, `${t('createMix.errors.valueMustBeMin')} 0`).max(100, `${t('createMix.errors.valueMustBeMax')} 100`),
  })

  return (
    <>
      <h1>{t('createField.addAnalysis')}</h1>
      {!addAnalysisSuccess ? (
        <Formik
          initialValues={{
            fieldId,
            pH: '8.17',
            N: '8.12',
            TMN: '9.26',
            P: '15.41',
            K: '176.03',
            Ca: '6012.11',
            Mg: '308.11',
            S: '10.55',
            B: '1.04',
            Cu: '0.72',
            Fe: '6.97',
            Mn: '2.78',
            Zn: '0.24',
            EC: '114.65',
            Carbonates: '3',
            bulkDensity: '1.17',
            aggregateStability: '76.55',
            mineralizableNitrogen: '11.28',
            organicCarbon: '1.6',
            hummus: '2.76',
            soilRespiration: '0.11',
            activeCarbon: '300',
            TOCTN: '50',
            sand: '12.16',
            silt: '64.71',
            clay: '23.13',
            coordinates: null,
          }}
          validationSchema={ValidationSchema}
          enableReinitialize
          onSubmit={(values) => {
            API.post('/fieldSoilData', { ...values }, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(() => {
              setAddAnalysisSuccess(true)
            })
          }}
        >
          {({
            values, handleSubmit, setFieldValue, handleChange, validateForm, errors,
          }) => (
            <form onSubmit={handleSubmit}>
              <SoilData
                fieldValues={values}
                formHandleChange={handleChange}
                validateForm={validateForm}
                formErrors={errors}
                setFieldValue={setFieldValue}
              />
            </form>
          )}
        </Formik>
      ) : (
        <>

          <SimpleBox>
            <SuccessNotification>{t('createField.addAnalysisSuccess')}</SuccessNotification>
            Можете да проверите състоянието на почвите ви в меню Почвени данни

            <ProcessButtonsHolder>

              <Button
                variant="contained"
                type="button"
                color="primary"
                endIcon={<FontAwesomeIcon icon={faAnglesRight} />}
                onClick={() => {
                  history.push('/soilAnalysisListing')
                }}
              >

                {t('navigation.soilAnalysis')}

              </Button>

              <Button
                variant="contained"
                type="button"
                color="primary"
                onClick={
              () => {
                history.push(`/fieldDetails/${fieldId}`)
              }
              }
                startIcon={<FontAwesomeIcon icon={faAnglesLeft} />}
              >
                {t('createField.backToTheField')}
              </Button>

            </ProcessButtonsHolder>
          </SimpleBox>
        </>
      )}
    </>
  )
}
