import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { MapDataContext } from '../../../../providers/MapDataProvider'
import { AccordionItemHeaderContainer } from './AccordionElements.styles'

type AccordionItemValueOptions = 'ndvi' | 'soilData' | 'weatherData' | 'crops' | 'zones'

export const AccordionItemHeader = ({ title, value, type }: {title: string, value: AccordionItemValueOptions, type: 'filter' | 'data'}) => {
  const {
    activeFilterSection, setActiveFilterSection, activeDataSection, setActiveDataSection,
  } = useContext(MapDataContext)

  const handleClick = () => {
    const valueToSet = (type === 'filter' && value === activeFilterSection) || (type === 'data' && value === activeDataSection) ? '' : value
    if (type === 'filter') {
      setActiveFilterSection(valueToSet)
    } else {
      setActiveDataSection(valueToSet)
    }
  }

  return (
    <AccordionItemHeaderContainer onClick={handleClick}>
      <h3>{title}</h3>
      <FontAwesomeIcon icon={value === activeFilterSection
         || value === activeDataSection ? faChevronDown : faChevronRight}
      />
    </AccordionItemHeaderContainer>
  )
}
