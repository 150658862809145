import React, { useState, useEffect, useContext } from 'react'
import * as ls from 'local-storage'
import { useTranslation } from 'react-i18next'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { API } from '../../providers/API'
import { ZoneListingType } from './ZonesListing.types'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { FieldItem } from '../FieldsListing/Fields.types'
import { InfoNotification } from '../SuccessNotification/SuccessNotification.styles'
import { Loading } from '../Loading/Loading'

interface FieldSelectProps {
  label: string
  id: number
}

interface AddFieldZoneChoiceProps {
  zone: ZoneListingType
  setZoneFields: any
  setShowFieldChoice: any
}

export const AddFieldChoice = (
  { zone, setZoneFields, setShowFieldChoice }: AddFieldZoneChoiceProps,
) => {
  const {
    userInfo,
  } = useContext(GlobalStateContext)
  const [fieldOptions, setFieldOptions] = useState<FieldSelectProps[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { t } = useTranslation()

  useEffect(() => {
    setIsLoading(true)
    API.get(`/fields/search/${userInfo.id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } })
      .then((response) => {
        const fieldOptionsArray:any = []
        response.data.forEach((item:FieldItem) => {
          fieldOptionsArray.push({ label: item.name, id: item.id })
        })

        setFieldOptions(fieldOptionsArray)
        setIsLoading(false)
      })
  }, [])

  const handleChooseField = (newValue: FieldSelectProps | null) => {
    const assignFieldData = {
      id: zone.id,
      fieldIds: newValue?.id,
    }
    API.post('/personalZoneAddField', assignFieldData, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setZoneFields(response.data.containedFields)
      setShowFieldChoice(false)
    })
  }

  if (!fieldOptions || isLoading) {
    return <Loading />
  }

  return (
    <>
      {fieldOptions.length > 0 ? (
        <>
          <label htmlFor="chooseField">{ t('general.chooseField')}</label>
          <Autocomplete
            disablePortal
            options={fieldOptions}
            defaultValue={fieldOptions[0]}
            renderInput={(params) => <TextField {...params} />}
            fullWidth
            onChange={(event, newValue: FieldSelectProps | null) => {
              handleChooseField(newValue)
            }}
            id="chooseField"
          />
        </>
      ) : (
        <InfoNotification>{t('general.noFields')}</InfoNotification>
      )}
    </>
  )
}
