import styled, { css } from 'styled-components'
import { theme } from '../../theme/theme'

export const TopContainer = styled.div`
    display: flex;
    min-height: 430px;

    

    ${theme.mediaQueries.lgMax} {
        min-height: 310px;
    }

    ${theme.mediaQueries.smMax} {
        display: block;
        min-height: 1px;
    }
`

export const MapContainer = styled.div<{containerMargin?: boolean}>(
  ({ containerMargin }) => css`
  position: relative;
    flex-grow: 1;
    border: 3px solid ${theme.colors.blueUI};
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: ${containerMargin ? theme.space.md : 0};

    ${theme.mediaQueries.smMax} {
        height: 200px;
    }
`,
)
export const FieldItemRow = styled.div<{blueBG?: boolean, fullWidth?: boolean}>(
  ({ blueBG, fullWidth }) => css`
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding-right: ${theme.space.md};
    margin-bottom: ${theme.space.sm};
    width: ${fullWidth ? '100%' : 'auto'};
    
    background: ${blueBG ? theme.colors.blueBGUILight : '#FFF'};
    color: #2C2C2C;
    font-weight: bold;
    transition: all 0.2s ease-in;

    &[role="button"] {
        cursor: pointer;

        &:hover {
            box-shadow: 0px 5px 8px rgb(6 192 226 / 30%);
        }
    }

    
    
    & .svgHolder {
        display: flex;
        justify-content: center;
        height: 70px;
        width: 90px;
        border-radius: 10px 0 0 10px;
        padding: ${theme.space.sm};
        box-sizing: border-box;
        background: ${theme.colors.orangeUI};
        flex-shrink: 0;
    }

    & .name {
        padding: 0 0 0 ${theme.space.md};
        flex-grow: 1;
        word-break: break-all;
    }

    .isMainField {
        font-size: 0.9em;
    }

    & .area {
        color: ${theme.colors.blueBGUI};
        display: flex;
        align-items: center;
        padding: 0 0 0 ${theme.space.md};

        img {
            margin-right: ${theme.space.sm};
        }
    }
    `,
)

export const FieldGridItem = styled.div<{notClickable?: boolean}>(
  ({ notClickable = false }) => css`
    position: relative;
    background: #FFF;
    border-radius: 10px;
    padding: ${theme.space.sm};
    cursor: ${notClickable ? 'default' : 'pointer'};

    ${FieldItemRow} {
        margin: 0 0 ${theme.space.sm} 0;
        background: ${theme.colors.blueBGUILight};
    }

    .fieldStats {
        padding: ${theme.space.sm};
        color: ${theme.colors.blueBGUI};
        display: flex;
        align-items: center;

        .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
            border-color: transparent !important;
            color: ${theme.colors.blueBGUI} !important;
        }

        .MuiOutlinedInput-root input {
            color: ${theme.colors.blueBGUI} !important;
        }

        .MuiOutlinedInput-root.MuiInputBase-root {
            padding-left: 0 !important;

            input {
                padding-left: 0 !important;
            }
        }

        .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root {
            margin: 0;
            
        }

        .MuiAutocomplete-endAdornment > .MuiIconButton-root svg {
            color: ${theme.colors.blueBGUI} !important;
            width: 1.4em;
            height: 1.4em;
            margin: 0 !important;
        }

        .MuiAutocomplete-endAdornment {
            top: calc(50% - 17px) !important;
        }

        &:nth-child(odd) {
            background: ${theme.colors.blueBGUILight};
        }

        svg {
            margin-right: ${theme.space.md};
        }
    }
    `,
)
