import React, { useContext } from 'react'
import { faCloud } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TimelineItemHolder } from './NdviField.styles'
import { AgromonData } from './NdviField.types'
import { AgromonContext } from '../../providers/AgromonStateProvider'

export const TimelineItem: React.FC<{itemData: AgromonData, isFirstItem: boolean}> = ({
  itemData, isFirstItem,
}) => {
  const { setSelectedDate, dateItemSelector, setDateItemSelector } = useContext(AgromonContext)
  const date = new Date(itemData.dt * 1000)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  const handleDateSelection = () => {
    setSelectedDate(itemData.dt)
    setDateItemSelector(`date_${day}-${month}-${year}`)
  }

  return (
    <TimelineItemHolder
      type="button"
      className={isFirstItem ? 'timelineFirstItem' : ''}
      onClick={handleDateSelection}
      id={`date_${day}-${month}-${year}`}
      data-timestamp={itemData.dt}
      isSelected={dateItemSelector === `date_${day}-${month}-${year}`}
    >
      <span className="date">{day}.{month}<br />{year}</span>
      <span className="cloudCoverageContainer">
        <FontAwesomeIcon icon={faCloud} color="#FFF" /> {itemData.cl.toFixed(0)}%
      </span>
    </TimelineItemHolder>
  )
}
